<h5>{{ 'settings.ipstAlertNowSettings.ipstSettings' | translate }}</h5>
<form [formGroup]="form">
  <div class="three-columns">
    <div class="ipst-switch-container">
      <ds-switch
        class="ipst-switch ps-3x"
        formControlName="enabled"
        data-cy="IpstWorkAreaSettingEnabledInput">
        {{ 'settings.ipst.workingAreaSettings.ipst' | translate }}
      </ds-switch>
      <ds-hint class="ipst-switch-hint">{{
        'settings.ipstAlertNowSettings.ipstSettingsHint' | translate
      }}</ds-hint>
    </div>
    <div>
      <ds-form-field class="mb-3x">
        <label ds-label for="akz">{{
          'settings.ipstAlertNowSettings.workingAreaSettings.akz' | translate
        }}</label>
        <input ds-input ds-form-validation id="akz" formControlName="akz" data-cy="AkzInput" />
      </ds-form-field>
    </div>
    <div>
      <ds-form-field class="mb-3x">
        <label ds-label for="ErrorLanguage">
          {{ 'settings.ipstAlertNowSettings.workingAreaSettings.errorLanguage' | translate }}</label
        >
        <ds-select
          type="text"
          [isFilterable]="false"
          ds-form-validation
          appendTo="body"
          [options]="'ErrorLanguage' | dsEnumDropdown"
          formControlName="errorLanguage"
          id="errorLanguage"
          data-cy="errorLanguage"></ds-select>
      </ds-form-field>
    </div>
  </div>
</form>
<hr />
