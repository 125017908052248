/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorForwardingService } from 'core/api-services';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import * as ErrorForwardingActions from '../actions/error-forwarding.actions';

@Injectable()
export class ErrorForwardingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly errorForwardingService: ErrorForwardingService
  ) {}

  loadFleetErrorForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorForwardingActions.loadErrorForwarding),
      concatMap(() =>
        this.errorForwardingService.getErrorForwardings().pipe(
          map(errorForwardings =>
            ErrorForwardingActions.loadErrorForwardingSuccess({ errorForwardings })
          ),
          catchError(errorMessage =>
            of(ErrorForwardingActions.loadErrorForwardingFailure({ errorMessage }))
          )
        )
      )
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ErrorForwardingActions.loadErrorForwardingFailure,
          ErrorForwardingActions.saveErrorForwardingFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  saveErrorForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorForwardingActions.saveErrorForwarding),
      concatMap(({ errorForwardingModel }) =>
        this.errorForwardingService.saveErrorForwarding(errorForwardingModel).pipe(
          map(() => {
            return ErrorForwardingActions.saveErrorForwardingSuccess({
              errorForwardingModel: errorForwardingModel,
            });
          }),
          catchError(errorMessage =>
            of(ErrorForwardingActions.saveErrorForwardingFailure({ errorMessage }))
          )
        )
      )
    )
  );

  saveErrorForwardingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorForwardingActions.saveErrorForwardingSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.actions.saveErrorForwardingSuccess');
        })
      ),
    { dispatch: false }
  );

  saveErrorForwardingFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorForwardingActions.saveErrorForwardingFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.actions.saveErrorForwardingFailure');
        })
      ),
    { dispatch: false }
  );
}
