<div class="flex-stretch-vertically">
  <div class="page-header wrap-reverse" *ngIf="pageHeading || hasToolbar">
    <h2 data-cy="pageHeading" [attr.data-translatekey]="pageHeading">
      {{ pageHeading | translate }}
    </h2>
    <app-tool-bar *ngIf="hasToolbar" [showItems]="showToolbarItems">
      <ng-content select="[tool-bar-content]"></ng-content>
    </app-tool-bar>
  </div>
  <div
    class="body-area"
    [class.edit]="(isEditMode$ | async) && !isNewLayout"
    [class.view]="(isEditMode$ | async) !== true && !isNewLayout"
    [class.editView]="isNewLayout">
    <ng-content select="[base-page-content]"></ng-content>
  </div>
</div>
