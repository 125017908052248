import { DirectedZoneDto } from 'core/dtos';
import {
  CheckboxModel,
  DateString,
  IZoneVertexMapItem,
  MapItem,
  VehicleFilterType,
  Zone,
} from 'core/models';
import { isDate } from 'lodash';

export function isDirectedZone(zone: Zone): zone is DirectedZoneDto {
  return 'orientation' in zone;
}

export function isZoneVertex(zone: MapItem | IZoneVertexMapItem): zone is IZoneVertexMapItem {
  return 'index' in zone;
}

export function isVehicleFilterType(filter: CheckboxModel[] | object): filter is VehicleFilterType {
  return 'path' in filter && 'name' in filter && 'pathSegments' in filter;
}

export function isDateString(value: object | number | string): value is DateString {
  if (typeof value === 'string' && value !== '') {
    const date = new Date(value);
    return isDate(date);
  }
  return false;
}
