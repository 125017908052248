import { BitmapText, Graphics } from 'pixi.js';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { TuggerDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { TuggerTrainMapItem } from '../vehicle-types/tuggertrain.graphic';

const dimensions: TuggerDimensions = {
  length: 186.7,
  width: 103.8,
  origin: 52,
  trailer: {
    length: 140,
    width: 101,
    barLength: 48,
    origin: 70,
  },
};

export class TuggerTrainManualMapItem extends TuggerTrainMapItem {
  protected tag: BitmapText | undefined;

  protected getDimensions(): VehicleDimensions {
    return dimensions;
  }

  protected createVehicleBackground(): Graphics {
    this.tag = VehicleGraphicHelper.createTag(`M`);

    return VehicleGraphicHelper.createVehicleBaseWithTag(this.dimensions, this.tag);
  }

  protected setOtherOrientation(relativeOrientation: number): void {
    if (this.tag) {
      const rad = VehicleGraphicHelper.calculateTagRotation(relativeOrientation);

      if (this.tag.rotation !== rad) this.tag.rotation = rad;
    }
  }
}
