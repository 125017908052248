import { createSelector } from '@ngrx/store';
import { selectVehicleEntities } from 'store-modules/vehicles-store/selectors/vehicles.selectors';
import { selectAllMaps } from './maps.selectors';
import { selectNavigationLayerEntities } from './navigation-layer.selectors';
import { selectAllVehicleMapData } from './vehicle-map-data-selectors';
import { convertToVehicleDataListItem } from './vehicle-map-data.helpers';

export const selectVehicleMapDataForList = createSelector(
  selectAllVehicleMapData,
  selectAllMaps,
  selectNavigationLayerEntities,
  selectVehicleEntities,
  (vehicleMapData, maps, navigationLayers, vehicles) => {
    return convertToVehicleDataListItem(vehicleMapData, maps, navigationLayers, vehicles);
  }
);
