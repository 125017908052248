import { MapDto, PoiDto, PoiOccupancy } from 'core/dtos';
import {
  DateString,
  GuidString,
  LoadType,
  PoiDeviceOccupancy,
  PoiGroupStrategy,
  PoiType,
  Pose2D,
} from 'core/models';
import { ReducedVehicle } from '../vehicles/vehicles.models';
export interface DeviceNodeValue {
  device: string | null;
  node: string | null;
  value: string | null;
}

export function isPoiWithLoadType(poi: Poi): boolean {
  return (
    [
      PoiType.ParkingSpace,
      PoiType.ChargingStation,
      PoiType.AssignableWaypoint,
      PoiType.WayPoint,
    ].find(x => x === poi.type) === undefined
  );
}

export interface Poi {
  id: GuidString;
  poiGroupId?: GuidString;
  name: string;
  type: PoiType;
  mainPose: Pose2D;
  entryVectorDistance: number | null;
  exitVectorDistance: number | null;
  isCloseToWall: boolean | null;
  poseAccuracy: number | null;
  mapId: GuidString;
  map?: MapDto;
  occupancy?: PoiOccupancy;
  occupancyDevice?: DeviceNodeValue | null;
  deviceOccupancy?: PoiDeviceOccupancy;
  booked: boolean;
  bookedDateTime?: DateString;
  bookedVehicleId?: GuidString;
  loadType: LoadType;
}

export interface MapFormPoi extends PoiDto {
  vehicle: ReducedVehicle | undefined;
  isDefined?: boolean;
}

export interface PoiGroup {
  id: GuidString;
  name: string;
  mapId: GuidString;
  pointsOfInterest: PoiDto[];
  strategy: PoiGroupStrategy;
  nextPointOfInterestIdToUse?: GuidString;
  bufferPlaces?: PoiDto[];
  map?: MapDto;
}

export interface PoiGroupTreeTable {
  id?: GuidString;
  name?: string;
  mapId?: GuidString;
  children?: PoiTreeTable[];
}

export interface PoiTreeTable extends PoiGroupTreeTable {
  poiGroupId?: GuidString;
  mapName: string;
  strategy: number;
  isNextPoi: boolean;
  type: number;
  loadType: LoadType;
  occupancy?: number;
  booked: number;
  deviceOccupancy?: number;
  isSensorMonitored: boolean;
}

export interface PoiGroupGridModel {
  id?: GuidString;
  tmId?: string;
  name?: string;
  mapId?: GuidString;
  mapName?: string;
  poiName?: string;
  type?: number;
  loadType?: LoadType;
  occupancy?: number;
  booked?: number;
  strategy?: number;
  poiMonitoring?: string;
  isNextPoi?: boolean;
  deviceOccupancy?: number;
  isSensorMonitored?: boolean;
}

export interface MapWithPoiGroups extends MapDto {
  poiGroups?: PoiGroup[];
}

export interface NodeGroupGridModel {
  id: GuidString;
  name: string;
  nodeNames: string;
  nextNode: string;
  nodeGroupType: string;
  decisionPoint: string;
}
