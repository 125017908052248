export enum VehicleConnectionHealth {
  Default = 'default',
  Cautious = 'caution',
  Critical = 'critical',
}

export function determineVehicleConnectionHealth(percentage: number): VehicleConnectionHealth {
  const CRITICAL_THRESHOLD = 80;
  const CAUTION_THRESHOLD = 90;
  if (percentage >= CRITICAL_THRESHOLD && percentage < CAUTION_THRESHOLD) {
    return VehicleConnectionHealth.Cautious;
  }
  if (percentage < CRITICAL_THRESHOLD) {
    return VehicleConnectionHealth.Critical;
  }

  return VehicleConnectionHealth.Default;
}
