/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { BoxToneOptions } from '@bmw-ds/components/ds-interfaces/box.interface';
import { ButtonVariant } from '@bmw-ds/components/ds-interfaces/button/button.types';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';

import { ConfirmModal } from '../confirm-modal/confirm-modal.model';

@Injectable({
  providedIn: 'root',
})
export class ModalDialogService {
  private readonly modalIsOpen = new Subject<boolean>();
  modalIsOpen$ = this.modalIsOpen.asObservable();

  private readonly isConfirmed = new Subject<boolean>();
  isConfirmed$ = this.isConfirmed.asObservable();

  private readonly modalData = new BehaviorSubject<ConfirmModal>({
    headerText: '',
    messageText1: '',
    messageText2: '',
    cancelText: '',
    confirmText: '',
    buttonVariant: '',
  });
  modalData$ = this.modalData.asObservable();

  constructor(protected readonly translateService: AtsTranslationService) {}

  confirmModalClose(isConfirmed: boolean): void {
    this.isConfirmed.next(isConfirmed);
  }

  createModal(
    translateKey: string,
    data?: object,
    accentBarTone: BoxToneOptions = 'default'
  ): Observable<boolean> {
    this.modalIsOpen.next(true);
    this.createDataForModal(translateKey, data, true, accentBarTone);
    return this.isConfirmed$.pipe(take(1));
  }

  createInfoModal(translateKey: string, data?: object): Observable<boolean> {
    this.modalIsOpen.next(true);
    this.createDataForModal(translateKey, data, false);
    return this.isConfirmed$.pipe(take(1));
  }

  createDeleteModal(translateKey: string, data?: object): Observable<boolean> {
    this.modalIsOpen.next(true);
    this.createDataForModal(translateKey, data, true, 'critical', 'destructive');
    return this.isConfirmed$.pipe(take(1));
  }

  createWarningModal(translateKey: string, data?: object): Observable<boolean> {
    this.modalIsOpen.next(true);
    this.createDataForModal(translateKey, data, false, 'critical');
    return this.isConfirmed$.pipe(take(1));
  }

  protected createDataForModal(
    translateKey: string,
    data?: object,
    showCancelButton = true,
    accentBarTone: BoxToneOptions = 'default',
    buttonVariant?: ButtonVariant
  ): void {
    this.modalData.next({
      headerText: this.translateService.get(`${translateKey}.headerText`),
      messageText1: this.translateService.get(`${translateKey}.messageText1`, data),
      messageText2: this.translateService.get(`${translateKey}.messageText2`, data),
      cancelText: showCancelButton ? this.translateService.get(`${translateKey}.cancelText`) : '',
      confirmText: this.translateService.get(`${translateKey}.confirmText`),
      accentBarTone,
      buttonVariant: buttonVariant,
    });
  }
}
