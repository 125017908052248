/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorForwardingService } from 'core/api-services';
import {
  ErrorForwardingModel,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
} from 'core/models';
import { ToolbarService } from 'core/services';
import { Observable, distinctUntilChanged, of } from 'rxjs';
import * as fromSettings from 'store-modules/settings-store';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-error-forwarding-list-container',
  templateUrl: './error-forwarding-list-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorForwardingListContainerComponent implements OnInit {
  isEditMode$ = of(false);
  searchTerm$: Observable<string> = of('');
  isLoading$: Observable<boolean> = of(false);
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  tableErrorForwarding$: Observable<ErrorForwardingModel[]> = of([]);

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly toolbarService: ToolbarService,
    private readonly errorForwardingService: ErrorForwardingService
  ) {}

  ngOnInit(): void {
    this.buildToolBarItems();
    this.dispatchActionsAndQuerySelectors();
  }

  buildToolBarItems(): void {
    this.TOOLBAR_ITEMS = [
      {
        key: ToolBarControlKeys.Search,
        type: ToolBarControls.Search,
      },
    ];

    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  dispatchActionsAndQuerySelectors(): void {
    this.tableErrorForwarding$ = this.errorForwardingService.getErrorForwardings();

    this.searchTerm$ = this.toolbarService.searchTerm$.pipe(distinctUntilChanged());
    this.rootStore.dispatch(
      fromRoot.showHideEditToggle({
        isVisible: false,
      })
    );
  }

  saveErrorForwarding(errorForwardingChange: ErrorForwardingModel): void {
    this.settingsStore.dispatch(
      fromSettings.saveErrorForwarding({ errorForwardingModel: errorForwardingChange })
    );
  }
}
