import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environment';
import { Store, select } from '@ngrx/store';
import { filterUndefined } from 'core/helpers';
import { AtsTranslationService, BreadcrumbService, TenantRouterService } from 'core/services';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { ToolBarComponent } from 'shared/components';
import * as fromRoot from 'store/index';
import { RootState } from 'store/reducers';

export interface TranslatedSelectItem extends SelectItem {
  translationKey: string;
}

@Component({
  selector: 'app-working-area-settings',
  templateUrl: './working-area-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingAreaSettingsComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  settings: TranslatedSelectItem[] = [
    {
      label: 'Functions',
      value: 'functions',
      translationKey: 'workingAreaSettings.functions',
    },
    {
      label: 'Error Forwarding',
      value: 'error-forwarding',
      translationKey: 'workingAreaSettings.errorForwarding',
    },
  ];

  selectedSetting?: SelectItem = this.settings[0];
  @ViewChild('toolbar') toolbar!: ToolBarComponent;

  constructor(
    private readonly tenantRouterService: TenantRouterService,
    private readonly rootStore: Store<RootState>,
    private readonly route: ActivatedRoute,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly atsTranslationService: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    if (environment.config.showDeveloperOptions === 'true') {
      this.settings.push({
        label: 'Developer Settings',
        value: 'developer-settings',
        translationKey: 'workingAreaSettings.developerSettings',
      });
    }
  }

  ngOnInit(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: true }));
    this.rootStore.dispatch(fromRoot.showSearchBar({ isVisible: true }));
    this.initializeSubscriptions();
    this.translateMenuItems();
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initializeSubscriptions(): void {
    this.atsTranslationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateMenuItems();
    });
    this.route.paramMap
      .pipe(
        switchMap(params =>
          this.rootStore.pipe(
            select(
              fromRoot.selectOrganizationByName(decodeURI(params.get('organizationName') || ''))
            ),
            filterUndefined(),
            take(1)
          )
        ),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(org => {
        if (this.route.snapshot.parent) {
          this.breadcrumbService.changeBreadcrumb(this.route.snapshot.parent, org.name);
        }
      });

    this.rootStore
      .select(fromRoot.getRouterInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.selectedSetting = this.settings.find(
          setting => setting.value === value.url.substring(value.url.lastIndexOf('/') + 1)
        );
        this.cdRef.markForCheck();
      });
  }

  async selectSetting(selectItem: SelectItem): Promise<boolean> {
    return this.tenantRouterService.navigate(['settings', selectItem.value]);
  }

  translateMenuItems(): void {
    this.settings = this.settings.map(item => {
      item.label = this.atsTranslationService.get(item.translationKey);
      return item;
    });
    this.cdRef.markForCheck();
  }
}
