/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { IpstSettingsService } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import { OrganizationDto } from 'core/dtos';
import { CanComponentDeactivate } from 'core/guards/prevent-unsaved-changes-guard.guard';
import { filterUndefined } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import { AtsActions, GuidString, IpstSettings, ToolBarItem, WorkingArea } from 'core/models';
import { EditBarService, PermissionService, ToastService, ToolbarService } from 'core/services';
import { ModalDialogService } from 'library/components';
import _, { isEqual } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-ipst-settings-container',
  templateUrl: './ipst-settings-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstSettingsContainerComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  ngUnsubscribe = new Subject<void>();
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  selectedOrganization?: OrganizationDto;
  lastIpstMessageResolved = false;

  ipstSettings: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
  };

  originalIpstSettings: IpstSettings = {
    url: '',
    user: '',
    organizationId: EMPTY_GUID,
    password: '',
    workingAreaSettings: [],
  };

  isEditMode$: Observable<boolean> = of(false);

  mainForm: UntypedFormGroup;
  private hasChanges = false;

  constructor(
    private readonly ipstService: IpstSettingsService,
    private readonly editBarService: EditBarService,
    private readonly toastService: ToastService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly toolbarService: ToolbarService,
    private readonly permissionService: PermissionService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalService: ModalDialogService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.mainForm = this.createMainForm();
  }

  ngOnInit(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: true }));
    this.editBarService.setCanDelete(false);
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
    this.editBarService.onSave = this.onSave.bind(this);
    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
    this.initializeSubscriptions();
    this.onEditFormChanges();
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(fromRoot.showHideEditToggle({ isVisible: false }));
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initializeSubscriptions(): void {
    this.rootStore
      .pipe(
        select(fromRoot.getRouterInfo),
        switchMap(({ params: { organizationName } }) =>
          this.rootStore.pipe(
            select(fromRoot.selectOrganizationByName(organizationName)),
            filterUndefined(),
            take(1)
          )
        ),
        filterUndefined(),
        take(1)
      )
      .subscribe(org => {
        this.selectedOrganization = org;
        this.getData(org);
      });

    this.rootStore.dispatch(
      fromRoot.showHideEditToggle({
        isVisible: this.permissionService.actionAllowedInOrg(
          AtsActions.IpstSettingsEdit,
          this.selectedOrganization?.id ?? ''
        ),
      })
    );

    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
  }

  getData(selectedOrganization: OrganizationDto): void {
    this.ipstService
      .getIpstSettings(selectedOrganization)
      .pipe(take(1))
      .subscribe(data => {
        this.ipstSettings = data as IpstSettings;
        this.fetchAllWorkingAreasAndPopulate(selectedOrganization);
      });
  }

  fetchAllWorkingAreasAndPopulate(selectedOrganization: OrganizationDto): void {
    this.rootStore
      .select(fromRoot.selectReducedWorkingAreas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(workingAreas => {
        this.expandAndPopulateWorkingAreas(selectedOrganization, workingAreas);
        this.ipstSettings.workingAreaSettings.sort((a, b) =>
          a.workingArea.localeCompare(b.workingArea)
        );

        this.originalIpstSettings = objectHelper.cloneDeep(this.ipstSettings);

        this.mainForm.patchValue({
          header: {
            url: this.ipstSettings.url,
            user: this.ipstSettings.user,
            password: this.ipstSettings.password,
            organizationId: this.ipstSettings.organizationId,
          },
          workingAreaSettings: this.ipstSettings.workingAreaSettings,
        });

        this.cdRef.markForCheck();
      });
  }

  expandAndPopulateWorkingAreas(
    selectedOrganization: OrganizationDto,
    workingAreas: WorkingArea[]
  ): void {
    const filteredWorkingAreas = workingAreas.filter(
      o => o.organizationId === selectedOrganization.id
    );

    this.ipstSettings.workingAreaSettings = this.ipstSettings?.workingAreaSettings.filter(
      was => filteredWorkingAreas.find(wa => wa.id === was.workingAreaId) !== undefined
    );

    for (const workingArea of filteredWorkingAreas) {
      const waSetting = this.ipstSettings?.workingAreaSettings.find(
        o => o.workingAreaId === workingArea.id
      );
      if (waSetting) {
        waSetting.workingArea = workingArea.name;
      } else {
        this.ipstSettings.workingAreaSettings.push({
          workingArea: workingArea.name,
          workingAreaId: workingArea.id,
          akz: '',
          enabled: false,
          defaultRecipientKey: '',
          recipientKeys: [],
          recipientList: [],
          errorLanguage: 0,
          alertNowUrl: '',
        });
      }
    }
  }

  onCancel(): void {
    this.ipstSettings = objectHelper.cloneDeep(this.originalIpstSettings);

    this.mainForm.patchValue({
      header: {
        url: this.ipstSettings.url,
        user: this.ipstSettings.user,
        password: this.ipstSettings.password,
        organizationId: this.ipstSettings.organizationId,
      },
      workingAreaSettings: this.ipstSettings.workingAreaSettings,
    });

    this.cdRef.markForCheck();
  }

  onSave(): void {
    this.originalIpstSettings = objectHelper.cloneDeep(this.ipstSettings);

    for (const workArea of this.ipstSettings.workingAreaSettings) {
      workArea.recipientKeys = _.uniq(workArea.recipientKeys);

      if (workArea.recipientKeys.length === 0) {
        workArea.recipientKeys.push('');
      }

      if (!workArea.recipientKeys.includes(workArea.defaultRecipientKey)) {
        workArea.defaultRecipientKey = workArea.recipientKeys[0];
      }
    }
    this.ipstService
      .updateIpstSettings(this.ipstSettings, this.selectedOrganization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastService.createSuccessToast('settings.ipst.saveSuccessMessage');
        this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
        this.hasChanges = false;
      });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.hasChanges) {
      return this.modalService.createModal('shared.modalExitEditMode');
    }
    return true;
  }

  private createMainForm(): UntypedFormGroup {
    return this.formBuilder.group({
      header: this.formBuilder.control({}),
      workingAreaSettings: this.formBuilder.control([]),
    });
  }

  private onEditFormChanges() {
    this.mainForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
    });

    this.mainForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(formValue => {
      this.ipstSettings = {
        url: formValue.header.url,
        user: formValue.header.user,
        password: formValue.header.password,
        organizationId: this.selectedOrganization?.id || this.originalIpstSettings.organizationId,
        workingAreaSettings: formValue.workingAreaSettings,
      };

      this.hasChanges = !isEqual(this.ipstSettings, this.originalIpstSettings);
      this.editBarService.setHasChanges(this.hasChanges);
    });
  }

  resolveAllIpstMessages(workAreaId: GuidString): void {
    this.lastIpstMessageResolved = false;
    this.cdRef.markForCheck();
    this.ipstService
      .resolveAllIpstMessages(workAreaId, this.selectedOrganization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.toastService.createSuccessToast('settings.ipst.resolveIpstMessagesSuccess');
          this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: false }));
          this.lastIpstMessageResolved = true;
          this.hasChanges = false;
          this.cdRef.markForCheck();
        },
        error: () => this.toastService.createErrorToast('settings.ipst.resolveIpstMessagesFailure'),
      });
  }
}
