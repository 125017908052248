import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BoxToneOptions } from '@bmw-ds/components/ds-interfaces/box.interface';
import { MenuButton, MenuListItem } from 'core/models';
import { AtsTranslationService, ToolbarService } from 'core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent implements OnInit, OnDestroy {
  @Input() key = '';
  @Input() label = '';
  @Input() icon = '';
  @Input() variant: 'primary' | 'outline' | 'ghost' = 'primary';
  tone: BoxToneOptions | '' = '';

  items: MenuListItem[] = [];
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly atsTranslateService: AtsTranslationService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.atsTranslateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.items.forEach(item => {
        if (item.id) {
          item.label = this.atsTranslateService.get(item.id);
        }
      });
    });
  }

  ngOnInit(): void {
    this.toolbarService
      .getDropdownItems(this.key)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((items: MenuButton[]) => {
        this.tone = items.find(item => item.tone)?.tone ?? '';
        this.createButtons(items);
        this.cdRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.toolbarService.clearDropdownItems();
  }

  createButtons(buttons: MenuButton[]): void {
    this.items = buttons.map(this.createDropdownItem.bind(this));
    this.cdRef.markForCheck();
  }

  createDropdownItem(button: MenuButton): MenuListItem {
    return {
      label: this.atsTranslateService.get(button.buttonLabel),
      id: button.buttonLabel,
      command: this.sendAction.bind(this, button.emitterAction),
      visible: button.visible,
      icon: button.icon,
      tone: button.tone,
    };
  }

  sendAction(action: number): void {
    this.toolbarService.sendButtonAction(action);
  }
}
