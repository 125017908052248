import { ChangeDetectionStrategy, Component, OnDestroy, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { FormValidationService } from 'core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ipst-alertnow-configuration-edit',
  templateUrl: './ipst-alertnow-configuration-edit.component.html',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstAlertNowConfigurationEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstAlertNowConfigurationEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowConfigurationEditComponent
  implements ControlValueAccessor, Validator, OnDestroy
{
  form: UntypedFormGroup;
  ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly formValidationService: FormValidationService
  ) {
    this.form = this.createAlertNowFormGroup();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get alertNowFormDetails(): { [key: string]: AbstractControl } | undefined {
    return this.form.controls;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.form.controls).forEach(key =>
      Object.assign(errors, this.form.get(key)?.errors)
    );

    return this.form.valid ? null : errors;
  }

  writeValue(alertNowUrl: { url: string }): void {
    const alertNowControl = this.form.get('url');
    alertNowControl?.setValue(alertNowUrl.url);
  }

  onTouched = (_value: string): void => {};

  registerOnChange(fn: () => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  private createAlertNowFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      url: [null, [this.formValidationService.validateUrl]],
    });
  }
}
