import { ChangeDetectionStrategy, Component, OnDestroy, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { IpstHeaderSetting } from 'core/dtos';
import { FormValidationService } from 'core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ipst-alertnow-general-info-edit',
  templateUrl: './ipst-alertnow-general-info-edit.component.html',
  styleUrls: ['./ipst-alertnow-general-info-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstAlertNowGeneralInfoEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstAlertNowGeneralInfoEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGeneralInfoEditComponent
  implements ControlValueAccessor, Validator, OnDestroy
{
  form: UntypedFormGroup;
  ngUnsubscribe = new Subject<void>();

  generalInformation: IpstHeaderSetting = {
    url: '',
    user: '',
    password: '',
    organizationId: '',
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly formValidationService: FormValidationService
  ) {
    this.form = this.createIpstFormGroup();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get ipstFormDetails(): { [key: string]: AbstractControl } | undefined {
    return this.form.controls;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.form.controls).forEach(key =>
      Object.assign(errors, this.form.get(key)?.errors)
    );

    return this.form.valid ? null : errors;
  }

  writeValue(val: IpstHeaderSetting): void {
    this.generalInformation = val;
    this.form.patchValue({
      url: this.generalInformation?.url,
      password: this.generalInformation?.password,
      user: this.generalInformation?.user,
    });
  }

  onTouched = (_value: IpstHeaderSetting): void => {};

  registerOnChange(fn: () => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  private createIpstFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      url: [null, [Validators.required, this.formValidationService.validateUrl]],
      user: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }
}
