import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IpstWorkingAreaSettingDto } from 'core/dtos';

@Component({
  selector: 'app-ipst-alertnow-configuration-view',
  templateUrl: './ipst-alertnow-configuration-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowConfigurationViewComponent {
  @Input() ipstAlertNowSetting?: IpstWorkingAreaSettingDto;
}
