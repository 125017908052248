import { HardwareVersion, VehicleType } from 'core/models';
import { Forklift4Am15tMapItem } from './items/forklift-4am15t.graphic';
import { Forklift4Am20tMapItem } from './items/forklift-4am20t.graphic';
import { ForkliftManualMapItem } from './items/forklift-manual.graphic';
import { TuggerTrain4AmMapItem } from './items/tuggertrain-4am.graphic';
import { TuggerTrainDSMapItem } from './items/tuggertrain-ds.graphic';
import { TuggerTrainManualMapItem } from './items/tuggertrain-manual.graphic';
import { GenericMapItem } from './items/underride-generic.graphic';
import { STRMapItem } from './items/underride-str.graphic';

export const UnderrideItems = {
  [HardwareVersion.StrVersion2]: STRMapItem,
  [HardwareVersion.StrVersion3]: STRMapItem,
  [HardwareVersion.StrVersion4]: STRMapItem,
  [HardwareVersion.DSType1]: GenericMapItem,
  [HardwareVersion.DSType2]: GenericMapItem,
  [HardwareVersion.DSType3]: GenericMapItem,
  [HardwareVersion.DSType4]: GenericMapItem,
  [HardwareVersion.DSType5]: GenericMapItem,
};

export const TuggerTrainItems = {
  [HardwareVersion.TuggerTrainFourAmV2]: TuggerTrain4AmMapItem,
  [HardwareVersion.TuggerTrainDsV1]: TuggerTrainDSMapItem,
  [HardwareVersion.TuggerTrainManual]: TuggerTrainManualMapItem,
};

export const ForkLiftItems = {
  [HardwareVersion.ForkliftFourAm_15t]: Forklift4Am15tMapItem,
  [HardwareVersion.ForkliftFourAm_20t]: Forklift4Am20tMapItem,
  [HardwareVersion.ForkliftManual]: ForkliftManualMapItem,
};

export const DefaultVersionItems = {
  [VehicleType.UnitLoad]: HardwareVersion.StrVersion4,
  [VehicleType.Forklift]: HardwareVersion.ForkliftFourAm_15t,
  [VehicleType.TuggerTrain]: HardwareVersion.TuggerTrainFourAmV2,
  [VehicleType.U_AGV]: HardwareVersion.DSType1,
};
