/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TourChainService } from 'core/api-services';
import { TEN_SECONDS } from 'core/constants';
import { catchError, concatMap, delay, map, of } from 'rxjs';
import * as MissionListItemActions from '../actions/mission-list-item.actions';
import * as MissionTraceActions from '../actions/mission-trace.actions';

@Injectable()
export class MissionListItemEffects {
  signalRRemoveCompletedMissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionListItemActions.signalRUpdateMissionList),
      delay(TEN_SECONDS),
      map(_ => MissionListItemActions.signalRRemoveCompletedMissions())
    )
  );

  loadActiveTourListItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MissionListItemActions.loadActiveTourListItems),
      concatMap(() =>
        this.tourChainService
          .getAllActiveTours()
          .pipe(
            map(tours => MissionListItemActions.loadActiveTourListItemsSuccess({ items: tours }))
          )
      ),
      catchError(errorMessage => of(MissionTraceActions.loadMissionTracesFailure({ errorMessage })))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tourChainService: TourChainService
  ) {}
}
