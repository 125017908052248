import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { IpstServiceFeatures, getDefaultIpstServiceFeatures } from 'core/dtos';
import { IpstAlertNowErrorMessagesViewComponent } from '../ipst-alertnow-error-messages-view/ipst-alertnow-error-messages-view.component';
import { IpstAlertNowErrorMessagesViewModel } from '../ipst-alertnow-error-messages-view/ipst-alertnow-error-messages.viewmodel';

@Component({
  selector: 'app-ipst-alertnow-error-messages-edit',
  templateUrl: './ipst-alertnow-error-messages-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowErrorMessagesEditComponent implements OnChanges {
  @Input() readonly ipstServiceFeatures: IpstServiceFeatures = getDefaultIpstServiceFeatures();
  @Input() ipstServiceFeaturesLoaded = false;
  @Output() readonly saveIncludeTuggerTrainErrors = new EventEmitter<boolean>();

  toggle = { isToggledOn: false, dateUpdated: null };
  viewModel: IpstAlertNowErrorMessagesViewModel;

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.viewModel = this.generateViewModel();
  }

  ngOnChanges({ ipstServiceFeatures }: TypedChanges<IpstAlertNowErrorMessagesViewComponent>): void {
    if (ipstServiceFeatures?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        includeTuggerTrainErrors:
          ipstServiceFeatures.currentValue.includeTuggerTrainErrorsToggle ??
          this.viewModel.includeTuggerTrainErrors,
      };
      this.cdr.markForCheck();
    }
  }

  generateViewModel(): IpstAlertNowErrorMessagesViewModel {
    return {
      includeTuggerTrainErrors: { isToggledOn: false, dateUpdated: null },
    };
  }

  onChangeIncludeTuggerTrainErrors(value: boolean): void {
    this.saveIncludeTuggerTrainErrors.emit(value);
  }
}
