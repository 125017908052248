/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DsHeaderTagConfiguration,
  DsHeaderUserInfo,
  DsNavigationBarComponent,
  DsThemeService,
} from '@bmw-ds/components';
import { environment } from '@environment';
import { Store, select } from '@ngrx/store';
import {
  RoleScope,
  User,
  UserManual,
  UserManualLinks,
  UserManualRouteNames,
  VehicleType,
} from 'core/models';

import { RolesService, UserService } from 'core/api-services';
import { RoleDto } from 'core/dtos';
import { AtsTranslationService, SessionService, TenantRouterService } from 'core/services';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AssignUserRoleDialogInput } from 'shared/components';
import { HeaderNavbarIntegrationService } from 'shared/services';
import * as fromRoot from 'store/index';
import { RootState } from 'store/reducers';

type SpecialWaRoles = 'Viewer' | 'Operator' | 'Supervisor' | 'WorkingArea Admin';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBarComponent
  implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, OnChanges
{
  @Input() closePanel = false;
  @Output() readonly openNotificationPanel = new EventEmitter<boolean>();

  openNotificationPanelFlag = false;
  hasNotifications = false;
  showOverlay = false;
  shouldShowBreadcrumbBar = false;
  shouldShowNotificationButton = false;
  shouldShowSupportRequestButton = false;
  url: UserManual[] = UserManualLinks;
  navBarRef?: DsNavigationBarComponent;
  localeID: string | null = 'en';
  missionTraceId = '';
  vehicleId = '';
  vehicleType = 0;
  userInfo: Partial<DsHeaderUserInfo> = {};
  isUserSettingsModalOpen = false;
  isUserDetailsModalOpen = false;
  userDetailsData?: AssignUserRoleDialogInput;
  private selectedUser: User | undefined = undefined;
  private readonly users: User[] = [];
  private workingAreaRoles: RoleDto[] = [];

  localeOptions = [
    { id: 'en', label: 'EN', language: 'English' },
    { id: 'de', label: 'DE', language: 'Deutsch' },
  ];

  environmentTagConfig: Partial<DsHeaderTagConfiguration> = {
    label: environment.config.environment,
  };

  isDevMode = environment.config.showDeveloperOptions === 'true';

  pageUrl = '';
  ngUnsubscribe = new Subject<void>();

  defaultUrl: UserManual = {
    name: UserManualRouteNames.Default,
    route: 'https://handbook.ats.azure.cloud.bmw/2-basics-ats-services/00_willkommen/',
  };

  constructor(
    private readonly routerService: TenantRouterService,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly rootStore: Store<RootState>,
    private readonly headerIntegrationService: HeaderNavbarIntegrationService,
    private readonly langTranslationService: AtsTranslationService,
    private readonly sessionService: SessionService,
    private readonly userService: UserService,
    private readonly rolesService: RolesService,
    protected readonly themeService: DsThemeService
  ) {}

  ngOnChanges({ closePanel }: TypedChanges<HeaderBarComponent>): void {
    if (!closePanel?.isFirstChange()) {
      this.openNotificationPanelFlag = false;
      this.openNotificationPanel.emit(this.openNotificationPanelFlag);
      this.cdRef.markForCheck();
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.navBarRef = this.headerIntegrationService.navBar$.getValue();
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.showOverlay = new Date().getMonth() === 11;
    this.cdRef.markForCheck();
    this.getRouterInfo();
    this.localeID = this.langTranslationService.currentLang;
    this.setUserInfo();
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getRouterInfo(): void {
    this.rootStore
      .select(fromRoot.getRouterInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(routerInfo => {
        this.shouldShowBreadcrumbBar = !(
          routerInfo.url.startsWith('/welcome') ||
          routerInfo.url.startsWith('/settings/environment') ||
          routerInfo.url.startsWith('/settings/infobar') ||
          routerInfo.url.startsWith('/settings/global-user-roles-permission/')
        );
        this.shouldShowNotificationButton =
          this.shouldShowBreadcrumbBar &&
          !routerInfo.url.includes('organization-settings') &&
          !routerInfo.url.endsWith('/ticket-management/ticket-creation');

        this.shouldShowSupportRequestButton = !routerInfo.url.match('/welcome');
        this.pageUrl = this.router.url;
        this.missionTraceId = routerInfo.params.missionTraceId;
        this.vehicleId = routerInfo.params.vehicleId;
        this.vehicleType = routerInfo.params.vehicleType;

        this.cdRef.markForCheck();
      });
  }

  convertValueToEnum(value: VehicleType | string | undefined): VehicleType | undefined {
    if (value === undefined) return undefined;

    const valueAsNumber = Number(value);
    if (VehicleType[valueAsNumber] !== undefined) {
      return valueAsNumber;
    }

    return undefined;
  }
  setUserInfo(): void {
    const username = this.sessionService.getDisplayName();
    this.userInfo = { username };
  }

  private initializeSubscriptions(): void {
    this.userService
      .getUsers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(users => {
        this.selectedUser = users.find(u => u.id === this.sessionService?.currentUser?.id);
      });

    this.rolesService
      .getRolesByScopeWithoutPermissions(RoleScope.WorkingArea)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(roles => {
        const nonAdminRoles: SpecialWaRoles[] = ['Viewer', 'Operator', 'Supervisor'];
        const nonAdminRoleSet = new Set<string>(nonAdminRoles);
        const adminRole: SpecialWaRoles = 'WorkingArea Admin';
        this.workingAreaRoles = roles
          .filter(r => nonAdminRoleSet.has(r.name))
          .concat(roles.filter(r => r.name === adminRole));
      });
  }

  onBellClick(): void {
    this.openNotificationPanelFlag = !this.openNotificationPanelFlag;
    this.openNotificationPanel.emit(this.openNotificationPanelFlag);
    this.cdRef.markForCheck();
  }

  onTicketCreationClick(): void {
    this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId), take(1)).subscribe(wa => {
      if (wa) {
        void this.routerService.navigate(['/ticket-management/ticket-creation'], {
          queryParams: {
            missionTraceId: this.missionTraceId,
            vehicleId: this.vehicleId,
            vehicleType: this.convertValueToEnum(this.vehicleType),
          },
        });
      } else {
        void this.router.navigate(['/welcome/ticket-management/ticket-creation']);
      }
    });
  }

  onVersionInformationClick(): void {
    this.rootStore.pipe(select(fromRoot.selectSelectedWorkingAreaId), take(1)).subscribe(wa => {
      if (wa) {
        void this.routerService.navigate(['/version-information']);
      } else {
        void this.router.navigate(['/welcome/version-information']);
      }
    });
  }

  onUserManualClick(): void {
    let route = '';
    for (const userManualRoutes of this.url) {
      if (this.pageUrl.includes(userManualRoutes.name)) {
        route = userManualRoutes.route;
      }
    }
    route === '' ? window.open(this.defaultUrl.route, '_blank') : window.open(route, '_blank');
  }

  onSendNotification(hasNotifications: boolean): void {
    this.hasNotifications = hasNotifications;
    this.cdRef.markForCheck();
  }

  async changeLocale(localeID: string | null): Promise<void> {
    if (localeID) {
      await this.langTranslationService.setLanguage(localeID);
    }
  }

  onLogout(): void {
    this.sessionService.logout();
  }

  onCloseModal(): void {
    this.isUserSettingsModalOpen = this.isUserDetailsModalOpen = false;
  }

  openMyAtsUserSettingsModal(): void {
    this.isUserSettingsModalOpen = true;
    this.cdRef.markForCheck();
  }

  openMyPermissionsModal(): void {
    this.userDetailsData = {
      currentUser: this.selectedUser,
      currentRole: this.workingAreaRoles.find(
        r => r.id === this.sessionService.currentUser?.roleId
      ),
      users: this.users,
      roles: this.workingAreaRoles,
      areaText: this.selectedUser?.name ?? '',
      translateKey: 'shared.editUserRoleModal',
    };
    this.isUserDetailsModalOpen = true;
    this.cdRef.markForCheck();
  }
}
