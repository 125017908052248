/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  EmulatorService,
  EvacuationDeviceSettingsService,
  FireFighterSettingsService,
  FleetSettingsService,
  InfobarService,
  IpstFeatureService,
  IpstSettingsService,
  JobSettingsService,
  LifService,
  MapSettingsService,
  OrderGatewayFeatureService,
  TrafficSettingsService,
} from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';

import { ToastService } from 'core/services/toast.service';
import { concatAll, firstValueFrom, of, skipWhile } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as fromOpcuaDevicesActions from 'store-modules/opcua-devices-store/actions/opcua-devices.actions';
import * as fromOpcuaDevicesSelectors from 'store-modules/opcua-devices-store/selectors/opcua-devices.selectors';
import { setHasChanges, setIsEditMode } from 'store/actions';
import { RootState } from 'store/reducers';
import * as SettingsActions from '../actions/settings.actions';

@Injectable()
export class SettingsEffects {
  loadFleetSettingsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadFleetSettings),
      concatMap(() =>
        this.fleetSettings.getFleetManagerFeaturesV2().pipe(
          map(fleetManagerFeatures =>
            SettingsActions.loadFleetSettingsSuccess({ fleetManagerFeatures })
          ),
          catchError(errorMessage => of(SettingsActions.loadFleetSettingsFailure({ errorMessage })))
        )
      )
    )
  );

  loadFleetSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadFleetSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.fleetServiceSection.fleetFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadIpstSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadIpstSettings),
      concatMap(() =>
        this.ipstFeatures.getIpstServiceFeatures().pipe(
          map(ipstServiceFeatures =>
            SettingsActions.loadIpstSettingsSuccess({ ipstServiceFeatures })
          ),
          catchError(errorMessage => of(SettingsActions.loadIpstSettingsFailure({ errorMessage })))
        )
      )
    )
  );

  loadIpstSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadIpstSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.ipstServiceSection.ipstFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadOrderGatewaySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadOrderGatewaySettings),
      concatMap(() =>
        this.orderGatewayFeatureService.getOrderGatewayFeatures().pipe(
          map(orderGatewayFeatures =>
            SettingsActions.loadOrderGatewaySettingsSuccess({ orderGatewayFeatures })
          ),
          catchError(errorMessage =>
            of(SettingsActions.loadOrderGatewaySettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadOrderGatewaySettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadOrderGatewaySettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.orderGatewaySection.failureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadShutdownRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadShutdownRequest),
      concatMap(() =>
        this.fleetSettings.getShutdownValues().pipe(
          map(shutdownRequest => SettingsActions.loadShutdownRequestSuccess({ shutdownRequest })),
          catchError(errorMessage =>
            of(SettingsActions.loadShutdownRequestFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadShutdownRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadShutdownRequestFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  loadInfobarMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadInfobarMessage),
      concatMap(() =>
        this.infobarService.getLatestActiveInfobarMessage().pipe(
          map(infobarMessage => SettingsActions.loadInfobarMessageSuccess({ infobarMessage })),
          catchError(errorMessage =>
            of(SettingsActions.loadInfobarMessageFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadInfobarMessageFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadInfobarMessageFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  loadTrafficSettingsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadTrafficSettings),
      concatMap(() =>
        this.trafficSettings.getTrafficManagementSettings().pipe(
          map(trafficManagementSettings =>
            SettingsActions.loadTrafficSettingsSuccess({ trafficManagementSettings })
          ),
          catchError(errorMessage =>
            of(SettingsActions.loadTrafficSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadTrafficSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadTrafficSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.trafficManagementSection.trafficFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadEmulatorSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadEmulatorSettings),
      concatMap(({ workAreaId }) =>
        this.emulatorService.getEmulatorManagerFeatures(workAreaId ?? EMPTY_GUID).pipe(
          map(emulatorManagerFeatures =>
            SettingsActions.loadEmulatorSettingsSuccess({ emulatorManagerFeatures })
          ),
          catchError(errorMessage =>
            of(SettingsActions.loadEmulatorSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadEmulatorSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadEmulatorSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.emulatorServiceSection.emulatorFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadEvacuationDeviceSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadEvacuationDeviceSettings),
      concatMap(() =>
        this.evacuationDeviceSettingService.getAllDevices().pipe(
          map(devices => SettingsActions.loadEvacuationDeviceSettingsSuccess({ devices })),
          catchError(errorMessage =>
            of(SettingsActions.loadEvacuationDeviceSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadEvacuationDeviceSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadEvacuationDeviceSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.evacuationModeSection.evacuationDeviceFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadEvacuationDeviceNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadEvacuationDeviceSettingsSuccess),
      switchMap(async action => {
        await firstValueFrom(
          this.store
            .select(fromOpcuaDevicesSelectors.selectOpcuaDevicesLoaded)
            .pipe(skipWhile(loaded => !loaded))
        );

        const allOpcuaDevices = await firstValueFrom(
          this.store.select(fromOpcuaDevicesSelectors.selectAllOpcuaDevices)
        );

        const serviceNameByDeviceName = new Map(
          allOpcuaDevices.map(d => [d.name, d.streamingServiceName])
        );

        const evacuationDevices = action.devices.filter(o => serviceNameByDeviceName.has(o.device));

        return evacuationDevices.map(ed =>
          fromOpcuaDevicesActions.setSelectedDevice({
            name: ed.device,
            streamingService: serviceNameByDeviceName.get(ed.device)!,
          })
        );
      }),
      concatAll()
    )
  );

  loadJobSettingsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadJobSettings),
      concatMap(() =>
        this.jobSettings.getJobManagerFeaturesV2().pipe(
          map(jobManagerFeatures => SettingsActions.loadJobSettingsSuccess({ jobManagerFeatures })),
          catchError(errorMessage => of(SettingsActions.loadJobSettingsFailure({ errorMessage })))
        )
      )
    )
  );

  loadJobSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadJobSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.jobAssignmentSection.jobFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  loadMapSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadMapSettings),
      concatMap(() =>
        this.mapSettingsService.getMapManagerFeaturesV2().pipe(
          map(mapManagerFeatures => SettingsActions.loadMapSettingsSuccess({ mapManagerFeatures })),
          catchError(errorMessage => of(SettingsActions.loadMapSettingsFailure({ errorMessage })))
        )
      )
    )
  );

  loadMapSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadMapSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.mapSection.mapFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  updateEmulatorSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateEmulatorSettings),
      concatMap(({ emulatorFeature, workAreaId }) =>
        this.emulatorService.updateEmulatorSettings(emulatorFeature, workAreaId).pipe(
          map(() =>
            SettingsActions.updateEmulatorSettingsSuccess({
              emulatorFeature,
            })
          ),
          catchError(errorMessage =>
            of(SettingsActions.updateEmulatorSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateEmulatorSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateEmulatorSettingsSuccess),
      tap(() => {
        this.toastService.createSuccessToast('settings.actions.saveEmulatorSettingsSuccess');
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateEmulatorSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.updateEmulatorSettingsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  activateEmulators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.activateEmulators),
      concatMap(({ workingAreaId }) =>
        this.emulatorService.activateEmulators(workingAreaId).pipe(
          map(() => SettingsActions.activateEmulatorsSuccess()),
          catchError(errorMessage => of(SettingsActions.activateEmulatorsFailure({ errorMessage })))
        )
      )
    )
  );

  activateEmulatorsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.activateEmulatorsSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.developerSettings.activateEmulatorsSuccessMessage'
          );
        })
      ),
    { dispatch: false }
  );

  deactivateEmulators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.deactivateEmulators),
      concatMap(({ workingAreaId }) =>
        this.emulatorService.deactivateEmulators(workingAreaId).pipe(
          map(() => SettingsActions.deactivateEmulatorsSuccess()),
          catchError(errorMessage =>
            of(SettingsActions.deactivateEmulatorsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  deactivateEmulatorsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.deactivateEmulatorsSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.developerSettings.deactivateEmulatorsSuccessMessage'
          );
        })
      ),
    { dispatch: false }
  );

  actionsFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SettingsActions.activateEmulatorsFailure,
          SettingsActions.deactivateEmulatorsFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  // V2 toggles

  toggleShutDownMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleShutDownMode),
      concatMap(({ toggle }) =>
        this.fleetSettings.updateShutdownModeV2(toggle).pipe(
          map(result => SettingsActions.toggleShutDownModeSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleShutDownModeFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleShutDownModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleShutDownModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.shutdownMode.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleShutDownModeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleShutDownModeFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.shutdownMode.saveFailure');
      }),
      map(() => SettingsActions.loadFleetSettings())
    )
  );

  toggleEvacuationModeWorkingArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEvacuationModeWorkingArea),
      concatMap(({ value: toggle }) =>
        this.fleetSettings.updateEvacuationMode(toggle).pipe(
          map(result => SettingsActions.toggleEvacuationModeWorkingAreaSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleEvacuationModeWorkingAreaFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEvacuationModeWorkingAreaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEvacuationModeWorkingAreaSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.evacuationMode.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEvacuationModeWorkingAreaFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEvacuationModeWorkingAreaFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.evacuationMode.saveFailure');
      }),
      map(() => SettingsActions.loadFleetSettings())
    )
  );

  toggleEnableVehicleSmoothing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEnableVehicleSmoothing),
      concatMap(({ toggle }) =>
        this.mapSettingsService.updateVehicleSmoothingSettings(toggle).pipe(
          map(result => SettingsActions.toggleEnableVehicleSmoothingSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleEnableVehicleSmoothingFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEnableVehicleSmoothingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEnableVehicleSmoothingSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.vehicleSmoothing.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEnableVehicleSmoothingFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEnableVehicleSmoothingFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.vehicleSmoothing.saveFailure');
        })
      ),
    { dispatch: false }
  );

  updateEvacuationDeviceSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateEvacuationDeviceSettings),
      concatMap(({ device }) =>
        this.evacuationDeviceSettingService.updateDeviceSetting(device).pipe(
          map(() =>
            SettingsActions.updateEvacuationDeviceSettingsSuccess({
              device,
            })
          ),
          catchError(errorMessage =>
            of(SettingsActions.updateEvacuationDeviceSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateEvacuationDeviceSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateEvacuationDeviceSettingsSuccess),
      tap(() => {
        this.toastService.createSuccessToast(
          'settings.actions.saveEvacuationDeviceSettingsSuccess'
        );
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateEvacuationDeviceSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.updateEvacuationDeviceSettingsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  toggleEndOfShiftMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEndOfShiftMode),
      concatMap(({ toggle }) =>
        this.jobSettings.updateEndOfShiftMode(toggle).pipe(
          map(result => SettingsActions.toggleEndOfShiftModeSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleEndOfShiftModeFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEndOfShiftModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEndOfShiftModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.endOfShift.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEndOfShiftModeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEndOfShiftModeFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.endOfShift.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleCollectivePause$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleCollectivePause),
      concatMap(({ toggle }) =>
        this.fleetSettings.updateCollectivePause(toggle).pipe(
          map(result => SettingsActions.toggleCollectivePauseSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleCollectivePauseFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleCollectivePauseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleCollectivePauseSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.collectivePause.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleCollectivePauseFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleCollectivePauseFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.collectivePause.saveFailure');
      }),
      map(() => SettingsActions.loadFleetSettings())
    )
  );

  toggleParkingAndCharging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleParkingAndCharging),
      concatMap(({ value }) =>
        this.jobSettings.updateParkingAndCharging(value).pipe(
          map(result => SettingsActions.toggleParkingAndChargingSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleParkingAndChargingFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleParkingAndChargingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleParkingAndChargingSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.parkingAndCharging.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleParkingAndChargingFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleParkingAndChargingFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.parkingAndCharging.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleWaitOnPoi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleWaitOnPoi),
      concatMap(({ value }) =>
        this.jobSettings.updateWaitOnPoi(value).pipe(
          map(result => SettingsActions.toggleWaitOnPoiSuccess({ result })),
          catchError(errorMessage => of(SettingsActions.toggleWaitOnPoiFailure({ errorMessage })))
        )
      )
    )
  );

  toggleWaitOnPoiSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleWaitOnPoiSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.waitOnPoi.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleWaitOnPoiFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleWaitOnPoiFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.waitOnPoi.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleAmaSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleAmaSettings),
      concatMap(({ value }) =>
        this.jobSettings.updateAmaSettings(value).pipe(
          map(result => SettingsActions.toggleAmaSettingsSuccess({ result })),
          catchError(errorMessage => of(SettingsActions.toggleAmaSettingsFailure({ errorMessage })))
        )
      )
    )
  );

  toggleAmaSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleAmaSettingsSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.amaSettings.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleAmaSettingsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleAmaSettingsFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.amaSettings.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleWaitingQueueSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleWaitingQueueSettings),
      concatMap(({ value }) =>
        this.jobSettings.updateWaitingQueueSettings(value).pipe(
          map(result => SettingsActions.toggleWaitingQueueSettingsSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleWaitingQueueSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleWaitingQueueSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleWaitingQueueSettingsSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.waitingQueue.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleWaitingQueueSettingsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleWaitingQueueSettingsFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.waitingQueue.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleTrafficSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleTrafficSettings),
      concatMap(({ value }) =>
        this.trafficSettings.updateTrafficSettings(value).pipe(
          map(result => SettingsActions.toggleTrafficSettingsSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleTrafficSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleTrafficSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleTrafficSettingsSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.trafficManagement.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleTrafficSettingsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleTrafficSettingsFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.trafficManagement.saveFailure');
      }),
      map(() => SettingsActions.loadTrafficSettings())
    )
  );

  toggleBackwardDrivingPathBased$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleBackwardDrivingPathBased),
      concatMap(({ value }) =>
        this.trafficSettings.updateBackwardDrivingByPath(value).pipe(
          map(result => SettingsActions.toggleBackwardDrivingPathBasedSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleBackwardDrivingPathBasedFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleBackwardDrivingByPathSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleBackwardDrivingPathBasedSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.functions.backwardDrivingPathBased.saveSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  toggleBackwardDrivingByPathFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleBackwardDrivingPathBasedFailure),
      tap(() => {
        this.toastService.createErrorToast(
          'settings.functions.backwardDrivingPathBased.saveFailure'
        );
      }),
      map(() => SettingsActions.loadTrafficSettings())
    )
  );

  toggleAvoidConflictAtPois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleAvoidConflictAtPois),
      concatMap(({ value }) =>
        this.trafficSettings.updateAvoidConflictAtPois(value).pipe(
          map(result => SettingsActions.toggleAvoidConflictAtPoisSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleAvoidConflictAtPoisFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleAvoidConflictAtPoisSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleAvoidConflictAtPoisSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.functions.considerIdleUnitLoad.saveSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  toggleAvoidConflictAtPoisFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleAvoidConflictAtPoisFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.considerIdleUnitLoad.saveFailure');
      }),
      map(() => SettingsActions.loadTrafficSettings())
    )
  );

  toggleMatrixOptimization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleMatrixOptimization),
      concatMap(({ toggle }) =>
        this.jobSettings.updateMatrixOptimization(toggle).pipe(
          map(result => SettingsActions.toggleMatrixOptimizationSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleMatrixOptimizationFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleMatrixOptimizationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleMatrixOptimizationSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.matrixOptimization.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleMatrixOptimizationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleMatrixOptimizationFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.matrixOptimization.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleBrakeTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleBrakeTest),
      concatMap(({ toggle }) =>
        this.jobSettings.updateBrakeTest(toggle).pipe(
          map(result => SettingsActions.toggleBrakeTestSuccess({ result })),
          catchError(errorMessage => of(SettingsActions.toggleBrakeTestFailure({ errorMessage })))
        )
      )
    )
  );

  toggleBrakeTestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleBrakeTestSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.brakeTest.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleBrakeTestFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleBrakeTestFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.brakeTest.saveFailure');
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  toggleIncludeTuggerTrain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleIncludeTuggerTrainErrors),
      concatMap(({ toggle }) =>
        this.ipstFeatures.updateIncludeTuggerTrainErrors(toggle).pipe(
          map(result => SettingsActions.toggleIncludeTuggerTrainErrorsSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleIncludeTuggerTrainErrorsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleIncludeTuggerTrainErrorsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleIncludeTuggerTrainErrorsSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.functions.includeTuggerTrainErrors.saveSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  toggleIncludeTuggerTrainErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleIncludeTuggerTrainErrorsFailure),
      tap(() => {
        this.toastService.createErrorToast(
          'settings.functions.includeTuggerTrainErrors.saveFailure'
        );
      }),
      map(() => SettingsActions.loadIpstSettings())
    )
  );

  toggleNewIpstToggle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleNewIpstToggle),
      concatMap(({ toggle }) =>
        this.ipstFeatures.updateNewIpstToggle(toggle).pipe(
          map(result => SettingsActions.toggleNewIpstToggleSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleNewIpstToggleFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleNewIpstToggleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleNewIpstToggleSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.newIpst.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleNewIpstToggleErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleNewIpstToggleFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.newIpst.saveFailure');
      }),
      map(() => SettingsActions.loadIpstSettings())
    )
  );

  togglePktInterface$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.togglePktInterface),
      concatMap(({ value }) =>
        this.orderGatewayFeatureService.updatePktInterfaceToggle(value).pipe(
          map(result => SettingsActions.togglePktInterfaceSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.togglePktInterfaceFailure({ errorMessage }))
          )
        )
      )
    )
  );

  togglePktInterfaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.togglePktInterfaceSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.pktInterfaceToggle.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  togglePktInterfaceErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.togglePktInterfaceFailure),
      tap(() => {
        this.toastService.createErrorToast('settings.functions.pktInterfaceToggle.saveFailure');
      }),
      map(() => SettingsActions.loadOrderGatewaySettings())
    )
  );

  updateMissionErrorHandlingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateMissionErrorHandlingSettings),
      concatMap(({ settings }) =>
        this.jobSettings.updateMissionErrorHandlingSettings(settings).pipe(
          map(result => SettingsActions.updateMissionErrorHandlingSettingsSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.updateMissionErrorHandlingSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateMissionErrorHandlingSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.updateMissionErrorHandlingSettingsSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'settings.functions.missionErrorHandlingDefaults.saveSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  updateMissionErrorHandlingSettingsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateMissionErrorHandlingSettingsFailure),
      tap(() => {
        this.toastService.createErrorToast(
          'settings.functions.missionErrorHandlingDefaults.saveFailure'
        );
      }),
      map(() => SettingsActions.loadJobSettings())
    )
  );

  graphManagerSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadGraphManagerSettings),
      concatMap(() =>
        this.lifService.getFeatureSettings().pipe(
          map(graphManagerFeatures =>
            SettingsActions.loadGraphManagerSettingsSuccess({ graphManagerFeatures })
          ),
          catchError(errorMessage =>
            of(SettingsActions.loadGraphManagerSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  graphManagerSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.loadGraphManagerSettingsFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.graphManager.loadFailure');
        })
      ),
    { dispatch: false }
  );

  toggleEnableGraphManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEnableGraphManager),
      concatMap(({ enableGraphManager }) =>
        this.lifService.updateFeatureSettings(enableGraphManager).pipe(
          map(graphManagerFeatures =>
            SettingsActions.toggleEnableGraphManagerSuccess({ graphManagerFeatures })
          ),
          catchError(errorMessage =>
            of(SettingsActions.toggleEnableGraphManagerFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEnableGraphManagerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEnableGraphManagerSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.graphManager.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEnableGraphManagerFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEnableGraphManagerFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.graphManager.saveFailure');
        })
      ),
    { dispatch: false }
  );

  restartGraphManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.restartGraphManager),
      concatMap(({ restartGraphManager }) => {
        if (restartGraphManager) {
          return this.lifService.restartGraphManager().pipe(
            map(_ => SettingsActions.restartGraphManagerSuccess({ restartGraphManager: true })),
            catchError(errorMessage =>
              of(SettingsActions.restartGraphManagerFailure({ errorMessage }))
            )
          );
        } else {
          return of(SettingsActions.restartGraphManagerSuccess({ restartGraphManager: false }));
        }
      })
    )
  );

  restartGraphManagerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.restartGraphManagerSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.graphManager.restartSuccess');
        })
      ),
    { dispatch: false }
  );

  restartGraphManagerFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.restartGraphManagerFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.graphManager.restartFailure');
        })
      ),
    { dispatch: false }
  );

  toggleBeginShiftMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleBeginShiftMode),
      concatMap(({ beginShiftMode }) =>
        this.lifService.updateBeginShiftMode(beginShiftMode).pipe(
          map(graphManagerFeatures =>
            SettingsActions.toggleBeginShiftModeSuccess({ graphManagerFeatures })
          ),
          catchError(errorMessage =>
            of(SettingsActions.toggleBeginShiftModeFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleBeginShiftModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleBeginShiftModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.beginShiftMode.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleBeginShiftModeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleBeginShiftModeFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.beginShiftMode.saveFailure');
        })
      ),
    { dispatch: false }
  );

  toggleEvacuationModeFireFighter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.toggleEvacuationModeFireFighter),
      concatMap(({ value: toggle }) =>
        this.fireFighterSettingsService.updateEvacuationModeFireFighter(toggle).pipe(
          map(result => SettingsActions.toggleEvacuationModeFireFighterSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.toggleEvacuationModeFireFighterFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEvacuationModeFireFighterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEvacuationModeFireFighterSuccess),
        tap(() => {
          this.toastService.createSuccessToast('settings.functions.evacuationMode.saveSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEvacuationModeFireFighterFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.toggleEvacuationModeFireFighterFailure),
        tap(() => {
          this.toastService.createErrorToast('settings.functions.evacuationMode.saveFailure');
        })
      ),
    { dispatch: false }
  );

  retrieveAllFireFighterSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.retrieveAllFireFighterSettings),
      concatMap(() =>
        this.fireFighterSettingsService.getAllFireFighterSettings().pipe(
          map(result => SettingsActions.retrieveAllFireFighterSettingsSuccess({ result })),
          catchError(errorMessage =>
            of(SettingsActions.retrieveAllFireFighterSettingsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  retrieveAllFireFighterSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.retrieveAllFireFighterSettingsSuccess),
        tap(() => {})
      ),
    { dispatch: false }
  );

  retrieveAllFireFighterSettingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.retrieveAllFireFighterSettingsFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  loadLastIpstMessageResolve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.loadLastIpstMessageResolve),
      concatMap(({ workAreaId: workingAreaId }) =>
        this.ipstSettingsService.getLastResolveIpstMessage(workingAreaId).pipe(
          map(response => {
            return SettingsActions.loadLastIpstMessageResolveSuccess({
              lastMessageResolve: response,
            });
          }),
          catchError(errorMessage =>
            of(SettingsActions.loadLastIpstMessageResolveFailure({ errorMessage }))
          )
        )
      )
    )
  );

  resolveAllIpstMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.resolveAllIpstMessages),
      concatMap(action =>
        this.ipstSettingsService.resolveAllIpstMessages(action.workAreaId, action.org).pipe(
          map(() => {
            this.toastService.createSuccessToast('settings.ipst.resolveIpstMessagesSuccess');
            return SettingsActions.resolveAllIpstMessagesSuccess();
          }),
          catchError(errorMessage => {
            this.toastService.createErrorToast('settings.ipst.resolveIpstMessagesFailure');
            return of(SettingsActions.resolveAllIpstMessagesFailure(errorMessage));
          })
        )
      )
    )
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly fleetSettings: FleetSettingsService,
    private readonly jobSettings: JobSettingsService,
    private readonly trafficSettings: TrafficSettingsService,
    private readonly mapSettingsService: MapSettingsService,
    private readonly toastService: ToastService,
    private readonly emulatorService: EmulatorService,
    private readonly infobarService: InfobarService,
    private readonly evacuationDeviceSettingService: EvacuationDeviceSettingsService,
    private readonly ipstFeatures: IpstFeatureService,
    private readonly ipstSettingsService: IpstSettingsService,
    private readonly orderGatewayFeatureService: OrderGatewayFeatureService,
    private readonly lifService: LifService,
    private readonly fireFighterSettingsService: FireFighterSettingsService
  ) {}
}
