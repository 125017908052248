import { createSelector } from '@ngrx/store';
import { EntityList, GuidString, MapMode } from 'core/models';

import { Dictionary } from '@ngrx/entity';
import { MapDto } from 'core/dtos';
import * as fromMapsFeatureState from '../reducers';
import * as fromMaps from '../reducers/maps.reducer';

const selectMapsState = createSelector(
  fromMapsFeatureState.getMapsFeatureState,
  fromMapsFeatureState.getMapsState
);

export const selectMapsLoading = createSelector(selectMapsState, fromMaps.getLoading);

export const selectMapsLoaded = createSelector(selectMapsState, fromMaps.getLoaded);

export const selectMapsErrorMessage = createSelector(selectMapsState, fromMaps.getErrorMessage);

export const selectMapId = createSelector(selectMapsState, fromMaps.getSelectedMapId);

export const selectMapsEntities = createSelector(selectMapsState, fromMaps.getMapsEntities);

export const selectAllMaps = createSelector(selectMapsState, fromMaps.getAllMaps);

export const selectMapMode = createSelector(selectMapsState, fromMaps.getMapMode);

export const selectIsMissionMode = createSelector(selectMapsState, fromMaps.getIsMissionMode);

export const isPoiCreateMode = createSelector(selectMapMode, mode => MapMode.Poi === mode);

export const isZoneCreateMode = createSelector(selectMapMode, mode => MapMode.Zone === mode);

export const isZoneSetCreateMode = createSelector(selectMapMode, mode => MapMode.ZoneSet === mode);

export const isPillarsGridCreateMode = createSelector(
  selectMapMode,
  mode => MapMode.PillarsGrid === mode
);

export const isVehicleEmulatorCreateMode = createSelector(
  selectMapMode,
  mode => MapMode.VehicleEmulator === mode
);

export const isBackgroundImageCreateMode = createSelector(
  selectMapMode,
  mode => MapMode.AddBackgroundImage === mode
);

export const isBaseImageCreateMode = createSelector(
  selectMapMode,
  mode => MapMode.AddBaseMapImage === mode
);

export const isNavigationLayerMode = createSelector(
  selectMapMode,
  mode => MapMode.NavigationLayers === mode
);

export const isVehicleConnectionHealthMode = createSelector(
  selectMapMode,
  mode => MapMode.VehicleConnectionHealth === mode
);

export const isRouteConfigurationMode = createSelector(
  selectMapMode,
  mode => MapMode.RouteConfiguration === mode
);

export const isVehicleLocalizeMode = createSelector(
  selectMapMode,
  mode => MapMode.VehicleLocalize === mode
);

export const isSelectViewMode = createSelector(
  selectMapMode,
  mode =>
    mode !== undefined &&
    [MapMode.Poi, MapMode.Zone, MapMode.PillarsGrid, MapMode.VehicleEmulator].includes(mode)
);

export const isVehicleMode = createSelector(selectMapMode, mode => MapMode.Vehicle === mode);

export const selectMapsList = createSelector(selectAllMaps, (maps): EntityList[] =>
  maps.map(({ id, name }) => ({ id, name }))
);

export const selectSelectedMap = createSelector(
  selectMapId,
  selectMapsEntities,
  (selectedMapId, maps) => maps[selectedMapId.toString()]
);

export const selectSelectedMapEmpty = createSelector(
  selectMapsLoaded,
  selectMapId,
  selectMapsEntities,
  (loaded, selectedMapId, maps: Dictionary<MapDto>) => {
    return getMapEmpty(loaded, selectedMapId, maps);
  }
);

function getMapEmpty(loaded: boolean, selectedMapId: GuidString, maps: Dictionary<MapDto>) {
  if (!loaded) {
    return;
  }

  const emptyList = Object.keys(maps).length === 0;

  if (selectedMapId === '' && emptyList) {
    return true;
  }

  if (selectedMapId === '') return;

  if (!maps || emptyList) {
    return true;
  }

  if (maps[selectedMapId.toString()] === undefined) {
    return;
  }

  return false;
}
