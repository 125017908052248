import { createAction, props } from '@ngrx/store';
import { MapDto, MapImage, NewMapDto, PreviewDeleteMapDto } from 'core/dtos';
import { GuidString, MapMode } from 'core/models';

export enum MapsTypes {
  SelectMap = '[Maps] Select map',
  LoadMapView = '[Maps] Load map view',
  ShowMapView = '[Maps] Show map view',
  MapMode = '[Maps] Set Map Mode',
  IsMissionMode = '[Maps] Set is Mission Mode',

  LoadMaps = '[Maps] Load Maps',
  LoadMapsSuccess = '[Maps] Load Maps Success',
  LoadMapsFailure = '[Maps] Load Maps Fail',
  AddMapSuccess = '[Maps] Add Map Success',
  CreateMapContainer = '[Maps] Create Map Container',
  CreateMapContainerSuccess = '[Maps] Create Map Container Success',
  CreateMapContainerFailure = '[Maps] Create Map Container Failure',
  CreateMapFromImage = '[Maps] Save Map From Image',
  CreateMapSuccess = '[Maps] Save Map Success',
  CreateMapFailure = '[Maps] Save Map Failure',
  UpdateMap = '[Maps] Update Map',
  UpdateMapSuccess = '[Maps] Update Map Success',
  UpdateMapFailure = '[Maps] Update Map Failure',
  PreviewDeleteMap = '[Maps] Preview Delete Map',
  PreviewDeleteMapSuccess = '[Maps] Preview Delete Map Success',
  PreviewDeleteMapFailure = '[Maps] Preview Delete Map Failure',
  DeleteMap = '[Maps] Delete Map',
  DeleteMapSuccess = '[Maps] Delete Map Success',
  DeleteMapFailure = '[Maps] Delete Map Failure',
  AddBackground = '[Maps] Add Background Image',
  AddBackgroundSuccess = '[Maps] Add Background Image Success',
  AddBackgroundFailure = '[Maps] Add Background Image Failure',
  UpdateBackground = '[Maps] Update Background Image',
  UpdateBackgroundSuccess = '[Maps] Update Background Image Success',
  UpdateBackgroundFailure = '[Maps] Update Background Image Failure',
  UpdateBackgroundUrlSuccess = '[Maps] Update Background Image Url Success',
}

export enum SignalRMapsTypes {
  SignalrCreateMap = '[SignalR] Create Map',
  SignalrUpdateMap = '[SignalR] Update Map',
  SignalrDeleteMap = '[SignalR] Delete Map',
}

// #region LOAD
export const loadMaps = createAction(MapsTypes.LoadMaps);

export const loadMapsSuccess = createAction(MapsTypes.LoadMapsSuccess, props<{ maps: MapDto[] }>());

export const loadMapsFailure = createAction(
  MapsTypes.LoadMapsFailure,
  props<{ errorMessage: string }>()
);

export const showMapView = createAction(MapsTypes.ShowMapView);

export const selectMap = createAction(MapsTypes.SelectMap, props<{ mapId: GuidString }>());

export const loadMapView = createAction(MapsTypes.LoadMapView, props<{ mapId: GuidString }>());

export const setMapMode = createAction(MapsTypes.MapMode, props<{ mode: MapMode }>());

// #endregion

// #region Create & Update
export const addMapSuccess = createAction(MapsTypes.AddMapSuccess, props<{ map: MapDto }>());

export const updateMap = createAction(MapsTypes.UpdateMap, props<{ map: MapDto }>());

export const updateMapSuccess = createAction(MapsTypes.UpdateMapSuccess, props<{ map: MapDto }>());

export const updateMapFailure = createAction(
  MapsTypes.UpdateMapFailure,
  props<{ errorMessage: string }>()
);

export const createMapContainer = createAction(
  MapsTypes.CreateMapContainer,
  props<{ newMap: NewMapDto }>()
);

export const createMapContainerSuccess = createAction(
  MapsTypes.CreateMapContainerSuccess,
  props<{ map: MapDto }>()
);

export const createMapContainerFailure = createAction(
  MapsTypes.CreateMapContainerFailure,
  props<{ errorMessage: string }>()
);

export const createMapFromImage = createAction(
  MapsTypes.CreateMapFromImage,
  props<{ newMap: NewMapDto }>()
);

export const createMapSuccess = createAction(MapsTypes.CreateMapSuccess);

export const createMapFailure = createAction(
  MapsTypes.CreateMapFailure,
  props<{ errorMessage: string }>()
);
// #endregion

// #region Delete Map
export const deleteMap = createAction(MapsTypes.DeleteMap, props<{ map: MapDto }>());

export const previewDeleteMap = createAction(
  MapsTypes.PreviewDeleteMap,
  props<{ mapId: GuidString }>()
);

export const previewDeleteMapSuccess = createAction(
  MapsTypes.PreviewDeleteMapSuccess,
  props<{ previewDeleteMapDTO: PreviewDeleteMapDto }>()
);

export const previewDeleteMapFailure = createAction(
  MapsTypes.PreviewDeleteMapFailure,
  props<{ errorMessage: string }>()
);

export const deleteMapSuccess = createAction(MapsTypes.DeleteMapSuccess, props<{ map: MapDto }>());

export const deleteMapFailure = createAction(
  MapsTypes.DeleteMapFailure,
  props<{ errorMessage: string }>()
);
// #endregion

// #region SignalR
export const setIsMissionMode = createAction(
  MapsTypes.IsMissionMode,
  props<{ isMissionMode: boolean }>()
);

export const signalRCreateMap = createAction(
  SignalRMapsTypes.SignalrCreateMap,
  props<{ map: MapDto }>()
);

export const signalRUpdateMap = createAction(
  SignalRMapsTypes.SignalrUpdateMap,
  props<{ map: MapDto }>()
);

export const signalRDeleteMap = createAction(
  SignalRMapsTypes.SignalrDeleteMap,
  props<{ map: MapDto }>()
);
// #endregion

// #region Background
export const addBackgroundImage = createAction(
  MapsTypes.AddBackground,
  props<{ mapId: GuidString; backgroundImage: MapImage }>()
);

export const addBackgroundImageSuccess = createAction(
  MapsTypes.AddBackgroundSuccess,
  props<{ mapId: GuidString; backgroundTransparency: number }>()
);

export const addBackgroundImageFailure = createAction(
  MapsTypes.AddBackgroundFailure,
  props<{ errorMessage: string }>()
);

export const updateBackgroundImage = createAction(
  MapsTypes.UpdateBackground,
  props<{ mapId: GuidString; backgroundImage: MapImage }>()
);

export const updateBackgroundImageSuccess = createAction(
  MapsTypes.UpdateBackgroundSuccess,
  props<{ mapId: GuidString; backgroundTransparency: number }>()
);

export const updateBackgroundImageFailure = createAction(
  MapsTypes.UpdateBackgroundFailure,
  props<{ errorMessage: string }>()
);

export const updateBackgroundImageUrlSuccess = createAction(
  MapsTypes.UpdateBackgroundUrlSuccess,
  props<{ mapId: GuidString; backgroundUrl: string }>()
);
// #endregion
