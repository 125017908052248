import {
  AdxCollisionRecordDto,
  CollisionPoint,
  MergedVehicle,
  VehicleDto,
  VehicleLocationSignalr,
} from 'core/dtos';
import { Pos2D, Vector2D } from 'core/models';

export function setVehicleDefaultValues(dto: VehicleDto[]): MergedVehicle[] {
  const fillTheta = (t: Vector2D): Pos2D => ({
    x: t.x,
    y: t.y,
    theta: t.theta ?? 0,
  });

  return dto.map(v => ({
    ...v,
    path: v.path?.map(fillTheta) ?? [],
    trailers: v.trailers ?? null,
  }));
}

export function setUpdateToVehicles(
  vehicles: MergedVehicle[],
  updates: VehicleLocationSignalr[]
): MergedVehicle[] {
  for (const update of updates) {
    const existing = vehicles.find(it => it.id === update.id);

    if (existing && update) {
      // Performance critical so avoid the spread operator here
      existing.name = update.name;
      existing.maintenanceModeEnabled = update.maintenanceModeEnabled;
      existing.hasError = update.hasError;
      existing.isPaused = update.isPaused;
      existing.isCharging = update.isCharging;
      existing.isBusy = update.isBusy;
      existing.isRetired = update.isRetired;
      existing.isSwitchedOff = update.isSwitchedOff;
      existing.isConnected = update.isConnected;
      existing.isLoaded = update.isLoaded;
      existing.mode = update.mode;
      existing.pose2D = update.pose2D;
      existing.path = update.path;
      existing.trailers = update.trailers;
    }
  }
  return vehicles;
}

export function mapAdxRecordToCollision(record: AdxCollisionRecordDto): CollisionPoint {
  const collision: CollisionPoint = {
    id: record.message.collisionId,
    mapId: record.message.mapId,
    prioritizedVehicleId: record.message.prioritizedVehicle.vehicleId,
    pausedVehicleId: record.message.pausedVehicle.vehicleId,
    pose2D: {
      orientation: 0,
      x: record.message.collisionPoint.x,
      y: record.message.collisionPoint.y,
    },
    collisionCase: record.message.collisionCase,
    releaseType: record.message.releaseType,
    isDeadlockCollision: record.message.isDeadlockCollision,
  };
  return collision;
}
