import { EMPTY_GUID } from 'core/constants/constant';
import {
  BatteryLevelStatus,
  HardwareVersion,
  LoadType,
  Pose2D,
  ReducedMap,
  ReducedVehicle,
  SoftwareUpdateStatus,
  VehicleAvailability,
  VehicleMode,
  VehicleStatus,
  VehicleType,
} from 'core/models';

export function createDummyVehicle(map: ReducedMap, pose2d: Pose2D): ReducedVehicle {
  return {
    id: EMPTY_GUID,
    mapId: map.id,
    pose2D: pose2d,
    internalIdentifier: '',
    name: '',
    mode: VehicleMode.Autonomous,
    availability: VehicleAvailability.Available,
    batteryLevel: 100,
    batteryLevelStatus: BatteryLevelStatus.Green,
    status: VehicleStatus.Idle,
    isLoaded: false,
    softwareVersion: '',
    softwareVersionChangedDateUtc: new Date().toDateString(),
    softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
    softwareDownloadPercentage: 0,
    initializationDateTime: new Date().toDateString(),
    lastStateMessageProcessedUtc: new Date().toDateString(),
    ipAddress: '',
    maintenanceModeEnabled: false,
    isErrorForwardingEnabled: true,
    hasError: false,
    isPaused: false,
    isCharging: false,
    isBusy: false,
    isRetired: false,
    isEmulator: true,
    brakeTestRequired: false,
    workingAreaId: map.workAreaId,
    isConnected: true,
    isSwitchedOff: false,
    vehicleType: VehicleType.UnitLoad,
    zoneSetId: EMPTY_GUID,
    desiredZoneSetId: EMPTY_GUID,
    isActiveZoneSet: true,
    fleetId: null,
    vehicleKey: '123456788',
    trailers: null,
    trailerCount: 0,
    hardwareVersion: HardwareVersion.StrVersion3,
    supportedLoadTypes: [],
    loadType: LoadType.Glt1400X1250,
  };
}
