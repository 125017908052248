<ds-box>
  <ds-box-header></ds-box-header>
  <ds-box-content divider="full">
    <div class="row">
      <div class="col" style="display: flex">
        <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.recipientKeys' | translate }}</h4>
      </div>
    </div>
    <div>
      <div>
        <label ds-label for="recipientList">
          {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
        </label>
        <ds-tag>{{
          'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate
        }}</ds-tag>
      </div>
    </div>

    <hr class="lineSep my-4x" />

    <div class="row">
      <div class="col" style="display: flex">
        <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.alertNowZone' | translate }}</h4>
      </div>
    </div>
    <div>
      <label ds-label for="Zone">
        {{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}
      </label>
      <div>{{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}</div>
    </div>

    <hr class="lineSep" />

    <div class="row">
      <div class="col" style="display: flex">
        <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}</h4>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="form-field-spacing col-sm-6">
          <label ds-label for="groupName">
            {{
              'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupName' | translate
            }}
          </label>
          <div>
            {{
              'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupName' | translate
            }}
          </div>
        </div>

        <div class="key-container col-sm-6">
          <label ds-label for="recipientKey">
            {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
          </label>
          <ds-tag> key </ds-tag>
        </div>

        <div class="vehicle-container col-sm-12">
          <label ds-label for="vehicles">
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicles' | translate }}
          </label>
          <ds-tag>
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicles' | translate }}
          </ds-tag>
        </div>
      </div>
    </div>
  </ds-box-content>
</ds-box>
