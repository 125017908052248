/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import {
  DockStepDto,
  DockToChargeStepDto,
  DockToMappingLookupStepDto,
  DropStepDto,
  EventDto,
  GotoAndPushSideButtonStepDto,
  GoToMappingLookupStepDto,
  GotoStepDto,
  LiftStepDto,
  MissionDto,
  NodeType,
  OpcuaDeviceNodeResponseModel,
  OpcuaDeviceResponseModel,
  ProcessChainDto,
  RaiseEventStepDto,
  StartToChargeStepDto,
  TurnDollyStepDto,
  WaitForEventStepDto,
  WaitForSapAcknowledgementDto,
  WaitForScanDestinationStepDto,
  WaitForSideButtonStepDto,
  WaitForWarehouseTaskAcknowledgementDto,
  WaitStepDto,
} from 'core/dtos';
import { ProcessChainGroupDto } from 'core/dtos/jobs/process-chain-group.dto';
import objectHelper from 'core/helpers/object.helper';
import {
  DockStepModel,
  DockToChargeStepModel,
  DockToMappingLookupStepModel,
  DropStepModel,
  EventDetail,
  EventType,
  GotoAndPushSideButtonStepModel,
  GoToMappingLookupStepModel,
  GotoStepModel,
  GuidString,
  LiftStepModel,
  LoadType,
  MappingType,
  Mission,
  ProcessChain,
  ProcessChainDetails,
  ProcessChainGroupExecutionMode,
  ProcessChainGroupHierarchyModel,
  ProcessChainGroupModel,
  ProcessChainGroupTraceHierarchyModel,
  ProcessChainTraceStatus,
  ProcessChainTriggerType,
  ProcessChainType,
  StartToChargeStepModel,
  StepType,
  StepTypeBackend,
  TableProcessChain,
  TableProcessChainGroup,
  TreeOverviewProcessChain,
  TurnDollyStepModel,
  VehicleType,
  WaitForEndOfStepStepModel,
  WaitForSapAcknowledgementStepModel,
  WaitForScanDestinationStepModel,
  WaitForSideButtonStepModel,
  WaitForWarehouseTaskAcknowledgementModel,
  WaitStepModel,
} from 'core/models';

const processChainId1: GuidString = 'pc ID 1';
const processChainId2: GuidString = 'pc ID 2';
const processChainId3: GuidString = 'pc ID 3';
const processChainId5: GuidString = 'pc ID 5';
const processChainId7: GuidString = 'pc ID 7';
const waitingQueueId1: GuidString = 'queue ID 1';
const waitingQueueId2: GuidString = 'queue ID 2';
const processChainMission1: GuidString = 'process Chain MissionId 1';
const waitingQueueMission1: GuidString = 'queue MissionId 1';
const waitingQueueStepWithPole_PoiId: GuidString = '00000000-0000-0000-0000-000000000004';
const processChainGroupId1: GuidString = 'pcg ID 1';
const processChainGroupId2: GuidString = 'pcg ID 2';

const mapId1: GuidString = 'map ID 1';
const mapName1 = 'map name 1';
const mapId2: GuidString = 'map ID 2';
const mapName2 = 'map name 2';
const fleetId1: GuidString = 'fleet ID 1';
const fleetName1 = 'fleet name 1';
const processEventId1: GuidString = 'process event ID 1';
const processEventName1 = 'process event name 1';
const connectionEventId1: GuidString = 'connection event ID 1';
const connectionEventName1 = 'connection event name 1';
const amaEventId1: GuidString = 'ama event ID 1';
const amaEventName1 = 'ama event name 1';
const connectionId1: GuidString = 'Connection Id 1';
const deviceIdAma1 = 'device Id 2';

const waitStepDto1: WaitStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.Wait,
  timeSpanMillis: 3000,
};

const waitStep1: WaitStepModel = {
  sequenceNumber: 0,
  type: StepType.Wait,
  timeSpanS: 3,
};

const liftStepDto1: LiftStepDto = {
  sequenceNumber: 1,
  type: StepTypeBackend.Lift,
};

const liftStep1: LiftStepModel = {
  sequenceNumber: 1,
  type: StepType.Lift,
};

const dropStepDto1: DropStepDto = {
  sequenceNumber: 2,
  type: StepTypeBackend.Drop,
};

const dropStep1: DropStepModel = {
  sequenceNumber: 2,
  type: StepType.Drop,
};

const gotoStepDto1: GotoStepDto = {
  sequenceNumber: 3,
  type: StepTypeBackend.Goto,
  pointOfInterestGroupId: 'poi Group ID 1',
  pointOfInterestName: 'poi Name 1',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const gotoStep1: GotoStepModel = {
  sequenceNumber: 3,
  type: StepType.Goto,
  pointOfInterestGroupId: 'poi Group ID 1',
  pointOfInterestName: 'poi Name 1',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const dockStepDto1: DockStepDto = {
  sequenceNumber: 4,
  type: StepTypeBackend.Dock,
  pointOfInterestGroupId: 'poi Group ID 2',
  pointOfInterestName: 'poi Name 2',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const dockStep1: DockStepModel = {
  sequenceNumber: 4,
  type: StepType.Dock,
  pointOfInterestGroupId: 'poi Group ID 2',
  pointOfInterestName: 'poi Name 2',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const dockToChargeStepDto1: DockToChargeStepDto = {
  sequenceNumber: 5,
  type: StepTypeBackend.DockToCharge,
  pointOfInterestGroupId: 'poi Group ID 3',
  pointOfInterestName: 'poi Name 3',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const dockToChargeStep1: DockToChargeStepModel = {
  sequenceNumber: 5,
  type: StepType.DockToCharge,
  pointOfInterestGroupId: 'poi Group ID 3',
  pointOfInterestName: 'poi Name 3',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const raiseEventStepDto1: RaiseEventStepDto = {
  sequenceNumber: 6,
  type: StepTypeBackend.RaiseEvent,
  eventId: processEventId1,
  eventName: processEventName1,
};

const raiseConnectionEventStepDto1: RaiseEventStepDto = {
  sequenceNumber: 8,
  type: StepTypeBackend.RaiseEvent,
  eventId: connectionEventId1,
  eventName: connectionEventName1,
};

const raiseForAmaEventStepDto1: RaiseEventStepDto = {
  sequenceNumber: 10,
  type: StepTypeBackend.RaiseEvent,
  eventId: amaEventId1,
  eventName: amaEventName1,
};

const startToChargeStepDto1: StartToChargeStepDto = {
  sequenceNumber: 7,
  type: StepTypeBackend.StartToCharge,
};

const startToChargeStepDto2: StartToChargeStepDto = {
  sequenceNumber: 9,
  type: StepTypeBackend.StartToCharge,
};

const startToChargeStepDto3: StartToChargeStepDto = {
  sequenceNumber: 8,
  type: StepTypeBackend.StartToCharge,
};

const startToChargeStep1: StartToChargeStepModel = {
  sequenceNumber: 6,
  type: StepType.StartToCharge,
};

const startToChargeStep2: StartToChargeStepModel = {
  sequenceNumber: 7,
  type: StepType.StartToCharge,
};

const waitForProcessEventStepDto1: WaitForEventStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.WaitForEvent,
  eventId: processEventId1,
  eventName: processEventName1,
};

const waitForEndOfStepStep1: WaitForEndOfStepStepModel = {
  sequenceNumber: 0,
  type: StepType.WaitForEndOfStep,
  waitForStep: {
    missionIndex: 0,
    sequenceNumber: 5,
  },
  waitForStepId: '0-5',
};

const goToMappingLookUpStepDto1: GoToMappingLookupStepDto = {
  sequenceNumber: 1,
  type: StepTypeBackend.GoToMapping,
  mappingType: MappingType.Destination,
  fallbackPointOfInterestGroupId: null,
  fallbackPointOfInterestId: null,
  fallbackPointOfInterestName: null,
  hasStepCompletionNotificationEnabled: false,
};

const goToMappingLookUpStep1: GoToMappingLookupStepModel = {
  sequenceNumber: 1,
  type: StepType.GoToMapping,
  mappingType: MappingType.Destination,
  fallbackPointOfInterestGroupId: null,
  fallbackPointOfInterestId: null,
  fallbackPointOfInterestName: null,
  hasStepCompletionNotificationEnabled: false,
};

const dockToMappingLookUpStepDto1: DockToMappingLookupStepDto = {
  sequenceNumber: 2,
  type: StepTypeBackend.DockToMapping,
  mappingType: MappingType.Source,
  fallbackPointOfInterestGroupId: null,
  fallbackPointOfInterestId: null,
  fallbackPointOfInterestName: null,
  hasStepCompletionNotificationEnabled: false,
};

const dockToMappingLookUpStep1: DockToMappingLookupStepModel = {
  sequenceNumber: 2,
  type: StepType.DockToMapping,
  mappingType: MappingType.Source,
  fallbackPointOfInterestGroupId: null,
  fallbackPointOfInterestId: null,
  fallbackPointOfInterestName: null,
  hasStepCompletionNotificationEnabled: false,
};

const gotoStepDto2: GotoStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.Goto,
  pointOfInterestGroupId: 'poi Group ID 2',
  pointOfInterestName: 'poi Name 2',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const gotoStep2: GotoStepModel = {
  sequenceNumber: 0,
  type: StepType.Goto,
  pointOfInterestGroupId: 'poi Group ID 2',
  pointOfInterestName: 'poi Name 2',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const waitForScanDestinationStep1: WaitForScanDestinationStepModel = {
  sequenceNumber: 0,
  type: StepType.WaitForScanDestination,
  nodeName: 'AMA Ping',
  device: 'AMAStreamingService1',
  streamingService: 'Ama Devices',
  destination: 'Destination',
  huNumber: '1',
};

const waitForScanDestinationStepDto1: WaitForScanDestinationStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.WaitForScanDestination,
  nodeName: 'AMA Ping',
  device: 'AMAStreamingService1',
  streamingService: 'Ama Devices',
  destination: 'Destination',
  huNumber: '1',
};

const waitForWarehouseTaskAcknowledgementStepDto1: WaitForWarehouseTaskAcknowledgementDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.WaitForWarehouseTaskAcknowledgement,
  eventKey: 'confirm-warehouse-task',
};

const waitForWarehouseTaskAcknowledgementStep1: WaitForWarehouseTaskAcknowledgementModel = {
  sequenceNumber: 0,
  type: StepType.WaitForWarehouseTaskAcknowledgement,
  eventKey: 'confirm-warehouse-task',
};

const turnDollyStepDto: TurnDollyStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.TurnDolly,
  pointOfInterestGroupId: 'turn dolly poi',
  pointOfInterestName: 'turn dolly poi',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const gotoAndPushSideButtonStepDto: GotoAndPushSideButtonStepDto = {
  sequenceNumber: 0,
  type: StepTypeBackend.GotoAndPushSideButton,
  pointOfInterestGroupId: 'Goto and push side button poi',
  pointOfInterestName: 'Goto and push side button poi',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const waitForSideButtonStepDto: WaitForSideButtonStepDto = {
  sequenceNumber: 1,
  type: StepTypeBackend.WaitForSideButton,
};

const waitForScanDestinationStepDto: WaitForScanDestinationStepDto = {
  sequenceNumber: 2,
  type: StepTypeBackend.WaitForScanDestination,
  device: '',
  streamingService: '',
  nodeName: '',
  destination: null,
  huNumber: null,
};

const waitForSapAcknowledgementStepDto: WaitForSapAcknowledgementDto = {
  sequenceNumber: 3,
  type: StepTypeBackend.WaitForSapAcknowledgement,
};

const turnDollyStep: TurnDollyStepModel = {
  sequenceNumber: 0,
  type: StepType.TurnDolly,
  pointOfInterestGroupId: 'turn dolly poi',
  pointOfInterestName: 'turn dolly poi',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const gotoAndPushSideButtonStep: GotoAndPushSideButtonStepModel = {
  sequenceNumber: 0,
  type: StepType.GotoAndPushSideButton,
  pointOfInterestGroupId: 'Goto and push side button poi',
  pointOfInterestName: 'Goto and push side button poi',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const waitForSideButtonStep: WaitForSideButtonStepModel = {
  sequenceNumber: 1,
  type: StepType.WaitForSideButton,
};

const waitForScanDestinationStep: WaitForScanDestinationStepModel = {
  sequenceNumber: 2,
  type: StepType.WaitForScanDestination,
  device: '',
  streamingService: '',
  nodeName: '',
  destination: null,
  huNumber: null,
};

const waitForSapAcknowledgementStep: WaitForSapAcknowledgementStepModel = {
  sequenceNumber: 3,
  type: StepType.WaitForSapAcknowledgement,
};

const queueStep1: GotoStepModel = {
  sequenceNumber: 0,
  type: StepType.Goto,
  pointOfInterestGroupId: 'wait poi ID 1',
  pointOfInterestName: 'wait poi Name 1',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const queueStep2: GotoStepModel = {
  sequenceNumber: 1,
  type: StepType.Goto,
  pointOfInterestGroupId: 'wait poi ID 2',
  pointOfInterestName: 'wait poi Name 2',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

const queueStepPole: GotoStepModel = {
  sequenceNumber: 2,
  type: StepType.Goto,
  pointOfInterestGroupId: waitingQueueStepWithPole_PoiId,
  pointOfInterestName: 'pole poi Name',
  trajectory: [],
  hasStepCompletionNotificationEnabled: false,
};

// ------------------------------------------------------------------------------------------------

const processEventDto1: EventDto = {
  id: processEventId1,
  name: processEventName1,
  type: EventType.Process,
  connectionId: '',
  deviceId: '',
};

const processEvent1: EventDetail = {
  id: processEventId1,
  name: processEventName1,
  type: EventType.Process,
  connectionId: '',
  deviceId: '',
  missionIndex: 0,
  sequenceNumber: 5,
  usedDuringSave: false,
};

const connectionEventDto1: EventDto = {
  id: connectionEventId1,
  name: connectionEventName1,
  type: EventType.Connection,
  connectionId: connectionId1,
  deviceId: 'device Id 1',
};

const amaEventDto1: EventDto = {
  id: amaEventId1,
  name: amaEventName1,
  type: EventType.Ama,
  connectionId: 'Connection Id 2',
  deviceId: deviceIdAma1,
};

const missionDto1: MissionDto = {
  id: 'mission ID 1',
  name: 'mission name 1',
  mapId: mapId1,
  processChainId: processChainId1,
  steps: [
    waitStepDto1,
    liftStepDto1,
    dropStepDto1,
    gotoStepDto1,
    dockStepDto1,
    dockToChargeStepDto1,
    raiseEventStepDto1,
    startToChargeStepDto1,
    raiseConnectionEventStepDto1,
    startToChargeStepDto2,
    raiseForAmaEventStepDto1,
  ],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 35,
  isAssignmentDelayTimeActive: true,
  assignmentDelayTime: 3,
};

const missionDto2: MissionDto = {
  id: 'mission ID 2',
  name: 'mission name 2',
  mapId: mapId1,
  processChainId: processChainId1,
  steps: [waitForProcessEventStepDto1, goToMappingLookUpStepDto1, dockToMappingLookUpStepDto1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionDto3: MissionDto = {
  id: 'mission ID 3',
  name: 'mission name 3',
  mapId: mapId2,
  processChainId: processChainId2,
  steps: [gotoStepDto2],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 30,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionDto4: MissionDto = {
  id: 'mission ID 4',
  name: 'mission name 4',
  mapId: mapId2,
  processChainId: processChainId3,
  steps: [waitForProcessEventStepDto1, goToMappingLookUpStepDto1, dockToMappingLookUpStepDto1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionDto5: MissionDto = {
  id: 'mission ID 1',
  name: 'mission name 1',
  mapId: mapId1,
  processChainId: processChainId1,
  steps: [
    waitStepDto1,
    liftStepDto1,
    dropStepDto1,
    gotoStepDto1,
    dockStepDto1,
    dockToChargeStepDto1,
    raiseEventStepDto1,
    startToChargeStepDto1,
    startToChargeStepDto3,
  ],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 35,
  isAssignmentDelayTimeActive: true,
  assignmentDelayTime: 3,
};

const missionDto6: MissionDto = {
  id: 'mission 6',
  name: 'mission 6',
  mapId: mapId2,
  processChainId: processChainId3,
  steps: [waitForScanDestinationStepDto1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 22,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionWithTurnDollyStepDto: MissionDto = {
  id: 'turn Dolly Mission',
  name: 'Turn Dolly Mission',
  mapId: mapId2,
  processChainId: 'processChainDtoWithTurnDollyStepDto',
  steps: [
    turnDollyStepDto,
    waitForSideButtonStepDto,
    waitForScanDestinationStepDto,
    waitForSapAcknowledgementStepDto,
  ],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 17,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionWithTurnDollyStep: Mission = {
  id: 'turn Dolly Mission',
  name: 'Turn Dolly Mission',
  mapId: mapId2,
  processChainId: 'processChainDtoWithTurnDollyStepDto',
  steps: [
    turnDollyStep,
    waitForSideButtonStep,
    waitForScanDestinationStep,
    waitForSapAcknowledgementStep,
  ],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 17,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionWithGotoAndPushSideButtonStepDto: MissionDto = {
  id: 'GotoAndPushSideButtonMission',
  name: 'Goto And Push Side Button Mission',
  mapId: mapId2,
  processChainId: 'processChainDtoWithGotoAndPushSideButtonStepDto',
  steps: [gotoAndPushSideButtonStepDto, waitForSideButtonStepDto, waitForScanDestinationStepDto],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionWithGotoAndPushSideButtonStep: Mission = {
  id: 'GotoAndPushSideButtonMission',
  name: 'Goto And Push Side Button Mission',
  mapId: mapId2,
  processChainId: 'processChainDtoWithGotoAndPushSideButtonStepDto',
  steps: [gotoAndPushSideButtonStep, waitForSideButtonStep, waitForScanDestinationStep],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const mission1: Mission = {
  id: 'mission ID 1',
  name: 'mission name 1',
  mapId: mapId1,
  processChainId: processChainId1,
  steps: [
    waitStep1,
    liftStep1,
    dropStep1,
    gotoStep1,
    dockStep1,
    dockToChargeStep1,
    startToChargeStep1,
    startToChargeStep2,
  ],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 35,
  isAssignmentDelayTimeActive: true,
  assignmentDelayTime: 3,
};

const mission2: Mission = {
  id: 'mission ID 2',
  name: 'mission name 2',
  mapId: mapId1,
  processChainId: processChainId1,
  steps: [waitForEndOfStepStep1, goToMappingLookUpStep1, dockToMappingLookUpStep1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const mission3: Mission = {
  id: 'mission ID 3',
  name: 'mission name 3',
  mapId: mapId2,
  processChainId: processChainId2,
  steps: [gotoStep2],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 30,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const mission4: Mission = {
  id: 'mission x',
  name: 'mission x',
  mapId: mapId2,
  processChainId: processChainId3,
  steps: [waitForEndOfStepStep1, goToMappingLookUpStep1, dockToMappingLookUpStep1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const mission6: Mission = {
  id: 'mission 6',
  name: 'mission 6',
  mapId: mapId2,
  processChainId: processChainId3,
  steps: [waitForScanDestinationStep1],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 22,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const queueMission1: Mission = {
  id: waitingQueueMission1,
  name: 'Queue Mission 1',
  mapId: mapId2,
  processChainId: waitingQueueId1,
  steps: [queueStep1, queueStep2],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const queueMissionWithPole: Mission = {
  id: waitingQueueMission1,
  name: 'Queue Mission with Pole',
  mapId: mapId2,
  processChainId: waitingQueueId2,
  steps: [queueStep1, queueStep2, queueStepPole],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: true,
  maxExecutionTime: 5,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const missionWithPole: Mission = {
  id: processChainMission1,
  name: 'Mission with Pole',
  mapId: mapId2,
  processChainId: processChainId7,
  steps: [queueStep1, queueStep2, queueStepPole],
  abortAction: { stepNumber: 0, device: '', nodeName: '', value: '' },
  pauseConditions: [{ stepNumber: 0, device: '', nodeName: '', value: '' }],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 10,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const processChainDto1: ProcessChainDto = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  isActive: true,
  workAreaId: 'areaId 1',
  missionIds: [missionDto1.id, missionDto2.id],
  missions: [missionDto1, missionDto2],
  events: [processEventDto1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: 'AMA',
  deviceTriggerNode: 'AMA NODE',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: processChainGroupId1,
  loadType: LoadType.Glt1400X1250,
};

const processChainDto5: ProcessChainDto = {
  id: processChainId5,
  name: 'pc name 5',
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  workAreaId: 'areaId 1',
  missionIds: [missionDto1.id, missionDto2.id],
  missions: [missionDto1, missionDto2],
  events: [processEventDto1, connectionEventDto1, amaEventDto1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainWithWaitForScanDestinationStepDto: ProcessChainDto = {
  id: 'processChainWithWaitForScanDestinationStepDto',
  name: 'processChainWithWaitForScanDestinationStepDto',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionDto6.id],
  missions: [missionDto6],
  events: [],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainWithWaitForScanDestinationStep: ProcessChain = {
  id: 'processChainWithWaitForScanDestinationStepDto',
  name: 'processChainWithWaitForScanDestinationStepDto',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionDto6.id],
  missions: [mission6],
  fleetId: fleetId1,
  eventDetails: [],
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const missionWithWaitForWarehouseTaskAckStepDto: MissionDto = {
  ...missionDto6,
  steps: [waitForWarehouseTaskAcknowledgementStepDto1],
};

const missionWithWaitForWarehouseTaskAckStep: Mission = {
  ...mission6,
  steps: [waitForWarehouseTaskAcknowledgementStep1],
};

const processChainWithWaitForWarehouseTaskAcknowledgementStepDto: ProcessChainDto = {
  id: 'processChainWithWaitForWarehouseTaskAcknowledgementStepDto',
  name: 'processChainWithWaitForWarehouseTaskAcknowledgementStepDto',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithWaitForWarehouseTaskAckStepDto.id],
  missions: [missionWithWaitForWarehouseTaskAckStepDto],
  events: [],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainWithWaitForWarehouseTaskAcknowledgementStep: ProcessChain = {
  id: 'processChainWithWaitForWarehouseTaskAcknowledgementStepDto',
  name: 'processChainWithWaitForWarehouseTaskAcknowledgementStepDto',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithWaitForWarehouseTaskAckStepDto.id],
  missions: [missionWithWaitForWarehouseTaskAckStep],
  fleetId: fleetId1,
  eventDetails: [],
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainDtoWithTurnDollyStepDto: ProcessChainDto = {
  id: 'processChainDtoWithTurnDollyStep',
  name: 'processChainDtoWithTurnDollyStep',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithTurnDollyStepDto.id],
  missions: [missionWithTurnDollyStepDto],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  events: [],
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainDtoWithTurnDollyStep: ProcessChain = {
  id: 'processChainDtoWithTurnDollyStep',
  name: 'processChainDtoWithTurnDollyStep',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithTurnDollyStepDto.id],
  missions: [missionWithTurnDollyStep],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  eventDetails: [],
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainDtoWithGotoAndPushSideButtonStepDto: ProcessChainDto = {
  id: 'processChainDtoWithGotoAndPushSideButtonStep',
  name: 'processChainDtoWithGotoAndPushSideButtonStep',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithGotoAndPushSideButtonStepDto.id],
  missions: [missionWithGotoAndPushSideButtonStepDto],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  events: [],
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainDtoWithGotoAndPushSideButtonStep: ProcessChain = {
  id: 'processChainDtoWithGotoAndPushSideButtonStep',
  name: 'processChainDtoWithGotoAndPushSideButtonStep',
  materialNumber: '',
  source: '',
  destination: '',
  workAreaId: 'areaId 1',
  missionIds: [missionWithGotoAndPushSideButtonStep.id],
  missions: [missionWithGotoAndPushSideButtonStep],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'Ama 1 ',
  deviceTriggerNode: 'Node 1',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  eventDetails: [],
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const tableProcessChain1: TableProcessChain = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  isActive: true,
  fleetId: fleetId1,
  fleetName: fleetName1,
  mapId: mapId1,
  mapName: mapName1,
  type: ProcessChainType.Basic,
  processChainGroupId: processChainGroupId1,
  processChainGroupName: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  vehicleType: VehicleType.UnitLoad,
};

const treeOverviewProcessChain1: TreeOverviewProcessChain = {
  id: processChainId1,
  name: 'pc name 1',
};

const processChainDto2: ProcessChainDto = {
  id: processChainId2,
  name: 'pc name 2',
  materialNumber: 'mat 2',
  source: 'src 2',
  destination: 'dest 2',
  isActive: true,
  workAreaId: 'areaId 2',
  missionIds: [missionDto3.id],
  missions: [missionDto3],
  events: [],
  fleetId: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: 'pcg ID 1',
  loadType: LoadType.Glt1400X1250,
};

const processChainDto3: ProcessChainDto = {
  id: processChainId3,
  name: 'pc name 3',
  materialNumber: 'mat 3',
  source: 'src 3',
  destination: 'dest 3',
  isActive: true,
  workAreaId: 'areaId 3',
  missionIds: [missionDto4.id],
  missions: [missionDto4],
  events: [],
  fleetId: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  maxQueueLength: null,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChain1: ProcessChain = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  isActive: true,
  workAreaId: 'areaId 1',
  missionIds: [mission1.id, mission2.id],
  missions: [mission1, mission2],
  eventDetails: [processEvent1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: 'AMA',
  deviceTriggerNode: 'AMA NODE',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: processChainGroupId1,
  loadType: LoadType.Glt1400X1250,
};

const processChain2: ProcessChain = {
  id: processChainId2,
  name: 'pc name 2',
  materialNumber: 'mat 2',
  source: 'src 2',
  destination: 'dest 2',
  isActive: true,
  workAreaId: 'areaId 2',
  missionIds: [mission3.id],
  missions: [mission3],
  eventDetails: [],
  fleetId: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: processChainGroupId1,
  loadType: LoadType.Glt1400X1250,
};

const processChain3: ProcessChain = {
  id: processChainId3,
  name: 'pc name 3',
  materialNumber: 'mat 3',
  source: 'src 3',
  destination: 'dest 3',
  isActive: true,
  workAreaId: 'areaId 3',
  missionIds: [mission4.id],
  missions: [mission4],
  eventDetails: [],
  fleetId: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChain5: ProcessChain = {
  id: processChainId5,
  name: 'pc name 5',
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  workAreaId: 'areaId 3',
  missionIds: [mission4.id],
  missions: [mission4],
  eventDetails: [],
  fleetId: '',
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'AMA Device ',
  deviceTriggerNode: 'AMA Node',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChain6: ProcessChain = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  isActive: true,
  workAreaId: 'areaId 1',
  missionIds: [mission1.id, mission2.id],
  missions: [mission1, mission2],
  eventDetails: [processEvent1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.Device,
  deviceTriggerDevice: 'AMA',
  deviceTriggerNode: 'AMA NODE',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainReplenishmentTime1: ProcessChain = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  isActive: true,
  workAreaId: 'areaId 1',
  missionIds: [mission1.id, mission2.id],
  missions: [mission1, mission2],
  eventDetails: [processEvent1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: 'AMA',
  deviceTriggerNode: 'AMA NODE',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: 22,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const waitingQueue1: ProcessChain = {
  id: waitingQueueId1,
  name: 'Queue name 1',
  workAreaId: 'areaId 1',
  missionIds: [queueMission1.id],
  missions: [queueMission1],
  triggerType: ProcessChainTriggerType.System,
  type: ProcessChainType.WaitingQueue,
  maxQueueLength: 5,
  eventDetails: [],
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  fleetId: '',
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const getTableWaitingQueue1: TableProcessChain = {
  id: waitingQueueId1,
  name: 'Queue name 1',
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  fleetId: '',
  fleetName: '',
  mapId: mapId1,
  mapName: mapName1,
  type: ProcessChainType.WaitingQueue,
  processChainGroupId: '',
  processChainGroupName: '',
  triggerType: ProcessChainTriggerType.System,
  vehicleType: VehicleType.UnitLoad,
};

const waitingQueueWithPole: ProcessChain = {
  id: waitingQueueId2,
  name: 'Has Pole already',
  workAreaId: 'areaId 2',
  missionIds: [queueMissionWithPole.id],
  missions: [queueMissionWithPole],
  triggerType: ProcessChainTriggerType.System,
  type: ProcessChainType.WaitingQueue,
  maxQueueLength: 5,
  eventDetails: [],
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  fleetId: '',
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainWithPole: ProcessChain = {
  id: processChainId7,
  name: 'Process Chain With Pole',
  workAreaId: 'areaId 2',
  missionIds: [missionWithPole.id],
  missions: [missionWithPole],
  triggerType: ProcessChainTriggerType.System,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  eventDetails: [],
  materialNumber: '',
  source: '',
  destination: '',
  isActive: true,
  fleetId: '',
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '',
  loadType: LoadType.Glt1400X1250,
};

const waitingQueueDetails: ProcessChainDetails = {
  ...processChain1,
  fleetName: fleetName1,
  mapId: mapId1,
  mapName: mapName1,
  abortConnectionName: '',
  maxQueueLength: 5,
  missionDetails: [
    ...processChain1.missions.map(m => {
      return { ...m, assignableWaypoint: null };
    }),
  ],
  processChainGroupName: '-',
  loadType: LoadType.Glt1400X1250,
};

const processChainDetails1: ProcessChainDetails = {
  ...processChain1,
  fleetName: fleetName1,
  mapId: mapId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  mapName: mapName1,
  abortConnectionName: '',
  maxQueueLength: 0,
  missionDetails: [
    ...processChain1.missions.map(m => {
      return { ...m, assignableWaypoint: null };
    }),
  ],
  processChainGroupName: '-',
  loadType: LoadType.Glt1400X1250,
};

const processReplenishmentTimeChainDetails1: ProcessChainDetails = {
  ...processChainReplenishmentTime1,
  fleetName: fleetName1,
  mapId: mapId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  mapName: mapName1,
  abortConnectionName: '',
  maxQueueLength: 0,
  missionDetails: [
    ...processChainReplenishmentTime1.missions.map(m => {
      return { ...m, assignableWaypoint: null };
    }),
  ],
  processChainGroupName: '-',
  loadType: LoadType.Glt1400X1250,
};

const processChainDeviceDetails: ProcessChainDetails = {
  ...processChain6,
  fleetName: fleetName1,
  mapId: mapId1,
  mapName: mapName1,
  abortConnectionName: '',
  missionDetails: [
    ...processChain6.missions.map(m => {
      return { ...m, assignableWaypoint: null };
    }),
  ],
  processChainGroupName: '-',
  loadType: LoadType.Glt1400X1250,
};

const processChainDto4: ProcessChainDto = {
  id: processChainId1,
  name: 'pc name 1',
  materialNumber: 'mat 1',
  source: 'src 1',
  destination: 'dest 1',
  workAreaId: 'areaId 1',
  missionIds: [missionDto5.id, missionDto2.id],
  missions: [missionDto5, missionDto2],
  events: [processEventDto1],
  fleetId: fleetId1,
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: 'AMA',
  deviceTriggerNode: 'AMA NODE',
  deviceTriggerValue: 'True',
  deviceTriggerBlockingTimeSeconds: 0,
  isActive: true,
  type: ProcessChainType.Basic,
  maxQueueLength: 0,
  enableRepetitiveProcess: false,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: 'pcg ID 1',
  loadType: LoadType.Glt1400X1250,
};

const tableProcessChain2: TableProcessChain = {
  id: processChainId2,
  name: 'pc name 2',
  materialNumber: 'mat 2',
  source: 'src 2',
  destination: 'dest 2',
  isActive: true,
  fleetId: '',
  fleetName: '',
  mapId: mapId2,
  mapName: mapName2,
  type: ProcessChainType.Basic,
  processChainGroupId: processChainGroupId1,
  processChainGroupName: '',
  triggerType: ProcessChainTriggerType.TransportOrder,
  vehicleType: VehicleType.UnitLoad,
};

const tableProcessChain3: TableProcessChain = {
  ...processChain3,
  mapId: mapId2,
  mapName: mapName2,
  processChainGroupId: '',
  processChainGroupName: '',
  fleetName: '',
  vehicleType: VehicleType.UnitLoad,
};

const tableProcessChain5: TableProcessChain = {
  ...processChain5,
  mapId: mapId2,
  mapName: mapName2,
  processChainGroupId: '',
  processChainGroupName: '',
  fleetName: '',
  vehicleType: VehicleType.UnitLoad,
};

const processChainDetails2: ProcessChainDetails = {
  ...processChain2,
  fleetName: '',
  mapId: mapId2,
  mapName: mapName2,
  abortConnectionName: '',
  missionDetails: [
    ...processChain2.missions.map(m => {
      return { ...m, assignableWaypoint: null };
    }),
  ],
  processChainGroupName: '-',
  loadType: LoadType.Glt1400X1250,
};

const treeOverviewProcessChain2: TreeOverviewProcessChain = {
  id: processChainId2,
  name: 'pc name 2',
};

const nodesList: OpcuaDeviceNodeResponseModel[] = [
  {
    device: 'AMASteamingService1',
    name: 'AMA Ping',
    nodeId: 'ns=2;s=AMA/Controller/Ping_ATS',
    comment: '',
    type: NodeType.Procedure,
    subscriptionStatus: 'Pending',
    values: ['false'],
    range: [],
    state: {
      name: 'AMA Ping',
      value: null,
      measurementDate: null,
      rawValue: '',
    },
  },
  {
    device: 'AMA!',
    name: 'AMA Ping2',
    nodeId: 'ns=2;s=AMA/Controller/Ping_ATS',
    comment: '',
    type: NodeType.Telemetry,
    subscriptionStatus: 'Pending',
    values: ['false'],
    range: [],
    state: {
      name: 'AMA Ping2',
      value: null,
      measurementDate: null,
      rawValue: '',
    },
  },
  {
    device: '',
    name: 'jobs.processConfig.fakeNodeName',
    nodeId: '',
    comment: '',
    type: NodeType.None,
    subscriptionStatus: '',
    values: ['false'],
    range: [],
    state: {
      name: 'jobs.processConfig.fakeNodeName',
      value: null,
      measurementDate: null,
      rawValue: '',
    },
  },
];

const deviceList: OpcuaDeviceResponseModel[] = [
  {
    name: 'AMASteamingService1',
    description: 'Ight, AMA head out!',
    server: 'localhost',
    workAreas: [
      {
        deviceName: 'AMASteamingService1',
        workAreaId: 'bb2b55bc-dd94-4262-a5af-966431d57b31',
        workAreaName: '',
      },
    ],
    streamingServiceName: 'Ama Devices',
    pingNodeName: 'Ping Node ',
    lastPingReceivedDate: null,
    akz: 'AMADPX2',
    nodes: nodesList,
    notifications: [],
    readonly: false,
    recipientKey: '',
    maxLatencyMs: null,
  },
  {
    name: 'Ama2',
    description: 'Ama Emulator',
    server: 'opc.tcp://localhost:62541',
    workAreas: [
      {
        deviceName: 'Ama2',
        workAreaId: 'bb2b55bc-dd94-4262-a5af-966431d57b31',
        workAreaName: '',
      },
    ],
    streamingServiceName: 'Ama Devices',
    pingNodeName: 'Ping Node ',
    lastPingReceivedDate: null,
    akz: 'AMADPX2',
    nodes: nodesList,
    notifications: [],
    readonly: false,
    recipientKey: '',
    maxLatencyMs: null,
  },
];

const circularInterlockProcessChainId = 'd17b2401-414e-45b2-bd2c-eeac181b3fbe';
const circularInterlockProcessChainMapId = 'dc14a79c-37ef-44c7-9df4-dc6c11716c0e';
const circularInterlockProcessChainEvent1Id = '425ec3e4-c849-4ecd-aa57-eae20d740b4a';
const circularInterlockProcessChainEvent2Id = '927bac85-56f8-4e49-a04e-39d927094b8f';
const circularInterlockProcessChainEvent3Id = 'f238daee-fcc8-43e2-986b-ae8904660499';
const circularInterlockProcessChainEvent4Id = 'a93b85fd-b9f2-40fa-8997-a33de40d0e5a';
const circularInterlockProcessChainEvent5Id = '0946be8e-7f14-4700-bbd2-ed6060aed274';
const circularInterlockProcessChainEvent6Id = '5f977885-6b20-4bfc-abea-0b04b90063bf';
const circularInterlockProcessChainMission1Id = '472828c1-a412-4719-9362-53ca5111d92b';
const circularInterlockProcessChainMission2Id = 'a3785b25-8b14-4a91-b434-751654d39afa';
const circularInterlockProcessChainMission3Id = '2dd8e5c1-ef91-44f4-a7a6-8cca5b3ec925';

const processChainDtoWithCircularInterlock: ProcessChainDto = {
  type: ProcessChainType.Basic,
  id: circularInterlockProcessChainId,
  name: 'CircularInterlock ProcessChain',
  materialNumber: 'M_Interlock',
  source: 'S_Interlock',
  destination: 'D_Interlock',
  isActive: true,
  enableRepetitiveProcess: false,
  missionIds: [
    circularInterlockProcessChainMission1Id,
    circularInterlockProcessChainMission2Id,
    circularInterlockProcessChainMission3Id,
  ],
  missions: [
    {
      id: circularInterlockProcessChainMission1Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'FirstMission',
      steps: [
        {
          mappingType: MappingType.Destination,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 0,
          type: StepTypeBackend.DockToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 1,
          type: StepTypeBackend.Lift,
        },
        {
          eventId: circularInterlockProcessChainEvent1Id,
          eventName: 'Step 2 in FirstMission',
          sequenceNumber: 2,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent2Id,
          eventName: 'Step 2 in ThirdMission',
          sequenceNumber: 3,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent3Id,
          eventName: 'Step 3 in FirstMission',
          sequenceNumber: 4,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent5Id,
          eventName: 'Step 3 in ThirdMission',
          sequenceNumber: 5,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          pointOfInterestName: '',
          pointOfInterestGroupId: '8dd59da2-0d9d-44b0-98ad-181cba21db8f',
          trajectory: [],
          sequenceNumber: 6,
          type: StepTypeBackend.Goto,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 7,
          type: StepTypeBackend.Drop,
        },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      isMaxExecutionTimeActive: false,
      maxExecutionTime: 0,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
    },
    {
      id: circularInterlockProcessChainMission2Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'SecondMission',
      steps: [
        {
          mappingType: MappingType.MaterialNumber,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 0,
          type: StepTypeBackend.DockToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 1,
          type: StepTypeBackend.Lift,
        },
        {
          eventId: circularInterlockProcessChainEvent4Id,
          eventName: 'Step 2 in SecondMission',
          sequenceNumber: 2,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent1Id,
          eventName: 'Step 2 in FirstMission',
          sequenceNumber: 3,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent6Id,
          eventName: 'Step 3 in SecondMission',
          sequenceNumber: 4,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent3Id,
          eventName: 'Step 3 in FirstMission',
          sequenceNumber: 5,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          mappingType: MappingType.Destination,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 6,
          type: StepTypeBackend.GoToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 7,
          type: StepTypeBackend.Drop,
        },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      isMaxExecutionTimeActive: false,
      maxExecutionTime: 0,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
    },
    {
      id: circularInterlockProcessChainMission3Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'ThirdMission',
      steps: [
        {
          pointOfInterestName: '',
          pointOfInterestGroupId: '8dd59da2-0d9d-44b0-98ad-181cba21db8f',
          trajectory: [],
          sequenceNumber: 0,
          type: StepTypeBackend.Dock,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 1,
          type: StepTypeBackend.Lift,
        },
        {
          eventId: circularInterlockProcessChainEvent2Id,
          eventName: 'Step 2 in ThirdMission',
          sequenceNumber: 2,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent4Id,
          eventName: 'Step 2 in SecondMission',
          sequenceNumber: 3,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent5Id,
          eventName: 'Step 3 in ThirdMission',
          sequenceNumber: 4,
          type: StepTypeBackend.RaiseEvent,
        },
        {
          eventId: circularInterlockProcessChainEvent6Id,
          eventName: 'Step 3 in SecondMission',
          sequenceNumber: 5,
          type: StepTypeBackend.WaitForEvent,
        },
        {
          mappingType: MappingType.MaterialNumber,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 6,
          type: StepTypeBackend.GoToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        {
          sequenceNumber: 7,
          type: StepTypeBackend.Drop,
        },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      isMaxExecutionTimeActive: true,
      maxExecutionTime: 9,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
    },
  ],
  events: [
    {
      id: circularInterlockProcessChainEvent6Id,
      name: 'Step 3 in SecondMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
    {
      id: circularInterlockProcessChainEvent2Id,
      name: 'Step 2 in ThirdMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
    {
      id: circularInterlockProcessChainEvent4Id,
      name: 'Step 2 in SecondMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
    {
      id: circularInterlockProcessChainEvent3Id,
      name: 'Step 3 in FirstMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
    {
      id: circularInterlockProcessChainEvent1Id,
      name: 'Step 2 in FirstMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
    {
      id: circularInterlockProcessChainEvent5Id,
      name: 'Step 3 in ThirdMission',
      type: EventType.Process,
      connectionId: undefined,
      deviceId: '',
    },
  ],
  fleetId: '00000000-0000-0000-0000-000000000000',
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  triggerType: ProcessChainTriggerType.TransportOrder,
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  maxQueueLength: null,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainModelWithCircularInterlock: ProcessChain = {
  id: circularInterlockProcessChainId,
  name: 'CircularInterlock ProcessChain',
  materialNumber: 'M_Interlock',
  source: 'S_Interlock',
  destination: 'D_Interlock',
  isActive: true,
  enableRepetitiveProcess: false,
  missionIds: [
    circularInterlockProcessChainMission1Id,
    circularInterlockProcessChainMission2Id,
    circularInterlockProcessChainMission3Id,
  ],
  missions: [
    {
      id: circularInterlockProcessChainMission1Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'FirstMission',
      steps: [
        {
          mappingType: MappingType.Destination,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 0,
          type: StepType.DockToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 1, type: StepType.Lift },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 2,
          waitForStep: { sequenceNumber: 1, missionIndex: 2 },
          waitForStepId: '2-1',
        },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 3,
          waitForStep: { sequenceNumber: 2, missionIndex: 2 },
          waitForStepId: '2-2',
        },
        {
          pointOfInterestName: '',
          pointOfInterestGroupId: '8dd59da2-0d9d-44b0-98ad-181cba21db8f',
          trajectory: [],
          sequenceNumber: 4,
          type: StepType.Goto,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 5, type: StepType.Drop },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      maxExecutionTime: 0,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
      isMaxExecutionTimeActive: false,
    },
    {
      id: circularInterlockProcessChainMission2Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'SecondMission',
      steps: [
        {
          mappingType: MappingType.MaterialNumber,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 0,
          type: StepType.DockToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 1, type: StepType.Lift },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 2,
          waitForStep: { sequenceNumber: 1, missionIndex: 0 },
          waitForStepId: '0-1',
        },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 3,
          waitForStep: { sequenceNumber: 2, missionIndex: 0 },
          waitForStepId: '0-2',
        },
        {
          mappingType: MappingType.Destination,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 4,
          type: StepType.GoToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 5, type: StepType.Drop },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      isMaxExecutionTimeActive: false,
      maxExecutionTime: 0,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
    },
    {
      id: circularInterlockProcessChainMission3Id,
      mapId: circularInterlockProcessChainMapId,
      processChainId: circularInterlockProcessChainId,
      name: 'ThirdMission',
      steps: [
        {
          pointOfInterestName: '',
          pointOfInterestGroupId: '8dd59da2-0d9d-44b0-98ad-181cba21db8f',
          trajectory: [],
          sequenceNumber: 0,
          type: StepType.Dock,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 1, type: StepType.Lift },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 2,
          waitForStep: { sequenceNumber: 1, missionIndex: 1 },
          waitForStepId: '1-1',
        },
        {
          type: StepType.WaitForEndOfStep,
          sequenceNumber: 3,
          waitForStep: { sequenceNumber: 2, missionIndex: 1 },
          waitForStepId: '1-2',
        },
        {
          mappingType: 3,
          fallbackPointOfInterestId: null,
          fallbackPointOfInterestName: null,
          fallbackPointOfInterestGroupId: null,
          sequenceNumber: 4,
          type: StepType.GoToMapping,
          hasStepCompletionNotificationEnabled: false,
        },
        { sequenceNumber: 5, type: StepType.Drop },
      ],
      abortAction: null,
      pauseConditions: [],
      assignableWaypointId: null,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 10,
      maxExecutionTime: 9,
      isMaxExecutionTimeActive: true,
      isAssignmentDelayTimeActive: false,
      assignmentDelayTime: 0,
    },
  ],
  eventDetails: [
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent1Id,
      missionIndex: 0,
      sequenceNumber: 1,
      usedDuringSave: false,
      connectionId: undefined,
      deviceId: '',
      name: 'Step 2 in FirstMission',
    },
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent3Id,
      missionIndex: 0,
      sequenceNumber: 2,
      usedDuringSave: false,
      connectionId: undefined,
      deviceId: '',
      name: 'Step 3 in FirstMission',
    },
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent4Id,
      missionIndex: 1,
      sequenceNumber: 1,
      usedDuringSave: false,
      connectionId: undefined,
      deviceId: '',
      name: 'Step 2 in SecondMission',
    },
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent6Id,
      missionIndex: 1,
      name: 'Step 3 in SecondMission',
      sequenceNumber: 2,
      connectionId: undefined,
      deviceId: '',
      usedDuringSave: false,
    },
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent2Id,
      missionIndex: 2,
      sequenceNumber: 1,
      usedDuringSave: false,
      connectionId: undefined,
      deviceId: '',
      name: 'Step 2 in ThirdMission',
    },
    {
      type: EventType.Process,
      id: circularInterlockProcessChainEvent5Id,
      missionIndex: 2,
      sequenceNumber: 2,
      usedDuringSave: false,
      connectionId: undefined,
      deviceId: '',
      name: 'Step 3 in ThirdMission',
    },
  ],
  fleetId: '00000000-0000-0000-0000-000000000000',
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  deviceTriggerDevice: '',
  deviceTriggerNode: '',
  deviceTriggerValue: '',
  deviceTriggerBlockingTimeSeconds: 0,
  triggerType: ProcessChainTriggerType.TransportOrder,
  type: ProcessChainType.Basic,
  maxQueueLength: null,
  replenishmentTime: null,
  missionPrioritizationTime: 0,
  processChainGroupId: '00000000-0000-0000-0000-000000000000',
  loadType: LoadType.Glt1400X1250,
};

const processChainGroupDto1: ProcessChainGroupDto = {
  id: processChainGroupId1,
  mapId: mapId1,
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  name: 'Pair One',
  processChainIds: [processChainId1, processChainId2],
  executionMode: ProcessChainGroupExecutionMode.Parallel,
};

const processChainGroupDto2: ProcessChainGroupDto = {
  id: processChainGroupId2,
  mapId: mapId2,
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  name: 'Pair Two',
  processChainIds: [processChainId3, processChainId5],
  executionMode: ProcessChainGroupExecutionMode.Sequential,
};

const processChainGroupModel1: ProcessChainGroupModel = {
  id: processChainGroupId1,
  mapId: mapId1,
  mapName: mapName1,
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  groupName: 'Pair One',
  processChains: [
    { id: processChainId1, name: tableProcessChain1.name, mapId: tableProcessChain1.mapId },
    { id: processChainId2, name: tableProcessChain2.name, mapId: tableProcessChain2.mapId },
  ],
  executionMode: ProcessChainGroupExecutionMode.Parallel,
};

const tableProcessChainGroup1: TableProcessChainGroup = {
  ...processChainGroupModel1,
  name: processChainGroupModel1.groupName,
};

const processChainGroupModel2: ProcessChainGroupModel = {
  id: processChainGroupId2,
  mapId: mapId2,
  mapName: mapName2,
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  groupName: 'Pair Two',
  processChains: [
    { id: processChainId2, name: processChain2.name, mapId: mapId2 },
    { id: processChainId3, name: processChain3.name, mapId: mapId2 },
  ],
  executionMode: ProcessChainGroupExecutionMode.Parallel,
};

const tableProcessChainGroup2: TableProcessChainGroup = {
  ...processChainGroupModel2,
  name: processChainGroupModel2.groupName,
};

const processChainGroupHierarchyModel1: ProcessChainGroupHierarchyModel = {
  id: processChainGroupId1,
  groupName: 'Pair One',
  name: processChain1.name,
};

const processChainGroupTraceHierarchyModel1: ProcessChainGroupTraceHierarchyModel = {
  id: processChainGroupId1,
  groupName: 'Pair One',
  name: processChain1.name,
  createdDateTime: '',
  status: ProcessChainTraceStatus.InQueue,
};

const processChainGroupTraceHierarchyModel: ProcessChainGroupTraceHierarchyModel[] = [
  {
    id: processChainGroupId1,
    groupName: 'Pair One',
    name: processChain1.name,
    createdDateTime: '2023-06-07T14:38:01.9838377Z',
    status: ProcessChainTraceStatus.InQueue,
  },
  {
    id: processChainGroupId2,
    groupName: 'Pair Two',
    name: processChain2.name,
    createdDateTime: '2023-06-07T14:38:01.9838377Z',
    status: ProcessChainTraceStatus.Waiting,
  },
];

export class ProcessConfiguratorTestHelper {
  static getDeviceList(): OpcuaDeviceResponseModel[] {
    return objectHelper.cloneDeep(deviceList);
  }

  static getProcessChainDto1(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDto1);
  }

  static getProcessChainDto2(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDto2);
  }

  static getProcessChainDto3(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDto3);
  }

  static getProcessChainDto4(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDto4);
  }

  static getProcessChainDto5(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDto5);
  }

  static getProcessChainWithWaitForScanDestinationStepDto(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainWithWaitForScanDestinationStepDto);
  }

  static getProcessChainWithWaitForWarehouseTaskAcknowledgementStepDto(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainWithWaitForWarehouseTaskAcknowledgementStepDto);
  }

  static getProcessChainWithWaitForWarehouseTaskAcknowledgementStep(): ProcessChain {
    return objectHelper.cloneDeep(processChainWithWaitForWarehouseTaskAcknowledgementStep);
  }

  static getProcessChainDtoWithCircularInterlock(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDtoWithCircularInterlock);
  }

  static getProcessChainWithTurnDollyStepDto(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDtoWithTurnDollyStepDto);
  }

  static getProcessChainWithTurnDollyStep(): ProcessChain {
    return objectHelper.cloneDeep(processChainDtoWithTurnDollyStep);
  }

  static getProcessChainWithGotoAndPushSideButtonStepDto(): ProcessChainDto {
    return objectHelper.cloneDeep(processChainDtoWithGotoAndPushSideButtonStepDto);
  }

  static getProcessChainWithGotoAndPushSideButtonStep(): ProcessChain {
    return objectHelper.cloneDeep(processChainDtoWithGotoAndPushSideButtonStep);
  }

  static getProcessChainDtos(): ProcessChainDto[] {
    return objectHelper.cloneDeep([processChainDto1, processChainDto2, processChainDto5]);
  }

  static getProcessChain1(): ProcessChain {
    return objectHelper.cloneDeep(processChain1);
  }

  static getProcessChain2(): ProcessChain {
    return objectHelper.cloneDeep(processChain2);
  }

  static getProcessChain3(): ProcessChain {
    return objectHelper.cloneDeep(processChain3);
  }

  static getProcessChain5(): ProcessChain {
    return objectHelper.cloneDeep(processChain5);
  }

  static getProcessChains(): ProcessChain[] {
    return objectHelper.cloneDeep([processChain1, processChain2]);
  }

  static getReplenishmentTimeProcessChainDetails1(): ProcessChainDetails {
    return objectHelper.cloneDeep(processReplenishmentTimeChainDetails1);
  }
  static getWaitingQueue1(): ProcessChain {
    return objectHelper.cloneDeep(waitingQueue1);
  }

  static getProcessChainModelWithCircularInterlock(): ProcessChain {
    return objectHelper.cloneDeep(processChainModelWithCircularInterlock);
  }

  static getWaitingQueues(): ProcessChain[] {
    return objectHelper.cloneDeep([
      processChain1,
      processChain2,
      waitingQueue1,
      waitingQueueWithPole,
    ]);
  }

  static getProcessChainWithPole(): ProcessChain {
    return processChainWithPole;
  }

  static getPolePoiId(): GuidString {
    return waitingQueueStepWithPole_PoiId;
  }

  static getProcessChainWithWaitForScanDestinationStep(): ProcessChain {
    return objectHelper.cloneDeep(processChainWithWaitForScanDestinationStep);
  }

  static getTableProcessChain1(): TableProcessChain {
    return objectHelper.cloneDeep(tableProcessChain1);
  }

  static getTableProcessChain2(): TableProcessChain {
    return objectHelper.cloneDeep(tableProcessChain2);
  }

  static getTableProcessChains(): TableProcessChain[] {
    return objectHelper.cloneDeep([
      tableProcessChain1,
      tableProcessChain2,
      tableProcessChain3,
      tableProcessChain5,
    ]);
  }

  static getTableWaitingQueue1(): TableProcessChain {
    return objectHelper.cloneDeep(getTableWaitingQueue1);
  }

  static getProcessChainDetails1(): ProcessChainDetails {
    return objectHelper.cloneDeep(processChainDetails1);
  }

  static getProcessChainDetails5(): ProcessChainDetails {
    return objectHelper.cloneDeep(processChainDeviceDetails);
  }

  static getProcessChainDetails2(): ProcessChainDetails {
    return objectHelper.cloneDeep(processChainDetails2);
  }

  static getProcessChainDetails(): ProcessChainDetails[] {
    return objectHelper.cloneDeep([processChainDetails1, processChainDetails2]);
  }

  static getWaitingQueueDetails1(): ProcessChainDetails {
    return objectHelper.cloneDeep(waitingQueueDetails);
  }

  static getTreeOverviewProcessChain1(): TreeOverviewProcessChain {
    return objectHelper.cloneDeep(treeOverviewProcessChain1);
  }

  static getTreeOverviewProcessChain2(): TreeOverviewProcessChain {
    return objectHelper.cloneDeep(treeOverviewProcessChain2);
  }

  static getTreeOverviewProcessChains(): TreeOverviewProcessChain[] {
    return objectHelper.cloneDeep([treeOverviewProcessChain1, treeOverviewProcessChain2]);
  }

  static getMissions(): Mission[] {
    return [mission3, mission4, mission6];
  }

  static getMission1(): Mission {
    return objectHelper.cloneDeep(mission1);
  }

  static getMission3(): Mission {
    return objectHelper.cloneDeep(mission3);
  }

  static getMission4(): Mission {
    return objectHelper.cloneDeep(mission4);
  }

  static getMission6(): Mission {
    return objectHelper.cloneDeep(mission6);
  }

  static getLiftStep1(): LiftStepModel {
    return { ...liftStep1, sequenceNumber: 0 };
  }

  static getProcessChainGroupDto1(): ProcessChainGroupDto {
    return objectHelper.cloneDeep(processChainGroupDto1);
  }

  static getProcessChainGroupDto2(): ProcessChainGroupDto {
    return objectHelper.cloneDeep(processChainGroupDto2);
  }

  static getProcessChainGroupModel1(): ProcessChainGroupModel {
    return objectHelper.cloneDeep(processChainGroupModel1);
  }

  static getProcessChainGroups(): ProcessChainGroupDto[] {
    return objectHelper.cloneDeep([processChainGroupDto1, processChainGroupDto2]);
  }

  static getProcessChainGroupModels(): ProcessChainGroupModel[] {
    return objectHelper.cloneDeep([processChainGroupModel1, processChainGroupModel2]);
  }

  static getTableProcessChainGroups(): TableProcessChainGroup[] {
    return objectHelper.cloneDeep([tableProcessChainGroup1, tableProcessChainGroup2]);
  }

  static getTableProcessChainGroup1(): TableProcessChainGroup {
    return objectHelper.cloneDeep(tableProcessChainGroup1);
  }

  static getTableProcessChainGroup2(): TableProcessChainGroup {
    return objectHelper.cloneDeep(tableProcessChainGroup2);
  }

  static getProcessChainGroupHierarchyModel1(): ProcessChainGroupHierarchyModel {
    return objectHelper.cloneDeep(processChainGroupHierarchyModel1);
  }

  static getProcessChainGroupTraceHierarchyModel1(): ProcessChainGroupTraceHierarchyModel {
    return objectHelper.cloneDeep(processChainGroupTraceHierarchyModel1);
  }

  static getProcessChainGroupTraceHierarchyModel(): ProcessChainGroupTraceHierarchyModel[] {
    return objectHelper.cloneDeep(processChainGroupTraceHierarchyModel);
  }

  // tslint:disable-next-line:max-file-line-count
}
