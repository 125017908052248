<div class="container-fluid g-0">
  <div class="row mb-4x">
    <div class="col me-2x">
      <label ds-label for="errorMessage">
        {{ 'settings.ipstAlertNowSettings.helpTool.errorMessage' | translate }}</label
      >
      <ds-select
        id="errorMessage"
        appendTo="body"
        data-cy="helpToolErrorMessage"
        [options]="errors | dsDropdown : 'label' : 'id'"
        [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
        required
        [itemLabelTemplate]="dropdownTemplate"
        [(ngModel)]="selectedErrorMessage"
        (optionSelected)="handleSelection($event)"></ds-select>
    </div>

    <div class="col me-2x">
      <label ds-label for="vehicle">
        {{ 'settings.ipstAlertNowSettings.helpTool.vehicle' | translate }}</label
      >
      <ds-select
        id="vehicle"
        appendTo="body"
        data-cy="helpToolVehicle"
        [options]="vehicles | dsDropdown : 'name' : 'id'"
        [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
        required
        [(ngModel)]="selectedVehicle"
        (optionSelected)="handleSelection($event)"></ds-select>
    </div>
    <div class="col me-2x">
      <label ds-label for="alertNowZone">
        {{ 'settings.ipstAlertNowSettings.helpTool.alertNowZone' | translate }}</label
      >
      <ds-select
        id="alertNowZone"
        appendTo="body"
        data-cy="helpToolAlertNowZone"
        [options]="zones | dsDropdown : 'zoneName' : 'id'"
        [labellingConfig]="{ placeholder: 'placeholders.select' | translate }"
        required
        [(ngModel)]="selectedZone"
        (optionSelected)="handleSelection($event)"></ds-select>
    </div>
  </div>
</div>
<ng-template #dropdownTemplate let-option>
  <div>
    <span class="seperate-content" [ds-tooltip]="option.disabled ? disabledTooltip : null">
      {{ option.data.label }}
    </span>
  </div>
</ng-template>
