/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable max-lines*/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BrakeTestService,
  EmulatorService,
  MapsService,
  MissionDispatchService,
  VehicleService,
} from 'core/api-services';
import { BaseVehicleDto, VehicleDetailsSignalRDto, VehicleDto } from 'core/dtos';
import { GuidString, MapMode, ReducedVehicle, VehicleType } from 'core/models';
import { SecondarySideMenuService } from 'core/services/secondary-sidemenu.service';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';

import { EMPTY_GUID } from 'core/constants';
import { of } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions/ui.actions';
import { setMapMode } from '../../maps-store/actions/maps.actions';

import { VehiclePollingSignalRService } from 'core/signalR/modules';
import * as VehiclesActions from '../actions/vehicles.actions';

const vehicleActionFailure = 'vehicles.actionFailure';
const vehiclePoseMovementFialure = 'vehicles.poseMovementFailure';

@Injectable()
export class VehiclesEffects {
  loadVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.loadVehicles),
      concatMap(() =>
        this.vehicleService.getVehicles().pipe(
          map(vehicles => VehiclesActions.loadVehiclesSuccess({ vehicles })),
          catchError(errorMessage => of(VehiclesActions.loadVehiclesFailure({ errorMessage })))
        )
      )
    )
  );

  addVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addVehicle),
      map(action => action.newVehicle),
      concatMap((payload: BaseVehicleDto) => {
        return this.vehicleService.createVehicle(payload).pipe(
          switchMap(vehicleCreated => [
            VehiclesActions.addVehicleSuccess({ vehicleCreated: vehicleCreated }),
          ]),
          catchError(errorMessage => of(VehiclesActions.addVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  signalRAddVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.signalRAddVehicle),
      map(action => action.vehicle),
      concatMap((payload: VehicleDetailsSignalRDto) => {
        return this.vehicleService.getVehicleById(payload.id).pipe(
          switchMap(vehicleCreated => [
            VehiclesActions.signalRAddVehicleSuccess({ vehicleCreated: vehicleCreated }),
          ]),
          catchError(errorMessage => of(VehiclesActions.addVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  emulatorAddVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addEmulatorVehicle),
      concatMap(payload => {
        return this.emulatorService.createEmulator(payload.newEmulatorVehicle).pipe(
          switchMap(emulatorConfig => [
            VehiclesActions.addEmulatorVehicleSuccess({
              vehicleCreated: emulatorConfig.vehicles[0],
            }),
          ]),
          catchError(errorMessage => [
            VehiclesActions.addEmulatorVehicleFailure({ errorMessage }),
            setIsEditMode({ isEditMode: true }),
          ])
        );
      })
    )
  );

  emulatorUpdateVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateEmulatorVehicle),
      concatMap(payload => {
        return this.emulatorService
          .updateEmulator({ id: EMPTY_GUID, ...payload.emulatorVehicle })
          .pipe(
            switchMap(emulatorConfig => [
              VehiclesActions.updateEmulatorVehicleSuccess({
                vehicleUpdated: emulatorConfig.vehicles[0],
              }),
            ]),
            catchError(errorMessage => [
              VehiclesActions.updateEmulatorVehicleFailure({ errorMessage }),
              setIsEditMode({ isEditMode: true }),
            ])
          );
      })
    )
  );

  emulatorUpdateVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateEmulatorVehicleSuccess),
      concatMap(payload => {
        return this.vehicleService
          .getVehicleById(payload.vehicleUpdated.id || '')
          .pipe(map(vehicle => VehiclesEffects.getEmulatorAddUpdateSuccessActions(vehicle)));
      }),
      tap(() => {
        this.toastService.createSuccessToast('shared.emulatorActions.updated');
      }),
      concatMap(() => [setHasChanges({ hasChanges: false })])
    )
  );

  setEmulatorMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.setEmulatorMode),
      concatMap(({ vehicleId, mode }) =>
        this.emulatorService.setEmulatorMode(vehicleId, mode).pipe(
          map(() => VehiclesActions.setEmulatorModeSuccess({ vehicleId, mode })),
          catchError(errorMessage => of(VehiclesActions.setEmulatorModeFailure({ errorMessage })))
        )
      )
    )
  );

  setEmulatorModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.setEmulatorModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.emulatorActions.emulatorModeSuccess');
        })
      ),
    { dispatch: false }
  );

  setEmulatorModeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.setEmulatorModeFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.emulatorActions.emulatorModeError');
        })
      ),
    { dispatch: false }
  );

  toggleEmulatorPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.toggleEmulatorPath),
      concatMap(({ vehicleId, isRealPathActive }) =>
        this.emulatorService.toggleEmulatorPath(vehicleId, isRealPathActive).pipe(
          map(() => VehiclesActions.toggleEmulatorPathSuccess({ vehicleId, isRealPathActive })),
          catchError(errorMessage =>
            of(VehiclesActions.toggleEmulatorPathFailure({ errorMessage }))
          )
        )
      )
    )
  );

  toggleEmulatorPathSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.toggleEmulatorPathSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.emulatorActions.toggleEmulatorPathSuccess');
        })
      ),
    { dispatch: false }
  );

  toggleEmulatorPathFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.toggleEmulatorPathFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.emulatorActions.toggleEmulatorPathError');
        })
      ),
    { dispatch: false }
  );

  triggerEmulatorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.triggerEmulatorError),
      concatMap(({ vehicleId, vehicleType, hardwareVersion }) =>
        this.emulatorService.triggerEmulatorError(vehicleId, vehicleType, hardwareVersion).pipe(
          map(() => VehiclesActions.triggerEmulatorErrorSuccess({ vehicleId })),
          catchError(errorMessage =>
            of(VehiclesActions.triggerEmulatorErrorFailure({ errorMessage }))
          )
        )
      )
    )
  );

  triggerEmulatorErrorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.triggerEmulatorErrorSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'shared.emulatorActions.triggerEmulatorErrorSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  triggerEmulatorErrorFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.triggerEmulatorErrorFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.emulatorActions.triggerEmulatorErrorFailure');
        })
      ),
    { dispatch: false }
  );

  triggerClearEmulatorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.triggerClearEmulatorError),
      concatMap(({ vehicleId }) =>
        this.emulatorService.triggerClearEmulatorError(vehicleId).pipe(
          map(() => VehiclesActions.triggerClearEmulatorErrorSuccess({ vehicleId })),
          catchError(errorMessage =>
            of(VehiclesActions.triggerClearEmulatorErrorFailure({ errorMessage }))
          )
        )
      )
    )
  );

  triggerClearEmulatorErrorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.triggerClearEmulatorErrorSuccess),
        tap(() => {
          this.toastService.createSuccessToast(
            'shared.emulatorActions.triggerClearEmulatorErrorSuccess'
          );
        })
      ),
    { dispatch: false }
  );

  triggerClearEmulatorErrorFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.triggerClearEmulatorErrorFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'shared.emulatorActions.triggerClearEmulatorErrorFailure'
          );
        })
      ),
    { dispatch: false }
  );

  emulatorAddVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addEmulatorVehicleSuccess),
      concatMap(payload => {
        return this.vehicleService
          .getVehicleById(payload.vehicleCreated.id || '')
          .pipe(map(vehicle => VehiclesEffects.getEmulatorAddUpdateSuccessActions(vehicle)));
      }),
      tap(() => {
        this.toastService.createSuccessToast('shared.emulatorActions.created');
      }),
      concatMap(emulatorActions => [...emulatorActions, setHasChanges({ hasChanges: false })])
    )
  );

  emulatorAddVehicleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.addEmulatorVehicleFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        }),
        concatMap(() => [setHasChanges({ hasChanges: false })])
      ),
    { dispatch: false }
  );

  addVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addVehicleSuccess),
      tap(({ vehicleCreated }) => {
        this.toastService.createSuccessToast('shared.actions.saved', vehicleCreated);
        this.tenantRouterService
          .navigate(['/vehicles/view/' + vehicleCreated.id], {
            queryParams: {
              isNewUnitLoadVehicle: vehicleCreated.vehicleType === VehicleType.UnitLoad,
            },
          })
          .catch(() => {});
      }),
      switchMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  deleteVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.deleteVehicle),
      map(action => action.vehicle),
      concatMap((vehicle: { id: GuidString; name: string }) => {
        return this.vehicleService.deleteVehicle(vehicle.id).pipe(
          map(() => VehiclesActions.deleteVehicleSuccess({ vehicle })),
          catchError(errorMessage => of(VehiclesActions.deleteVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  deleteEmulatorVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.deleteEmulatorVehicle),
      map(action => action.vehicle),
      concatMap((vehicle: { id: GuidString; name: string }) => {
        return this.emulatorService.deleteEmulator(vehicle.id).pipe(
          map(() => VehiclesActions.deleteVehicleSuccess({ vehicle })),
          catchError(errorMessage => of(VehiclesActions.deleteVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  updateVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateVehicle),
      map(action => action.vehicle),
      concatMap(payload => {
        return this.vehicleService.updateVehicle(payload).pipe(
          switchMap(() => [
            VehiclesActions.updateVehicleSuccess({
              vehicle: {
                id: payload.id.toString(),
                changes: payload,
              },
            }),
          ]),
          catchError(errorMessage => of(VehiclesActions.updateVehicleFailure({ errorMessage })))
        );
      })
    )
  );

  updateVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateVehicleSuccess),
      tap(({ vehicle }) => {
        this.toastService.createSuccessToast('shared.actions.updated', vehicle.changes);
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  changeWorkingArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.changeWorkingArea),
      concatMap(vehicle => {
        return this.vehicleService
          .changeWorkingArea(vehicle.vehicleId, vehicle.workingAreaSelected.id)
          .pipe(
            map(transferredVehicle =>
              VehiclesActions.changeWorkingAreaSuccess({
                transferredVehicle: transferredVehicle,
                workingAreaSelected: vehicle.workingAreaSelected,
              })
            ),
            catchError(errorMessage =>
              of(VehiclesActions.changeWorkingAreaFailure({ errorMessage }))
            )
          );
      })
    )
  );

  changeWorkingAreaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.changeWorkingAreaSuccess),
        tap(({ transferredVehicle, workingAreaSelected }) => {
          if (workingAreaSelected?.organizationName && workingAreaSelected?.name) {
            this.router
              .navigate(
                [workingAreaSelected.organizationName, workingAreaSelected.name, 'vehicles'],
                {
                  queryParams: { transferredVehicleId: transferredVehicle.id },
                }
              )
              .catch(errorMessage =>
                VehiclesActions.changeWorkingAreaFailure({ errorMessage: errorMessage })
              );
          }
        })
      ),
    { dispatch: false }
  );

  ungroupVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.ungroupVehicleSuccess),
      tap(({ vehicle }) => {
        this.toastService.createSuccessToast('shared.actions.vehicleUngrouped', vehicle.changes);
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateMap),
      concatMap(({ vehicleId, vehicleName, mapId, mapName }) =>
        this.mapService.updateMap(vehicleId, mapId).pipe(
          map(() => VehiclesActions.updateMapSuccess({ vehicleId, vehicleName, mapId, mapName })),
          catchError(errorMessage => of(VehiclesActions.updateMapFailure({ errorMessage })))
        )
      )
    )
  );

  updateMapSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateMapSuccess),
        tap(({ vehicleName, mapName }) => {
          this.toastService.createSuccessToast('shared.actions.mapUpdated', {
            mapName: mapName,
            vehicleName: vehicleName,
          });
        })
      ),
    { dispatch: false }
  );

  updateZoneSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateZoneSet),
      concatMap(({ vehicleId, zoneSetId }) =>
        this.mapService.updateZoneSet(vehicleId, zoneSetId).pipe(
          map(() => VehiclesActions.updateZoneSetSuccess({ vehicleId, zoneSetId })),
          catchError(errorMessage => of(VehiclesActions.updateZoneSetFailure({ errorMessage })))
        )
      )
    )
  );

  updateZoneSetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateZoneSetSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.zonesUpdated');
        })
      ),
    { dispatch: false }
  );

  localizeVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.localizeVehicle),
      concatMap(({ vehicleId, vehicleName, mapId, pose }) =>
        this.vehicleService.localizeVehicle(vehicleId, pose).pipe(
          map(() =>
            VehiclesActions.localizeVehicleSuccess({ vehicleId, vehicleName, mapId, pose })
          ),
          catchError(errorMessage =>
            of(VehiclesActions.localizeVehicleFailure({ errorMessage, mapId }))
          )
        )
      )
    )
  );

  localizeVehicleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.localizeVehicleSuccess),
      tap(({ vehicleName, mapId }) => {
        void this.vehiclePollingSignalRService.joinVehicleLocationsMapGroup(mapId);
        this.secondarySideMenuService.setDisplaySideBar(false);
        this.secondarySideMenuService.setLocalizeVehicle(false);
        this.toastService.createSuccessToast('shared.actions.localizeUpdate', {
          name: vehicleName,
        });
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setMapMode({ mode: MapMode.None })])
    )
  );

  localizeVehicleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.localizeVehicleFailure),
        tap(({ mapId }) => {
          void this.vehiclePollingSignalRService.joinVehicleLocationsMapGroup(mapId);
          this.toastService.createErrorToast(vehicleActionFailure);
        })
      ),
    { dispatch: false }
  );

  deleteVehicleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.deleteVehicleSuccess),
        tap(({ vehicle }) => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: vehicle.name,
          });
        }),
        concatMap(() => [setHasChanges({ hasChanges: false })])
      ),
    { dispatch: false }
  );

  updateMaintenanceMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateMaintenanceMode),
      map(action => action.vehicle),
      concatMap((vehicle: ReducedVehicle) => {
        return this.vehicleService.updateMaintenanceMode(vehicle).pipe(
          first(),
          switchMap(() => [
            VehiclesActions.updateMaintenanceModeSuccess({
              vehicle: {
                id: vehicle.id.toString(),
                changes: vehicle,
              },
            }),
          ]),
          catchError(errorMessage => {
            return of(VehiclesActions.updateMaintenanceModeFailure({ errorMessage }));
          })
        );
      })
    )
  );

  updateMaintenanceModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateMaintenanceModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.maintenanceModeSuccess');
        })
      ),
    { dispatch: false }
  );

  updateMaintenanceModeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateMaintenanceModeFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.maintenanceModeFailure');
        })
      ),
    { dispatch: false }
  );

  massUpdateMaintenanceMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.massUpdateMaintenanceMode),
      map(action => action.vehicleIds),
      concatMap((vehicleIds: GuidString[]) => {
        return this.vehicleService.massMaintenanceModeUpdate(vehicleIds).pipe(
          first(),
          switchMap(() => [VehiclesActions.massUpdateMaintenanceModeSuccess({ vehicleIds })]),
          catchError(errorMessage => {
            return of(VehiclesActions.massUpdateMaintenanceModeFailure({ errorMessage }));
          })
        );
      })
    )
  );

  massUpdateMaintenanceModeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.massUpdateMaintenanceModeSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.massMaintenanceMode');
        })
      ),
    { dispatch: false }
  );

  massUpdateMaintenanceModeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.massUpdateMaintenanceModeFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.errorMassMaintenanceMode');
        })
      ),
    { dispatch: false }
  );

  updateErrorForwarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.updateErrorForwarding),
      map(action => action.vehicle),
      concatMap((vehicle: ReducedVehicle) => {
        return this.vehicleService.updateErrorForwarding(vehicle).pipe(
          first(),
          switchMap(() => [
            VehiclesActions.updateErrorForwardingSuccess({
              vehicle: {
                id: vehicle.id.toString(),
                changes: vehicle,
              },
            }),
          ]),
          catchError(errorMessage => {
            return of(VehiclesActions.updateErrorForwardingFailure({ errorMessage }));
          })
        );
      })
    )
  );

  updateErrorForwardingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateErrorForwardingSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.updateErrorForwardingSuccess');
        })
      ),
    { dispatch: false }
  );

  updateErrorForwardingFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateErrorForwardingFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.updateErrorForwardingFailure');
        })
      ),
    { dispatch: false }
  );

  pauseVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.pauseVehicle),
      concatMap(({ vehicleId }) =>
        this.vehicleService.pauseVehicle(vehicleId).pipe(
          map(() => VehiclesActions.pauseVehicleSuccess({ vehicleId })),
          catchError(errorMessage => of(VehiclesActions.pauseVehicleFailure({ errorMessage })))
        )
      )
    )
  );

  pauseVehicleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.pauseVehicleSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.pauseVehicle');
        })
      ),
    { dispatch: false }
  );

  resumeVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.resumeVehicle),
      concatMap(({ vehicleId }) =>
        this.vehicleService.resumeVehicle(vehicleId).pipe(
          map(() => VehiclesActions.resumeVehicleSuccess({ vehicleId })),
          catchError(errorMessage => of(VehiclesActions.resumeVehicleFailure({ errorMessage })))
        )
      )
    )
  );

  resumeVehicleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.resumeVehicleSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.resumeVehicle');
        })
      ),
    { dispatch: false }
  );

  sendBrakeTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.sendBrakeTest),
      concatMap(({ vehicleId }) =>
        this.brakeTestService.sendBrakeTest(vehicleId).pipe(
          map(() => VehiclesActions.sendBrakeTestSuccess({ vehicleId })),
          catchError(errorMessage => of(VehiclesActions.sendBrakeTestFailure({ errorMessage })))
        )
      )
    )
  );

  sendBrakeTestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendBrakeTestSuccess),
        tap(({ vehicleId }) => {
          this.toastService.createSuccessToast('shared.actions.brakeTest', vehicleId);
        })
      ),
    { dispatch: false }
  );

  sendBrakeTestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendBrakeTestFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  requestFactsheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.requestFactsheet),
      concatMap(({ vehicleId }) =>
        this.vehicleService.requestFactsheet(vehicleId).pipe(
          map(() => VehiclesActions.requestFactsheetSuccess({ vehicleId })),
          catchError(errorMessage => of(VehiclesActions.requestFactsheetFailure({ errorMessage })))
        )
      )
    )
  );

  requestFactsheetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.requestFactsheetSuccess),
        tap(({ vehicleId }) => {
          this.toastService.createSuccessToast(
            'shared.actions.vehicleFactsheetRequestSuccess',
            vehicleId
          );
        })
      ),
    { dispatch: false }
  );

  requestFactsheetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.requestFactsheetFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  sendMission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.sendMission),
      concatMap(({ missionDispatch }) =>
        this.missionDispatchService.sendMission(missionDispatch).pipe(
          map(() => VehiclesActions.sendMissionSuccess({ missionDispatch })),
          catchError(errorMessage => of(VehiclesActions.sendMissionFailure({ errorMessage })))
        )
      )
    )
  );

  sendMissionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendMissionSuccess),
        tap(({ missionDispatch }) => {
          this.toastService.createSuccessToast('shared.actions.sendMission', missionDispatch);
        })
      ),
    { dispatch: false }
  );

  sendMissionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendMissionFailure),
        tap(({ errorMessage }) => {
          if (errorMessage !== undefined && errorMessage.includes('409')) {
            this.toastService.createErrorToast(
              'shared.vehicles.actions.manualDoubleBookPoiSendStepFailure'
            );
          } else {
            this.toastService.createErrorToast(vehicleActionFailure);
          }
        })
      ),
    { dispatch: false }
  );

  sendAdHocPose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.sendAdHocPose),
      concatMap(({ vehicleId, pose, mapId }) =>
        this.missionDispatchService.sendAdHocPose(vehicleId, pose, mapId).pipe(
          map(missionTrace => VehiclesActions.sendAdHocPoseSuccess({ missionTrace })),
          catchError(errorMessage => of(VehiclesActions.sendAdHocPoseFailure({ errorMessage })))
        )
      )
    )
  );

  sendAdHocPoseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendAdHocPoseSuccess),
        tap(({ missionTrace }) => {
          this.toastService.createSuccessToast('shared.actions.sendAdHocPose', missionTrace);
        })
      ),
    { dispatch: false }
  );

  sendAdHocPoseFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendAdHocPoseFailure),
        tap(() => {
          this.toastService.createErrorToast(vehiclePoseMovementFialure);
        })
      ),
    { dispatch: false }
  );

  resetEmulator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.resetEmulator),
      concatMap(({ vehicleId }) =>
        this.emulatorService.resetEmulator(vehicleId).pipe(
          map(() => VehiclesActions.resetEmulatorSuccess({ vehicleId })),
          catchError(errorMessage => of(VehiclesActions.resetEmulatorFailure({ errorMessage })))
        )
      )
    )
  );

  resetEmulatorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.resetEmulatorSuccess),
        tap(() => {
          this.toastService.createSuccessToast('shared.actions.resetEmulatorSuccess');
        }),
        concatMap(() => [setHasChanges({ hasChanges: false })])
      ),
    { dispatch: false }
  );

  sendWakeOtherVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.sendWakeOtherVehicles),
      concatMap(() =>
        this.vehicleService.wakeOtherVehicles().pipe(
          map(dto => VehiclesActions.sendWakeOtherVehiclesSuccess({ response: dto })),
          catchError(errorMessage =>
            of(VehiclesActions.sendWakeOtherVehiclesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  sendWakeOtherVehiclesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.sendWakeOtherVehiclesSuccess),
        tap(({ response }) => {
          let translateKey = 'vehicles.wakeOtherVehicles.noOfflineVehicles';
          let params = {};

          if (response.masterId) {
            translateKey = 'vehicles.wakeOtherVehicles.success';
            params = {
              masterName: response.masterName,
              vehicleCount: response.offlineVehicles.length || 0,
            };
          }
          this.toastService.createSuccessToast(translateKey, params);
        })
      ),
    { dispatch: false }
  );

  updateMapFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.updateMapFailure),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          VehiclesActions.loadVehiclesFailure,
          VehiclesActions.addVehicleFailure,
          VehiclesActions.deleteVehicleFailure,
          VehiclesActions.updateVehicleFailure,
          VehiclesActions.ungroupVehicleFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        }),
        concatMap(() => [setHasChanges({ hasChanges: false })])
      ),
    { dispatch: false }
  );

  actionFailures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          VehiclesActions.updateZoneSetFailure,
          VehiclesActions.localizeVehicleFailure,
          VehiclesActions.pauseVehicleFailure,
          VehiclesActions.resumeVehicleFailure,
          VehiclesActions.changeWorkingAreaFailure,
          VehiclesActions.sendWakeOtherVehiclesFailure
        ),
        tap(() => {
          this.toastService.createErrorToast(vehicleActionFailure);
        }),
        concatMap(() => [setHasChanges({ hasChanges: false })])
      ),
    { dispatch: false }
  );

  private static getEmulatorAddUpdateSuccessActions(vehicle: VehicleDto) {
    return [VehiclesActions.addEmulatorVehicleToStoreSuccess({ vehicleCreated: vehicle })];
  }

  constructor(
    private readonly actions$: Actions,
    private readonly vehicleService: VehicleService,
    private readonly missionDispatchService: MissionDispatchService,
    private readonly brakeTestService: BrakeTestService,
    private readonly toastService: ToastService,
    private readonly tenantRouterService: TenantRouterService,
    private readonly router: Router,
    private readonly mapService: MapsService,
    private readonly emulatorService: EmulatorService,
    private readonly secondarySideMenuService: SecondarySideMenuService,
    private readonly vehiclePollingSignalRService: VehiclePollingSignalRService
  ) {}
}
