/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  FeatureToggle,
  GraphManagerSettingsDto,
  LIFUploadModel,
  LayerCreateStatus,
  LayoutDto,
  LifUpdatePreview,
  LifUploadResponse,
  NodeDto,
  NodeOccupancyStatusChangeDto,
  UnbookNodeResult,
  UploadLifRequest,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GraphNodeType, GuidString } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { Observable, catchError, map, of } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class LifService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.LIF;
  private readonly servicePathNodes = API_SERVICES.Nodes;
  private readonly servicePathFeatures = API_SERVICES.Features;

  protected apiUrl = environment.Services.GraphManager;

  constructor(
    httpClient: HttpClient,
    store: Store<RootState>,
    protected readonly translate: AtsTranslationService
  ) {
    super(httpClient, store);
  }

  getGraphLayer(mapId: GuidString): Observable<LayoutDto[]> {
    return this.get(`${this.servicePath}/layouts/${mapId}`);
  }

  getNodeBookings(): Observable<void> {
    return this.get(`${this.servicePathNodes}/nodeBookings`);
  }

  getNodeBooking(nodeId: GuidString): Observable<NodeOccupancyStatusChangeDto> {
    return this.get(`${this.servicePathNodes}/isNodeBooked/${nodeId}`);
  }

  unbookNode(nodeId: GuidString): Observable<UnbookNodeResult> {
    return this.post(`${this.servicePathNodes}/cancelBookedNode/${nodeId}`);
  }

  getNodesAndEdges(): Observable<LayoutDto> {
    return this.get<LayoutDto>(`${this.servicePath}/layouts`);
  }

  getNodesByMap(mapId: GuidString): Observable<NodeDto[]> {
    return this.get<NodeDto[]>(`${this.servicePathNodes}/by-map/${mapId}`);
  }

  getNodesByType(mapId: GuidString, nodeType: GraphNodeType): Observable<NodeDto[]> {
    return this.get<NodeDto[]>(`${this.servicePathNodes}/by-map/${mapId}/by-type/${nodeType}`);
  }

  importLifFile(layer: LIFUploadModel): Observable<LifUploadResponse> {
    if (!layer.lifFile) {
      return of({
        status: LayerCreateStatus.Failed,
        message: this.translate.get('maps.navigationLayerActions.lifFileUploadFail'),
      });
    }

    const uploadLifRequest = this.createUploadLifRequest(layer);

    return this.requestWithStatus('post', uploadLifRequest.url, uploadLifRequest.formData).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError(error => of(error)),
      map(res => {
        return this.createUploadResponse(res, layer.navigationLayerId);
      })
    );
  }

  updateLif(lifUpdatePreview: LifUpdatePreview): Observable<LifUpdatePreview> {
    return this.post(`${API_SERVICES.LIF}/updateLif`, lifUpdatePreview);
  }

  getNavigationLayersIdsWithLif(mapId: GuidString): Observable<GuidString[]> {
    return this.get<GuidString[]>(`${API_SERVICES.LIF}/hasLayouts/${mapId}`);
  }

  previewUpdateLif(layer: LIFUploadModel): Observable<LifUpdatePreview> {
    const uploadLifRequest = this.createUploadLifRequest(layer);

    return this.requestWithStatus('post', uploadLifRequest.url, uploadLifRequest.formData).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError(error => of(error)),
      map(res => {
        return res.body;
      })
    );
  }

  createUploadLifRequest(layer: LIFUploadModel): UploadLifRequest {
    const formData = new FormData();
    if (layer.lifFile) formData.append('lifFile', layer.lifFile);
    formData.append('mapId', layer.mapId.toString());

    const url = layer.updateLif
      ? `${API_SERVICES.PreviewUpdateLif}/${layer.mapId}/${layer.navigationLayerId}`
      : `${API_SERVICES.LIF}/${layer.mapId}/${layer.navigationLayerId}`;
    return { formData, url };
  }

  createUploadResponse(
    response: HttpResponse<LifUploadResponse | string>,
    navigationLayerId?: GuidString
  ): LifUploadResponse {
    return response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.NoContent
      ? {
          status: LayerCreateStatus.Success,
          message: this.translate.get('maps.navigationLayerActions.lifFIleUploaded'),
          navigationLayerId,
        }
      : {
          status: LayerCreateStatus.Failed,
          message: this.translate.get('maps.navigationLayerActions.lifFileUploadFail'),
        };
  }

  getFeatureSettings(): Observable<GraphManagerSettingsDto> {
    return this.get<GraphManagerSettingsDto>(`${this.servicePathFeatures}`);
  }

  updateFeatureSettings(value: boolean): Observable<FeatureToggle> {
    return this.put<FeatureToggle>(`${this.servicePathFeatures}`, {
      isToggledOn: value,
    });
  }

  restartGraphManager(): Observable<void> {
    return this.get<void>(`${this.servicePathFeatures}/Restart`);
  }

  updateBeginShiftMode(value: boolean): Observable<FeatureToggle> {
    return this.put<FeatureToggle>(`${this.servicePathFeatures}/BeginShiftMode`, {
      isToggledOn: value,
    });
  }

  updateNode(node: NodeDto): Observable<LayoutDto[]> {
    return this.put<LayoutDto[]>(`${this.servicePath}/node/${node.map.id}`, node);
  }
}
