import { IPointData } from 'pixi.js';

export type MapItem = IMapItem | IPillarLabelItem | IZoneVertexMapItem;

export interface IMapItem {
  id: string; // NDS: GuidString - Change on PillarGrid
  position: IPointData;
  type: MapItemType;
}

export enum MapItemType {
  Poi = 'poi',
  PillarGrid = 'pillarGrid',
  Collision = 'collision',
  IntersectionCollision = 'intersectionCollision',
  Zone = 'zone',
  ZoneVertex = 'zoneVertex',
  Vehicle = 'vehicle',
  Node = 'node',
  RouteConfig_Node = 'routeConfigNode',
  RouteConfig_Edge = 'routeConfigEdge',
  Segment = 'segment',
}

export enum MapItemSelectMode {
  New,
  Existing,
}

export interface SelectedMapItem<T extends IMapItem> {
  item: T;
  isFocused: boolean;
  mode?: MapItemSelectMode;
}

export interface IPillarLabelItem extends IMapItem {
  row: number;
  column: number;
}

export interface IZoneVertexMapItem extends IMapItem {
  index: number;
}

export function isSelectedMapItem(
  item: SelectedMapItem<MapItem> | IMapItem | object | undefined
): item is SelectedMapItem<MapItem> {
  return item !== undefined && 'item' in item && 'isFocused' in item;
}

export function isMapItem(item: IMapItem | object): item is IMapItem {
  if (typeof item === 'string') {
    return false;
  }
  return 'id' in item && 'position' in item && 'type' in item;
}

export function isNewMapItem(item: IMapItem | object): item is IMapItem {
  if (typeof item === 'string') {
    return false;
  }
  return 'id' in item && 'type' in item;
}
