import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { DsTimepickerComponentConfiguration } from '@bmw-ds/components';
import { ShutdownModeDto, VehicleDto } from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { VehicleAvailability, VehiclePowerSavingType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { format } from 'date-fns';
import { TIME_TO_SHUTDOWN } from 'modules/settings/constants/settings.constant';
import { LocaleSettings } from 'primeng/calendar';
import { getLocale } from 'shared/helpers';

export interface ShutdownModeModalInput {
  toggle: boolean;
  shutdownData: ShutdownModeDto;
  mode: 'toggle' | 'edit';
}

interface ShutdownModeModalViewModel {
  dto: ShutdownModeDto;
  titleKey: 'title' | 'editWakeUpTime';
  showHeading: boolean;
  headingKey: string;
  toggle: boolean;
  confirmBtnKey: string;
}

@Component({
  selector: 'app-shutdown-mode-modal',
  templateUrl: './shutdown-mode-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShutdownModeModalComponent implements OnInit, OnChanges {
  shutdownData: ShutdownModeDto | undefined;
  @Input() shutdownModeInputData: ShutdownModeModalInput | undefined = undefined;
  @Input() isModalOpen = false;
  @Input() allVehicles: VehicleDto[] = [];
  @Input() showVehiclesToExclude = false;

  vm: ShutdownModeModalViewModel = {
    titleKey: 'title',
    showHeading: false,
    headingKey: '',
    toggle: false,
    confirmBtnKey: 'shared.editBar.save',
    dto: {
      isToggledOn: false,
      endShutdownAndWakeUpVehiclesDateTime: null,
      numberOfVehiclesToExclude: 0,
      latestWakeup: null,
      dateUpdated: null,
      vehicleShutdownType: VehiclePowerSavingType.Str,
    },
  };

  config: Partial<DsTimepickerComponentConfiguration> = {
    timezone: 'UTC',
    hours: 1,
    minutes: 1,
    format: 'HH:mm',
  };
  calendarLocale?: LocaleSettings;
  isShutdownValid = true;
  isWakeUpTimeValid = true;
  isVehiclesToExcludeValid = true;
  isVerificationModalOpen = false;
  selectedWakeupDate: Date | null = this.createEarliestAllowedWakeupDate(TIME_TO_SHUTDOWN + 1);
  viewTime: string = format(this.selectedWakeupDate || new Date(), 'HH:mm');
  wakeOtherVehicles = false;

  @Output() readonly confirmToggleShutdown = new EventEmitter<ShutdownModeDto>();
  @Output() readonly cancelShutdownToggle = new EventEmitter<void>();
  @Output() readonly reopen = new EventEmitter<void>();

  constructor(private readonly translator: AtsTranslationService) {}
  ngOnChanges({ isModalOpen }: TypedChanges<ShutdownModeModalComponent>): void {
    if (this.shutdownModeInputData) {
      this.vm = this.createViewModel(this.shutdownModeInputData);
    }

    if (this.vm.dto.endShutdownAndWakeUpVehiclesDateTime) {
      this.wakeOtherVehicles = true;
    }

    if (this.vm.toggle && isModalOpen?.currentValue && this.wakeOtherVehicles) {
      this.setSelectedWakeupDate();
      this.validateShutdownForm();
    }
  }

  ngOnInit(): void {
    this.calendarLocale = getLocale(this.translator.currentLang);
  }

  setSelectedWakeupDate(): void {
    if (this.shutdownModeInputData) {
      const selectedWakeupDateString =
        this.shutdownModeInputData.mode === 'edit'
          ? this.vm.dto.endShutdownAndWakeUpVehiclesDateTime
          : this.vm.dto.latestWakeup;

      if (selectedWakeupDateString) {
        this.selectedWakeupDate = new Date(selectedWakeupDateString);

        this.viewTime = format(this.selectedWakeupDate || new Date(), 'HH:mm');
      }
    }
  }

  createEarliestAllowedWakeupDate(delayInMinutes: number): Date {
    const earliestAllowedWakeupDate = new Date();
    earliestAllowedWakeupDate.setMinutes(earliestAllowedWakeupDate.getMinutes() + delayInMinutes);
    return earliestAllowedWakeupDate;
  }

  createViewModel(data: ShutdownModeModalInput): ShutdownModeModalViewModel {
    const action = data.toggle ? 'enable' : 'disable';
    const newVm = objectHelper.cloneDeep(this.vm);

    if (data.mode === 'toggle') {
      newVm.confirmBtnKey = 'settings.functions.' + action;
    } else {
      newVm.titleKey = 'editWakeUpTime';
    }

    if (data.mode === 'edit') {
      newVm.confirmBtnKey = 'shared.editBar.save';
    }

    newVm.showHeading = data.mode === 'toggle';
    newVm.headingKey = 'settings.functions.shutdownMode.' + action;
    newVm.toggle = data.toggle;
    newVm.dto = objectHelper.cloneDeep(data.shutdownData);
    if (this.showVehiclesToExclude && data.shutdownData.numberOfVehiclesToExclude === 0) {
      newVm.dto.numberOfVehiclesToExclude = 1;
    } else if (!this.showVehiclesToExclude && data.shutdownData.numberOfVehiclesToExclude !== 0) {
      newVm.dto.numberOfVehiclesToExclude = 0;
    }
    return newVm;
  }

  onClose(): void {
    this.isShutdownValid = true;
    this.cancelShutdownToggle.emit();
  }

  onConfirm(): void {
    if (this.wakeOtherVehicles) {
      this.validateShutdownForm();
    }

    if (this.isShutdownValid || !this.vm.toggle || !this.wakeOtherVehicles) {
      if (this.vm.toggle && this.showVehiclesToExclude) {
        const numberOfVehiclesOnChargingStation = this.allVehicles.filter(
          v => v.availability === VehicleAvailability.Available && v.isCharging === true
        ).length;
        numberOfVehiclesOnChargingStation ? this.toggle() : (this.isVerificationModalOpen = true);
      } else {
        this.toggle();
      }
    }
  }

  onVerificationModalClose(): void {
    this.onClose();
    this.isVerificationModalOpen = false;
  }

  onVerificationConfirm(): void {
    this.toggle();
    this.isVerificationModalOpen = false;
  }

  toggle(): void {
    const result: ShutdownModeDto = {
      ...this.vm.dto,
      isToggledOn: this.vm.toggle,
      endShutdownAndWakeUpVehiclesDateTime:
        this.wakeOtherVehicles && this.selectedWakeupDate
          ? this.selectedWakeupDate.toISOString()
          : null,
      numberOfVehiclesToExclude:
        this.wakeOtherVehicles && this.selectedWakeupDate
          ? this.vm.dto.numberOfVehiclesToExclude
          : 0,
    };
    this.confirmToggleShutdown.emit(result);
  }

  validateShutdownForm(): void {
    this.isWakeUpTimeValid = false;
    this.isVehiclesToExcludeValid = false;

    if (this.selectedWakeupDate !== null && this.viewTime) {
      this.selectedWakeupDate.setSeconds(0, 0);
      this.selectedWakeupDate = new Date(
        format(this.selectedWakeupDate, 'yyyy-M-dd') + ' ' + this.viewTime
      );
      this.isWakeUpTimeValid =
        this.selectedWakeupDate > this.createEarliestAllowedWakeupDate(TIME_TO_SHUTDOWN);
    }

    if (this.vm.dto.numberOfVehiclesToExclude > 0) this.isVehiclesToExcludeValid = true;
    this.isShutdownValid = this.isWakeUpTimeValid;
    if (this.showVehiclesToExclude)
      this.isShutdownValid = this.isShutdownValid && this.isVehiclesToExcludeValid;
  }

  onChangeWakeUp(checked: boolean): void {
    if (!checked) {
      this.isShutdownValid = true;
      this.vm.dto.endShutdownAndWakeUpVehiclesDateTime = null;
    } else {
      this.setSelectedWakeupDate();
      this.validateShutdownForm();
    }
  }
}
