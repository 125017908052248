/* eslint-disable max-lines */
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { GraphNode, GraphNodeShape, GraphNodeType, MapItemType } from 'core/models';
import { Colors } from 'library/styles';
import { MapPixiHelper, getMinMaxValue } from 'modules/maps/helpers';
import { BitmapText, Container, Graphics, Sprite, Texture } from 'pixi.js';
import { GeometryConverter, calculateOffsetFromCenter } from 'shared/helpers';
import { MapItemBase, RotateMapItem } from '../map-item-container';
import { MapItemRotationContainer, RotationStyle } from '../map-item-rotation-container.graphic';
import { MapLayerDrawing } from '../map-layer-drawing';
import { FontName, MapFonts } from '../map-layer-fonts.constant';
import { GraphicsEx } from '../pixi';
import {
  NodeArrowStyle,
  NodeName,
  NodePoiBgStyle,
  NodePoiStyle,
  NodeRotation,
  NodeRotationStyle,
  NodeShape,
  NodeStyle,
  SHOW_WAYPOINT_LABELS,
} from './graph-layer.constant';
import { GraphNodeIcon, NodeIconOptions, NodeTypeIcon } from './node-icon.model';

const textures: Record<GraphNodeIcon, Texture> = {
  [GraphNodeIcon.Fueling]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Fueling]),
  [GraphNodeIcon.StoppingPoint]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.StoppingPoint]
  ),
  [GraphNodeIcon.Parking]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Parking]),

  [GraphNodeIcon.Charging]: MapPixiHelper.createTexture(NodeIconOptions[GraphNodeIcon.Charging]),
  [GraphNodeIcon.ContainerLane]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.ContainerLane]
  ),
  [GraphNodeIcon.DisposeStation]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.DisposeStation]
  ),
  [GraphNodeIcon.ContainerStation]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.ContainerStation]
  ),
  [GraphNodeIcon.ContainerTowerStation]: MapPixiHelper.createTexture(
    NodeIconOptions[GraphNodeIcon.ContainerTowerStation]
  ),
};

export class NodeMapItem implements MapItemBase, RotateMapItem {
  container: MapItemRotationContainer;
  protected readonly node: GraphNode;

  private labelGraphic: Container | undefined;

  private icon: Sprite | undefined;
  private childIcon: Sprite | undefined;

  private arrow: Graphics | undefined;
  private selection: Graphics | undefined;
  nodeGraphic: Graphics | GraphicsEx | undefined;

  private config: NodeGraphicConfig | undefined;

  constructor(drawing: MapLayerDrawing, node: GraphNode, type = MapItemType.Node) {
    this.node = node;
    this.container = drawing.addChild(
      new MapItemRotationContainer(node.nodeId.toString(), type, this.getRotationStyle(node))
    );

    this.container.position.copyFrom(node.nodePosition);
    this.container.setScaleReverse();

    this.createGraphic(node);
  }

  // #region Create Graphic
  private getNodeConfig(node: GraphNode): NodeGraphicConfig {
    return {
      color: this.getNodeColor(node),
      size: this.getNodeSize(node),
      icon: this.getNodeIconType(node),
      selection: this.getEnableSelection(node),
      outline: node.hasRule,
      showLabel: node.nodeType !== GraphNodeType.Waypoint || SHOW_WAYPOINT_LABELS,
      hasArrow: node.isReTrackingPoint,
      hasFooter: this.getNodeIconShape(node) === GraphNodeShape.RectangleFooter,
      isRound: this.isRoundGraphic(node),
      rotation: node.reTrackingOrientation || 0,
    };
  }

  protected getNodeColor(node: GraphNode): number {
    if (node.isSwitchNode) return Colors.graphLayer.NodeColorSwitch;

    if (!this.isRoundGraphic(node)) return Colors.graphLayer.NodePoiColor;

    return Colors.graphLayer.NodeColor;
  }

  private isRoundGraphic(node: GraphNode): boolean {
    const shape = this.getNodeIconShape(node);
    return shape === GraphNodeShape.SmallRound || shape === GraphNodeShape.MediumRound;
  }

  private getNodeIconShape(node: GraphNode): GraphNodeShape {
    return NodeShape[node.nodeType];
  }

  protected getNodeSize(node: GraphNode): number {
    if (node.nodeType === GraphNodeType.Waypoint && !(node.isReTrackingPoint || node.isSwitchNode))
      return NodeStyle.WaypointSize;

    return NodeStyle.NodeSize;
  }

  private getNodeIconType(node: GraphNode): GraphNodeIcon | undefined {
    return NodeTypeIcon[node.nodeType];
  }

  protected getEnableSelection(_node: GraphNode): boolean {
    return true;
  }

  protected createGraphic(node: GraphNode): void {
    this.container.remove();
    this.config = this.getNodeConfig(node);

    this.container.interactive = this.config.selection;
    this.container.buttonMode = this.config.selection;

    this.container.addChild((this.nodeGraphic = this.createNodeGraphic(this.config)));
    this.nodeGraphic.addChild((this.selection = this.createSelection()));

    if (this.config.hasArrow) {
      this.container.addChild((this.arrow = this.createArrowGraphic(this.config)));
    }

    if (this.config.showLabel) {
      this.container.addChild((this.labelGraphic = this.createNodeLabelGraphic(node.nodeName)));
    }

    if (this.config.hasFooter) {
      this.nodeGraphic.addChild(this.createFooterGraphic()); // Move to CreateNodeGraphic
    }
  }

  setScale(scale: number): void {
    const maxScale = NodeArrowStyle.Scale * 2;
    const minScale = maxScale * NodeStyle.MinScaleFactor;
    const scaleFactor = getMinMaxValue(scale, maxScale, minScale);

    this.nodeGraphic?.scale.set(1 / scaleFactor);
    this.arrow?.scale.set(1 / (scaleFactor / maxScale));
  }

  protected createNodePoiBackground(): Graphics {
    const nodeColor = this.getNodeColor(this.node);

    const baseBackground = new Graphics()
      .beginFill(nodeColor)
      .lineStyle(NodePoiStyle.NodePoiWidth, nodeColor)
      .drawRoundedRect(
        -NodePoiStyle.NodePoiOutlineWidth / 2,
        -NodePoiStyle.NodePoiOutlineLength / 2,
        NodePoiStyle.NodePoiOutlineWidth,
        NodePoiStyle.NodePoiOutlineLength,
        NodePoiStyle.NodePoiOutlineRadius
      )
      .endFill();

    baseBackground
      .beginFill(NodePoiStyle.NodePoiHeaderBackgroundColor)
      .drawRoundedRect(
        -NodePoiStyle.NodePoiHeaderX,
        -NodePoiStyle.NodePoiHeaderY,
        NodePoiStyle.NodePoiHeaderWidth,
        NodePoiStyle.NodePoiHeaderLength,
        NodePoiStyle.NodePoiHeaderRadius
      )
      .endFill();

    return baseBackground;
  }

  protected createSelection(): Graphics {
    if (this.config?.isRound) {
      return this.createRoundGraphicSelected();
    } else {
      return this.createSquareGraphicSelected();
    }
  }

  private createNodeGraphic(config: NodeGraphicConfig): Graphics | GraphicsEx {
    return config.isRound ? this.createRoundGraphic(config) : this.createSquareGraphic(config);
  }

  private createRoundGraphic(config: NodeGraphicConfig): GraphicsEx {
    const graphic = new GraphicsEx();

    if (config.outline) {
      graphic.lineStyle(
        NodeStyle.NodeOutlineWidth,
        Colors.graphLayer.NodeColor,
        NodeStyle.NodeOutlineAlpha,
        NodeStyle.NodeOutlineAlignment
      );
    }

    graphic.beginFill(config.color, NodeStyle.NodeAlpha).drawCircle(0, 0, config.size).endFill();
    graphic.scale.set(NodeStyle.NodeScale);

    if (config.icon !== undefined) {
      graphic.addChild((this.icon = this.createIcon(config.icon)));
    }

    return graphic;
  }

  private createSquareGraphic(config: NodeGraphicConfig): Graphics {
    const graphic = this.createNodePoiBackground();
    graphic.scale.set(NodeStyle.NodeScale);

    if (config.icon !== undefined) {
      graphic.addChild((this.childIcon = this.createIcon(config.icon)));
    }

    return graphic;
  }

  private createIcon(icon: GraphNodeIcon): Sprite {
    return MapPixiHelper.createIcon(textures[icon], NodeIconOptions[icon]);
  }

  private createFooterGraphic(): Graphics {
    const footer = new Graphics();

    footer
      .beginFill(NodePoiStyle.NodePoiFooterBackgroundColor)
      .drawRoundedRect(
        NodePoiBgStyle.NodePoiBgX,
        NodePoiBgStyle.NodePoiBgY,
        NodePoiBgStyle.NodePoiBgWidth1,
        NodePoiBgStyle.NodePoiBgHeight,
        NodePoiBgStyle.NodePoiBgWidthRadius1
      )
      .endFill();

    footer
      .beginFill(NodePoiStyle.NodePoiFooterBackgroundColor)
      .drawRoundedRect(
        NodePoiBgStyle.NodePoiBgX,
        NodePoiBgStyle.NodePoiBgY,
        NodePoiBgStyle.NodePoiBgWidth2,
        NodePoiBgStyle.NodePoiBgHeight,
        NodePoiBgStyle.NodePoiBgWidthRadius2
      )
      .endFill();

    footer.addChild(this.createFooterText('aST'));
    return footer;
  }

  private createArrowGraphic(config: NodeGraphicConfig): Graphics {
    const graphic = MapPixiHelper.createArrow({
      x: 0,
      y: 0,
      orientation: 90,
      color: NodeArrowStyle.Color,
      size: NodeArrowStyle.Size,
    });

    graphic.pivot.set(
      0,
      this.config?.isRound ? NodeArrowStyle.Offset : NodeArrowStyle.SquareOffset
    );
    graphic.rotation = GeometryConverter.radianToPositiveValue(config.rotation ?? 0);

    return graphic;
  }

  private createFooterText(name: string): Graphics {
    const text = new BitmapText(` ${name} `, MapFonts[FontName.BoldWhite]);
    const background = new Graphics();

    text.align = 'center';
    text.scale.set(NodePoiBgStyle.NodePoiScale);
    text.rotation = NodePoiStyle.NodePoiRotation;

    background.addChild(text);
    background.pivot.set(NodePoiBgStyle.NodePoiTextAlignX, NodePoiBgStyle.NodePoiTextAlignY);

    return background;
  }

  private createNodeLabelGraphic(name: string): Graphics {
    const text = new BitmapText(` ${name} `, MapFonts[FontName.Default]);
    const background = new Graphics();

    text.align = 'center';
    text.scale.set(NodeName.scale);

    background.addChild(text);

    background
      .beginFill(NodeName.background, NodeName.alpha)
      .drawRoundedRect(
        0,
        -NodeName.padding / 2,
        text.width,
        text.height + NodeName.padding,
        NodeName.cornerRadius
      )
      .endFill();

    if (this.config?.isRound) {
      background.pivot.set(text.width / 2, text.height + NodeName.yOffset);
    } else {
      background.pivot.set(text.width / 2, text.height + NodePoiStyle.NodePoiLabelOffset);
    }

    return background;
  }

  private createRoundGraphicSelected(): Graphics {
    if (!this.nodeGraphic) return new Graphics();

    const selection = MapPixiHelper.createSelection({
      width: NodeStyle.NodeOutlineWidth,
      size: this.config?.size ?? NodeStyle.WaypointSize,
      color: NodePoiStyle.NodePoiBorderColorSelected,
    });

    selection.visible = this.selection?.visible ?? false;

    return selection;
  }

  private createSquareGraphicSelected(): Graphics {
    if (!this.nodeGraphic) return new Graphics();

    const selection = new Graphics()
      .lineStyle(NodePoiStyle.NodePoiBorderWidth, NodePoiStyle.NodePoiBorderColorSelected)
      .drawRoundedRect(
        NodePoiStyle.NodePoiOutlineX,
        NodePoiStyle.NodePoiOutlineY,
        NodePoiStyle.NodePoiOutlineWidth,
        NodePoiStyle.NodePoiOutlineLength,
        NodePoiStyle.NodePoiOutlineRadius
      );

    selection.name = 'selection';
    selection.filters = [new DropShadowFilter()];
    selection.visible = this.selection?.visible ?? false;

    return selection;
  }
  // #endregion

  // #region Update
  toggleSelection(isSelected = false): void {
    if (this.selection) {
      this.selection.visible = isSelected;
    }
  }

  revertGraphic(node: GraphNode): void {
    this.createGraphic(node);
  }

  updateGraphic(node: GraphNode): void {
    this.toggleSelection(true);
    this.createGraphic(node);

    if (this.selection?.visible) {
      this.toggleRotationMode(node.isReTrackingPoint);
    }
  }
  // #endregion

  // #region Rotate
  get allowRotation(): boolean {
    return this.node.isReTrackingPoint;
  }

  toggleRotationMode(enable: boolean): void {
    if (enable) this.container.showRotation(this.config?.rotation ?? 0, this.arrow);
    else this.container.clearRotation();
  }

  rotate(degrees: number): void {
    this.container.setItemAngle(degrees);
  }

  rotateLabel(): void {
    const rotationRadians =
      this.container.rotation +
      (this.labelGraphic?.rotation ?? 0) +
      NodePoiStyle.NodePoiBorderWidth +
      NodeName.padding +
      (this.labelGraphic?.height ?? 0);

    const rotationOffset = calculateOffsetFromCenter(
      rotationRadians,
      this.nodeGraphic?.height ?? 0,
      this.nodeGraphic?.width ?? 0
    );

    this.labelGraphic?.pivot.set(
      this.labelGraphic?.width / 2,
      rotationOffset + NodePoiStyle.NodePoiBorderWidth / 2
    );
  }

  onMapRotation(mapRotation: number): void {
    if (this.labelGraphic) this.labelGraphic.rotation = mapRotation;

    if (this.config?.isRound) {
      if (this.icon) this.icon.rotation = mapRotation;
    } else {
      this.rotateLabel();
      if (this.childIcon) this.childIcon.rotation = mapRotation;
    }
  }

  protected getRotationStyle(node: GraphNode): RotationStyle {
    return {
      ...NodeRotationStyle,
      offset: this.isRoundGraphic(node) ? NodeRotationStyle.offset : NodeRotation.SquareOffset,
    };
  }
  // #endregion
}

export interface NodeGraphicConfig {
  color: number;
  size: number;
  selection: boolean;
  icon?: GraphNodeIcon;
  outline?: boolean;
  showLabel: boolean;
  isRound?: boolean;
  hasArrow?: boolean;
  hasFooter?: boolean;
  rotation?: number;
}
