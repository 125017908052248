import { Coordinate } from 'core/dtos';

export interface Vector2D {
  x: number;
  y: number;
  theta?: number;
}

export interface Pos2D {
  x: number;
  y: number;
  theta: number;
}

export interface Pose2D extends Vector2D {
  orientation: number;
}

export interface Pose2DO {
  x: number;
  y: number;
  orientation: number;
}

export function isPose2D(position: Pose2D | Vector2D | Coordinate): position is Pose2D {
  return 'orientation' in position;
}

export function arePositionsEqual(
  position1: Pose2D | Coordinate,
  position2: Pose2D | Coordinate
): boolean {
  return position1.x === position2.x && position1.y === position2.y;
}
