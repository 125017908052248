import { SoftwareUpdateVersionStatus } from 'core/models';
import { Icons } from 'library/constants';

const softwareUpdateStatus = {
  [SoftwareUpdateVersionStatus.Unknown]: Icons.Question,
  [SoftwareUpdateVersionStatus.Outdated]: Icons.Warning,
  [SoftwareUpdateVersionStatus.Approved]: Icons.Check,
  [SoftwareUpdateVersionStatus.Test]: Icons.Exchange,
};

export const IconCollection = {
  softwareUpdateStatus,
};
