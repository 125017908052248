<ng-container [ngSwitch]="showOnIndependentFromWorkingArea">
  <div *ngSwitchCase="true">
    <div class="navigation-bar__wrapper position-sticky top-0">
      <ds-navigation-bar
        [(activeItemId)]="activeItemId"
        data-cy="sideMenu"
        (itemSelect)="onItemSelected($event)"
        (isExpandedChange)="isExpandedChanged($event)">
        <ds-navigation-bar-item
          id="sideMenu.settings.roles"
          label="{{ 'sideMenu.settings.roles' | translate }}"
          data="settings/global-user-roles-permission/environment-users"
          data-cy="roles"
          icon="group_settings"
          class="to-bottom"></ds-navigation-bar-item>
        <ds-navigation-bar-item
          id="sideMenu.settings.auditLogs"
          label="{{ 'sideMenu.settings.auditLog' | translate }}"
          data="settings/auditlog"
          icon="history"
          data-cy="auditLog"></ds-navigation-bar-item>
      </ds-navigation-bar>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="navigation-bar__wrapper position-sticky top-0">
      <ds-navigation-bar
        [(activeItemId)]="activeItemId"
        data-cy="sideMenu"
        (itemSelect)="onItemSelected($event)"
        (isExpandedChange)="isExpandedChanged($event)">
        <ds-navigation-bar-item
          label="{{ 'sideMenu.home.parent' | translate }}"
          icon="home"
          (click)="onParentSelected('welcome')"
          data-cy="homeParent">
          <ds-navigation-bar-item
            id="sideMenu.home.overview"
            label="{{ 'sideMenu.home.overview' | translate }}"
            data="welcome"
            data-cy="home">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.home.reports"
            label="{{ 'sideMenu.home.reports' | translate }}"
            data="home/reports"
            data-cy="reports">
          </ds-navigation-bar-item
        ></ds-navigation-bar-item>

        <ds-navigation-bar-item
          label="{{ 'sideMenu.maps.parent' | translate }}"
          icon="map"
          (click)="onParentSelected('maps')"
          data-cy="mapsParent">
          <ds-navigation-bar-item
            id="sideMenu.maps.mapView"
            label="{{ 'sideMenu.maps.mapView' | translate }}"
            data="maps"
            data-cy="mapView">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.maps.manageMaps"
            label="{{ 'sideMenu.maps.manageMaps' | translate }}"
            data="maps/manage"
            data-cy="manageMaps">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.maps.manageZoneSets"
            label="{{ 'sideMenu.maps.manageZoneSets' | translate }}"
            data="maps/zone-sets"
            data-cy="manageZoneSets">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.maps.mapData"
            label="{{ 'sideMenu.maps.mapData' | translate }}"
            data="maps/map-data"
            data-cy="mapData">
          </ds-navigation-bar-item
        ></ds-navigation-bar-item>

        <ds-navigation-bar-item
          label="{{ 'sideMenu.jobs.parent' | translate }}"
          icon="list_checked"
          (click)="onParentSelected('jobs/missions/active')"
          data-cy="jobsParent">
          <ds-navigation-bar-item
            id="sideMenu.jobs.active"
            [label]="'sideMenu.jobs.active' | translate"
            data="jobs/missions/active"
            data-cy="active">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.jobs.history"
            label="{{ 'sideMenu.jobs.history' | translate }}"
            data="jobs/missions/history"
            data-cy="history">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.jobs.processConfig"
            label="{{ 'sideMenu.jobs.processConfig' | translate }}"
            data="jobs/processConfigurator"
            data-cy="processConfigurator">
          </ds-navigation-bar-item
        ></ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.vehicles"
          label="{{ 'sideMenu.vehicles.vehicles' | translate }}"
          (click)="onParentSelected('vehicles')"
          icon="ats-vehicle-icon"
          data-cy="vehiclesParent">
          <ds-navigation-bar-item
            id="sideMenu.vehicles.vehiclesOverview"
            label="{{ 'sideMenu.vehicles.vehiclesOverview' | translate }}"
            data="vehicles"
            data-cy="vehicles"></ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.vehicles.softwareManagement"
            label="{{ 'sideMenu.vehicles.softwareManagement' | translate }}"
            data="vehicles/software-management"
            data-cy="softwareManagement"></ds-navigation-bar-item>
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          label="{{ 'sideMenu.pois.parent' | translate }}"
          icon="map_pin"
          (click)="onParentSelected('pois')"
          data-cy="poisParent">
          <ds-navigation-bar-item
            id="sideMenu.pois.poiGroups"
            label="{{ 'sideMenu.pois.poiGroups' | translate }}"
            data="pois"
            data-cy="pois">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.pois.mappingTable"
            label="{{ 'sideMenu.pois.mappingTable' | translate }}"
            data="mapping"
            data-cy="mappingTable">
          </ds-navigation-bar-item>
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.opcua-devices.parent"
          label="{{ 'sideMenu.opcua-devices.parent' | translate }}"
          data="devices"
          icon="robot"
          data-cy="devices">
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.failureCommenting.parent"
          label="{{ 'sideMenu.failureCommenting.parent' | translate }}"
          data="failure/failure-categories"
          icon="error"
          (click)="onParentSelected('failure/failure-categories')"
          data-cy="failure-commenting">
          <ds-navigation-bar-item
            id="sideMenu.failureCommenting.failure-categories"
            label="{{ 'sideMenu.failureCommenting.failureCategories' | translate }}"
            data="failure/failure-categories"
            data-cy="failureSettings"></ds-navigation-bar-item>

          <ds-navigation-bar-item
            id="sideMenu.failureCommenting.comment-settings"
            label="{{ 'sideMenu.failureCommenting.commentSettings' | translate }}"
            data="failure/comment-settings"
            data-cy="masterData"></ds-navigation-bar-item>

          <ds-navigation-bar-item
            id="sideMenu.failureCommenting.comment-overview"
            label="{{ 'sideMenu.failureCommenting.commentOverview' | translate }}"
            data="failure/comment-overview"
            data-cy="failureOverview"></ds-navigation-bar-item>
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.settings.settings"
          label="{{ 'sideMenu.settings.settings' | translate }}"
          data="settings/functions"
          icon="settings"
          (click)="onParentSelected('settings/functions')"
          data-cy="settings">
          <ds-navigation-bar-item
            id="sideMenu.settings.functions"
            label="{{ 'sideMenu.settings.functions' | translate }}"
            data="settings/functions"
            data-cy="functions">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            id="sideMenu.settings.error-forwarding"
            label="{{ 'sideMenu.settings.errorForwarding' | translate }}"
            data="settings/error-forwarding"
            data-cy="errorForwarding">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            *ngIf="showDeveloperOptions === 'true' && newIpstSettingsEnabled"
            id="sideMenu.settings.ipstAlertNowSettings"
            label="{{ 'sideMenu.settings.ipstAlertNowSettings' | translate }}"
            data="settings/ipstAlertNowSettings"
            data-cy="ipstAlertNowSettings">
          </ds-navigation-bar-item>
          <ds-navigation-bar-item
            *ngIf="showDeveloperOptions === 'true'"
            id="sideMenu.settings.developerSettings"
            label="{{ 'sideMenu.settings.developerSettings' | translate }}"
            data="settings/developer-settings"
            data-cy="developerSettings">
          </ds-navigation-bar-item>
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          *ngIf="graphManagerEnabled === true"
          id="sideMenu.callOff.callOff"
          label="{{ 'sideMenu.callOff.callOff' | translate }}"
          data="call-off"
          icon="road_map"
          data-cy="callOff">
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.settings.roles"
          label="{{ 'sideMenu.settings.roles' | translate }}"
          data="{{ selectedWorkingAreaSettingsUrl }}"
          data-cy="waroles"
          icon="group_settings"
          class="to-bottom">
        </ds-navigation-bar-item>

        <ds-navigation-bar-item
          id="sideMenu.settings.auditLogs"
          label="{{ 'sideMenu.settings.auditLog' | translate }}"
          routerLink="auditlog"
          icon="history"
          data="auditlog"
          data-cy="auditLog">
        </ds-navigation-bar-item>
      </ds-navigation-bar>
    </div>
  </div>
</ng-container>
