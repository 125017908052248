/* eslint-disable @typescript-eslint/no-magic-numbers */
import { BitmapText, Graphics } from 'pixi.js';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

const dimensions: ForkliftDimensions = {
  length: 202.9,
  width: 109,
  origin: 100, // No Pivot provided yet
  fork: {
    width: 50,
    length: 100,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class ForkliftManualMapItem extends ForkliftMapItem {
  protected tag: BitmapText | undefined;

  protected getDimensions(): ForkliftDimensions {
    return this.getForkDimensions({ ...dimensions });
  }

  protected createVehicleBackground(): Graphics {
    this.tag = VehicleGraphicHelper.createTag(`M`);

    return VehicleGraphicHelper.createVehicleBaseWithTag(this.dimensions, this.tag);
  }

  protected setOtherOrientation(relativeOrientation: number): void {
    if (this.tag) {
      const rad = VehicleGraphicHelper.calculateTagRotation(relativeOrientation);

      if (this.tag.rotation !== rad) this.tag.rotation = rad;
    }
  }
}
