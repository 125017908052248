import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { environment } from '@environment';
import {
  FeatureToggle,
  IpstServiceFeatures,
  JobManagerFeatures,
  MapManagerFeatures,
  OrderGatewayFeatures,
  getDefaultIpstServiceFeatures,
  getDefaultJobManagerFeatures,
  getDefaultMapManagerFeatures,
  getDefaultOrderGatewayFeatures,
} from 'core/dtos';
import { AtsTranslationService } from 'core/services';
import { TemporaryViewModel } from 'modules/settings/components/temporary/temporary.viewmodel';
import { Subject } from 'rxjs';
import { FunctionsModalInput } from '../functions-modal/functions-modal.component';

@Component({
  selector: 'app-temporary',
  templateUrl: './temporary.component.html',
  styleUrls: ['./temporary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemporaryComponent implements OnChanges, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  viewModel: TemporaryViewModel;
  graphManagerEnabled = false;
  isModalOpen = false;
  modalInputData: FunctionsModalInput = {
    title: '',
    description: undefined,
    heading: undefined,
    toggleOn: false,
  };
  showDeveloperOptions = environment.config.showDeveloperOptions;

  @Input() readonly jobManagerSettings: JobManagerFeatures = getDefaultJobManagerFeatures();
  @Input() jobManagerSettingsLoaded = false;
  @Input() readonly mapManagerSettings: MapManagerFeatures = getDefaultMapManagerFeatures();
  @Input() mapManagerSettingsLoaded = false;
  @Input() readonly ipstServiceFeatures: IpstServiceFeatures = getDefaultIpstServiceFeatures();
  @Input() ipstServiceFeaturesLoaded = false;

  @Input() readonly orderGatewayFeatures: OrderGatewayFeatures = getDefaultOrderGatewayFeatures();
  @Input() orderGatewayFeaturesLoaded = false;

  @Input() readonly graphManagerFeatures: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  @Input() graphManagerFeaturesLoaded = false;

  @Output() readonly saveMatrixOptimization = new EventEmitter<boolean>();
  @Output() readonly saveBrakeTest = new EventEmitter<boolean>();
  @Output() readonly saveIncludeTuggerTrainErrors = new EventEmitter<boolean>();
  @Output() readonly saveNewIpstToggle = new EventEmitter<boolean>();
  @Output() readonly savePktInterfaceToggle = new EventEmitter<boolean>();
  @Output() readonly restartGraphManager = new EventEmitter<boolean>();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly translation: AtsTranslationService
  ) {
    this.viewModel = this.generateViewModel();
  }

  generateViewModel(): TemporaryViewModel {
    return {
      matrixOptimization: { isToggledOn: false, dateUpdated: null },
      brakeTest: { isToggledOn: false, dateUpdated: null },
      includeTuggerTrainErrors: { isToggledOn: false, dateUpdated: null },
      newIpstToggle: { isToggledOn: false, dateUpdated: null },
      pktInterfaceToggle: { isToggledOn: false, dateUpdated: null },
    };
  }

  ngOnChanges({
    jobManagerSettings,
    ipstServiceFeatures,
    orderGatewayFeatures,
    graphManagerFeatures,
  }: TypedChanges<TemporaryComponent>): void {
    if (jobManagerSettings?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        matrixOptimization:
          jobManagerSettings.currentValue.matrixOptimizationToggle ??
          this.viewModel.matrixOptimization,
        brakeTest: jobManagerSettings.currentValue.brakeTestToggle ?? this.viewModel.brakeTest,
      };
    }

    if (ipstServiceFeatures?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        includeTuggerTrainErrors:
          ipstServiceFeatures.currentValue.includeTuggerTrainErrorsToggle ??
          this.viewModel.includeTuggerTrainErrors,
        newIpstToggle:
          ipstServiceFeatures.currentValue.newIpstToggle ?? this.viewModel.newIpstToggle,
      };
    }

    if (orderGatewayFeatures?.currentValue) {
      this.viewModel = {
        ...this.viewModel,
        pktInterfaceToggle:
          orderGatewayFeatures.currentValue.pktInterfaceToggle ?? this.viewModel.pktInterfaceToggle,
      };
    }

    if (graphManagerFeatures?.currentValue) {
      this.graphManagerEnabled = graphManagerFeatures.currentValue.isToggledOn;
    }
  }

  onChangeMatrixOptimization(value: boolean): void {
    this.saveMatrixOptimization.emit(value);
  }

  onChangeBrakeTest(value: boolean): void {
    this.saveBrakeTest.emit(value);
  }

  onChangeIncludeTuggerTrainErrors(value: boolean): void {
    this.saveIncludeTuggerTrainErrors.emit(value);
  }

  onChangeNewIpstToggle(value: boolean): void {
    this.saveNewIpstToggle.emit(value);
  }

  onChangeTogglePktInterface(value: boolean): void {
    this.savePktInterfaceToggle.emit(value);
  }

  onRestartGraphManager(): void {
    if (this.translation.currentLang === 'en') {
      this.modalInputData = {
        title: 'Restart Graph Manager?',
        heading: 'Warning',
        description:
          'Are you sure that you want to restart Graph Manager Service? ' +
          'This would interrupt the running tours.',
        toggleOn: false,
        customText: 'Restart',
      };
    } else {
      this.modalInputData = {
        title: 'Graph Manager neu starten?',
        heading: 'Warnung',
        description:
          'Möchten Sie den Graph Manager Service wirklich neu starten? ' +
          'Dies würde die laufenden Touren unterbrechen.',
        toggleOn: false,
        customText: 'Neustart',
      };
    }
    this.isModalOpen = true;
    this.cdRef.markForCheck();
  }

  onConfirm(isConfirmed: boolean): void {
    if (this.isModalOpen && isConfirmed) {
      this.restartGraphManager.emit(true);
    }
    this.isModalOpen = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
