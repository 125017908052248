import { DateString } from 'core/models';

export interface GraphManagerSettings {
  enableGraphManager?: boolean;
  maxVehicleDistance?: number;
  isBeginShiftModeEnabled?: boolean;
}

export interface GraphManagerSettingsDto {
  settings: GraphManagerSettings;
  graphManagerEnabledUpdatedTimeUtc: DateString | null;
  isBeginShiftModeEnabledUpdatedTimeUtc: DateString | null;
}

export function getDefaultGraphManagerFeatures(): GraphManagerSettingsDto {
  return {
    graphManagerEnabledUpdatedTimeUtc: null,
    isBeginShiftModeEnabledUpdatedTimeUtc: null,
    settings: {
      enableGraphManager: false,
      isBeginShiftModeEnabled: false,
    },
  };
}
