import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { ErrorForwarding } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { ErrorForwardingModel, HelpToolFilterDto } from 'core/models';
import { Observable, map } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ErrorForwardingService extends TenantHttpClient {
  readonly servicePath = API_SERVICES.ErrorForwarding;

  protected apiUrl = environment.Services.Ipst;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getErrorForwardings(): Observable<ErrorForwardingModel[]> {
    return this.get<ErrorForwardingModel[]>(this.servicePath).pipe(
      map(errors =>
        errors.map(err => ({
          ...err,
          compositeKeyId: err.type + '_' + err.errorSource,
        }))
      )
    );
  }

  getHelpToolRecipientKeys(filter: HelpToolFilterDto): Observable<string[]> {
    return this.post<string[]>(`${this.servicePath}/helpTool`, filter).pipe();
  }

  saveErrorForwarding(errorModel: ErrorForwardingModel): Observable<ErrorForwarding> {
    const errorForwardingDto = {
      type: errorModel.type,
      source: errorModel.errorSource,
      sendNotification: errorModel.sendNotification,
    };
    return this.post<ErrorForwardingModel>(
      `${this.servicePath}/${errorModel.type}/notification`,
      errorForwardingDto
    );
  }
}
