/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MM_PER_CM } from 'core/constants';
import { VehicleDto } from 'core/dtos';
import { LoadTypeDimensions } from 'core/models';
import { MapItemBase } from '../../map-item-container';
import { UnderrideDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { UnderrideMapItem } from '../vehicle-types/underride.graphic';

export const strDimensions: UnderrideDimensions = {
  length: 144,
  width: 64.1,
  origin: 144 - 39.34, //393.40 mm from front
  load: {
    width: 70,
    length: 110,
  },
  loadPosition: 72,
};

export class STRMapItem extends UnderrideMapItem implements MapItemBase {
  protected getDimensions(vehicle: VehicleDto): VehicleDimensions {
    if (vehicle.supportedLoadTypes.length === 0) {
      return strDimensions;
    }

    const loadType = vehicle.supportedLoadTypes[0];
    let loadDimensions = LoadTypeDimensions[loadType];

    if (!loadDimensions) {
      loadDimensions = strDimensions.load;
    } else {
      loadDimensions = {
        width: loadDimensions.width / MM_PER_CM,
        length: loadDimensions.length / MM_PER_CM,
      };
    }

    return {
      ...strDimensions,
      load: loadDimensions,
    };
  }
}
