/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodeGroupService } from 'core/api-services';
import { TenantRouterService } from 'core/services/tenant-router.service';
import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as NodeGroupActions from '../actions/node-group.actions';

@Injectable()
export class NodeGroupEffects {
  loadNodeGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.loadNodeGroups),
      concatMap(() =>
        this.nodeGroupService.getAllNodeGroups().pipe(
          map(nodeGroups => {
            return NodeGroupActions.loadNodeGroupsSuccess({
              nodeGroups,
            });
          }),
          catchError(errorMessage => of(NodeGroupActions.loadNodeGroupsFailure({ errorMessage })))
        )
      )
    )
  );

  loadNodeGroupsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.loadNodeGroupsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadNodeGroupsFailure');
        })
      ),
    { dispatch: false }
  );

  loadOccupiedNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.loadOccupiedNodes),
      concatMap(() =>
        this.nodeGroupService.getAllOccupiedNodes().pipe(
          map(() => NodeGroupActions.loadOccupiedNodesSuccess()),
          catchError(errorMessage =>
            of(NodeGroupActions.loadOccupiedNodesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  getNodeOccupied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.getNodeOccupied),
      concatMap(action =>
        this.nodeGroupService.isNodeOccupied(action.nodeId).pipe(
          map(() => NodeGroupActions.loadOccupiedNodesSuccess()),
          catchError(errorMessage =>
            of(NodeGroupActions.loadOccupiedNodesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadOccupiedNodesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.loadNodeGroupsFailure),
        tap(() => {
          this.toastService.createErrorToast('shared.actions.loadNodeGroupsFailure');
        })
      ),
    { dispatch: false }
  );

  createNodeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.createNodeGroup),
      concatMap(({ nodeGroup }) =>
        this.nodeGroupService.createNodeGroup(nodeGroup).pipe(
          map(nodeGroup => NodeGroupActions.createNodeGroupSuccess({ nodeGroup })),
          catchError(errorMessage => of(NodeGroupActions.createNodeGroupFailure({ errorMessage })))
        )
      )
    )
  );

  createNodeGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.createNodeGroupSuccess),
      tap(({ nodeGroup }) => {
        this.toastService.createSuccessToast('shared.actions.created', {
          name: nodeGroup.name,
        });
        this.router.navigate(['/pois/nodegroups/']).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  createNodeGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.createNodeGroupFailure),
        tap(() => {
          this.toastService.createErrorToast('nodeGroups.creationFailure');
        })
      ),
    { dispatch: false }
  );

  updateNodeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.updateNodeGroup),
      concatMap(({ nodeGroup }) =>
        this.nodeGroupService.updateNodeGroup(nodeGroup).pipe(
          map(updatedNodeGroup =>
            NodeGroupActions.updateNodeGroupSuccess({ nodeGroup: updatedNodeGroup })
          ),
          catchError(errorMessage => of(NodeGroupActions.updateNodeGroupFailure({ errorMessage })))
        )
      )
    )
  );

  updateNodeGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.updateNodeGroupSuccess),
      tap(({ nodeGroup }) => {
        this.toastService.createSuccessToast('shared.actions.updated', {
          name: nodeGroup.name,
        });
        this.router.navigate(['/pois/nodegroup/view/' + nodeGroup.id.toString()]).catch(() => {});
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateNodeGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.updateNodeGroupFailure),
        tap(() => {
          this.toastService.createErrorToast('nodeGroups.updateNodeGroupFailure');
        })
      ),
    { dispatch: false }
  );

  deleteNodeGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeGroupActions.deleteNodeGroup),
      concatMap(({ nodeGroup }) =>
        this.nodeGroupService.deleteNodeGroup(nodeGroup.id).pipe(
          map(() => NodeGroupActions.deleteNodeGroupSuccess({ nodeGroup })),
          catchError(errorMessage => of(NodeGroupActions.deleteNodeGroupFailure({ errorMessage })))
        )
      )
    )
  );

  deleteNodeGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.deleteNodeGroupSuccess),
        tap(state => {
          this.toastService.createSuccessToast('shared.actions.deleted', {
            name: state.nodeGroup.name,
          });
        })
      ),
    { dispatch: false }
  );

  deleteNodeGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeGroupActions.deleteNodeGroupFailure),
        tap(() => this.toastService.createErrorToast('nodeGroups.deleteNodeGroupFailure'))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly nodeGroupService: NodeGroupService,
    private readonly toastService: ToastService,
    private readonly router: TenantRouterService
  ) {}
}
