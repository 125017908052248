<div style="display: flex">
  <button
    ds-button
    data-cy="expandAll"
    [variant]="'ghost'"
    (click)="expandAllClicked()"
    [disabled]="disableExpand$ | async">
    {{ 'shared.treeView.expandAll' | translate }}
  </button>
  <button
    ds-button
    data-cy="collapseAll"
    [variant]="'ghost'"
    (click)="collapseAllClicked()"
    [disabled]="disableCollapse$ | async">
    {{ 'shared.treeView.collapseAll' | translate }}
  </button>
</div>
