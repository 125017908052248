import { GuidString, MappingType, WaitForStepDetails } from 'core/models';
import { StepType } from './step.enum';

export interface BasicStep {
  pointOfInterestId?: GuidString;
  pointOfInterestName?: string;
  pointOfInterestGroupId?: GuidString;
  timeSpanS?: number;
  sequenceNumber: number;
  eventId?: GuidString;
  trajectory?: GuidString[];
  mappingType?: MappingType;
  eventName?: string;
}

export interface StepOdata extends BasicStep {
  type: StepTypeOdata;
}

export type StepTypeOdata =
  | 'Goto'
  | 'Dock'
  | 'Wait'
  | 'Lift'
  | 'Drop'
  | 'WaitForEvent'
  | 'DockToCharge'
  | 'RaiseEvent'
  | 'GotoMapping'
  | 'DockToMapping'
  | 'StartToCharge';

export type WaitStepModel = StepModelBase<StepType.Wait> & { timeSpanS: number };

type UnknownModel = StepModelBase<StepType.Unknown>;

export type LiftStepModel = StepModelBase<StepType.Lift>;

export type DropStepModel = StepModelBase<StepType.Drop>;

export type GotoStepModel = StepModelBase<StepType.Goto>;

export type TurnDollyStepModel = StepModelBase<StepType.TurnDolly>;

export type GotoAndPushSideButtonStepModel = StepModelBase<StepType.GotoAndPushSideButton>;

export type GotoPoseStepModel = StepModelBase<StepType.GotoPose>;

export type DockStepModel = StepModelBase<StepType.Dock>;

export type DockToChargeStepModel = StepModelBase<StepType.DockToCharge>;

export type StartToChargeStepModel = StepModelBase<StepType.StartToCharge>;

export type StopToChargeStepModel = StepModelBase<StepType.StopToCharge>;

export type WaitForSideButtonStepModel = StepModelBase<StepType.WaitForSideButton>;

export type WaitForEndOfStepStepModel = StepModelBase<StepType.WaitForEndOfStep> & {
  waitForStep: WaitForStepDetails;
  waitForStepId: string;
};

export type StepFallbackPointOfInterestModel = {
  mappingType: MappingType;
  fallbackPointOfInterestId: GuidString | null;
  fallbackPointOfInterestName: string | null;
  fallbackPointOfInterestGroupId: GuidString | null;
  hasStepCompletionNotificationEnabled: boolean;
};

export type GoToMappingLookupStepModel = StepModelBase<StepType.GoToMapping> &
  StepFallbackPointOfInterestModel;

export type DockToMappingLookupStepModel = StepModelBase<StepType.DockToMapping> &
  StepFallbackPointOfInterestModel;

export type WaitForDeviceStepModel = StepModelBase<StepType.WaitForDevice> & {
  device: string;
  streamingService: string;
  substeps: DeviceSubStepModel[];
};

export enum DeviceSubStepState {
  Pending = 0,
  Running = 1,
  Completed = 2,
  Cancelled = 3,
  TimedOut = 4,
}

export interface DeviceSubStepModel {
  nodeName: string;
  value: string;
  type?: DeviceSubStepType;
  state?: DeviceSubStepState;
  updatedDateTime?: string;
  sequenceNumber: number;
}

export enum DeviceSubStepType {
  Read = 0,
  Write = 1,
}

export type WaitForScanDestinationStepModel = StepModelBase<StepType.WaitForScanDestination> & {
  device: string;
  streamingService: string;
  nodeName: string;
  huNumber: string | null;
  destination: string | null;
};

export type WaitForSapAcknowledgementStepModel = StepModelBase<StepType.WaitForSapAcknowledgement>;

export type WaitForWarehouseTaskAcknowledgementModel =
  StepModelBase<StepType.WaitForWarehouseTaskAcknowledgement> & {
    eventKey: 'confirm-warehouse-task';
  };

export type PoiStepType =
  | StepType.Goto
  | StepType.Dock
  | StepType.DockToCharge
  | StepType.TurnDolly
  | StepType.GotoAndPushSideButton;

export type MappingStepType = StepType.GoToMapping | StepType.DockToMapping;

interface PoiStepModelBase<T extends PoiStepType> extends BasicStepModelBase<T> {
  pointOfInterestGroupId: GuidString;
  pointOfInterestId?: GuidString;
  pointOfInterestName: string;
  mappingType?: MappingType;
  trajectory: GuidString[];
  hasStepCompletionNotificationEnabled: boolean;
}

export interface BasicStepModelBase<T extends StepType> {
  type: T;
  sequenceNumber: number;
}

export type StepModelBase<T extends StepType> = T extends infer P
  ? P extends PoiStepType
    ? PoiStepModelBase<P>
    : BasicStepModelBase<T>
  : BasicStepModelBase<T>;

export type StepModel =
  | WaitStepModel
  | LiftStepModel
  | DropStepModel
  | GotoStepModel
  | DockStepModel
  | DockToChargeStepModel
  | StartToChargeStepModel
  | StopToChargeStepModel
  | WaitForEndOfStepStepModel
  | GoToMappingLookupStepModel
  | DockToMappingLookupStepModel
  | WaitForSideButtonStepModel
  | WaitForDeviceStepModel
  | WaitForScanDestinationStepModel
  | WaitForSapAcknowledgementStepModel
  | WaitForWarehouseTaskAcknowledgementModel
  | TurnDollyStepModel
  | GotoAndPushSideButtonStepModel
  | GotoPoseStepModel
  | UnknownModel;

type HasMapping = StepModel & { mappingType: MappingType };
type HasSubSteps = StepModel & { substeps: DeviceSubStepModel[] };

export const hasStepMapping = (step: StepModel): step is HasMapping => {
  switch (step.type) {
    case StepType.GoToMapping:
    case StepType.DockToMapping:
      return true;
    case StepType.Goto:
    case StepType.Dock:
    case StepType.DockToCharge:
    case StepType.TurnDolly:
      return !!step.mappingType;
    default:
      return false;
  }
};

export const hasStepSubsteps = (
  step: StepModel | TourStepModel
): step is HasSubSteps | HasTourSubSteps => step.type === StepType.WaitForDevice;

export type TourPoiStepType = StepType.Goto | StepType.GotoAndPushSideButton;

type HasTourSubSteps = TourStepModel & { substeps: DeviceSubStepModel[] };

interface TourPoiStepModelBase<T extends TourPoiStepType> extends BasicStepModelBase<T> {
  nodeId: GuidString;
  nodeGroupId?: GuidString | null;
  hasArrivalNotificationEnabled: boolean;
}

export type TourStepModelBase = {
  raiseEventId: GuidString | null;
};

export type SapStepModel = BasicStepModelBase<StepType.SAP> &
  TourStepModelBase & {
    hasArrivalNotificationEnabled: boolean;
  };

export type GotoTourStepModel = TourPoiStepModelBase<StepType.Goto> & TourStepModelBase;

export type GotoAndPushSideButtonTourStepModel =
  TourPoiStepModelBase<StepType.GotoAndPushSideButton> & TourStepModelBase;

export type WaitForDeviceTourStepModel = BasicStepModelBase<StepType.WaitForDevice> &
  TourStepModelBase & {
    device: string;
    streamingService: string;
    substeps: DeviceSubStepModel[];
  };

export type WaitForSapAcknowledgementTourStepModel =
  BasicStepModelBase<StepType.WaitForSapAcknowledgement> &
    TourStepModelBase & {
      preStorageLocation?: string;
    };

export type WaitForEndOfStepTourStepModel = BasicStepModelBase<StepType.WaitForEndOfStep> &
  TourStepModelBase & {
    waitEventId: GuidString | null;
    waitForStepId: string;
  };

export type LiftTourStepModel = StepModelBase<StepType.Lift> &
  TourStepModelBase & {
    height: number | null;
  };

export type DropTourStepModel = StepModelBase<StepType.Drop> &
  TourStepModelBase & {
    height: number | null;
  };

export type TourStepModel =
  | GotoTourStepModel
  | GotoAndPushSideButtonTourStepModel
  | SapStepModel
  | DropTourStepModel
  | LiftTourStepModel
  | WaitForDeviceTourStepModel
  | WaitForSapAcknowledgementTourStepModel
  | WaitForEndOfStepTourStepModel;
