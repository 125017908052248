<button
  *ngIf="menuItems?.length"
  ds-button
  variant="ghost"
  class="action-menu-button"
  [size]="iconSize"
  [attr.data-cy]="buttonAutomationId"
  [disclosureIcon]="variant === 'vertical' ? 'more_vertical' : 'more_horizontal'"
  [ds-menu-trigger-for]="menu"></button>

<ds-menu #menu [xPosition]="listPosition" appendTo="body" (opened)="onMenuOpened()">
  <ng-container *ngFor="let item of menuItems">
    <div *ngIf="item.visible !== false">
      <ds-list-item
        *ngIf="!item.tooltipOptions; else tooltipItemTemplate"
        [attr.data-cy]="item.automationId"
        [disabled]="item.disabled"
        [icon]="item.icon"
        (click)="onItemSelected(item)"
        >{{ item.label }}</ds-list-item
      >
    </div>

    <ng-template #tooltipItemTemplate>
      <ds-list-item
        [attr.data-cy]="item.automationId"
        [disabled]="item.disabled"
        [icon]="item.icon"
        (click)="onItemSelected(item)"
        ds-tooltip="{{ item.tooltipOptions?.tooltipLabel ?? '' | translate }}"
        [dsTooltipConfig]="item.tooltipOptions?.tooltipConfig"
        >{{ item.label }}</ds-list-item
      >
    </ng-template>
  </ng-container></ds-menu
>
