import { createSelector } from '@ngrx/store';

import { ProcessChainGroupTraceInputModel } from 'modules/jobs/mission-monitoring/components/process-chain-group-trace-list/process-chain-group-trace-list-model';
import { selectAllProcessChainGroupTraces } from './process-chain-group-trace.selectors';

export const selectAllProcessChainTracesWithGroup = createSelector(
  selectAllProcessChainGroupTraces,
  (processChainGroupTraces): ProcessChainGroupTraceInputModel[] => {
    return processChainGroupTraces.flatMap(groupTrace =>
      groupTrace.processChainTraces.map(x => {
        return {
          id: x.id,
          mapId: groupTrace.mapId,
          workAreaId: groupTrace.workAreaId,
          groupStatus: groupTrace.status,
          groupName: groupTrace.name,
          name: x.processChainName,
          status: x.status,
          processChainGroupTraceId: groupTrace.id,
          createdDateTime: groupTrace.createdDateTime,
        };
      })
    );
  }
);
