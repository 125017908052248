import { GuidString } from 'core/models';

export enum ZoneType {
  Restricted = 0,
  PreferredDirection = 1,
  SpeedLimit = 2,
  NoReplanning = 3,
  Eraser = 4,
  Interaction = 6,
  LimitedCapacity = 7,
  Intersection = 8,
  TrafficManagementCustom = 9,
  AlertNow = 10,
}
interface VectorDto {
  x: number;
  y: number;
}
interface ZoneDto {
  id: GuidString;
  polygon: VectorDto[];
  mapId: GuidString;
  zoneSetId: GuidString;
  masterZoneId: GuidString;
  zoneName?: string;
}

export interface RestrictedZoneDto extends ZoneDto {
  zoneType: ZoneType.Restricted;
}

export interface IntersectionZoneDto extends ZoneDto {
  zoneType: ZoneType.Intersection;
  maximumWaitingTimeSeconds: number;
  device: string;
  interactions: InteractionDto[];
  feedback: InteractionFeedbackDto[];
  alertWhenDeactivated: boolean;
  isActive: boolean;
  allConditionsMet?: boolean;
  deviceIsOnline: boolean;
  ignoresConvoyCollisions: boolean;
  onlyAllowVehiclesStoppedOutside: boolean;
  considerManualVehicles: boolean;
  ignorePriorities: boolean;
}

export interface DirectedZoneDto extends ZoneDto {
  zoneType: ZoneType.PreferredDirection;
  orientation: number;
  alignCost: number;
  antiAlignCost: number;
  crossCost: number;
}

export interface SpeedZoneDto extends ZoneDto {
  zoneType: ZoneType.SpeedLimit;
  speed: number;
}

export interface NoReplanningZoneDto extends ZoneDto {
  zoneType: ZoneType.NoReplanning;
  isWaitingArea: boolean;
  ignoresConvoyCollisions: boolean;
}

export interface EraserZoneDto extends ZoneDto {
  zoneType: ZoneType.Eraser;
}

export enum InteractionType {
  Read = 0,
  Write = 1,
}

export interface InteractionDto {
  node: string;
  value: string;
  type: InteractionType;
  isMet?: boolean;
}

export enum InteractionZoneTrigger {
  ZoneLocked = 1,
  ZoneUnlocked = 2,
  ZoneOccupied = 3,
  ZoneFreed = 4,
  ZoneHasMemberships = 5,
  ZoneNoMemberships = 6,
}

export interface InteractionFeedbackDto {
  node: string;
  value: string;
  trigger: InteractionZoneTrigger;
}

export interface InteractionZoneDto extends ZoneDto {
  zoneType: ZoneType.Interaction;
  device: string;
  maxNumberOfVehicles: number;
  interactions: InteractionDto[];
  feedback: InteractionFeedbackDto[];
  isActive: boolean;
  allConditionsMet?: boolean;
  alertWhenDeactivated: boolean;
  deviceIsOnline?: boolean;
  considerManualVehicles: boolean;
}

export interface InteractionZoneState {
  interactions: InteractionStateDto[];
  interactionZoneId: GuidString;
  masterZoneId: GuidString;
  allConditionsMet: boolean;
  deviceIsOnline: boolean;
}

export interface InteractionStateDto extends InteractionDto {
  isMet?: boolean;
}

export interface LimitedCapacityZoneDto extends ZoneDto {
  zoneType: ZoneType.LimitedCapacity;
  maxNumberOfVehicles: number;
  isActive: boolean;
  considerManualVehicles: boolean;
}

export interface TrafficManagementCustomZoneDto extends ZoneDto {
  zoneType: ZoneType.TrafficManagementCustom;
  isActive: boolean;
  deactivateTrafficManager: boolean;
  ignoresConvoyCollisions: boolean;
  ignoresDeadlockAreaCollisions: boolean;
  ignoresForklifts: boolean;
  ignoresTuggerTrains: boolean;
  ignoresStandingTuggerTrains: boolean;
  ignoresStandingForklifts: boolean;
  ignoresStandingUnitLoads: boolean;
}

export interface AlertNowZoneDto extends ZoneDto {
  zoneType: ZoneType.AlertNow;
  zoneName: string;
  vehicleTypes: string[];
}
