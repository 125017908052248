import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { AuthGql } from 'core/api-services';
import { AuditLogTableRow, OrganizationDto, WorkingAreaDto } from 'core/dtos';
import { ToolBarControlKeys, ToolBarControls, ToolBarItem } from 'core/models';
import { AtsTranslationService, ToolbarService } from 'core/services';
import { compact } from 'lodash';
import { AuditLogDataService } from 'modules/settings/services/auditlog-data.service';
import { Observable, Subject, firstValueFrom, of, takeUntil } from 'rxjs';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-auditlog-container',
  templateUrl: './auditlog-container.component.html',
  styleUrls: ['./auditlog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogContainerComponent implements OnInit, OnDestroy {
  allWorkingAreas$: Observable<WorkingAreaDto[]> = of();
  allOrganizations$: Observable<OrganizationDto[]> = of([]);
  organizationList: OrganizationDto[] = [];
  workingAreasList: WorkingAreaDto[] = [];
  readonly DEFAULT_ROWS = 50;
  isEditMode$ = of(false);
  TOOLBAR_ITEMS: ToolBarItem[] = [];
  ngUnsubscribe = new Subject<void>();
  pageSize = this.DEFAULT_ROWS;

  selectedWa?: WorkingAreaDto;

  filtering: AuthGql.AuditLogFilterInput | null = null;

  private readonly _sorting: Pick<AuthGql.LogPageQueryVariables, 'order'> = {
    order: AuthGql.SortEnumType.Desc,
  };

  constructor(
    private readonly toolbarService: ToolbarService,
    private readonly auditLogDataService: AuditLogDataService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly query: AuthGql.LogPageGQL,
    private readonly translationService: AtsTranslationService
  ) {}

  ngOnInit(): void {
    this.dispatchActionsAndQuerySelectors();
    this.buildToolBarItems();
  }

  buildToolBarItems(): void {
    this.TOOLBAR_ITEMS = [
      {
        key: ToolBarControlKeys.Search,
        type: ToolBarControls.Search,
      },
    ];

    this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
  }

  private dispatchActionsAndQuerySelectors(): void {
    this.rootStore
      .pipe(select(fromRoot.selectAllOrganizations))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(orgs => (this.organizationList = orgs));

    this.allOrganizations$ = of(this.organizationList);
    this.allWorkingAreas$ = of(this.organizationList.flatMap(org => org.workAreas));
    this.rootStore.dispatch(fromRoot.showSearchBar({ isVisible: false }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const pageSize =
        params.request.endRow != null && params.request.startRow != null
          ? params.request.endRow - params.request.startRow
          : this.pageSize;

      const criteria = [
        this.auditLogDataService.criteriaWorkingAreas(params.request),
        this.auditLogDataService.criteriaOrganization(params.request),
        this.auditLogDataService.criteriaAuditEventType(params.request),
        this.auditLogDataService.criteriaUpdates(params.request),
        ...this.auditLogDataService.criteriaCreatedUtcDate(params.request),
      ];

      this.filtering = { and: compact(criteria) };

      if (this.filtering.and?.length === 0) {
        this.filtering = null;
      }

      firstValueFrom(
        this.query.fetch({
          order: this._sorting.order,
          offset: params.request.startRow ?? 0,
          pageSize: pageSize,
          filter: this.filtering,
        })
      )
        .then(result => {
          const langProp = this.translationService.currentLang === 'de' ? 'textDe' : 'textEn';
          const nodes = result.data.auditLog?.items ?? [];
          const rows: AuditLogTableRow[] = nodes.map(it => ({
            auditTableEventType: it.auditTableEventType,
            createdUtc: it.createdUtc,
            waName: it.workArea?.name ?? '',
            orgName: it.workArea?.organization.name ?? '',
            action: it[langProp],
            id: it.id,
          }));

          params.success({
            rowData: rows,
            rowCount: result.data.auditLog?.totalCount,
          });
        })
        .catch(err => {
          params.fail();
          console.error(err);
        });
    },
  };
}
