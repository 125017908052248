/* eslint-disable max-lines */
import { EMPTY_GUID } from 'core/constants';
import {
  CoordinateSystemOffset,
  LayersCreateModel,
  NavigationLayerCardModel,
  NavigationLayerCreateModel,
  NavigationLayerResponseModel,
} from 'core/dtos';
import { NavigationLayerListViewItem, VehicleGroup } from 'core/models';
import { MapNavigationLayerColors } from 'modules/maps/constants';

const map1Id = 'bb2b55bc-dd94-4262-a5af-966431d57b31';
const map2Id = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';
const map3Id = '03840cd2-a27a-4c83-a9ba-2fc05e3f0300';
const map4Id = '26e35f79-8e1a-40fa-8ee1-002c5e6b858e';
const map5Id = '26e35f79-8e1a-40fa-8ee1-002c5e6b855s';
const navigationLayer1id = 'acbc3d54-0e7a-43b0-865c-daeaf4a8971a';

const map1NL2Name = 'map 1 NL 2';

const newNavigationLayer: NavigationLayerCreateModel = {
  id: EMPTY_GUID,
  mapId: EMPTY_GUID,
  name: 'test',
  imgFile: undefined,
  imageOffset: { x: 0, y: 0, rotation: 0 },
  coordinateSystemOffset: undefined,
  resolution: 0.5,
  vehicleGroup: VehicleGroup.Str,
};

const navigationLayerViewModels: NavigationLayerListViewItem[] = [
  {
    id: 'acbc3d54-0e7a-43b0-865c-daeaf4a8971a',
    mapId: map1Id,
    name: 'map 1 NL 1',
    vehicleGroup: VehicleGroup.Str,
    resolution: '1px = 5.00cm',
    offset: 'x=2.5m, y=3m, theta=45°',
    hasLif: undefined,
  },
  {
    id: 'b5da63ce-2e7d-43b5-865d-c56ef4a8971b',
    mapId: map1Id,
    name: map1NL2Name,
    vehicleGroup: VehicleGroup.TuggerTrainFourAmV2,
    resolution: '1px = 5.00cm',
    offset: 'x=0.25m, y=0.75m, theta=30°',
    hasLif: undefined,
  },
  {
    id: '5dffb38e-7c79-4c2e-af76-45a0d8c0ab74',
    mapId: '03840cd1-a25a-4c33-a7ba-1fc05e3f0212',
    name: 'map 2 navlayer',
    vehicleGroup: VehicleGroup.Str,
    resolution: '1px = 20.00cm',
    offset: 'x=0cm, y=0cm',
    hasLif: undefined,
  },
];

const navigationLayers: NavigationLayerResponseModel[] = [
  {
    id: navigationLayer1id,
    name: 'map 1 NL 1',
    mapId: map1Id,
    widthInPixels: 325,
    heightInPixels: 550,
    resolution: 0.05,
    occupiedThresh: 0,
    createdOn: '2020-12-17T12:13:16',
    freeThresh: 0,
    imageOffset: {
      x: 50,
      y: 60,
      rotation: 45,
    },
    coordinateSystemOffset: {
      translation: {
        x: 50,
        y: 60,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 45,
    },
    imageUrl: '',
    displayOrder: 0,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: 'b5da63ce-2e7d-43b5-865d-c56ef4a8971b',
    name: map1NL2Name,
    mapId: map1Id,
    widthInPixels: 1667,
    heightInPixels: 3458,
    resolution: 0.05,
    occupiedThresh: 0,
    createdOn: '2020-09-14T12:08:11',
    freeThresh: 0,
    imageOffset: {
      x: 5,
      y: 15,
      rotation: 30,
    },
    coordinateSystemOffset: {
      translation: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 1,
    vehicleGroup: VehicleGroup.TuggerTrainFourAmV2,
  },
  {
    id: '5dffb38e-7c79-4c2e-af76-45a0d8c0ab74',
    name: 'map 2 navlayer',
    mapId: map2Id,
    widthInPixels: 212,
    heightInPixels: 188,
    resolution: 0.2,
    occupiedThresh: 0,
    createdOn: '2019-10-01T12:00:00',
    freeThresh: 0,
    imageOffset: {
      x: 0,
      y: 0,
      rotation: 0,
    },
    coordinateSystemOffset: {
      translation: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 0,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: '5dffb38e-7c79-4c2e-af76-45a0d8c0ab75',
    name: 'map 3',
    mapId: map3Id,
    widthInPixels: 212,
    heightInPixels: 188,
    resolution: 0.2,
    occupiedThresh: 0,
    createdOn: new Date().toISOString(),
    freeThresh: 0,
    imageOffset: {
      x: 0,
      y: 0,
      rotation: 0,
    },
    coordinateSystemOffset: {
      translation: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 0,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: '02820fed-00eb-46e8-be23-9f07668a8fff',
    name: 'negative offset layer',
    mapId: map4Id,
    widthInPixels: 500,
    heightInPixels: 250,
    resolution: 0.2,
    occupiedThresh: 0,
    createdOn: new Date().toISOString(),
    freeThresh: 0,
    imageOffset: {
      x: -15,
      y: -20,
      rotation: 0,
    },
    coordinateSystemOffset: {
      translation: {
        x: -15,
        y: -20,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 0,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: '02820fed-00eb-46e8-be23-9f07668a8fff',
    name: 'NL 1',
    mapId: map5Id,
    widthInPixels: 500,
    heightInPixels: 250,
    resolution: 0.2,
    occupiedThresh: 0,
    createdOn: new Date().toISOString(),
    freeThresh: 0,
    imageOffset: {
      x: 0,
      y: 0,
      rotation: 0,
    },
    coordinateSystemOffset: {
      translation: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 0,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: '02820fed-00eb-46e8-be23-9f07668a8ggg',
    name: 'NL 2',
    mapId: map5Id,
    widthInPixels: 500,
    heightInPixels: 250,
    resolution: 0.2,
    occupiedThresh: 0,
    createdOn: new Date().toISOString(),
    freeThresh: 0,
    imageOffset: {
      x: 15,
      y: 20,
      rotation: 0,
    },
    coordinateSystemOffset: {
      translation: {
        x: 15,
        y: 20,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 3,
    vehicleGroup: VehicleGroup.Str,
  },
  {
    id: 'b5da63ce-2e7d-43b5-865d-c56ef4a8991b',
    name: map1NL2Name,
    mapId: map2Id,
    widthInPixels: 1667,
    heightInPixels: 3458,
    resolution: 0.05,
    occupiedThresh: 0,
    createdOn: '2020-09-14T12:08:11',
    freeThresh: 0,
    imageOffset: {
      x: 5,
      y: 15,
      rotation: 30,
    },
    coordinateSystemOffset: {
      translation: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    imageUrl: '',
    displayOrder: 1,
    vehicleGroup: VehicleGroup.TuggerTrainFourAmV2,
  },
];

export class NavigationLayerMockData {
  static getNavigationLayers(): NavigationLayerResponseModel[] {
    return [this.getNavigationLayer1(), this.getNavigationLayer2(), this.getMap2NavigationLayer()];
  }

  static getNavigationLayersWithDisplayOrderIndexOutOfBounds(): NavigationLayerResponseModel[] {
    return [navigationLayers[5], navigationLayers[6]];
  }

  static getNavigationLayersForMap1(): NavigationLayerResponseModel[] {
    return [this.getNavigationLayer1(), this.getNavigationLayer2()];
  }

  static getNavigationLayer1(): NavigationLayerResponseModel {
    return navigationLayers[0];
  }

  static getNewNavigationLayer(): NavigationLayerCreateModel {
    return newNavigationLayer;
  }

  static getNavigationLayer2(): NavigationLayerResponseModel {
    return navigationLayers[1];
  }
  static getMap2NavigationLayer(): NavigationLayerResponseModel {
    return navigationLayers[2];
  }

  static getMap3WithNegativeNavigationLayerOffset(): NavigationLayerResponseModel {
    return navigationLayers[5];
  }

  static getMap2WithNavigationLayerTuggerTrain(): NavigationLayerResponseModel[] {
    return [navigationLayers[6], navigationLayers[1]];
  }

  static getNavigationLayerCreate(): NavigationLayerCreateModel {
    return {
      ...navigationLayers[5],
      imgFile: undefined,
      coordinateSystemOffset: undefined,
    };
  }

  static getNavigationLayerWithUndefined(): NavigationLayerResponseModel {
    return { ...navigationLayers[0], resolution: 0 };
  }

  static getNavigationLayerCardsWithDisplayOrderIndexOutOfBounds(): NavigationLayerCardModel[] {
    return [
      {
        ...navigationLayers[5],
        borderColor: MapNavigationLayerColors[0],
        isSelected: true,
        opacity: 1,
        showOffsetPanel: false,
        hasChanged: false,
      },
      {
        ...navigationLayers[6],
        borderColor: MapNavigationLayerColors[0],
        isSelected: false,
        opacity: 1,
        showOffsetPanel: false,
        hasChanged: false,
      },
    ];
  }

  static getNavigationLayerCards(): NavigationLayerCardModel[] {
    return [this.getNavigationLayer1Card(), this.getNavigationLayer2Card()];
  }

  static getNavigationLayerCardWithChanges(): NavigationLayerCardModel[] {
    return [
      {
        ...this.getNavigationLayer1Card(),
        coordinateSystemOffset: this.getCoordinateOffsetSystem(),
        applyCoordinateOffset: true,
        opacity: 100,
        showOffsetPanel: true,
        hasChanged: true,
      },
      {
        ...this.getNavigationLayer2Card(),
        coordinateSystemOffset: undefined,
        applyCoordinateOffset: false,
        opacity: 100,
        showOffsetPanel: true,
        hasChanged: false,
      },
    ];
  }

  static getNavigationLayerCards2(): NavigationLayerCardModel[] {
    return [
      {
        ...this.getNavigationLayer1Card(),
        coordinateSystemOffset: this.getCoordinateOffsetSystem(),
        applyCoordinateOffset: true,
        opacity: 100,
        showOffsetPanel: true,
        hasChanged: false,
      },
      {
        ...this.getNavigationLayer2Card(),
        coordinateSystemOffset: undefined,
        applyCoordinateOffset: false,
        opacity: 100,
        showOffsetPanel: true,
        hasChanged: false,
      },
    ];
  }

  static getNavigationLayer1Card(): NavigationLayerCardModel {
    return {
      ...this.getNavigationLayer1(),
      borderColor: MapNavigationLayerColors[0],
      isSelected: true,
      opacity: 1,
      showOffsetPanel: false,
      hasChanged: false,
    };
  }

  static getNavigationLayer2Card(): NavigationLayerCardModel {
    return {
      ...this.getNavigationLayer2(),
      borderColor: MapNavigationLayerColors[1],
      isSelected: true,
      opacity: 0,
      showOffsetPanel: false,
      hasChanged: false,
    };
  }

  static getCoordinateOffsetSystem(): CoordinateSystemOffset {
    return {
      translation: {
        x: 50,
        y: 60,
      },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 45,
    };
  }

  static getNavigationLayer3Card(): NavigationLayerCardModel {
    return {
      ...this.getNavigationLayer2(),
      id: '1e2d9184-3a2a-47be-aaeb-562bb993010a',
      name: 'Third Card',
      displayOrder: 2,
      borderColor: MapNavigationLayerColors[2],
      isSelected: true,
      opacity: 0,
      showOffsetPanel: false,
    };
  }

  static getCreateNavigationLayerModel(): NavigationLayerCreateModel {
    const layer1 = this.getNavigationLayer1();

    const createLayer: NavigationLayerCreateModel = {
      ...layer1,
      id: EMPTY_GUID,
      imgFile: undefined,
      coordinateSystemOffset: undefined,
      vehicleGroup: VehicleGroup.Str,
    };
    return { ...createLayer };
  }

  static getNavigationLayerViewModels(): NavigationLayerListViewItem[] {
    return navigationLayerViewModels;
  }

  static layerCreateModel(): LayersCreateModel {
    return {
      mapId: '123',
      id: '345',
      name: '',
      imgFile: undefined,
      imageOffset: { x: 0, y: 0, rotation: 34 },
      resolution: undefined,
      vehicleGroup: VehicleGroup.Unknown,
    };
  }
}
