import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FeatureToggle } from 'core/dtos';
import { AtsActions } from 'core/models';
import { AtsTranslationService, PermissionService } from 'core/services';
import { FunctionsModalInput } from '../functions-modal/functions-modal.component';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements OnChanges {
  @Input() translationKey = '';
  @Input() description = '';
  @Input() tooltipText = '';
  @Input() toggle: FeatureToggle = { isToggledOn: false, dateUpdated: null };
  @Input() requiredPermission: string = AtsActions.General_View;
  @Input() disabled = false;
  @Input() isLoaded = false;

  @Output() readonly saveToggle = new EventEmitter<boolean>();

  modalInputData: FunctionsModalInput | undefined = undefined;
  doesNotHaveRequiredPermission = false;
  isModalOpen = false;
  isToggledOn = false;
  constructor(
    private readonly translation: AtsTranslationService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnChanges({ requiredPermission }: TypedChanges<ToggleComponent>): void {
    if (requiredPermission?.currentValue) {
      this.doesNotHaveRequiredPermission = !this.permissionService.actionAllowed(
        requiredPermission?.currentValue
      );
    }
    this.isToggledOn = this.toggle.isToggledOn;
  }

  onToggle(checked: boolean): void {
    const action = checked ? 'enable' : 'disable';
    this.modalInputData = {
      title: this.translation.get(this.translationKey + '.title'),
      heading: this.translation.get(this.translationKey + '.' + action) + '?',
      description: this.translation.get(this.description),
      toggleOn: checked,
    };
    this.isModalOpen = true;
  }

  onConfirm(isConfirmed: boolean): void {
    if (this.isModalOpen && isConfirmed) {
      this.saveToggle.emit(this.modalInputData?.toggleOn);
    }
    if (this.isModalOpen && !isConfirmed) {
      this.isToggledOn = !this.modalInputData?.toggleOn;
    }
    this.isModalOpen = false;
  }
}
