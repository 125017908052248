<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-density zebra"
  [class.hide-column-names]="isHeadersHidden"
  [rowData]="userAccessList"
  rowSelection="single"
  [rowBuffer]="200"
  [sideBar]="false"
  [gridOptions]="gridOptions"
  [getRowId]="getRowIdForChangeDetection"
  [columnDefs]="translatedColumnDefs"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onRowSelected()"
  translationContext="shared.users.userDetailsDialog"
  data-cy="userRolesTable"></ag-grid-angular>
