import { MissionTraceAbortReason, OrderConfirmationOption } from 'core/dtos';
import { GuidString, MissionTrace } from 'core/models';
export enum ErrorHandlingOptions {
  Abort = 0,
  ContinueFromStep = 1,
  ReassignMission = 2,
}

type ErrorHandlingOptionId = 'abortOption' | 'continueFromStepOption' | 'reassignMissionOption';

export interface ErrorHandlingOption {
  id: ErrorHandlingOptionId;
  name: string;
  labelTextPath: string;
  value: ErrorHandlingOptions;
}

export interface MissionErrorHandlingModalViewModel {
  missionTraceId: GuidString;
  allMissionTracesInPc?: MissionTrace[];
  missionTrace: MissionTrace;
  selectedOption: ErrorHandlingOptions;
  confirmInterlock: boolean;
  confirmToCallOffSystem: OrderConfirmationOption;
  shouldConfirmToCallOffSystem: boolean;
  isProductiveMission: boolean;
  disableConfirm: boolean;
  hasInterlockMission: boolean;
  shouldSwitchVehicleIntoMaintenanceMode: boolean;
  errorHandlingOptions: ErrorHandlingOption[];
  reason?: MissionTraceAbortReason;
}
export interface MissionErrorHandlingModalResponseModel {
  selectedOption: ErrorHandlingOptions;
  confirmInterlock: boolean;
  confirmToCallOffSystem: OrderConfirmationOption;
  missionTraceId: GuidString;
  vehicleId?: GuidString;
  selectedStepNumber: number | undefined;
  shouldSwitchVehicleIntoMaintenanceMode: boolean;
  reason?: MissionTraceAbortReason;
}

export interface MissionErrorHandlingModalInputModel {
  missionTraceId: GuidString;
  allMissionTracesInPc?: MissionTrace[];
  selectedOption?: ErrorHandlingOptions;
  confirmInterlock: boolean;
  confirmToCallOffSystem: OrderConfirmationOption;
  hasInterLockMission: boolean;
}
