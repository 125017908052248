import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { MapDto } from 'core/dtos';
import { GuidString, MapMode } from 'core/models';

import * as MapsActions from '../actions';

export const featureKey = 'maps';

export interface MapsState extends EntityState<MapDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedMapId: GuidString;
  mapMode?: MapMode;
  isMissionMode: boolean;
  actionStatus: MapsActions.MapsTypes | null;
}

export const mapsAdapter: EntityAdapter<MapDto> = createEntityAdapter<MapDto>({
  sortComparer: (a: MapDto, b: MapDto) => a.name.localeCompare(b.name),
});

export const initialState: MapsState = mapsAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedMapId: '',
  mapMode: undefined,
  isMissionMode: false,
  actionStatus: null,
});

const mapsReducer = createReducer(
  initialState,

  on(MapsActions.loadMaps, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(MapsActions.loadMapsSuccess, (state, { maps }) =>
    mapsAdapter.setAll(
      maps.map(map => ({ ...map })),
      {
        ...state,
        loaded: true,
        loading: false,
      }
    )
  ),

  on(MapsActions.loadMapsFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
  })),

  on(MapsActions.addMapSuccess, (state, { map }) => mapsAdapter.addOne(map, state)),

  on(MapsActions.updateMapSuccess, (state, { map }) =>
    mapsAdapter.updateOne({ id: map.id.toString(), changes: map }, state)
  ),

  on(MapsActions.addBackgroundImageSuccess, (state, { mapId, backgroundTransparency }) =>
    mapsAdapter.updateOne({ id: mapId.toString(), changes: { backgroundTransparency } }, state)
  ),

  on(MapsActions.updateBackgroundImageSuccess, (state, { mapId, backgroundTransparency }) =>
    mapsAdapter.updateOne({ id: mapId.toString(), changes: { backgroundTransparency } }, state)
  ),

  on(MapsActions.updateBackgroundImageUrlSuccess, (state, { mapId, backgroundUrl }) =>
    mapsAdapter.updateOne(
      { id: mapId.toString(), changes: { mapBackgroundImageUrl: backgroundUrl } },
      state
    )
  ),

  on(MapsActions.signalRDeleteMap, MapsActions.deleteMapSuccess, (state, { map }) =>
    mapsAdapter.removeOne(map.id.toString(), {
      ...state,
      selectedMapId: state.selectedMapId === map.id ? '' : state.selectedMapId,
    })
  ),

  on(MapsActions.selectMap, (state, { mapId }) => ({
    ...state,
    selectedMapId: mapId,
  })),

  on(MapsActions.setMapMode, (state, { mode }) => ({
    ...state,
    mapMode: mode,
  })),

  on(MapsActions.setIsMissionMode, (state, { isMissionMode }) => ({
    ...state,
    isMissionMode: isMissionMode,
  })),

  on(MapsActions.addBackgroundImage, MapsActions.updateBackgroundImage, state => ({
    ...state,
    errorMessage: '',
  })),

  on(
    MapsActions.addBackgroundImageFailure,
    MapsActions.updateBackgroundImageFailure,
    MapsActions.deleteMapFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  )
);

export function reducer(state: MapsState | undefined, action: Action): MapsState {
  return mapsReducer(state, action);
}

export const { selectEntities, selectAll } = mapsAdapter.getSelectors();

export const getLoading = (state: MapsState): boolean => state.loading;
export const getLoaded = (state: MapsState): boolean => state.loaded;
export const getErrorMessage = (state: MapsState): string => state.errorMessage;
export const getSelectedMapId = (state: MapsState): GuidString => state.selectedMapId;
export const getMapMode = (state: MapsState): MapMode | undefined => state.mapMode;
export const getIsMissionMode = (state: MapsState): boolean => state.isMissionMode;
export const getMapsEntities = selectEntities;
export const getAllMaps = selectAll;
