<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  [accentBarTone]="contentModel.accentBarTone"
  #customDialog
  [isDismissable]="isDismissible"
  [ds-modal-trigger-for]="customDialog"
  [(isModalOpen)]="modalIsOpen"
  (modalDismiss)="onCancelButton()"
  *ngIf="contentModel$ | async as contentModel">
  <ds-box-header data-cy="dialogHeader">{{ contentModel.headerText }}</ds-box-header>

  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText1" *ngIf="contentModel.messageText1">
      <span> {{ contentModel.messageText1 }}</span>
    </div>
    <div class="modalMessageText2" *ngIf="contentModel.messageText2">
      <span [innerHTML]="contentModel.messageText2"></span>
    </div>
  </ds-box-content>

  <ds-box-footer data-cy="dialog-footer">
    <button
      *ngIf="contentModel.cancelText"
      ds-button
      data-cy="modalCancel"
      [variant]="'outline'"
      (click)="onCancelButton()">
      {{ contentModel.cancelText }}
    </button>
    <button
      *ngIf="contentModel.confirmText"
      [variant]="contentModel.buttonVariant"
      ds-button
      data-cy="modalConfirm"
      class="ms-2x"
      (click)="onConfirmButton()">
      {{ contentModel.confirmText }}
    </button>
  </ds-box-footer>
</ds-modal>
