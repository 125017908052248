import { BoxToneOptions } from '@bmw-ds/components/ds-interfaces/box.interface';

export class MenuButton {
  buttonLabel: string;
  emitterAction: number;
  visible: boolean;
  icon: string;
  tone: BoxToneOptions | undefined;

  constructor(
    buttonLabel: string,
    emitterAction: number,
    visible = true,
    icon = '',
    tone?: BoxToneOptions
  ) {
    this.buttonLabel = buttonLabel;
    this.emitterAction = emitterAction;
    this.visible = visible;
    this.icon = icon;
    this.tone = tone;
  }
}
