<label>{{ keyName }} </label>

<ds-tag
  *ngIf="showTag"
  class="ms-3x"
  [isDismissable]="false"
  [isInteractive]="false"
  data-cy="defaultTag">
  {{ 'settings.ipstAlertNowSettings.alertNowService.default' | translate }}
</ds-tag>
