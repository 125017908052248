/* eslint-disable max-lines */
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import {
  ColDef,
  ColumnApi,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
} from 'ag-grid-community';
import { HeaderValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { AtsTranslationService, ToolbarService } from 'core/services';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import * as AG_GRID_LOCALE_DE from '../../ag-grid-de.json';

@Directive()
export abstract class BaseAgGridTableDirective implements OnInit, OnDestroy {
  static readonly DEFAULT_PAGE_SIZE = 25;

  sizeColumsToFit = false;
  gridColumnApi!: ColumnApi;
  gridApi!: GridApi;
  translatedColumnDefs: ColDef[] = [];
  overlayNoRowsTemplate = '';
  overlayLoadingTemplate = 'Loading custom...';
  ngUnsubscribe = new Subject<void>();
  localeText = AG_GRID_LOCALE_DE;
  noRowsText = 'shared.treeTable.noContentMessage1';
  gridOptions: GridOptions = {
    suppressHorizontalScroll: false,
    localeText: (this.translationService.currentLang === 'de' && this.localeText) || undefined,
    context: { componentParent: this },
    rowSelection: 'single',
    stopEditingWhenCellsLoseFocus: true,
    overlayNoRowsTemplate: this.overlayNoRowsTemplate,
    enableBrowserTooltips: true,
    paginateChildRows: true,
    animateRows: !window.Cypress,
    suppressColumnVirtualisation: !!window.Cypress,
    suppressRowVirtualisation: !!window.Cypress,
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 50,
      flex: 1,
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              buttons: ['reset'],
            },
          },
          {
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['reset'],
            },
          },
        ],
      },
    },
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250,
          toolPanelParams: {
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
      ],
    },

    onModelUpdated: (event: ModelUpdatedEvent) => {
      event.api.getDisplayedRowCount() === 0
        ? event.api.showNoRowsOverlay()
        : event.api.hideOverlay();
    },
  };

  columns: ColDef[] = [];
  abstract getRowIdForChangeDetection: GetRowIdFunc;

  constructor(
    protected readonly translationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.translateHeader();

    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const filtersToolPanel = this.gridApi?.getToolPanelInstance('filters');
      this.gridApi?.refreshHeader();
      this.gridApi?.onFilterChanged();
      filtersToolPanel?.refresh();
      this.gridApi?.refreshCells({ force: true });
      this.setNoContentTemplate();
      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.gridApi?.destroy();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.sizeColumnsToFit();
    this.setNoContentTemplate();
    this.setupSearch();
  }

  translateHeader(): void {
    this.translatedColumnDefs = this.columns.map(c => {
      return c.headerName
        ? {
            ...c,
            headerValueGetter: this.localizeHeader.bind(this),
          }
        : c;
    });
  }

  localizeHeader(params: HeaderValueGetterParams): string {
    return this.translationService.get(params?.colDef.headerName || '');
  }

  setupSearch(): void {
    this.toolbarService.searchTerm$.pipe(distinctUntilChanged()).subscribe(searchTerm => {
      this.onSearchTermChanged(searchTerm);
    });
  }

  setNoContentTemplate(): void {
    this.overlayNoRowsTemplate = this.translationService.get(this.noRowsText);
  }

  onSearchTermChanged(query: string | undefined): void {
    query && this.gridApi.setQuickFilter(query);
  }

  resetPersistedFilterAndColumnState(): void {
    this.gridApi.setFilterModel(null);

    this.gridOptions.columnApi?.resetColumnState();
    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.sizeColumsToFit) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }
}
