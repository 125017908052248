import { GuidString } from '../core/string-typings';

export interface MissionForTicket {
  id: GuidString;
  name: string;
  nameWithStatusAndDate: string;
}

export interface AffectedDate {
  startDateUtc?: string;
  endDateUtc?: string;
}

export interface IssueDescriptionModel {
  isTransportIssue: boolean;
  isMonitoringIssue: boolean;
  isConfigurationIssue: boolean;
  isTrafficManagementIssue: boolean;
  isParkingChargingIssue: boolean;
  isMissionAssignmentIssue: boolean;
  isUiDelayIssue: boolean;
  zoneManagementIssue: boolean;
  other: boolean;
}

export interface IssueDescriptionModelStr {
  isWarrantyIssue: boolean;
  isHardwareIssue: boolean;
  isFunctionsIssue: boolean;
  isNavigationLocalizationIssue: boolean;
}

export enum TicketPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}
