export enum GraphNodeType {
  Waypoint = 0,
  StoppingPoint = 1,
  Fueling = 2,
  Parking = 3,
  Charging = 4,
  ContainerLane = 5,
  DisposeStation = 6,
  ContainerStation = 7,
  ContainerTowerStation = 8,
}

export enum GraphNodeShape {
  SmallRound = 0,
  MediumRound = 1,
  Rectangle = 2,
  RectangleFooter = 3,
}

export enum RouteConfigState {
  Selected,
  Unavailable,
  Available,
}

export enum NodeOccupancyStatus {
  Free = 0,
  Occupied = 1,
  Booked = 2,
}
