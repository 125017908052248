import { MapPixiHelper } from 'modules/maps/helpers';
import { Texture } from 'pixi.js';
import { VehicleLayerImages } from '../map-layer-images.constant';
import { IconStyle } from './vehicle-layer.constant';

export const VehicleTextures = {
  vehicleArrow: MapPixiHelper.createTexture({
    path: VehicleLayerImages.Vehicle_Graphic_Arrow,
    ...IconStyle,
  }),

  initializeIcon: MapPixiHelper.createTexture({
    path: VehicleLayerImages.Vehicle_Graphic_NotInitialized,
    ...IconStyle,
  }),

  disconnectIcon: MapPixiHelper.createTexture({
    path: VehicleLayerImages.Vehicle_Graphic_Disconnect,
    ...IconStyle,
  }),

  pauseIcon: MapPixiHelper.createTexture({
    path: VehicleLayerImages.Vehicle_Graphic_Paused,
    ...IconStyle,
  }),

  tuggerTrailer: Texture.from(VehicleLayerImages.Png_Vehicle_Graphic_Tugger_Trailer),
  tuggerTrailerUFrame: Texture.from(VehicleLayerImages.Png_Vehicle_Graphic_Tugger_Trailer_UFrame),
};
