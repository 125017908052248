import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ErrorAggregate } from 'core/dtos';

import * as ErrorActions from '../actions/errors.actions';

export const featureKey = 'errors';

export interface ErrorsState extends EntityState<ErrorAggregate> {
  oDataCount: number;
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
}

export const errorsAdapter: EntityAdapter<ErrorAggregate> = createEntityAdapter<ErrorAggregate>({
  selectId: (model: ErrorAggregate) => `${model.type}_${model.vehicleId}`,
});

export const initialState: ErrorsState = errorsAdapter.getInitialState({
  oDataCount: 0,
  loaded: false,
  loading: false,
  errorMessage: '',
});

const errorsReducer = createReducer(
  initialState,

  on(ErrorActions.loadErrors, ErrorActions.loadErrorsByVehicleId, state => ({
    ...state,
    oDataCount: 0,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(ErrorActions.loadErrorAggregateSuccess, (state, { errors, count }) =>
    errorsAdapter.setAll(errors, {
      ...state,
      oDataCount: count,
      loaded: true,
      loading: false,
    })
  ),

  on(ErrorActions.signalRUpsertError, (state, { error }) =>
    errorsAdapter.upsertOne(error, {
      ...state,
      oDataCount: state.oDataCount + 1,
    })
  ),

  on(ErrorActions.signalRRemoveError, (state, { error }) =>
    errorsAdapter.removeOne(`${error.type}_${error.vehicleId}`, {
      ...state,
      oDataCount: state.oDataCount - 1,
    })
  ),

  on(ErrorActions.loadErrorAggregateFailure, (state, { errorMessage }) => ({
    ...state,
    oDataCount: 0,
    loaded: false,
    loading: false,
    errorMessage,
  }))
);

export function reducer(state: ErrorsState | undefined, action: Action): ErrorsState {
  return errorsReducer(state, action);
}

export const { selectEntities, selectAll } = errorsAdapter.getSelectors();

export const getErrorsLoading = (state: ErrorsState): boolean => state.loading;
export const getErrorsLoaded = (state: ErrorsState): boolean => state.loaded;
export const getErrorsErrorMessage = (state: ErrorsState): string => state.errorMessage;
export const getErrorsEntities = selectEntities;
export const getErrors = selectAll;
