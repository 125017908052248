import { EMPTY_GUID } from 'core/constants/constant';
import {
  Coordinate,
  NavigationLayerCardModel,
  NavigationLayerResponseModel,
  NavigationLayerStorageModel,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { GuidString, VehicleGroup } from 'core/models';
import { first, max, min } from 'lodash';
import { GeometryConverter, sortBy } from 'shared/helpers';

interface MapContent {
  width: number;
  height: number;
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  centre: Coordinate;
}

export class MapContainerDetails {
  mapId: GuidString = EMPTY_GUID;
  layers!: NavigationLayerCardModel[];
  resolution!: number;
  totalWidth!: number;
  totalHeight!: number;

  content: MapContent = {
    width: 0,
    height: 0,
    imageWidth: 0,
    imageHeight: 0,
    centre: { x: 0, y: 0 },
  };

  offset: Coordinate = {
    x: 0,
    y: 0,
  };
  visibleLayers: NavigationLayerCardModel[];

  get isEmpty(): boolean {
    return !this.layers || this.layers.length === 0;
  }

  get unitLoadLayer(): NavigationLayerResponseModel | undefined {
    return this.layers.find(l => l.vehicleGroup === VehicleGroup.Str);
  }

  get defaultMapLayer(): NavigationLayerResponseModel | undefined {
    if (this.unitLoadLayer) {
      return this.unitLoadLayer;
    }

    return first(this.layers);
  }

  readonly defaultCard = {
    isSelected: true,
    opacity: 100,
    showOffsetPanel: true,
  };

  constructor(
    layers: NavigationLayerResponseModel[],
    cards: NavigationLayerStorageModel[],
    navigationLayersEnabled = false
  ) {
    this.layers = objectHelper.cloneDeep(sortBy(layers, p => p.displayOrder)).map(l => {
      const card = cards.find(c => c.id === l.id);
      l.isSelected = l.isSelected === undefined ? true : l.isSelected;

      return {
        ...l,
        ...card,
      };
    });

    this.visibleLayers = this.layers.filter(l => l.isSelected);
    if (!navigationLayersEnabled) {
      this.visibleLayers = this.layers.map(l => {
        return {
          ...l,
          isSelected: true,
        };
      });
      this.layers = this.visibleLayers;
    }

    this.mapId = this.layers && this.layers.length > 0 ? this.layers[0].mapId : EMPTY_GUID;
    this.resolution = min(this.layers.map(l => l.resolution)) ?? 0;

    this.offset.x = min(this.layers.map(l => l.imageOffset.x)) ?? 0;
    this.offset.y = min(this.layers.map(l => l.imageOffset.y)) ?? 0;

    this.totalWidth = this.calculateYSize();
    this.totalHeight = this.calculateXSize();
    this.content = this.getContentArea();
  }

  private calculateRotatedCorners(l: NavigationLayerCardModel): number[][] {
    const angleRad = GeometryConverter.degreesToRadians(l.imageOffset.rotation);
    const corners = [
      [l.imageOffset.x, l.imageOffset.y],
      [l.imageOffset.x + l.widthInPixels, l.imageOffset.y],
      [l.imageOffset.x, l.imageOffset.y + l.heightInPixels],
      [l.imageOffset.x + l.widthInPixels, l.imageOffset.y + l.heightInPixels],
    ];
    return corners.map(([x, y]) => [
      l.imageOffset.x +
        (x - l.imageOffset.x) * Math.cos(angleRad) -
        (y - l.imageOffset.y) * Math.sin(angleRad),
      l.imageOffset.y +
        (x - l.imageOffset.x) * Math.sin(angleRad) +
        (y - l.imageOffset.y) * Math.cos(angleRad),
    ]);
  }

  private calculateYSize(): number {
    const minY =
      min(this.visibleLayers.flatMap(l => this.calculateRotatedCorners(l).map(([_, y]) => y))) ?? 0;
    const maxY =
      max(this.visibleLayers.flatMap(l => this.calculateRotatedCorners(l).map(([_, y]) => y))) ?? 0;
    return Math.abs(maxY - minY);
  }

  private calculateXSize(): number {
    const minX =
      min(this.visibleLayers.flatMap(l => this.calculateRotatedCorners(l).map(([x, _]) => x))) ?? 0;
    const maxX =
      max(this.visibleLayers.flatMap(l => this.calculateRotatedCorners(l).map(([x, _]) => x))) ?? 0;
    return Math.abs(maxX - minX);
  }

  private getContentArea(): MapContent {
    const height = this.calculateYSize();
    const width = this.calculateXSize();

    const allCorners = this.visibleLayers.flatMap(l => this.calculateRotatedCorners(l));
    const xCoordinates = allCorners.map(([x, _]) => x);
    const yCoordinates = allCorners.map(([_, y]) => y);

    return {
      width,
      height,
      imageWidth: this.unitLoadLayer?.widthInPixels ?? 0,
      imageHeight: this.unitLoadLayer?.heightInPixels ?? 0,
      centre: {
        x: ((max(xCoordinates) ?? 0) + (min(xCoordinates) ?? 0)) / 2,
        y: ((max(yCoordinates) ?? 0) + (min(yCoordinates) ?? 0)) / 2,
      },
    };
  }
  updateLayers(cards: NavigationLayerCardModel[]): void {
    this.layers = objectHelper.cloneDeep(cards);
  }
}
