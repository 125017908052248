import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { BaseZoneSetDto, ZoneSetDto } from 'core/dtos';
import { getClosedZone } from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, Zone } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ZonesService extends TenantHttpClient {
  private readonly zoneServicePath = API_SERVICES.Zones;
  private readonly zoneSetServicePath = API_SERVICES.ZoneSet;

  protected apiUrl = environment.Services.MapManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getZonesByZoneSetId(zoneSetId: GuidString): Observable<Zone[]> {
    return this.get<Zone[]>(`${this.zoneServicePath}/zoneSet/${zoneSetId.toString()}`);
  }

  getZonesByMapId(mapId: GuidString): Observable<Zone[]> {
    return this.get<Zone[]>(`${this.zoneServicePath}/mapId/${mapId.toString()}`);
  }

  createZone(zone: Zone): Observable<Zone> {
    return this.post<Zone>(`${this.zoneServicePath}/`, this.addZoneClosePoint(zone));
  }

  createZoneFrom(zone: Zone, zoneSet: BaseZoneSetDto): Observable<ZoneSetDto> {
    return this.post<ZoneSetDto>(
      {
        template: '{path}/createFrom/{zoneSetId}/zone',
        path: this.zoneSetServicePath,
        zoneSetId: zoneSet.id,
      },
      {
        zoneDto: this.addZoneClosePoint(zone),
        createZoneSetRequest: {
          mapId: zoneSet.mapId,
          name: zoneSet.name,
          description: zoneSet.description,
        },
      }
    );
  }

  updateZone(zone: Zone): Observable<Zone> {
    return this.put<Zone>(
      {
        template: '{path}/{zoneId}',
        path: this.zoneServicePath,
        zoneId: zone.id,
      },
      this.addZoneClosePoint(zone)
    );
  }

  updateZoneFrom(zone: Zone, zoneSet: BaseZoneSetDto): Observable<ZoneSetDto> {
    return this.put<ZoneSetDto>(
      {
        template: '{path}/createFrom/{zoneSetId}/zone/{masterZoneId}',
        path: this.zoneSetServicePath,
        zoneSetId: zoneSet.id,
        masterZoneId: zone.masterZoneId,
      },
      {
        zoneDto: this.addZoneClosePoint(zone),
        createZoneSetRequest: {
          mapId: zoneSet.mapId,
          name: zoneSet.name,
          description: zoneSet.description,
        },
      }
    );
  }

  deleteZone(zone: Zone): Observable<Zone> {
    return this.delete(`${this.zoneServicePath}/${zone.id}`).pipe(map(() => zone));
  }

  deleteZoneFrom(zone: Zone, zoneSet: BaseZoneSetDto): Observable<ZoneSetDto> {
    return this.post<ZoneSetDto>(
      {
        template: '{path}/createFrom/{zoneSetId}/zone/{masterZoneId}/delete',
        path: this.zoneSetServicePath,
        zoneSetId: zoneSet.id,
        masterZoneId: zone.masterZoneId,
      },
      {
        mapId: zoneSet.mapId,
        name: zoneSet.name,
        description: zoneSet.description,
      }
    );
  }

  async deleteZonesByMapId(mapId: GuidString): Promise<void> {
    return firstValueFrom(this.delete<void>(`${this.zoneServicePath}/${mapId}`));
  }

  private addZoneClosePoint(zone: Zone): Zone {
    return getClosedZone(zone);
  }
}
