import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PoiDto } from 'core/dtos';
import { EntityList, GuidString } from 'core/models';

import * as fromPoisFeatureState from '../reducers';
import * as fromPois from '../reducers/pois.reducer';

const selectPoisState = createSelector(
  fromPoisFeatureState.getPoisFeatureState,
  fromPoisFeatureState.getPoisState
);

export const selectPoiLoading = createSelector(selectPoisState, fromPois.getLoading);
export const selectPoiLoaded = createSelector(selectPoisState, fromPois.getLoaded);
export const selectPoiErrorMessage = createSelector(selectPoisState, fromPois.getErrorMessage);
export const selectPoiId = createSelector(selectPoisState, fromPois.getSelectedPoiId);

export const selectActionStatus = createSelector(selectPoisState, fromPois.getActionStatus);

export const selectPoiEntities = createSelector(selectPoisState, fromPois.getEntities);

export const selectAllPois = createSelector(selectPoisState, fromPois.getAllPois);

export const selectPoisWithoutGroup = createSelector(selectAllPois, pois =>
  pois.filter(poi => !poi.poiGroupId)
);

export const selectPoisWithoutGroupByMapId = (
  mapId?: GuidString
): MemoizedSelector<object, PoiDto[]> =>
  createSelector(selectPoisWithoutGroup, (pois: PoiDto[]) =>
    pois.filter(poi => poi.mapId === mapId)
  );

export const selectPoisList = createSelector(selectAllPois, (pois): EntityList[] =>
  pois.map(({ id, name }) => ({ id, name }))
);
