<div *ngIf="showDeveloperOptions" class="alertNowUrl">
  <div>
    <div class="heading">
      <h5 class="urlHeader">
        {{ 'settings.ipstAlertNowSettings.alertNowService.urlConfiguration' | translate }}
      </h5>
    </div>

    <div class="icon">
      <ds-icon
        data-cy="infoIcon"
        icon="information"
        ds-tooltip="{{ 'settings.ipstAlertNowSettings.alertNowService.urlTooltip' | translate }}"
        size="sm"
        [dsTooltipConfig]="{ origin: 'right' }"></ds-icon>
    </div>
  </div>

  <app-ipst-alertnow-configuration-edit
    *ngIf="(isEditMode$ | async) === true; else viewTemplate"
    [formControl]="$any(mainForm.get('alertNowUrl'))">
  </app-ipst-alertnow-configuration-edit>

  <ng-template #viewTemplate>
    <app-ipst-alertnow-configuration-view
      [ipstAlertNowSetting]="workingAreaSetting"></app-ipst-alertnow-configuration-view>
  </ng-template>
</div>

<ds-segmented-control [(activeItemId)]="activeId">
  <ds-segmented-control-item id="recipientKeysTab">{{
    'settings.ipstAlertNowSettings.alertNowService.tabs.recipientKey' | translate
  }}</ds-segmented-control-item>
  <ds-segmented-control-item id="zoneAlertNowGroupsTab" data-cy="zoneAlertNowGroupsTab"
    >{{ 'settings.ipstAlertNowSettings.alertNowService.tabs.zoneAlertNowGroup' | translate }}
  </ds-segmented-control-item>
</ds-segmented-control>

<ng-container *ngIf="activeId === 'recipientKeysTab'">
  <div class="button-container pt-4x" *ngIf="isEditMode$ | async">
    <button ds-button (click)="onAddRecipientKey()" data-cy="addRecipientKey">
      {{ 'settings.ipstAlertNowSettings.alertNowService.addRecipientKey' | translate }}
    </button>
  </div>

  <div style="height: 100%">
    <div class="d-flex justify-content-end">
      <button
        ds-button
        class="ms-4x"
        [variant]="'ghost'"
        [icon]="resetIcon"
        (click)="resetPersistedFilterAndColumnState()">
        {{ 'shared.tableColumnActions.reset' | translate }}
      </button>
    </div>
    <div class="grid-container">
      <ag-grid-angular
        data-cy="alertNowServiceTable"
        class="ag-theme-density zebra"
        rowSelection="single"
        [appPersistTableFilterAndColumnState]="{
          persistKey: 'recipientKeys-list',
          gridOptions,
          rowData: undefined
        }"
        [gridOptions]="gridOptions"
        [rowData]="recipientKeys"
        [columnDefs]="translatedColumnDefs"
        [animateRows]="true"
        [getRowId]="getRowIdForChangeDetection"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeId === 'zoneAlertNowGroupsTab'">
  <div *ngIf="!hasRecipientKeys; else zoneGroupsTemplate" class="noData">
    <div class="content-message">
      {{ 'settings.ipstAlertNowSettings.alertNowService.noKeysFound' | translate }}
    </div>

    <button
      ds-button
      data-cy="AddRecipientKeyButton"
      class="mt-10x"
      [icon]="'add'"
      (click)="onAddRecipientKey()">
      {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
    </button>
  </div>

  <ng-template #zoneGroupsTemplate>
    <div *ngIf="showAddGroups && (isEditMode$ | async) === false; else cardTemplate" class="noData">
      <div class="content-message">
        {{ 'settings.ipstAlertNowSettings.alertNowService.noGroupsConfigured' | translate }}
      </div>
      <button
        ds-button
        data-cy="zoneAlertNowGroupButton"
        class="mt-10x"
        [icon]="'add'"
        (click)="onAddZoneAlertNowGroup()">
        {{ 'settings.ipstAlertNowSettings.alertNowService.zoneAlertNowGroup' | translate }}
      </button>
    </div>

    <ng-template #cardTemplate>
      <div class="groupColumns">
        <div class="groupColumn">
          <div class="verticalColumn">
            <app-ipst-alertnow-group-edit
              *ngIf="isEditMode$ | async; else viewGroupTemplate"></app-ipst-alertnow-group-edit>
            <ng-template #viewGroupTemplate>
              <app-ipst-alertnow-group-view></app-ipst-alertnow-group-view>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
