<ds-menu #dsmenu appendTo="body">
  <ng-container *ngFor="let item of items">
    <ds-list-item
      *ngIf="item.visible !== false"
      class="menu-button-critical"
      [ngClass]="item.tone ? 'menu-button-list-item-' + item.tone : ''"
      [icon]="item.icon"
      (click)="item.command()"
      >{{ item.label }}</ds-list-item
    >
  </ng-container>
</ds-menu>
<button
  #btn
  ds-button
  [ds-menu-trigger-for]="dsmenu"
  *ngIf="items.length"
  [variant]="variant"
  cy-data="menu-button"
  [icon]="icon"
  [ngClass]="tone">
  {{ label }}
</button>
