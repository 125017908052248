import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  AvoidConflictsAtPoisDto,
  BackwardDrivingByPathDto,
  TrafficManagementSettings,
  TrafficManagementSettingsDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class TrafficSettingsService extends TenantHttpClient {
  protected apiUrl = environment.Services.TrafficManager;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getTrafficManagementSettings(): Observable<TrafficManagementSettings> {
    return this.get<TrafficManagementSettings>(`${this.servicePath}`);
  }

  updateTrafficSettings(value: boolean): Observable<TrafficManagementSettingsDto> {
    return this.put<TrafficManagementSettingsDto>(`${this.servicePath}/TrafficManagement`, {
      isToggledOn: value,
    });
  }

  updateBackwardDrivingByPath(value: boolean): Observable<BackwardDrivingByPathDto> {
    return this.put<BackwardDrivingByPathDto>(`${this.servicePath}/PathBasedBackwardDriving`, {
      isToggledOn: value,
    });
  }

  updateAvoidConflictAtPois(value: boolean): Observable<AvoidConflictsAtPoisDto> {
    return this.put<AvoidConflictsAtPoisDto>(`${this.servicePath}/AvoidConflictsAtPois`, {
      isToggledOn: value,
    });
  }
}
