import { CM_PER_METER } from 'core/constants';
import { Coordinate, VehicleDto } from 'core/dtos';
import { Graphics, LINE_CAP } from 'pixi.js';
import { LayerGraphicHelper } from '../../helpers';
import { ContainerEx } from '../../pixi';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { ForkliftStyle } from '../vehicle-layer.constant';
import { VehicleMapItem } from './vehicle.graphic';

export interface ForkDimensions {
  shadow: Coordinate[];
  forks: Coordinate[][];
}

export abstract class ForkliftMapItem extends VehicleMapItem {
  protected dimensions!: ForkliftDimensions;
  private forkDimensions: ForkDimensions | undefined;

  protected get forkLength(): number {
    return this.vehicle.forkLength * CM_PER_METER;
  }

  // #region Create
  protected setup(_vehicle: VehicleDto, _itemContainer: ContainerEx): void {
    this.forkDimensions = this.calculateForkDimensions(this.dimensions);

    if (this.forkDimensions) this.container.addChildAtBottom(this.createForks(this.forkDimensions));
  }

  protected createVehicleBackground(): Graphics {
    return VehicleGraphicHelper.createVehicleBase(this.dimensions);
  }

  protected getDefaultVehicleColor(): number {
    return ForkliftStyle.Color;
  }

  // #endregion

  // #region Forks & Load
  protected getForkDimensions(dimensions: ForkliftDimensions): ForkliftDimensions {
    if (this.vehicle.forkLength > 0) {
      dimensions.fork.length = this.forkLength;
    }

    return dimensions;
  }

  private calculateForkDimensions(dimensions: ForkliftDimensions): ForkDimensions | undefined {
    if (!dimensions.fork) return undefined;

    const length = dimensions.fork.length;
    const width = dimensions.fork?.width;
    const forkMiddle = width / 2;

    return {
      shadow: [
        { x: 0, y: -forkMiddle },
        { x: -length, y: width },
      ],
      forks: [
        [
          { x: 0, y: forkMiddle },
          { x: -length, y: forkMiddle },
        ],
        [
          { x: 0, y: -forkMiddle },
          { x: -length, y: -forkMiddle },
        ],
      ],
    };
  }

  protected createForks(forkDimensions: ForkDimensions): Graphics {
    const shadow = forkDimensions.shadow;

    const graphic = new Graphics()
      .beginFill(ForkliftStyle.ForkShadowColor, ForkliftStyle.ForkOpacity)
      .drawRect(shadow[0].x, shadow[0].y, shadow[1].x, shadow[1].y)
      .endFill();

    forkDimensions?.forks.forEach(f => this.addForkLine(graphic, f[0], f[1]));

    LayerGraphicHelper.positionToXOffset(graphic, this.dimensions, this.baseBackground.pivot.x);

    return graphic;
  }

  private addForkLine(graphic: Graphics, from: Coordinate, to: Coordinate): Graphics {
    return graphic
      .lineTextureStyle({
        cap: LINE_CAP.ROUND,
        color: ForkliftStyle.ForkColor,
        width: ForkliftStyle.ForkWidth,
      })
      .moveTo(from.x, from.y)
      .lineTo(to.x, to.y);
  }

  protected createLoad(): Graphics {
    const dimensions = this.dimensions;
    const loaded = VehicleGraphicHelper.createLoaded(dimensions);

    if (dimensions.fork) {
      LayerGraphicHelper.positionToXOffset(
        loaded,
        dimensions,
        -(dimensions.fork.length / 2 + dimensions.fork.loadOffset)
      ); // Move to the left of the rear
    }

    return loaded;
  }
  // #endregion

  // #region Position & Rotation

  protected calculateLabelOffset(
    orientation: number,
    xPositionOffset: number,
    textHeight: number
  ): number {
    return VehicleGraphicHelper.calculateLabelOffset(
      this.dimensions,
      orientation,
      xPositionOffset,
      textHeight
    );
  }
  // #endregion
}
