import {
  EventTraceDto,
  FailureCommentDto,
  MissionFailureReason,
  PackedEventTrace,
  PackedMissionFailureReason,
  PackedStepTrace,
  PackedTourStepTrace,
  ReducedMissionFailureReason,
  StepTraceDto,
} from 'core/dtos';
import {
  DateString,
  GuidString,
  LoadType,
  MissionAssignmentConditions,
  MissionDeliveryStatus,
  MissionDisplayHighlightLevel,
  MissionFormat,
  MissionPriorityLevel,
  MissionStatus,
  MissionTrace,
  MissionTraceTrigger,
  MissionTraceTriggerDisplayFilter,
  StepTraceModel,
  StepType,
  StepTypeBackend,
  VehicleType,
} from 'core/models';
import { TourStepDto } from './tour-step.dto';

export interface MissionTraceUiDto {
  id: GuidString;
  missionName: string;
  vehicleName: string;
  status: MissionStatus;
  missionId?: GuidString;
  vehicleId?: GuidString;
  source?: string;
  destination?: string;
  materialNumber?: string;
  createdDateTime: DateString;
  updatedDateTime: DateString;
  trigger: MissionTraceTrigger;
  deliveryStatus: MissionDeliveryStatus;
  currentStepAttributeText: string;
  currentStepSequenceNumber: number;
  canBeContinuedFromStep: boolean;
  canBeReAssigned: boolean;
  canAbort: boolean;
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel;
  errorHandlingAllowed: boolean;
  canRetry: boolean;
  assignmentConditions: MissionAssignmentConditions[];
  stepCount: number;
  isAssignedToVehicle: boolean;
  isAlarmForLateDeliveryActive: boolean;
  currentStepType?: StepTypeBackend;
  missionFormat: MissionFormat;
}

export interface MissionTraceDto extends MissionTraceUiDto {
  id: GuidString;
  missionId: GuidString;
  vehicleId: GuidString;
  vehicleName: string;
  vehicleType: VehicleType;
  missionName: string;
  status: MissionStatus;
  stepTraces: StepTraceDto[];
  missionStepNo: string;
  missionStep?: string;
  createdDateTime: DateString;
  updatedDateTime: DateString;
  trigger: MissionTraceTrigger;
  materialNumber: string;
  processChainName: string;
  processChainId: GuidString;
  processChainTraceId: GuidString;
  lateDeliveryAlarmTime: number;
  isAlarmForLateDeliveryActive: boolean;
  maxExecutionTime: number;
  isMaxExecutionTimeActive: boolean;
  provisioningTime?: DateString;
  forecastedEndTime?: DateString;
  mapId?: GuidString;
  fleetId?: GuidString;
  fleetName?: string;
  source?: string;
  destination?: string;
  eventTraces: EventTraceDto[];
  interlockDetails: InterlockDetail[];
  assignableWaypointId: GuidString | null;
  assignableWaypointName?: string;
  deliveryStatus: MissionDeliveryStatus;
  canAbort: boolean;
  canRetry: boolean;
  errorHandlingAllowed: boolean;
  tourSteps?: TourStepDto[];
  priorityLevel: MissionPriorityLevel;
  assignmentDelayEndDateTime: DateString;
  failureComment: FailureCommentDto;
  retries?: MissionTraceDto[];
  startStepSequenceNumber?: number;
  loadType: LoadType;
  tourChainId: GuidString | null;
  tourChainName: string | null;
}

export interface InterlockDetail {
  eventId: GuidString;
  raisingMissionId: GuidString;
  stepSequenceNumber: number;
  stepType: StepTypeBackend;
  stepAttribute: string;
  raisingMissionName: string;
  frontendStepSequenceNumber: number;
}

export type PackedInterlockDetails = [
  GuidString, // EventId
  GuidString | null, // RaisingMissionId
  number, // StepSequenceNumber
  number, // FrontendStepSequenceNumber
  StepTypeBackend, // StepType
  string, // StepAttribute
  string // RaisingMissionName
];

export interface FailureMissionTrace {
  missionFailureReasonId: GuidString | null | undefined;
  missionTraceId: GuidString | undefined;
  missionFailureShiftGroupId: GuidString | null | undefined;
}

export interface MissionListItem extends MissionFailureReason, MissionTraceUiDto {
  id: GuidString;
  timestamp: DateString;
  status: MissionStatus;
  createdDateTime: DateString;
  updatedDateTime: DateString;
  trigger: MissionTraceTrigger;
  currentStepSequenceNumber: number;
  provisioningTime: DateString | null;
  fleetName?: string;
  lateDeliveryAlarmTime: number;
  deliveryStatus: MissionDeliveryStatus;
  canBeContinuedFromStep: boolean;
  canBeReAssigned: boolean;
  canAbort: boolean;
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel;
  errorHandlingAllowed: boolean;
  canRetry: boolean;
  stepCount: number;
  forecastedEndTime: DateString | null;
  missionName: string;
  vehicleName: string;
  vehicleId: GuidString;
  assignmentDelayEndDateTime: DateString | null;
  missionFormat: MissionFormat;
  missionFailureReasonId: GuidString | null;
  missionFailureReasonComments: string | null;
  missionFailureMinutesToSolve: number | null;
  missionFailureMinutesForEmergencyProcess?: number | null;
  priorityLevel: MissionPriorityLevel;
  assignableWaypointName: string | undefined;
  assignableWaypointId: GuidString | null;
  mapId: GuidString;
  missionId: GuidString;
  processChainTraceId: GuidString;
  completedDateTime: DateString | null;
  missionFailureReason: ReducedMissionFailureReason;
  processChainName: string;
  processChainId: GuidString;
  materialNumber: string;
  statusEnum: MissionStatus;
  vehicleType: VehicleType;
  isVehiclePaused: boolean | null;
  triggerDisplayFilter: MissionTraceTriggerDisplayFilter;
  stepNr: string;
  step?: StepType;
  toolTip: string | null;
  hasManualSupport: boolean;
  tourSteps?: TourStepDto[];
  assignmentDelayEndTime: DateString | null;
  fleetId?: GuidString;
  currentStepAttributeText: string;
  source?: string;
  destination?: string;
  missionStepNo: string;
  missionStep?: string;
  stepTraces: StepTraceModel[];
  isAlarmForLateDeliveryActive: boolean;
  maxExecutionTime: number;
  isMaxExecutionTimeActive: boolean;
  stepType: StepType;
  assignmentConditions: MissionAssignmentConditions[];
  isAssignedToVehicle: boolean;
  eventTraces: EventTraceDto[];
  interlockDetails: InterlockDetail[];
  currentStepTypeFrontEnd: StepType;
  stepDetail: string | undefined;
  currentStepType: StepTypeBackend;
  failureComment: FailureCommentDto;
  loadType: LoadType;
  tourChainId: GuidString | null;
  tourChainName: string | null;
}

export interface MissionListSignalRDto {
  // MessagePack object
  id: GuidString;
  timestamp: string;
  mapId?: GuidString;
  wa: GuidString;
  mId: GuidString;
  mission: string;
  failure: PackedMissionFailureReason;
  pcName: string;
  pctId: GuidString;
  pcId: GuidString;
  mat: string;
  src?: string;
  dst?: string;
  status: MissionStatus;
  mStatus: MissionStatus;
  fId?: GuidString;
  vhc: string;
  vId: GuidString;
  vhcType: VehicleType;
  vhcPause?: boolean;
  trigger: MissionTraceTrigger;
  curStepNr: number;
  curStpType?: StepTypeBackend;
  stpCnt: number;
  created: Date;
  updated: Date;
  loadType?: LoadType;
  provisioning?: Date;
  forecasted?: Date;
  complete?: Date;
  deliverTime?: number;
  deliverStatus: MissionDeliveryStatus;
  lateDelAlarmTime?: number;
  isAlarmLateDel: boolean;
  hasSupport: boolean;
  wayPtId?: GuidString;
  wayPt?: string;
  abort: boolean;
  retry: boolean;
  errorHandle: boolean;
  isMaxExecTimeActive: boolean;
  reassign: boolean;
  continue: boolean;
  isVhcAssign: boolean;
  maxExec?: number;
  highlight: MissionDisplayHighlightLevel;
  fmt: MissionFormat;
  tours?: PackedTourStepTrace[];
  priority?: MissionPriorityLevel;
  delayEnd?: Date;
  stepText?: string;
  aCond: MissionAssignmentConditions[];
  interlocks: PackedInterlockDetails[];
  events: PackedEventTrace[];
  steps: PackedStepTrace[];
  tourChainId: GuidString | null;
  tourChainName: string | null;
}

export interface MissionListSignalr extends MissionTrace {
  id: GuidString;
  timestamp?: string;
}

export function getMissionFailureReason(missionTrace: MissionTrace): ReducedMissionFailureReason {
  return {
    missionFailureReasonId: missionTrace.failureComment.missionFailureReasonId,
    missionFailureReasonComments: missionTrace.failureComment.missionFailureReasonComments,
    missionFailureMinutesToSolve: missionTrace.failureComment.missionFailureMinutesToSolve,
    missionFailureMinutesForEmergencyProcess:
      missionTrace.failureComment.missionFailureMinutesForEmergencyProcess,
    durationOfMissionInFailed: missionTrace.failureComment.durationOfMissionInFailed,
    missionFailureStartDateTime: missionTrace.failureComment.missionFailureStartDateTime ?? null,
    missionFailureEndDateTime: missionTrace.failureComment.missionFailureEndDateTime ?? null,
    missionFailureLocationId: missionTrace.failureComment.missionFailureLocationId,
    missionFailureShiftGroupId: missionTrace.failureComment.missionFailureShiftGroupId,
    vehicleName: missionTrace.vehicleName,
    missionName: missionTrace.missionName,
  };
}

export function isMissionFinalStatus(mission: MissionListSignalr): boolean {
  return [
    MissionStatus.Aborted,
    MissionStatus.Completed,
    MissionStatus.CompletedWithSupport,
  ].includes(mission.status);
}
