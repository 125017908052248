import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { IpstWorkingAreaSetting } from 'core/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IpstGroupsWorkareaEditModel } from './ipst-groups-workarea-edit.model';

@Component({
  selector: 'app-ipst-groups-workarea-edit',
  templateUrl: './ipst-groups-workarea-edit.component.html',
  styleUrls: ['./ipst-groups-workarea-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstGroupsWorkareaEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstGroupsWorkareaEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstGroupsWorkareaEditComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  value: IpstWorkingAreaSetting = {
    recipientKeys: [],
    akz: '',
    enabled: false,
    defaultRecipientKey: '',
    workingArea: '',
    workingAreaId: '',
    errorLanguage: 0,
    alertNowUrl: '',
    recipientList: [],
  };

  form: FormGroup;
  recipientKeysFormArray: FormArray = this.formBuilder.array([]);
  ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {
    this.form = this.createEditForm();
  }

  get canDeleteRecipientKey(): boolean {
    return this.recipientKeysFormArray.controls.length > 1;
  }

  ngOnInit(): void {
    this.onEditFormChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  modelToFormValues(): IpstGroupsWorkareaEditModel {
    return {
      enabled: this.value.enabled,
      akz: this.value.akz,
      recipientKeys: this.value.recipientKeys.map(k => {
        return { enabled: k === this.value?.defaultRecipientKey, value: k };
      }),
    };
  }

  addRecipientKey(): void {
    this.recipientKeysFormArray.push(
      this.formBuilder.group({
        enabled: [false, [Validators.required]],
        value: ['', [Validators.required]],
      })
    );
  }

  deleteRecipientKey(index: number): void {
    if (!this.canDeleteRecipientKey) {
      return;
    }

    const controlGroup = this.recipientKeysFormArray.at(index);

    this.recipientKeysFormArray.removeAt(index);

    if (controlGroup.value.enabled) {
      this.setDefaultRecipientKey(0);
    }
  }

  setDefaultRecipientKey(index: number): void {
    const val = this.recipientKeysFormArray.value;

    for (let i = 0; i < val.length; i++) {
      val[i].enabled = index === i;
    }

    this.recipientKeysFormArray.patchValue(val, { emitEvent: false });
  }

  onTouched = (_value: IpstWorkingAreaSetting): void => {};

  registerOnChange(updateCallBack: (changes: IpstWorkingAreaSetting) => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fv => {
      if (this.value) {
        this.value.akz = fv.akz;
        this.value.enabled = fv.enabled;
        this.value.recipientKeys = fv.recipientKeys.map((k: { value: string }) => {
          return k.value;
        });
        this.value.defaultRecipientKey = fv.recipientKeys.filter(
          (k: { enabled: boolean; value: string }) => k.enabled
        )[0]?.value;
      }

      updateCallBack(this.value);
    });
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.form.controls).forEach(key =>
      Object.assign(errors, this.form.get(key)?.errors)
    );

    return this.form.valid ? null : errors;
  }

  writeValue(value: IpstWorkingAreaSetting): void {
    if (!value) return;

    this.value = value;

    this.value.recipientKeys = this.value.recipientKeys ?? [];

    this.value.recipientKeys.forEach((r: string) =>
      this.recipientKeysFormArray.push(
        this.formBuilder.group({
          enabled: [false],
          value: [r],
        })
      )
    );

    this.form.patchValue(this.modelToFormValues());
  }

  private createEditForm(): FormGroup {
    return this.formBuilder.group({
      enabled: [false, [Validators.required]],
      akz: ['', []],
      recipientKeys: this.recipientKeysFormArray,
    });
  }

  private onEditFormChanges() {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fv => {
      if (fv.enabled && !this.form.controls.akz.hasValidator(Validators.required)) {
        this.form.controls.akz.addValidators([Validators.required]);
        this.form.controls.akz.updateValueAndValidity({ emitEvent: true });
      } else if (!fv.enabled && this.form.controls.akz.hasValidator(Validators.required)) {
        this.form.controls.akz.clearValidators();
        this.form.controls.akz.updateValueAndValidity({ emitEvent: true });
      }
    });
  }
}
