import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AtsActions, ErrorForwardingModel, VehicleType } from 'core/models';
import { AtsTranslationService, PermissionService, ToolbarService } from 'core/services';
import { Icons } from 'library/constants';
import {
  BaseAgGridTableDirective,
  GridValueFormatters,
  GridValueGetter,
  SwitchNotificationCellComponent,
} from 'shared/components';

@Component({
  selector: 'app-error-forwarding-list',
  templateUrl: './error-forwarding-list.component.html',
  styleUrls: ['./error-forwarding-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorForwardingListComponent
  extends BaseAgGridTableDirective
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableErrorForwarding: ErrorForwardingModel[] = [];
  @Input() searchTerm?: string;

  @Output() readonly saveErrorForwarding = new EventEmitter<ErrorForwardingModel>();
  @Output() readonly vehicleTypesUsedInErrorForwarding = new EventEmitter<number[]>();
  @Output() readonly filterVehicleTypesFlag = new EventEmitter<boolean>();

  resetIcon = Icons.Reset;
  showVehicleFilter = false;
  filterVehicleTypes = true;
  vehicleTypeFilter: string[] = [];
  vehicleTypeInErrorNotificationList: number[] = [];

  readonly columns: ColDef[] = [
    {
      field: 'type',
      tooltipField: 'type',
      headerName: 'settings.errorForwarding.list.type',
    },
    {
      field: 'level',
      tooltipField: 'level',
      headerName: 'settings.errorForwarding.list.level',
      valueFormatter: GridValueFormatters.capitalizeFormatter,
    },
    {
      field: 'errorSource',
      tooltipField: 'error source',
      headerName: 'settings.errorForwarding.list.source',
      width: 60,
      wrapText: true,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      valueGetter: GridValueGetter.getTranslatedValueGetterFn(
        this.translationService,
        'settings.errorForwarding.ipstErrorSources.'
      ),
    },
    {
      field: 'description',
      tooltipField: 'description',
      headerName: 'settings.errorForwarding.list.description',
      wrapText: true,
      autoHeight: true,
      flex: 4,
    },
    {
      field: 'sendNotification',
      headerName: 'settings.errorForwarding.list.sendNotification',
      filter: 'agSetColumnFilter',
      filterParams: {
        valueGetter: GridValueFormatters.booleanFormatter(this.translationService, 'switchBoolean'),
      },
      cellRenderer: SwitchNotificationCellComponent,
    },
  ];

  canEdit?: boolean;
  vehicleTypeMapping: Record<VehicleType, string> = {
    [VehicleType.UnitLoad]: '',
    [VehicleType.TuggerTrain]: '',
    [VehicleType.Forklift]: '',
    [VehicleType.U_AGV]: '',
  };

  constructor(
    protected readonly translationService: AtsTranslationService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    private readonly permissionService: PermissionService
  ) {
    super(translationService, toolbarService, cdRef);
    this.canEdit = this.permissionService.actionAllowed(AtsActions.ToggleSendingErrors);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges({
    searchTerm,
    tableErrorForwarding,
  }: TypedChanges<ErrorForwardingListComponent>): void {
    if (searchTerm?.currentValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
      this.onSearchTermChanged(this.searchTerm);
    }
    if (!tableErrorForwarding?.isFirstChange() && tableErrorForwarding?.currentValue) {
      this.filterQuickFilterByVehicleType(tableErrorForwarding?.currentValue);
    }
  }

  filterQuickFilterByVehicleType(errorForwardingModel: ErrorForwardingModel[]): void {
    const errorSources = errorForwardingModel.map(x => x.errorSource);

    const vehicleTypes = new Set<number>();
    for (const error of errorSources) {
      switch (error) {
        case 'STR':
          vehicleTypes.add(VehicleType.UnitLoad);
          break;
        case 'Forklift': // Legacy data
        case 'Forklift4am': // 2023.5 onwards
        case 'Forklift15': // Was only in demo shortly
        case 'Forklift20': // Was only in demo shortly
          vehicleTypes.add(VehicleType.Forklift);
          break;
        case 'TuggerTrain':
        case 'TuggerDS':
        case 'TuggerSchiller':
          vehicleTypes.add(VehicleType.TuggerTrain);
          break;
        default:
          break;
      }
    }

    for (const vehicleType of vehicleTypes) {
      this.vehicleTypeInErrorNotificationList.push(vehicleType);
    }

    this.showVehicleFilter = this.vehicleTypeInErrorNotificationList.length > 1 ? true : false;
    this.cdRef.markForCheck();
    this.vehicleTypesUsedInErrorForwarding.emit(this.vehicleTypeInErrorNotificationList);
    this.filterVehicleTypesFlag.emit(this.filterVehicleTypes);
  }

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
    this.gridApi.setIsExternalFilterPresent(this.isExternalFilterPresent.bind(this));
    this.gridApi.setDoesExternalFilterPass(this.doesExternalFilterPass.bind(this));
  }

  getRowIdForChangeDetection: GetRowIdFunc<ErrorForwardingModel> = (
    params: GetRowIdParams<ErrorForwardingModel>
  ) => params.data.compositeKeyId;

  onChangeErrorNotification(changedErrorForwarding: ErrorForwardingModel): void {
    this.saveErrorForwarding.emit(changedErrorForwarding);
    const rowIndex = this.gridApi.getRowNode(changedErrorForwarding.compositeKeyId);
    if (rowIndex) {
      rowIndex.setDataValue('sendNotification', changedErrorForwarding.sendNotification);
    }
    this.cdRef.markForCheck();
    if (this.gridApi) this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    return this.vehicleTypeFilter?.length > 0;
  }

  doesExternalFilterPass(node: RowNode<ErrorForwardingModel>): boolean {
    if (node.data) {
      return this.vehicleTypeFilter?.some(filter => node.data?.errorSource.startsWith(filter));
    } else {
      return true;
    }
  }

  selectedVehicleTypesChanged(vehicleTypeFilters: VehicleType[]): void {
    const vehicleTypeMappingEN = {
      [VehicleType.UnitLoad]: this.translationService.get(
        'settings.errorForwarding.ipstErrorSources.STR'
      ),
      [VehicleType.TuggerTrain]: 'Tugger',
      [VehicleType.Forklift]: this.translationService.get(
        'settings.errorForwarding.ipstErrorSources.Forklift'
      ),
      [VehicleType.U_AGV]: '',
    };

    const vehicleTypeMappingDE = {
      [VehicleType.UnitLoad]: this.translationService.get(
        'settings.errorForwarding.ipstErrorSources.STR'
      ),
      [VehicleType.TuggerTrain]: 'Tugger',
      [VehicleType.Forklift]: 'Forklift',
      [VehicleType.U_AGV]: '',
    };

    this.translationService.currentLang === 'de'
      ? (this.vehicleTypeMapping = vehicleTypeMappingDE)
      : (this.vehicleTypeMapping = vehicleTypeMappingEN);

    this.vehicleTypeFilter = vehicleTypeFilters.map(
      vehicleType => this.vehicleTypeMapping[vehicleType]
    );
    if (this.gridApi) this.gridApi.onFilterChanged();

    if (vehicleTypeFilters.length === 0) {
      this.resetPersistedFilterAndColumnState();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
