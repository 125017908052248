import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpandCollapseButtonsService {
  private readonly showExpandCollapsAllButton = new BehaviorSubject<boolean>(false);
  private readonly clickExpandAll = new Subject<boolean>();
  private readonly clickCollapseAll = new Subject<boolean>();
  private readonly disableExpandAllButton = new BehaviorSubject<boolean>(true);
  private readonly disableCollapseAllButton = new Subject<boolean>();

  setShowExpandCollapseAllButtons(show: boolean): void {
    this.showExpandCollapsAllButton.next(show);
  }

  getShowExpandCollapseAllButtons(): Observable<boolean> {
    return this.showExpandCollapsAllButton.asObservable();
  }

  setClickExpandAll(): void {
    this.clickExpandAll.next(true);
  }

  getClickExpandAll(): Observable<{}> {
    return this.clickExpandAll.asObservable();
  }

  setClickCollapseAll(): void {
    this.clickCollapseAll.next(true);
  }

  getClickCollapseAll(): Observable<{}> {
    return this.clickCollapseAll.asObservable();
  }

  setDisableCollapseAllButton(disable: boolean): void {
    this.disableCollapseAllButton.next(disable);
  }

  getDisableCollapseAllButton(): Observable<boolean> {
    return this.disableCollapseAllButton.asObservable();
  }

  setDisableExpandAllButton(disable: boolean): void {
    this.disableExpandAllButton.next(disable);
  }

  getDisableExpandAllButton(): Observable<boolean> {
    return this.disableExpandAllButton.asObservable();
  }
}
