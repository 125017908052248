/* eslint-disable @typescript-eslint/prefer-for-of */
import { ErrorAggregate, MapDto } from 'core/dtos';
import { getTrafficLightStatus } from 'core/helpers';
import {
  MissionTrace,
  ReducedVehicle,
  TreeTableVehicle,
  VehicleErrorType,
  VehicleType,
} from 'core/models';
import { getMissionTrace } from 'store-modules/vehicles-store/selectors/vehicles.selectors.helpers';

export function convertToFilteredTreeTableVehicles(
  allActiveReducedVehicles: ReducedVehicle[],
  allErrors: ErrorAggregate[],
  allMaps: MapDto[],
  missionTraces: MissionTrace[]
): TreeTableVehicle[] {
  const treeTableVehicles: TreeTableVehicle[] = allActiveReducedVehicles.map(v =>
    convertToTreeTableVehicle(v, allErrors, allMaps, missionTraces)
  );

  const unitLoadWithFleet: TreeTableVehicle[] = treeTableVehicles.filter(
    v => v.vehicleType === VehicleType.UnitLoad && v.fleetId
  );
  const unitLoadWithoutFleet = treeTableVehicles.filter(
    v => v.vehicleType === VehicleType.UnitLoad && !v.fleetId
  );

  const tuggerTrains: TreeTableVehicle[] = treeTableVehicles.filter(
    v => v.vehicleType === VehicleType.TuggerTrain
  );
  const forkLifts: TreeTableVehicle[] = treeTableVehicles.filter(
    v => v.vehicleType === VehicleType.Forklift
  );
  const uagv: TreeTableVehicle[] = treeTableVehicles.filter(
    v => v.vehicleType === VehicleType.U_AGV
  );

  return unitLoadWithFleet
    .concat(wrapInFakeFleet(unitLoadWithoutFleet, 'unitLoadNoFleetAssigned'))
    .concat(wrapInFakeFleet(tuggerTrains, 'tuggerTrainNoFleetAssigned'))
    .concat(wrapInFakeFleet(forkLifts, 'forkliftNoFleetAssigned'))
    .concat(wrapInFakeFleet(uagv, 'uagvNoFleetAssigned'));
}

function convertToTreeTableVehicle(
  vehicle: ReducedVehicle,
  allErrors: ErrorAggregate[],
  allMaps: MapDto[],
  missionTraces: MissionTrace[]
): TreeTableVehicle {
  const vehicleMissionTrace = getMissionTrace(vehicle.id, missionTraces);
  const vehicleErrors = allErrors.filter(error => error.vehicleId === vehicle.id);
  const fleetAssignmentError = vehicleErrors.some(
    error => error.type === VehicleErrorType.AtsJob10
  );

  return {
    id: vehicle.id,
    name: vehicle.name,
    availability: vehicle.availability,
    batteryLevel: vehicle.batteryLevel,
    batteryLevelStatus: vehicle.batteryLevelStatus,
    mapId: vehicle.mapId,
    mapName: allMaps.find(m => m.id === vehicle.mapId)?.name,
    maintenanceModeEnabled: vehicle.maintenanceModeEnabled,
    mode: vehicle.mode,
    status: vehicle.status,
    fleetId: vehicle.fleetId,
    fleetName: vehicle.fleetName ?? 'NA',
    fleetAssignmentError: fleetAssignmentError,
    hardwareVersion: vehicle.hardwareVersion,
    isConnected: vehicle.isConnected,
    isSwitchedOff: vehicle.isSwitchedOff,
    vehicleType: vehicle.vehicleType,
    ...vehicleMissionTrace,
    isCharging: vehicle.isCharging,
    isPaused: vehicle.isPaused,
    isLoaded: vehicle.isLoaded,
    brakeTestRequired: vehicle.brakeTestRequired,
    trafficLightStatus: getTrafficLightStatus(vehicle),
    zoneSetId: vehicle.zoneSetId,
    isActiveZoneSet: vehicle.isActiveZoneSet,
    softwareVersion: vehicle.softwareVersion,
    softwareDownloadPercentage: vehicle.softwareDownloadPercentage,
    softwareUpdateStatus: vehicle.softwareUpdateStatus,
    softwareVersionChangedDateUtc: vehicle.softwareVersionChangedDateUtc,
    supportedLoadTypes: vehicle.supportedLoadTypes,
    metrics: vehicle.metrics,
  };
}

export function wrapInFakeFleet(
  vehicles: TreeTableVehicle[],
  fleetTranslationKey: string
): TreeTableVehicle[] {
  return vehicles.map(v => {
    return { ...v, fleetName: 'shared.treeTable.' + fleetTranslationKey };
  });
}
