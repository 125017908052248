import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RouteTab, Tab, isBasicTabs, isRouteTabs } from 'library/models';
import { TabTemplateContext } from 'library/models/template-context.models';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-tabs-content-noscroll-layout',
  templateUrl: './tabs-content-noscroll-layout.component.html',
  styleUrls: ['./tabs-content-noscroll-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsContentNoscrollLayoutComponent implements OnInit, OnChanges {
  @Input() isLoading = false;
  @Input() pageHeading = '';
  @Input() contentHeading = '';
  @Input() hasToolbar = true;
  @Input() useContentStyling = true;
  @Input() tabs: RouteTab[] | Tab[] = [];
  @Input() selectedKey: string | undefined;
  @Input() navigateOnSelect = true;
  @Input() tabTemplate!: TemplateRef<TabTemplateContext>;
  @Input() contentTemplate!: TemplateRef<TabTemplateContext>;

  @Output() readonly selectedTabIdChanged = new EventEmitter<string>();

  @ViewChild('routeTabTemplate', { static: true }) templateRouteTab!: TemplateRef<HTMLElement>;
  @ViewChild('listTabTemplate', { static: true }) templateListTab!: TemplateRef<HTMLElement>;
  @ViewChild('loadingTemplate', { static: true }) templateLoading!: TemplateRef<HTMLElement>;

  routeTabs: RouteTab[] = [];
  basicTabs: Tab[] = [];

  tabContent: TemplateRef<HTMLElement> = this.templateRouteTab;
  showContent = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setTabs(this.tabs);
    this.setShowContent();
    this.setTemplate();
  }

  ngOnChanges(changes: TypedChanges<TabsContentNoscrollLayoutComponent>): void {
    if (changes.tabs) {
      this.setTabs(changes.tabs.currentValue);
    }
    this.setShowContent();
    this.setTemplate();
  }

  setTabs(tabs: RouteTab[] | Tab[]): void {
    if (isRouteTabs(tabs)) {
      this.routeTabs = tabs;
    } else if (isBasicTabs(tabs)) {
      this.basicTabs = tabs;
    } else {
      this.basicTabs = [];
      this.routeTabs = [];
    }
  }

  setTemplate(): void {
    if (this.isLoading) {
      this.tabContent = this.templateLoading;
    }

    if (this.navigateOnSelect) {
      this.tabContent = this.templateRouteTab;
    } else {
      this.tabContent = this.templateListTab;
    }
  }

  setShowContent(): void {
    this.showContent = !this.isLoading && !isEmpty(this.basicTabs);
  }

  onSelectedTabIdChanged($event: string): void {
    this.selectedTabIdChanged.emit($event);
    this.onActiveTabChanged();
  }

  onActiveTabChanged(): void {
    this.cdRef.markForCheck();
  }
}
