import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

type VehicleAlertNowForm = {
  name: FormControl<string>;
  recipientKey: FormControl<string | null>;
  vehicles: FormControl<string | null>;
};

type ZoneGroupForm = {
  recipientList: FormArray<FormGroup<string>>;
  vehicleAlertNowGroup: FormArray<FormGroup<VehicleAlertNowForm>>;
};

@Component({
  selector: 'app-ipst-alertnow-group-edit',
  templateUrl: './ipst-alertnow-group-edit.component.html',
  styleUrls: ['./ipst-alertnow-group-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGroupEditComponent {
  zoneGroupFormGroup: FormGroup<ZoneGroupForm>;
  showVehicleAlertNowGroup = false;

  constructor() {
    this.zoneGroupFormGroup = this.createFormGroup();
  }

  get vehicleAlertNowFormFormArray(): FormArray<FormGroup<VehicleAlertNowForm>> {
    const { vehicleAlertNowGroup } = this.zoneGroupFormGroup.controls;
    return vehicleAlertNowGroup;
  }

  createFormGroup(): FormGroup<ZoneGroupForm> {
    return new FormGroup<ZoneGroupForm>({
      recipientList: new FormArray<FormGroup<string>>([], { validators: Validators.required }),
      vehicleAlertNowGroup: new FormArray<FormGroup<VehicleAlertNowForm>>(
        this.createVehicleAlertNowFormGroups(),
        { validators: Validators.required }
      ),
    });
  }

  createVehicleAlertNowFormGroups(): FormGroup<VehicleAlertNowForm>[] {
    return [this.createVehicleAlertNowForm()];
  }

  createVehicleAlertNowForm(): FormGroup<VehicleAlertNowForm> {
    return new FormGroup<VehicleAlertNowForm>({
      name: new FormControl('', {
        nonNullable: true,
      }),
      recipientKey: new FormControl('', {
        nonNullable: true,
      }),
      vehicles: new FormControl('', {
        nonNullable: false,
      }),
    });
  }

  addVehicleGroupConfig(): void {
    const newVehicleAlertNowForm = this.createVehicleAlertNowForm();
    this.vehicleAlertNowFormFormArray.push(newVehicleAlertNowForm);
  }

  onAddVehicleAlertNowGroup(): void {
    this.showVehicleAlertNowGroup = true;
  }

  onRemoveVehicleAlertNowGroup(): void {
    this.showVehicleAlertNowGroup = false;
  }

  onSave(): void {}

  onChangeRecipientKey(): void {}

  onChangeVehicles(): void {}

  onChangeZone(): void {}
}
