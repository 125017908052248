import { DateString, GuidString, VehicleGroup } from 'core/models';

export interface BaseVehicleMapDataDto {
  navigationLayerId: GuidString;
  name: string;
}

export interface VehicleMapDataDto extends BaseVehicleMapDataDto {
  id: GuidString;
  vehicleId: GuidString;
  fileName: string;
  blobUrl: string;
  createdOnUtc: DateString;
}

export interface DsMapUploadDataRequest extends BaseVehicleMapDataDto {
  mapDataFile: File | undefined;
  mapVersion: string;
}

export interface DsMapOptionDto extends BaseVehicleMapDataDto {
  id: GuidString;
}

export interface VehicleMapDataListItemModel {
  id: GuidString;
  name: string;
  fileName: string;
  blobUrl: string;
  mapName: string | undefined;
  mapId: GuidString | undefined;
  navigationLayerName: string | undefined;
  vehicleId: GuidString;
  vehicleName: string;
  createdOnUtc: DateString;
  hasDeletedData: boolean;
  vehicleGroup: VehicleGroup | undefined;
  navigationLayerId: GuidString;
}

export interface VehicleMapDataRequest {
  vehicleId: GuidString;
  vehicleMapData: BaseVehicleMapDataDto;
}

export interface VehicleMapDataDownloadRequest {
  mapId?: GuidString;
  navigationLayerId: GuidString;
  vehicleMapDataId: GuidString;
  vehicleId?: GuidString;
  vehicleName?: string;
  vehicleIds?: GuidString[];
}

export interface VehicleMapDataDownloadResponse {
  failedVehicleIds: GuidString[];
  successfullySent: number;
}

export interface VehicleMapResponseTemplateModel {
  icon: string;
  tone: 'critical' | 'caution' | 'positive';
  countMessage: string;
  responseMessageKey: string;
  state: VehicleMapDataDialogState;
}
export interface PreviewDeleteVehicleMapDataDto {
  vehicles: number;
}

export enum VehicleMapDataDialogState {
  UploadVehicleMapData,
  UploadSuccess,
  Failed,
  PartialSuccess,
  DownloadSuccess,
}
