import { EMPTY_GUID } from 'core/constants';
import { BaseZoneSetDto, ZoneSetDto } from 'core/dtos';
import { POIMatrixCalculationStatus, ZoneSetStatus, ZoneSetsListItem } from 'core/models';

export const newZoneSetId = '5c6c248e-5c30-11ec-bf63-0242ac130002';
export const defaultTime = '1970-01-01T00:00:00.000Z';

const map1Id = 'bb2b55bc-dd94-4262-a5af-966431d57b31';
const map2Id = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';
const map3Id = 'bb29b6b4-e3a9-4c57-8fe1-a89037c11f25';
const zoneSetMapId = '7e42a595-074e-4a55-8831-57dd626bea7f';
const zoneSetMapId2 = 'd5e31c23-6c9f-4457-8c76-f0c6b49ad6ce';
const utc = '2019-11-25T08:23:17.5517523Z';

const zoneSets: ZoneSetDto[] = [
  {
    id: 'f78a1d99-8c13-47a6-a5b2-a9609e1f5f97',
    name: 'Dummy Zone Set Name',
    description: 'Dummy Zone Set Description',
    mapId: map1Id,
    status: ZoneSetStatus.Active,
    poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
    createdOnUtc: utc,
    updatedOnUtc: utc,
    activatedOnUtc: utc,
    archivedOnUtc: '1970-01-01T00:00:00.000Z',
    masterZoneSetId: '0c2eae86-712d-4178-997d-d57c5bbb0558',
    zones: [],
  },
  {
    id: '0740f486-5c43-11ec-bf63-0242ac130002',
    name: 'Zone set',
    mapId: map2Id,
    description: 'Second Zone set',
    zones: [],
    createdOnUtc: new Date(0).toISOString(),
    updatedOnUtc: new Date(0).toISOString(),
    status: ZoneSetStatus.Draft,
    poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
    activatedOnUtc: new Date(0).toISOString(),
    archivedOnUtc: new Date(0).toISOString(),
    masterZoneSetId: '9c29d8aa-7558-45cf-9062-91ad773021ce',
  },
];

const zoneSetActive = {
  id: 'e9704898-002e-4209-aff5-827c202bf3bb',
  name: '27.10.21 17:56',
  mapId: map1Id,
  zones: [],
  status: ZoneSetStatus.Active,
  poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
  createdOnUtc: defaultTime,
  updatedOnUtc: defaultTime,
  activatedOnUtc: defaultTime,
  description: 'zone set description',
  masterZoneSetId: 'e9704898-002e-4209-aff5-827c202bf3bb',
  archivedOnUtc: null,
};

const addZoneSets: BaseZoneSetDto[] = [
  {
    id: EMPTY_GUID,
    name: 'Dummy Zone Set Name',
    description: 'Dummy Zone Set Description',
    mapId: zoneSetMapId,
  },
  {
    id: EMPTY_GUID,
    name: 'zone set from template',
    description: 'an template set',
    mapId: zoneSetMapId,
  },
];

const zoneSetsListItemsActive: ZoneSetsListItem = {
  id: map3Id,
  name: 'Test Active ZoneSet',
  description: 'Test Active ZoneSet Description',
  timeOfCreation: utc,
  timeOfActivation: utc,
  timeOfLastChange: utc,
  status: ZoneSetStatus.Active,
  poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
  mapId: zoneSetMapId2,
  lastTimeOfArchiving: '',
};

const zoneSetsListItemsDraft: ZoneSetsListItem = {
  id: map3Id,
  name: 'Test Draft ZoneSet',
  description: 'Test Draft ZoneSet Description',
  timeOfCreation: utc,
  timeOfActivation: utc,
  timeOfLastChange: utc,
  status: ZoneSetStatus.Draft,
  poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
  mapId: zoneSetMapId2,
  lastTimeOfArchiving: '',
};

const zoneSetsListItemsArchive: ZoneSetsListItem = {
  id: map3Id,
  name: 'Test Archive ZoneSet',
  description: 'Test Archive ZoneSet Description',
  timeOfCreation: utc,
  timeOfActivation: utc,
  timeOfLastChange: utc,
  status: ZoneSetStatus.Archive,
  poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
  mapId: zoneSetMapId2,
  lastTimeOfArchiving: '',
};
export class ZoneSetMockData {
  static getZoneSets(): ZoneSetDto[] {
    return [{ ...zoneSets[0] }, { ...zoneSets[1] }];
  }

  static getActiveZoneSet(): ZoneSetDto {
    return { ...zoneSetActive };
  }

  static getZoneSetForMap1(): ZoneSetDto {
    return { ...zoneSets[0] };
  }

  static getZoneSetForMap2(): ZoneSetDto {
    return { ...zoneSets[1] };
  }

  static getDraftZoneSet(): ZoneSetDto {
    return { ...zoneSetActive, status: ZoneSetStatus.Draft };
  }

  static getArchivedZoneSet(): ZoneSetDto {
    return { ...zoneSetActive, status: ZoneSetStatus.Archive };
  }

  static getEmptyZoneSetRequest(): BaseZoneSetDto {
    return { ...addZoneSets[0] };
  }

  static getEmptyZoneSetResponse(): ZoneSetDto {
    return {
      id: '',
      name: '',
      description: '',
      mapId: '',
      status: ZoneSetStatus.Draft,
      poiMatrixCalculationStatus: POIMatrixCalculationStatus.Latest,
      masterZoneSetId: '',
      zones: [],
      createdOnUtc: defaultTime,
      updatedOnUtc: defaultTime,
      activatedOnUtc: defaultTime,
      archivedOnUtc: null,
    };
  }

  static getZoneSetFromTemplateRequest(): BaseZoneSetDto {
    return { ...addZoneSets[1] };
  }

  static getZoneSetsListItems(): ZoneSetsListItem {
    return zoneSetsListItemsActive;
  }

  static getZoneSetsListItemsActive(): ZoneSetsListItem {
    return zoneSetsListItemsActive;
  }

  static getZoneSetsListItemsDraft(): ZoneSetsListItem {
    return zoneSetsListItemsDraft;
  }

  static getZoneSetsListItemsArchive(): ZoneSetsListItem {
    return zoneSetsListItemsArchive;
  }
}
