import { createAction, props } from '@ngrx/store';
import { LoadTypeConfigurationDto } from 'core/models';

export enum LoadTypeSettingsTypes {
  LoadJobLoadTypes = '[Settings] Load Job Load Types',
  LoadJobLoadTypesSuccess = '[Settings] Load Job Load Types Success',
  LoadJobLoadTypesFailure = '[Settings] Load Job Load Types Fail',

  UpdateLoadType = '[Dev Settings] Update Load Type',
  UpdateLoadTypeSuccess = '[Dev Settings] Update Load Type Success',
  UpdateLoadTypeFailure = '[Dev Settings]  Update Load Type Failure',
}

export const loadJobLoadTypes = createAction(LoadTypeSettingsTypes.LoadJobLoadTypes);

export const loadJobLoadTypesSuccess = createAction(
  LoadTypeSettingsTypes.LoadJobLoadTypesSuccess,
  props<{ configurations: LoadTypeConfigurationDto[] }>()
);

export const loadJobLoadTypesFailure = createAction(
  LoadTypeSettingsTypes.LoadJobLoadTypesFailure,
  props<{ errorMessage: string }>()
);

export const updateLoadType = createAction(
  LoadTypeSettingsTypes.UpdateLoadType,
  props<{ configuration: LoadTypeConfigurationDto }>()
);

export const updateLoadTypeSuccess = createAction(
  LoadTypeSettingsTypes.UpdateLoadTypeSuccess,
  props<{ configuration: LoadTypeConfigurationDto; notify: boolean }>()
);

export const updateLoadTypeFailure = createAction(
  LoadTypeSettingsTypes.UpdateLoadTypeFailure,
  props<{ errorMessage: string }>()
);
