/* eslint-disable @typescript-eslint/no-magic-numbers */

import { ConflictAreaColors, VehicleColors } from 'library/styles';
import { IconOptions } from 'modules/maps/helpers';
import { filters } from 'pixi.js';

export const IconStyle: IconOptions = {
  resourceOptions: { scale: 5 },
  scale: 2.7,
};

// #region Vehicle Types

export const VehicleStyle = {
  Corner: 7,
  BorderSize: 2,
  InitializeIconOffset: 5,
  PauseIconOffset: 40,

  TagWidth: 50,
  TagLine: 7,
  TagScale: 0.8,
};

export const ForkliftStyle = {
  Color: VehicleColors.ForkliftBody,
  ForkColor: VehicleColors.ForkColor,
  ForkShadowColor: VehicleColors.ForkShadowColor,
  ForkOpacity: 0.5,
  ForkWidth: 5,
};

export const TuggerTrailerStyle = {
  DrawBarColor: VehicleColors.DrawBar,
  DrawBarWidth: 10,
  Corner: 10,
};

// #endregion

// #region Vehicle State
export const VehicleLoadedStyle = {
  Color: VehicleColors.Loaded,
  Corner: 15,
  Border: 8,
};

export const VehicleErrorStyle = {
  Color: VehicleColors.Error,
  Opacity: 0.2,
  Size: 150,
};

export const VehicleName = {
  Opacity: 0.7,
  Padding: 4,
  Scale: 0.7,
  Radius: 3,
};

export const VehicleSelection = {
  Opacity: 0.15,
  Border: 3,
  Size: 180,
  Color: VehicleColors.Default,
};

export const VehiclePathStyle = {
  ArrowPoints: 3,
  ArrowSize: 0.15,
  SelectedArrowSize: 0.25,
  Size: 0.05,
  SelectedSize: 0.08,
  Opacity: 1,
};

export const ConflictAreaStyle = {
  OpacityFilter: new filters.AlphaFilter(0.3),
  AwarenessColor: ConflictAreaColors.Awareness,
  DeadLockColor: ConflictAreaColors.DeadLock,
  IntersectionZoneColor: ConflictAreaColors.IntersectionZone,
  ConflictAreaColor: ConflictAreaColors.ConflictArea,
};

export const VehicleDisconnect = {
  PulseDelay: 60,
};
// #endregion
