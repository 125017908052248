import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SelectListOption } from '@bmw-ds/components';
import { EMPTY_GUID } from 'core/constants';
import { VehicleDto } from 'core/dtos';
import { GuidString, HelpToolFilterDto, Zone } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { ErrorForwardingOptionListModel } from '../ipst-alert-now-help-tool/ipst-alert-now-help-tool.component';

@Component({
  selector: 'app-ipst-alert-now-help-tool-filters',
  templateUrl: './ipst-alert-now-help-tool-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowHelpToolFiltersComponent implements OnChanges {
  @Input() reset = false;

  @Input() vehicles: VehicleDto[] = [];
  @Input() zones: (Zone | undefined)[] = [];
  @Input() errors: ErrorForwardingOptionListModel[] = [];
  @Output() readonly retrieveRecipientKeys = new EventEmitter<HelpToolFilterDto>();

  selectedErrorMessage = '';
  selectedVehicle: GuidString = EMPTY_GUID;
  selectedZone: GuidString = EMPTY_GUID;
  isEnglish = false;
  actionDisabled = true;
  disabledTooltip = 'test';

  constructor(protected readonly atsTranslateService: AtsTranslationService) {
    this.disabledTooltip = this.atsTranslateService.get(
      'settings.ipstAlertNowSettings.helpTool.disabledErrorNotificationTooltipMessage'
    );
  }

  ngOnChanges({ reset }: TypedChanges<IpstAlertNowHelpToolFiltersComponent>): void {
    if (reset?.currentValue !== reset?.previousValue) {
      this.selectedErrorMessage = '';
      this.selectedVehicle = EMPTY_GUID;
      this.selectedZone = EMPTY_GUID;
    }
  }

  handleSelection(_event: SelectListOption | SelectListOption[] | null): void {
    if (
      this.selectedErrorMessage &&
      this.selectedVehicle !== EMPTY_GUID &&
      this.selectedZone !== EMPTY_GUID
    ) {
      this.retrieveRecipientKeys.emit({
        zoneId: this.selectedZone,
        vehicleId: this.selectedVehicle,
        errorNotificationType: this.selectedErrorMessage,
      });
    }
  }
}
