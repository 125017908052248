<form
  ds-form
  class="form-container no-border-top"
  data-cy="zoneGroupForm"
  (ngSubmit)="onSave()"
  [formGroup]="zoneGroupFormGroup">
  <ds-box class="no-border-top">
    <ds-box-content divider="full" class="no-border-top">
      <div class="row">
        <div class="col">
          <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.recipientKeys' | translate }}</h4>
        </div>
      </div>
      <div>
        <ds-form-field>
          <label ds-label for="recipientList">
            {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
          </label>
          <ds-select
            required
            ds-form-validation
            formControlName="recipientList"
            id="recipientList"
            data-cy="recipientListDropdown"
            [labellingConfig]="{
              placeholder: 'settings.ipstAlertNowSettings.alertNowService.selectKey' | translate
            }"
            (optionSelected)="onChangeRecipientKey()"></ds-select>
        </ds-form-field>
      </div>

      <hr class="lineSep" />

      <div class="row">
        <div class="col" style="display: flex">
          <h4>{{ 'settings.ipstAlertNowSettings.alertNowService.alertNowZone' | translate }}</h4>
        </div>
      </div>
      <div>
        <ds-form-field>
          <label ds-label for="recipientList">
            {{ 'settings.ipstAlertNowSettings.alertNowService.zone' | translate }}
          </label>
          <ds-select
            ds-form-validation
            required
            formControlName="recipientList"
            id="recipientList"
            data-cy="recipientListDropdown"
            [labellingConfig]="{
              placeholder: 'settings.ipstAlertNowSettings.alertNowService.zoneSelect' | translate
            }"
            (optionSelected)="onChangeZone()"></ds-select>
        </ds-form-field>
      </div>

      <hr class="lineSep my-4x" />

      <div class="row">
        <div class="col" style="display: flex">
          <h4>
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
          </h4>
        </div>
      </div>

      <div *ngIf="showVehicleAlertNowGroup">
        <h5>
          {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupNum' | translate }}
        </h5>
        <div class="row">
          <ds-form-field class="form-field-spacing col-sm-6">
            <label ds-label for="name">
              {{
                'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroupName' | translate
              }}
            </label>
            <input
              ds-input
              ds-form-validation
              id="name"
              formControlName="name"
              type="text"
              placeholder="{{ 'Enter name' | translate }}" />
          </ds-form-field>

          <ds-form-field class="key-container col-sm-6">
            <label ds-label for="recipientKey">
              {{ 'settings.ipstAlertNowSettings.alertNowService.recipientKey' | translate }}
            </label>
            <ds-select
              formControlName="recipientKey"
              id="recipientKey"
              required
              ds-form-validation
              [labellingConfig]="{
                placeholder:
                  'settings.ipstAlertNowSettings.alertNowService.selectRecipientKey' | translate
              }"
              (optionSelected)="onChangeRecipientKey()"></ds-select>
          </ds-form-field>

          <ds-form-field class="vehicle-container col-sm-12">
            <label ds-label for="vehicles">
              {{ 'settings.ipstAlertNowSettings.alertNowService.vehicles' | translate }}
            </label>
            <ds-select
              ds-form-validation
              required
              formControlName="vehicles"
              id="vehicles"
              data-cy="recipientListDropdown"
              [labellingConfig]="{
                placeholder:
                  'settings.ipstAlertNowSettings.alertNowService.selectVehicles' | translate
              }"
              (optionSelected)="onChangeVehicles()"></ds-select>
          </ds-form-field>
        </div>
      </div>

      <div class="vehicle-group-container">
        <div style="display: flex; justify-content: space-between">
          <button
            ds-button
            [variant]="'ghost'"
            [icon]="'add'"
            (click)="onAddVehicleAlertNowGroup()">
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
          </button>
          <button
            *ngIf="showVehicleAlertNowGroup"
            ds-button
            [variant]="'ghost-muted'"
            [icon]="'minus_circle'"
            (click)="onRemoveVehicleAlertNowGroup()">
            {{ 'settings.ipstAlertNowSettings.alertNowService.vehicleAlertNowGroup' | translate }}
          </button>
        </div>
        <div *ngIf="!showVehicleAlertNowGroup" class="max-hint">
          <ds-hint>
            {{ 'settings.ipstAlertNowSettings.alertNowService.hintMessage' | translate }}
          </ds-hint>
        </div>
      </div>
    </ds-box-content>
  </ds-box>
</form>
