/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  CellClickedEvent,
  ColDef,
  EditableCallbackParams,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  NewValueParams,
  RowNode,
} from 'ag-grid-community';
import { EMPTY_GUID } from 'core/constants';
import { WorkAreaSettingRecipientKeyDto } from 'core/dtos';
import {
  AlertNowTabDto,
  ExtendedDsMenuItem,
  GuidString,
  IpstWorkingAreaSetting,
} from 'core/models';
import { AtsTranslationService, EditBarService, ToastService, ToolbarService } from 'core/services';
import { Icons } from 'library/constants';
import { isEqual } from 'lodash';
import { Observable, takeUntil } from 'rxjs';
import { AgGridActionDirective, RecipientKeyCellComponent } from 'shared/components';

import objectHelper from 'core/helpers/object.helper';

import { environment } from '@environment';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-ipst-alertnow-settings-service',
  templateUrl: './ipst-alertnow-settings-service.component.html',
  styleUrls: ['./ipst-alertnow-settings-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertnowSettingsServiceComponent
  extends AgGridActionDirective<WorkAreaSettingRecipientKeyDto>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() workingAreaSetting: IpstWorkingAreaSetting | undefined;

  @Output() readonly save = new EventEmitter<AlertNowTabDto>();

  isEditMode$: Observable<boolean>;

  mainForm: UntypedFormGroup;
  selectedWorkingAreaId: GuidString = EMPTY_GUID;
  selectedRowId: GuidString | undefined = undefined;
  showDeveloperOptions = environment.config.showDeveloperOptions;
  canDeleteRecipientKey = true;
  hasRecipientKeys = false;
  hasZoneAlertNowGroups = false;
  showAddGroups = true;

  resetIcon = Icons.Reset;
  originalAlertNowUrl = '';
  recipientKeysTabName = 'recipientKeysTab';
  activeId = this.recipientKeysTabName;

  originalKeys: WorkAreaSettingRecipientKeyDto[] = [];
  recipientKeys: WorkAreaSettingRecipientKeyDto[] = [];
  editMode = false;
  columns: ColDef[] = [
    {
      field: 'recipientKey',
      tooltipField: 'type',
      headerName: 'Name',
      filter: true,
      sortable: true,
      sort: 'asc',
      cellRenderer: RecipientKeyCellComponent,
      editable: (params: EditableCallbackParams<WorkAreaSettingRecipientKeyDto>): boolean => {
        return params.context.componentParent.editMode;
      },
      onCellValueChanged: function (event: NewValueParams<WorkAreaSettingRecipientKeyDto>): void {
        event.context.componentParent.cdRef.markForCheck();

        if (event.data.recipientKey) {
          event.context.componentParent.editBarService.setIsFormValid(true);
          event.context.componentParent.editBarService.setHasChanges(true);
        } else {
          event.context.componentParent.editBarService.setIsFormValid(false);
          event.context.componentParent.editBarService.setHasChanges(false);
        }

        event.context.componentParent.editBarService.onSave =
          event.context.componentParent.onSave.bind(event.context.componentParent);
      },
    },
  ];

  constructor(
    protected readonly translationService: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly toolbarService: ToolbarService,
    private readonly editBarService: EditBarService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastService: ToastService
  ) {
    super(translationService, toolbarService, cdRef);

    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));
    this.mainForm = this.createMainForm();

    this.subscribeToWorkArea();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscribeToFormStatusChanges();
    this.subscribeToFormValueChanges();

    this.originalKeys = objectHelper.cloneDeep(this.workingAreaSetting?.recipientList) ?? [];
    this.recipientKeys = objectHelper.cloneDeep(this.workingAreaSetting?.recipientList) ?? [];
    this.originalAlertNowUrl = this.workingAreaSetting?.alertNowUrl ?? '';
    this.hasRecipientKeys = this.recipientKeys.length > 0;
    this.showAddGroups = !this.hasZoneAlertNowGroups;

    this.isEditMode$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.editMode = val;
      this.updateActionMenuColumn();
    });

    this.setAlertNowUrl();
    this.cdRef.detectChanges();
  }

  ngOnChanges({ workingAreaSetting }: TypedChanges<IpstAlertnowSettingsServiceComponent>): void {
    if (
      workingAreaSetting?.currentValue?.recipientList ||
      workingAreaSetting?.currentValue?.alertNowUrl
    ) {
      this.recipientKeys = workingAreaSetting.currentValue.recipientList;
      this.originalAlertNowUrl = workingAreaSetting.currentValue?.alertNowUrl ?? '';
      this.hasRecipientKeys = this.recipientKeys.length > 0;
      this.gridApi?.setRowData(this.recipientKeys);
      this.editBarService.onSave = this.onSave.bind(this);
      this.gridApi?.refreshCells();
    }
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  subscribeToFormStatusChanges(): void {
    this.mainForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
    });
  }

  subscribeToFormValueChanges(): void {
    this.mainForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.checkForChanges();
    });
  }

  subscribeToWorkArea(): void {
    this.rootStore
      .pipe(select(fromRoot.selectSelectedWorkingAreaId), takeUntil(this.ngUnsubscribe))
      .subscribe(wa => {
        this.selectedWorkingAreaId = wa;
      });
  }

  checkForChanges(): void {
    const keysChanged = !isEqual(this.recipientKeys, this.originalKeys);
    const urlChanged = !isEqual(this.mainForm.get('alertNowUrl')?.value, this.originalAlertNowUrl);
    const hasChanges = keysChanged || urlChanged;

    this.editBarService.setHasChanges(hasChanges);
  }

  createMainForm(): UntypedFormGroup {
    return this.formBuilder.group({
      alertNowUrl: ['', Validators.required],
    });
  }

  setAlertNowUrl(): void {
    const alertNowControl = this.mainForm.get('alertNowUrl');
    alertNowControl?.setValue({ url: this.workingAreaSetting?.alertNowUrl });
    alertNowControl?.updateValueAndValidity();
    this.checkForChanges();
  }

  setGridMenuItems(): void {
    if (this.editMode) {
      this.menuItems = [
        this.createMenuItem(
          'shared.treeTable.actionMenu.setAsDefault',
          this.onSetAsDefault.bind(this),
          true,
          false
        ),
        this.createMenuItem(
          'shared.treeTable.actionMenu.delete',
          this.onDelete.bind(this),
          true,
          false
        ),
      ];
    } else {
      this.menuItems = [];
    }
  }

  updateGridMenuItems(item?: WorkAreaSettingRecipientKeyDto | undefined): void {
    this.menuItems.forEach(m => {
      m.disabled = this.getIsGridMenuItemDisabled(item, m);
    });

    this.cdRef.markForCheck();
  }

  getIsGridMenuItemDisabled(
    item: WorkAreaSettingRecipientKeyDto | undefined,
    menuItem?: ExtendedDsMenuItem
  ): boolean {
    if (menuItem?.key !== 'shared.treeTable.actionMenu.setAsDefault') return false;
    return item === undefined || item.isDefault;
  }

  removeDuplicates(): void {
    this.recipientKeys = Array.from(
      new Map(this.recipientKeys.map(item => [item.recipientKey, item])).values()
    );
  }

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
  }

  onAddRecipientKey(): void {
    if (this.activeId === 'zoneAlertNowGroupsTab') {
      this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: true }));
      this.activeId = 'recipientKeysTab';
    }

    if (this.workingAreaSetting) {
      this.recipientKeys = [
        ...this.recipientKeys,
        {
          id: crypto.randomUUID(),
          workAreaId: this.workingAreaSetting.workingAreaId,
          recipientKey: '',
          isDefault: this.recipientKeys.length > 0 ? false : true,
          isNew: true,
        },
      ];
      this.gridApi.refreshCells();
      this.cdRef.markForCheck();
    }
  }

  onAddZoneAlertNowGroup(): void {
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: true }));
    this.editBarService.setHasChanges(false);
  }

  onSetAsDefault(rowNode: RowNode<WorkAreaSettingRecipientKeyDto>): void {
    this.recipientKeys.forEach(r => (r.isDefault = false));
    const node = this.recipientKeys.find(r => r.id === rowNode.data?.id);

    if (node) node.isDefault = true;
    this.recipientKeys = [...this.recipientKeys];
    this.gridApi?.redrawRows();
    this.cdRef.markForCheck();
  }

  onDelete(rowNode: RowNode<WorkAreaSettingRecipientKeyDto>): void {
    const item = rowNode.data;
    if (!this.canDeleteRecipientKey || !item) {
      return;
    }

    this.recipientKeys = this.recipientKeys.filter(r => r.id !== item?.id);
    if (item.isDefault && this.recipientKeys.length) {
      this.recipientKeys[0].isDefault = true;
    }

    this.gridApi?.redrawRows();
    this.cdRef.markForCheck();
  }

  onSave(): void {
    const emptyRecipientKeyExists = this.recipientKeys.some(s => !s.recipientKey);
    if (emptyRecipientKeyExists) {
      this.toastService.createErrorToast('settings.ipstAlertNowSettings.emptyRecipientKeyError');
      return;
    }

    this.removeDuplicates();

    if (this.workingAreaSetting) {
      const nodeList: WorkAreaSettingRecipientKeyDto[] = [];
      this.recipientKeys.forEach((node: WorkAreaSettingRecipientKeyDto) => {
        const item = { ...node };
        if (item.isNew) item.id = EMPTY_GUID;
        nodeList.push(item);
      });

      const updatedWorkingAreaSetting: AlertNowTabDto = {
        alertNowUrl:
          this.mainForm.get('alertNowUrl')?.value.url ?? this.workingAreaSetting.alertNowUrl,
        recipientList: nodeList,
        workingAreaId: this.selectedWorkingAreaId,
      };

      this.workingAreaSetting = {
        ...this.workingAreaSetting,
        alertNowUrl: updatedWorkingAreaSetting.alertNowUrl,
      };

      this.save.emit(updatedWorkingAreaSetting);
    }
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id.toString();
  };

  onCellSelected(_event: CellClickedEvent<WorkAreaSettingRecipientKeyDto>): void {}
}
