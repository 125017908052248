import { VehicleDto } from 'core/dtos';
import { CommentModalType, HardwareVersion, ReducedVehicle, VehicleType } from 'core/models';

export const directVehicleAccess = {
  onDirectVehicleAccess: (selectedVehicle: ReducedVehicle): void => {
    if (selectedVehicle && selectedVehicle.ipAddress) {
      let url = selectedVehicle.ipAddress;
      if (!url.startsWith('http')) {
        url = 'http:\\\\' + url;
      }
      window.open(url, '_blank');
    }
  },
};

export function strWebToolAccess(): void {
  const url = 'https://strwebbasedtool.bmwgroup.net/';
  window.open(url, '_blank');
}

export function getDefaultRecipientKeyFormat(recipientKey: string): string {
  return `Default (${recipientKey})`;
}

export function determineModalTypeForVehicle(
  selectedVehicle: VehicleDto | ReducedVehicle | undefined
): CommentModalType {
  if (selectedVehicle?.vehicleType === VehicleType.UnitLoad) {
    return CommentModalType.Default;
  } else if (selectedVehicle?.vehicleType === VehicleType.Forklift) {
    return CommentModalType.Forklift;
  } else if (
    selectedVehicle?.vehicleType === VehicleType.TuggerTrain &&
    selectedVehicle.hardwareVersion === HardwareVersion.TuggerTrainFourAmV2
  ) {
    return CommentModalType.Tugger;
  } else if (
    selectedVehicle?.vehicleType === VehicleType.TuggerTrain &&
    selectedVehicle.hardwareVersion === HardwareVersion.TuggerTrainDsV1
  ) {
    return CommentModalType.DsTugger;
  } else {
    return CommentModalType.Default;
  }
}

export function isManualVehicle(vehicle: VehicleDto | ReducedVehicle | undefined): boolean {
  return (
    vehicle?.hardwareVersion === HardwareVersion.ForkliftManual ||
    vehicle?.hardwareVersion === HardwareVersion.TuggerTrainManual
  );
}
