<h3>{{ 'settings.errorForwarding.list.heading' | translate }}</h3>

<app-vehicle-type-filter
  *ngIf="showVehicleFilter; else resetTemplate"
  (selectedVehicleTypesChanged)="selectedVehicleTypesChanged($event)"
  [vehicleTypes]="vehicleTypeInErrorNotificationList"
  [filterVehicleTypesFlag]="filterVehicleTypes">
</app-vehicle-type-filter>

<ng-template #resetTemplate>
  <div class="d-flex justify-content-end">
    <button
      ds-button
      [variant]="'ghost'"
      [icon]="resetIcon"
      class="ms-4x"
      (click)="resetPersistedFilterAndColumnState()">
      {{ 'shared.tableColumnActions.reset' | translate }}
    </button>
  </div>
</ng-template>

<ag-grid-angular
  data-cy="errorForwardingTable"
  class="ag-theme-density zebra"
  rowSelection="single"
  [appPersistTableFilterAndColumnState]="{
    persistKey: 'error-forwarding-list',
    gridOptions,
    rowData: undefined
  }"
  [gridOptions]="gridOptions"
  [rowData]="tableErrorForwarding"
  [getRowId]="getRowIdForChangeDetection"
  [columnDefs]="translatedColumnDefs"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [searchTerm]="searchTerm"
  (gridReady)="onGridReady($event)"
  [isExternalFilterPresent]="isExternalFilterPresent"
  [doesExternalFilterPass]="doesExternalFilterPass">
</ag-grid-angular>
