import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  PoiBookingStatusChangeDto,
  PoiDeviceOccupancyStatusChangeDto,
  PoiDto,
  PoiOccupancyStatusChangeDto,
} from 'core/dtos';
import { GuidString, OccupancyStatus } from 'core/models';

import * as PoiGroupsActions from '../actions/poi-groups.actions';
import * as PoisActions from '../actions/pois.actions';

export const featureKey = 'pois';

export interface PoisState extends EntityState<PoiDto> {
  loaded: boolean;
  loading: boolean;
  errorMessage: string;
  selectedPoiId: GuidString;
  actionStatus: PoisActions.PoiTypes | null;
}

export const poisAdapter: EntityAdapter<PoiDto> = createEntityAdapter<PoiDto>();

export const initialState: PoisState = poisAdapter.getInitialState({
  loaded: false,
  loading: false,
  errorMessage: '',
  selectedPoiId: '',
  actionStatus: null,
});

const poisReducer = createReducer(
  initialState,

  on(PoisActions.loadPois, state => ({
    ...state,
    loading: true,
    loaded: false,
    errorMessage: '',
  })),

  on(PoisActions.loadPoisSuccess, (state, { pois }) =>
    poisAdapter.setAll(pois, {
      ...state,
      loaded: true,
      loading: false,
      actionStatus: PoisActions.PoiTypes.LoadPoisSuccess,
    })
  ),

  on(PoisActions.loadPoisFailure, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    loaded: false,
    errorMessage,
    actionStatus: PoisActions.PoiTypes.LoadPoisFailure,
  })),

  on(PoisActions.signalRCreatePoi, (state, { poi }) =>
    poisAdapter.addOne(
      {
        ...poi,
        mainPose: {
          ...poi.mainPose,
          orientation: +poi.mainPose?.orientation || 0,
        },
        poiGroupId: undefined,
      },
      { ...state }
    )
  ),

  on(PoisActions.addPoiSuccess, (state, { poi }) =>
    poisAdapter.addOne(
      {
        ...poi,
        mainPose: {
          ...poi.mainPose,
          orientation: +poi.mainPose?.orientation || 0,
        },
        poiGroupId: undefined,
      },
      { ...state, loading: false, actionStatus: PoisActions.PoiTypes.AddPoiSuccess }
    )
  ),

  on(PoisActions.updatePoiSuccess, PoisActions.ungroupPoiSuccess, (state, { poi }) =>
    poisAdapter.updateOne(updatePoi(poi), {
      ...state,
      loading: false,
      actionStatus: PoisActions.PoiTypes.UpdatePoiSuccess,
    })
  ),

  on(PoisActions.signalRUpdatePoi, (state, { poi }) =>
    poisAdapter.updateOne(updatePoi(poi), {
      ...state,
    })
  ),

  on(PoisActions.signalRUpdatePoiBookingStatus, (state, { poiBookingStatus }) =>
    poisAdapter.updateOne(updatePoiBookingStatus(poiBookingStatus), {
      ...state,
    })
  ),

  on(PoisActions.signalRUpdatePoiOccupancyStatus, (state, { poiOccupancyStatus }) =>
    poisAdapter.updateOne(updatePoiOccupancyStatus(poiOccupancyStatus), {
      ...state,
    })
  ),

  on(PoisActions.signalRUpdatePoiDeviceOccupancyStatus, (state, { poiDeviceOccupancy }) =>
    poisAdapter.updateOne(updatePoiDeviceOccupancyStatus(poiDeviceOccupancy), {
      ...state,
    })
  ),

  on(
    PoiGroupsActions.addPoiGroupSuccess,
    PoiGroupsActions.updatePoiGroup,
    PoiGroupsActions.signalRCreatePoiGroup,
    PoiGroupsActions.signalRUpdatePoiGroup,
    (state, { poiGroup }) => {
      for (const poi of poiGroup.pointsOfInterest) {
        state = poisAdapter.updateOne({ id: poi.id.toString(), changes: poi }, state);
      }

      return state;
    }
  ),

  on(PoisActions.signalRDeletePoi, (state, { poi }) =>
    poisAdapter.removeOne(poi.id.toString(), {
      ...state,
    })
  ),

  on(PoisActions.deletePoiSuccess, (state, { poi }) =>
    poisAdapter.removeOne(poi.id.toString(), {
      ...state,
      loading: false,
      actionStatus: PoisActions.PoiTypes.DeletePoiSuccess,
    })
  ),

  on(
    PoisActions.addPoi,
    PoisActions.updatePoi,
    PoisActions.deletePoi,
    PoisActions.ungroupPoi,
    state => ({
      ...state,
      loading: true,
      errorMessage: '',
    })
  ),

  on(PoisActions.addPoiFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    actionStatus: PoisActions.PoiTypes.AddPoiFailure,
  })),

  on(PoisActions.deletePoiFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    actionStatus: PoisActions.PoiTypes.DeletePoiFailure,
  })),

  on(PoisActions.updatePoiFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    actionStatus: PoisActions.PoiTypes.UpdatePoiFailure,
  })),

  on(PoisActions.ungroupPoiFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    actionStatus: PoisActions.PoiTypes.UngroupPoiFailure,
  })),

  on(PoisActions.selectPoi, (state, { poiId }) => ({
    ...state,
    selectedPoiId: poiId,
  })),

  on(PoisActions.resetActionStatus, state => ({
    ...state,
    actionStatus: null,
  }))
);

export function reducer(state: PoisState | undefined, action: Action): PoisState {
  return poisReducer(state, action);
}

export const { selectEntities, selectAll } = poisAdapter.getSelectors();

export const getLoading = (state: PoisState): boolean => state.loading;
export const getLoaded = (state: PoisState): boolean => state.loaded;
export const getErrorMessage = (state: PoisState): string => state.errorMessage;
export const getSelectedPoiId = (state: PoisState): GuidString => state.selectedPoiId;
export const getActionStatus = (state: PoisState): PoisActions.PoiTypes | null =>
  state.actionStatus;
export const getEntities = selectEntities;
export const getAllPois = selectAll;

const updatePoi = (poi: PoiDto) => {
  return {
    id: poi.id.toString(),
    changes: {
      ...poi,
      mainPose: {
        ...poi.mainPose,
        orientation: +poi.mainPose?.orientation || 0,
      },
      poiGroupId: poi.poiGroupId !== poi.id ? poi.poiGroupId : undefined,
    },
  };
};

const updatePoiDeviceOccupancyStatus = (poiDeviceOccupancy: PoiDeviceOccupancyStatusChangeDto) => {
  return {
    id: poiDeviceOccupancy.poiId.toString(),
    changes: {
      deviceOccupancy: poiDeviceOccupancy.loadedState,
    },
  };
};

function updatePoiBookingStatus(poiBookingStatus: PoiBookingStatusChangeDto) {
  return {
    id: poiBookingStatus.poiId.toString(),
    changes: {
      booked: poiBookingStatus.booked,
      bookedDateTime: poiBookingStatus.bookedDateTime,
      bookedVehicleId: poiBookingStatus.vehicleId,
    },
  };
}

function updatePoiOccupancyStatus(poiOccupancyStatus: PoiOccupancyStatusChangeDto) {
  const isBookingReset = poiOccupancyStatus.status === OccupancyStatus.Occupied;
  const bookingUpdate = isBookingReset
    ? {
        booked: false,
        bookedDateTime: undefined,
        bookedVehicleId: undefined,
      }
    : {};
  return {
    id: poiOccupancyStatus.poiId.toString(),
    changes: {
      ...bookingUpdate,
      occupancy: {
        occupancyStatus: poiOccupancyStatus.status,
        statusUpdatedUtc: poiOccupancyStatus.statusUpdatedOnUtc,
        vehicleId: poiOccupancyStatus.vehicleId,
      },
    },
  };
}
