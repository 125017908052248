/* eslint-disable max-lines */
import {
  LayoutDto,
  RouteConfigurationDto,
  RouteConfigurationRequest,
  RuleDto,
  SwitchNodeDto,
} from 'core/dtos';
import {
  BaseRoute,
  EdgeSegment,
  GraphEdge,
  GraphLayout,
  GraphNode,
  GraphNodeType,
  GuidString,
  Node,
  RouteConfigEdge,
  RouteConfigNode,
  RouteConfigState,
  isRouteConfigEdge,
  isRouteConfigNode,
} from 'core/models';
import { first, isEmpty, last } from 'lodash';
import { MAX_ANGLE } from 'modules/maps/constants';
import { BehaviorSubject } from 'rxjs';
import { calculateAngleBetweenTwoVectorsWithSharedPoint } from 'shared/helpers';

const displayTypes = [
  GraphNodeType.Fueling,
  GraphNodeType.StoppingPoint,
  GraphNodeType.Charging,
  GraphNodeType.Parking,
  GraphNodeType.ContainerLane,
  GraphNodeType.DisposeStation,
  GraphNodeType.ContainerStation,
  GraphNodeType.ContainerTowerStation,
  GraphNodeType.Waypoint,
];

export class RouteConfigLayout extends GraphLayout {
  private switches: SwitchNodeDto[] = [];
  private routes: BaseRoute[] = [];
  routeEdges: GuidString[] = [];

  private readonly validChange = new BehaviorSubject<boolean>(this.switches.length > 0);
  readonly validChange$ = this.validChange.asObservable();

  get isValid(): boolean {
    return this.switches.length > 0;
  }

  get edgesInRoute(): GuidString[] {
    return this.switches.flatMap(s => s.edges.flatMap(e => e.edgeId));
  }

  get route(): RouteConfigurationRequest {
    return {
      switches: this.switches,
    };
  }

  get routeConfigNodes(): RouteConfigNode[] {
    return this.nodes.filter(isRouteConfigNode);
  }

  get routeConfigEdges(): RouteConfigEdge[] {
    return this.edges.filter(isRouteConfigEdge);
  }

  get nodesForDisplay(): RouteConfigNode[] {
    return this.routeConfigNodes.filter(
      n => n.isSwitchNode || displayTypes.some(t => n.nodeType === t)
    );
  }

  constructor(
    layout: LayoutDto,
    useSegments = true,
    routes: RouteConfigurationDto[] = [],
    rules?: RuleDto[]
  ) {
    super(layout, useSegments, rules);

    this.setRoutes(routes);
  }

  // #region Setup
  getInitialNode(dto: Node): RouteConfigNode {
    return { ...dto, state: this.getInitialNodeState(dto) };
  }

  getInitialNodeState(node: Node): RouteConfigState {
    return node.isSwitchNode ? RouteConfigState.Available : RouteConfigState.Unavailable;
  }

  getInitialEdge(dto: GraphEdge): RouteConfigEdge {
    return { ...dto, state: this.getInitialEdgeState() };
  }

  protected getInitialEdgeState(): RouteConfigState {
    return RouteConfigState.Unavailable;
  }

  resetLayoutState(): void {
    this.routeConfigNodes.forEach(n => (n.state = this.getInitialNodeState(n)));
    this.routeConfigEdges.forEach(e => {
      e.state = this.getInitialEdgeState();
      e.isInRoute = false;
    });
  }
  // #endregion

  // #region Routes
  setRouteEdges(): GuidString[] {
    return this.routes.flatMap(r => r.edges.map(e => e.edgeId));
  }

  getRouteById(routeId: GuidString): BaseRoute | undefined {
    return this.routes.find(r => r.id === routeId);
  }

  setRoutes(routes: RouteConfigurationDto[]): void {
    this.routes = routes.map(r => this.setRoute(r));
    this.routeEdges = this.setRouteEdges();
  }

  setRoute(routeConfig: RouteConfigurationDto): BaseRoute {
    const edges = routeConfig.switches.flatMap(s => this.getRouteSegment(routeConfig, s));

    return {
      ...routeConfig,
      edges: edges,
    };
  }

  getRouteSegment(route: RouteConfigurationDto, switchNode: SwitchNodeDto): GraphEdge[] {
    const node = this.getNode(switchNode.nodeId);

    if (node && isRouteConfigNode(node)) {
      node.routes = isEmpty(node.routes) ? [route.id] : [...(node.routes ?? []), route.id];
      node.color = route.color;
    }

    return switchNode.edges.flatMap(e => {
      const edges = this.getAllEdgesFromStartEdge(e.edgeId);

      edges.forEach(segEdge => {
        if (segEdge && isRouteConfigEdge(segEdge)) {
          segEdge.color = route.color;
        }
      });

      return edges;
    });
  }
  // #endregion

  // #region Get Nodes & Edges
  getAllEdgesFromStartEdge(edgeId: GuidString): GraphEdge[] {
    const segment = this.getSegmentFromEdge(edgeId);

    if (segment) {
      const edgesFromSegment = this.getEdgesFromSegment(segment);
      const segmentEndNode = this.getNode(segment.endNodeId);
      const edgesAfter =
        segmentEndNode && !segmentEndNode.isSwitchNode
          ? this.getEdgesAfterNode(segmentEndNode)
          : [];

      return [...edgesFromSegment, ...edgesAfter];
    }

    return [];
  }

  getSegmentsFromNode(
    selectedNodeId: string,
    selectedSegment: EdgeSegment | undefined
  ): EdgeSegment[] {
    const segments = this.segments.filter(i => i.startNodeId === selectedNodeId);

    if (selectedSegment)
      return segments.filter(segment => this.isValidNextSegment(segment, selectedSegment));

    return segments;
  }

  isValidNextSegment(nextSegment: EdgeSegment, selectedSegment: EdgeSegment): boolean {
    const lastEdgeInCurrentSegment = this.getEdgesFromSegment(selectedSegment).find(
      edge => edge.endNodeId === selectedSegment.endNodeId
    );
    const firstEdgeInNextSegment = this.getEdgesFromSegment(nextSegment).find(
      edge => edge.startNodeId === selectedSegment.endNodeId
    );
    if (
      !lastEdgeInCurrentSegment?.nurb?.path.length ||
      !firstEdgeInNextSegment?.nurb?.path.length ||
      firstEdgeInNextSegment.nurb.path.length < 2
    )
      return true;

    const lastPreviousNurbPoint = last(lastEdgeInCurrentSegment.nurb.path);
    const nextNurbPoint = firstEdgeInNextSegment.nurb.path[1];
    if (!lastPreviousNurbPoint || !nextNurbPoint) return true;
    return (
      calculateAngleBetweenTwoVectorsWithSharedPoint(
        lastPreviousNurbPoint,
        lastEdgeInCurrentSegment.endPosition,
        nextNurbPoint
      ) <= MAX_ANGLE
    );
  }

  getSegmentFromEdge(edgeId: GuidString): EdgeSegment | undefined {
    const segments = this.segments.filter(s => s.edges?.some(se => se === edgeId));

    if (segments.length > 1) {
      console.warn('Multiple Route Segments found');
    }
    return first(segments);
  }

  getEdgesFromSegment(segment: EdgeSegment | undefined): RouteConfigEdge[] {
    return segment && segment.edges !== undefined
      ? this.routeConfigEdges.filter(e => segment.edges?.some(se => se === e.edgeId))
      : [];
  }

  getEdgesAfterNode(node: GraphNode): GraphEdge[] {
    const nodeEdges = this.getStartEdgesFromNode(node);

    if (nodeEdges) {
      if (nodeEdges.length > 1) {
        throw new Error('Cannot get edges for a SwitchPoint');
      }

      const firstEdge = first(nodeEdges);
      const includedEdges: GraphEdge[] = [];

      if (firstEdge) {
        includedEdges.push(firstEdge);
        const segment = this.getSegmentFromEdge(firstEdge.edgeId);
        const segmentEdges = this.getEdgesFromSegment(segment);

        let nextEdge: GraphEdge | undefined = firstEdge;
        while (nextEdge) {
          nextEdge = this.getNextEdge(segmentEdges, nextEdge);

          if (nextEdge) includedEdges.push(nextEdge);
        }
      }

      return includedEdges;
    }

    return [];
  }

  getNextEdge(edges: GraphEdge[], firstEdge: GraphEdge): GraphEdge | undefined {
    const nextNode = this.getNode(firstEdge.endNodeId);
    if (nextNode) {
      return edges.find(e => e.startNodeId === nextNode.nodeId);
    }

    return undefined;
  }

  getEndNode(edgeId: string): GraphNode | undefined {
    const segment = this.segments.find(s => s.startEdgeId === edgeId);

    return this.nodes.find(n => n.nodeId === segment?.endNodeId);
  }
  // #endregion

  // #region Create Route
  addPointToRoute(
    selectedNodeId: GuidString,
    selectedEdgeId: GuidString,
    selectedSegment: EdgeSegment | undefined,
    selectedSwitches?: SwitchNodeDto[]
  ): void {
    if (selectedNodeId && selectedEdgeId) {
      const addSwitch: SwitchNodeDto = {
        nodeId: selectedNodeId.toString(),
        edges: [{ edgeId: selectedEdgeId.toString() }],
        previousSegment: selectedSegment,
      };
      if (selectedSwitches && this.switches.length === 0) {
        this.switches = selectedSwitches;
      }

      this.switches = [...this.switches, addSwitch];
    }

    this.setValidRoute();
  }

  setValidRoute(): void {
    this.validChange.next(this.switches.length > 0);
  }

  resetSwitches(): void {
    this.switches = [];
  }

  removePointFromRoute(): {
    removedItem: SwitchNodeDto | undefined;
    affectedEdges: RouteConfigEdge[];
  } {
    let affectedEdges: RouteConfigEdge[] = [];
    const lastItem = this.switches.pop();

    if (lastItem) {
      const segment = this.getSegmentFromEdge(lastItem.edges[0].edgeId);
      affectedEdges = this.getEdgesFromSegment(segment);
      const edgesInRoute = this.edgesInRoute;

      affectedEdges?.forEach(e => (e.isInRoute = edgesInRoute?.some(r => r === e.edgeId)));
    }

    this.setValidRoute();
    return { removedItem: lastItem, affectedEdges };
  }
  // #endregion
}
