<h3>{{ 'settings.developerSettings.title' | translate }}</h3>
<ds-tabs [activeTabId]="'emulatorTab'">
  <ds-tabs-label data-cy="emulatorTab" for="emulatorTab">
    {{ 'settings.featureToggles.emulatorServiceSection.emulatorService' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="emulatorTab">
    <app-emulator-settings
      class="container-fluid"
      [isEditMode]="isEditMode$ | async"
      [emulatorSettings]="emulatorSettings$ | async"
      (activateEmulators)="onActivateEmulators()"
      (deactivateEmulators)="onDeactivateEmulators()"
      (setEmulatorSettings)="onSetEmulatorSettings($event)">
    </app-emulator-settings>
  </ds-tabs-content>
</ds-tabs>
