import { GraphNodeType } from 'core/models';
import { IconOptions } from 'modules/maps/helpers';
import { GraphLayerImages } from '../map-layer-images.constant';

export enum GraphNodeIcon {
  StoppingPoint,
  Fueling,
  Parking,
  Charging,
  ContainerLane,
  DisposeStation,
  ContainerStation,
  ContainerTowerStation,
}

const NodeIconStyle: IconOptions = {
  resourceOptions: { scale: 20 },
  resolution: window.devicePixelRatio,
  scale: 60,
};

const NodePoiIconStyle: IconOptions = {
  resourceOptions: { scale: 20 },
  resolution: window.devicePixelRatio,
  scale: 22,
};

export const NodeIconOptions: Record<GraphNodeIcon, IconOptions> = {
  [GraphNodeIcon.Fueling]: { ...NodeIconStyle, path: GraphLayerImages.Graph_Node_Fuelling },
  [GraphNodeIcon.StoppingPoint]: {
    ...NodeIconStyle,
    scale: 50,
    path: GraphLayerImages.Graph_Node_Stopping,
  },
  [GraphNodeIcon.Parking]: { ...NodePoiIconStyle, path: GraphLayerImages.Graph_Node_Parking },
  [GraphNodeIcon.Charging]: { ...NodePoiIconStyle, path: GraphLayerImages.Graph_Node_Charging },
  [GraphNodeIcon.ContainerLane]: {
    ...NodePoiIconStyle,
    path: GraphLayerImages.Graph_Node_ContainerLane,
  },
  [GraphNodeIcon.DisposeStation]: {
    ...NodePoiIconStyle,
    path: GraphLayerImages.Graph_Node_DisposeStation,
  },
  [GraphNodeIcon.ContainerStation]: {
    ...NodePoiIconStyle,
    path: GraphLayerImages.Graph_Node_ContainerStation,
  },
  [GraphNodeIcon.ContainerTowerStation]: {
    ...NodePoiIconStyle,
    path: GraphLayerImages.Graph_Node_ContainerTowerStation,
  },
};

export const NodeTypeIcon: Partial<Record<GraphNodeType, GraphNodeIcon>> = {
  [GraphNodeType.Fueling]: GraphNodeIcon.Fueling,
  [GraphNodeType.StoppingPoint]: GraphNodeIcon.StoppingPoint,
  [GraphNodeType.Parking]: GraphNodeIcon.Parking,
  [GraphNodeType.Charging]: GraphNodeIcon.Charging,
  [GraphNodeType.ContainerLane]: GraphNodeIcon.ContainerLane,
  [GraphNodeType.DisposeStation]: GraphNodeIcon.DisposeStation,
  [GraphNodeType.ContainerStation]: GraphNodeIcon.ContainerStation,
  [GraphNodeType.ContainerTowerStation]: GraphNodeIcon.ContainerTowerStation,
};
