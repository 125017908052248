import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { ExpandCollapseButtonsService } from './service/expand-collapse-buttons.service';

@Component({
  selector: 'app-expand-collapse-buttons',
  templateUrl: './expand-collapse-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandCollapseButtonsComponent implements OnInit {
  disableCollapse$: Observable<boolean> = of(true);
  disableExpand$: Observable<boolean> = of(false);
  ngUnsubscribe = new Subject<void>();

  constructor(private readonly expandCollapseButtonsService: ExpandCollapseButtonsService) {}

  ngOnInit(): void {
    this.disableCollapse$ = this.expandCollapseButtonsService.getDisableCollapseAllButton();
    this.disableExpand$ = this.expandCollapseButtonsService.getDisableExpandAllButton();
  }

  collapseAllClicked(): void {
    this.expandCollapseButtonsService.setClickCollapseAll();
  }

  expandAllClicked(): void {
    this.expandCollapseButtonsService.setClickExpandAll();
  }
}
