import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { DsMenuComponent } from '@bmw-ds/components';
import { DsMenuItem } from '@bmw-ds/components/ds-menu/ds-menu.interface';
import { ExtendedDsMenuItem } from 'core/models';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent implements OnChanges {
  @ViewChild(DsMenuComponent) menu!: DsMenuComponent;
  @Input() menuItems?: ExtendedDsMenuItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() identifier?: any;
  @Input() buttonAutomationId?: string;
  @Input() listPosition?: 'end' | 'start' = 'end';
  @Input() iconSize?: 'sm' | 'md' | 'lg' = 'lg';
  @Input() variant?: 'vertical' | 'horizontal' = 'vertical';
  @Input() isMenuClosed = true;

  @Output() readonly itemSelected = new EventEmitter<DsMenuItem>();
  @Output() readonly menuOpened = new EventEmitter<void>();

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  onItemSelected(menuItem: ExtendedDsMenuItem): void {
    if (!menuItem.disabled) {
      this.itemSelected.emit(menuItem);

      if (menuItem.command) {
        menuItem.command(this.identifier);
      }
    }
  }

  ngOnChanges({ isMenuClosed }: TypedChanges<ActionMenuComponent>): void {
    if (isMenuClosed?.currentValue && this.menu) this.menu.handleCloseClick();
  }

  onMenuOpened(): void {
    this.cdRef.detectChanges();
    this.menuOpened.emit();
  }
}
