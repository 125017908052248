<div class="container list-container">
  <h4>
    {{ 'settings.ipstAlertNowSettings.helpTool.matchedRecipientKeyHeader' | translate }}
  </h4>
  <h4>
    {{ 'settings.ipstAlertNowSettings.helpTool.matchedRecipientKeyText' | translate }}
  </h4>

  <ul *ngIf="recipientKeys.length" data-cy="recipientKeyList">
    <li *ngFor="let recipientKey of recipientKeys" data-cy="recipientKeyItem">
      {{ recipientKey }}
    </li>
  </ul>

  <h3 style="opacity: 0.5" *ngIf="!recipientKeys.length" data-cy="helpToolNoData">
    {{ 'settings.ipstAlertNowSettings.helpTool.noData' | translate }}
  </h3>
</div>
