import { Injectable } from '@angular/core';
import { GuidString, MessageType, SignalRNextMessage } from 'core/models';
import { Subject, Subscription } from 'rxjs';

import {
  VehicleConflictAreaDimensions,
  VehicleIntersectionZonePath,
  VehicleLocationSignalr,
} from 'core/dtos';
import { poll, PollingIntervals } from '../polling-helper';
import { SignalRPackedService } from '../signalr-packed.service';
import { unpackConflictZone, unpackMapData, unpackZonePath } from '../unpack-functions.helper';

@Injectable({
  providedIn: 'root',
})
export class VehiclePollingSignalRService {
  lastWorkAreaId?: GuidString;
  lastMapId?: GuidString;
  mapDataPollingSubscription: Subscription | null = null;

  mapDataReceived = new Subject<VehicleLocationSignalr[]>();
  intersectionZonePathMessageReceivedNext = new Subject<
    SignalRNextMessage<VehicleIntersectionZonePath>
  >();
  vehicleConflictAreaDimensionsMessageReceivedNext = new Subject<
    SignalRNextMessage<VehicleConflictAreaDimensions>
  >();

  constructor(private readonly packedService: SignalRPackedService) {}

  // #region Vehicle Locations
  async joinVehicleLocationsWorkAreaGroup(workAreaId: GuidString): Promise<void> {
    this.stopMapsTabPolling();
    this.lastWorkAreaId = workAreaId;
    this.tryStartMapsTabPolling();
    return Promise.resolve();
  }

  async leaveVehicleLocationsWorkAreaGroup(): Promise<void> {
    this.stopMapsTabPolling();
    return Promise.resolve();
  }

  async joinVehicleLocationsMapGroup(mapId: GuidString): Promise<void> {
    this.stopMapsTabPolling();
    this.lastMapId = mapId;
    this.tryStartMapsTabPolling();
    return Promise.resolve();
  }

  async leaveVehicleLocationsMapGroup(): Promise<void> {
    this.stopMapsTabPolling();
    return Promise.resolve();
  }
  // #endregion

  // #region Zone
  async joinVehicleConflictAreaDimensionsGroup(_mapId: GuidString): Promise<void> {
    return Promise.resolve();
  }

  async leaveVehicleConflictAreaDimensionsGroup(): Promise<void> {
    return Promise.resolve();
  }

  async joinIntersectionZonePathGroup(_mapId: GuidString): Promise<void> {
    return Promise.resolve();
  }

  async leaveIntersectionZonePathGroup(): Promise<void> {
    return Promise.resolve();
  }
  // #endregion

  // #region Polling
  private tryStartMapsTabPolling(): void {
    if (this.mapDataPollingSubscription != null) return;

    if (this.lastWorkAreaId && this.lastMapId)
      this.mapDataPollingSubscription = this.startMapsTabPolling(
        this.lastWorkAreaId,
        this.lastMapId
      );
  }

  private stopMapsTabPolling(): void {
    this.mapDataPollingSubscription?.unsubscribe();
    this.mapDataPollingSubscription = null;
  }

  private startMapsTabPolling(waId: GuidString, mapId: GuidString): Subscription {
    return poll(PollingIntervals.mapVehicles, t =>
      this.packedService.fetchMapData(waId, mapId, t)
    ).subscribe(updates => {
      this.mapDataReceived.next(updates.map(unpackMapData));

      for (const item of updates) {
        this.vehicleConflictAreaDimensionsMessageReceivedNext.next({
          type: MessageType.Modified,
          payload: unpackConflictZone(waId, item.id, item.conflictZone),
        });
        this.intersectionZonePathMessageReceivedNext.next({
          type: MessageType.Modified,
          payload: unpackZonePath(mapId, item.id, item.intersectionZonePath),
        });
      }
    });
  }
  // #endregion
}
