<app-base-page-layout
  [pageHeading]="pageHeading"
  [hasToolbar]="hasToolbar"
  [isNewLayout]="isNewLayout">
  <div
    [ngClass]="{ 'scroll-child': scrollChild }"
    class="content-area pl-container body-area view"
    base-page-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col pb-0x">
          <h3
            *ngIf="contentHeading"
            data-cy="contentHeading"
            [attr.data-translatekey]="contentHeading">
            <ng-content select="[headingPrefix]"></ng-content>
            {{ contentHeading | translate }}
          </h3>
        </div>
        <div class="col pb-0x" *ngIf="showCollapseAndExpandButtons$ | async">
          <div class="expand-collapse-buttons float-end">
            <app-expand-collapse-buttons></app-expand-collapse-buttons>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'scroll-child': scrollChild }" class="pl-content">
      <ng-content class="col pb-0x" select="[plain-layout-content]"></ng-content>
    </div>
  </div>
</app-base-page-layout>
