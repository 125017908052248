/* eslint-disable @typescript-eslint/no-magic-numbers */

import { OrderPathDto, VehicleDto } from 'core/dtos';
import { Vector2D } from 'core/models';
import { VehiclePathColors } from 'library/styles';
import { LineArrowOptions, LineOptions, MapPixiHelper } from 'modules/maps/helpers';
import { Texture } from 'pixi.js';
import { GeometryHelper } from 'shared/helpers';
import { GraphicsEx } from '../../pixi';
import { TuggerDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { VehiclePathStyle } from '../vehicle-layer.constant';
import { TuggerTrainMapItem } from '../vehicle-types/tuggertrain.graphic';

const style: TuggerDimensions = {
  length: 190,
  width: 95.3,
  origin: 15.5,
  trailer: {
    length: 420,
    width: 130,
    barLength: 30,
  },
};

export class TuggerTrainDSMapItem extends TuggerTrainMapItem {
  protected orderPath: OrderPathDto | undefined;
  private completePath: GraphicsEx | undefined;

  get hasOrderPath(): boolean {
    return this.orderPath !== undefined && this.orderPath.path.length > 0;
  }

  protected getDimensions(): VehicleDimensions {
    return style;
  }

  protected getTrailerTexture(): Texture {
    return VehicleTextures.tuggerTrailerUFrame;
  }

  protected createPaths(vehicle: VehicleDto): void {
    super.createPaths(vehicle);

    if (!this.pathContainer.visible || !this.isSelected || !this.hasOrderPath) {
      if (this.completePath && this.completePath.hasPoints) this.completePath?.clear();

      return;
    }

    const lastPoint: Vector2D =
      vehicle.path && vehicle.path.length > 0
        ? vehicle.path[vehicle.path.length - 1]
        : vehicle.pose2D;

    const fullPath = this.getCompletePath(lastPoint);

    if (fullPath.length <= 1) {
      this.completePath?.clear();
      return;
    }

    if (!this.completePath)
      this.pathContainer.addChildAtBottom((this.completePath = new GraphicsEx()));

    const options: LineOptions = {
      size: VehiclePathStyle.Size,
      color: VehiclePathColors.CompletePath,
      alpha: VehiclePathStyle.Opacity,
    };

    const arrowOptions: LineArrowOptions = {
      ...options,
      size: VehiclePathStyle.SelectedArrowSize,
    };

    this.completePath.clear();

    MapPixiHelper.drawPathWithDirectionArrow(
      this.completePath,
      options,
      arrowOptions,
      lastPoint,
      fullPath
    );
  }

  // #region Path
  getOrderPath(): OrderPathDto | undefined {
    return this.orderPath;
  }

  resetOrderPath(): void {
    this.orderPath = undefined;
  }

  updateOrderPath(path: OrderPathDto | undefined): void {
    if (!path || path.path.length === 0) {
      this.resetOrderPath();
      return;
    }

    if (!this.orderPath || this.orderPath.orderId !== path.orderId) {
      this.orderPath = path;
      this.createPaths(this.vehicle);
    }
  }

  getCompletePath(from: Vector2D): Vector2D[] {
    const approxX = Math.trunc(from.x);
    const approxY = Math.trunc(from.y);

    const approxPoints =
      this.orderPath?.path.filter(
        p => Math.trunc(p.x) === approxX && Math.trunc(p.y) === approxY
      ) ?? [];

    const orderStartPoint = approxPoints.sort((a, b) => {
      return GeometryHelper.calculateDistance(a, from) > GeometryHelper.calculateDistance(b, from)
        ? 1
        : -1;
    })[0];

    if (approxPoints) {
      const start = this.orderPath?.path.lastIndexOf(orderStartPoint);
      return this.orderPath?.path.slice(start) ?? [];
    }

    return [];
  }

  // #endregion
}
