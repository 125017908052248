import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from 'store/index';
@Component({
  selector: 'app-base-page-layout',
  templateUrl: './base-page-layout.component.html',
  styleUrls: ['./base-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasePageLayoutComponent {
  @Input() pageHeading = '';
  @Input() hasToolbar = true;
  @Input() showToolbarItems = true;
  @Input() isNewLayout = false;

  isEditMode$: Observable<boolean>;

  constructor(rootStore: Store<fromRoot.RootState>) {
    this.isEditMode$ = rootStore.pipe(select(fromRoot.selectIsEditMode));
    rootStore.dispatch(fromRoot.showSearchBar({ isVisible: true }));
  }
}
