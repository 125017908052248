import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { WorkAreaSettingRecipientKeyDto } from 'core/dtos';

@Component({
  selector: 'app-recipient-key-cell',
  templateUrl: './recipient-key-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientKeyCellComponent implements ICellRendererAngularComp {
  constructor(private readonly cdRef: ChangeDetectorRef) {}

  rowData?: WorkAreaSettingRecipientKeyDto;
  showTag = false;
  keyName = '';

  agInit(params: ICellRendererParams<WorkAreaSettingRecipientKeyDto>): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams<WorkAreaSettingRecipientKeyDto>): boolean {
    this.rowData = params.data;
    this.showTag = this.rowData?.isDefault ? true : false;
    this.keyName = this.rowData?.recipientKey ?? '';
    this.cdRef.detectChanges();

    return true;
  }
}
