<ds-form-field>
  <ds-select
    ds-form-validation
    data-cy="stepTypeDropdown"
    name="stepType"
    id="stepType"
    [labellingConfig]="labellingConfig"
    [options]="'stepTypes' | dsEnumDropdown : true : removableItems"
    [(ngModel)]="selectedStepType"
    required
    [disabled]="disabled"
    [ds-tooltip]="'jobs.processConfig.selectMapFirstToolTip' | translate"
    [dsTooltipConfig]="{ disabled: !disabled }"
    (optionSelected)="onChangeStepType()"></ds-select>
</ds-form-field>
