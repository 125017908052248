/* eslint-disable max-lines */
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHubProtocol,
  LogLevel,
} from '@microsoft/signalr';
import { Store, select } from '@ngrx/store';
import { DISTANT_FUTURE, FIVE_SECONDS, ONE_MINUTE } from 'core/constants';
import { filterUndefined } from 'core/helpers';
import { GuidString, ToastSeverityEnum, WorkingArea } from 'core/models';
import { SessionService } from 'core/services/session.service';
import { ToastService } from 'core/services/toast.service';
import { Message } from 'primeng/api';
import { filter } from 'rxjs/operators';
import * as fromRoot from 'store/index';
import { selectReducedSelectedWorkingArea } from 'store/selectors';
import { SignalrRoutes } from './signalr-routes';

export class SignalRService {
  protected readonly hubConnection: HubConnection;
  protected readonly signalRGroups = new Map<string, Set<string>>();
  protected connectionTask?: Promise<void>;
  private isReconnected = false;
  private isConnected = true;

  protected selectedWorkingArea?: WorkingArea;

  constructor(
    protected readonly sessionService: SessionService,
    protected store: Store<fromRoot.RootState>,
    protected toastService: ToastService,
    url: string,
    protocol: IHubProtocol
  ) {
    this.hubConnection = this.createConnection(url, protocol);

    this.hubConnectionMaintenance();
    this.preventTabSleeping();

    this.store
      .pipe(select(selectReducedSelectedWorkingArea), filterUndefined())
      .subscribe(this.onWorkingAreaSelected.bind(this));

    this.toastService.closedMessages$
      .pipe(filter(o => o.id === 'disconnect'))
      .subscribe((_m: Message) => {
        // eslint-disable-next-line
        window.location = window.location;
      });
  }

  setReconnected(value: boolean): boolean {
    this.isConnected = value;
    return (this.isReconnected = value);
  }

  get getIsReconnected(): boolean {
    return this.isReconnected;
  }

  get getIsConnected(): boolean {
    return this.isConnected;
  }

  protected isConnectedOrTest(): boolean {
    // In the test we're always connected
    return window.signalrMock != null || this.hubConnection.state === HubConnectionState.Connected;
  }

  checkOnlineStatus(): void {
    if (this.getIsConnected) {
      this.toastService.clear('disconnect');
    } else {
      this.disconnectedToast();
    }
  }

  has(messageGroup: string, componentName: string): boolean {
    const messageGroupValue = this.signalRGroups.get(messageGroup);
    if (messageGroupValue === undefined) return false;
    return messageGroupValue.has(componentName);
  }

  add(messageGroup: string, componentName: string): void {
    const value = this.signalRGroups.get(messageGroup) ?? new Set<string>();
    value.add(componentName);
    this.signalRGroups.set(messageGroup, value);
  }

  remove(messageGroup: string, componentName: string): void {
    const value = this.signalRGroups.get(messageGroup) ?? new Set<string>();
    value.delete(componentName);
    this.signalRGroups.set(messageGroup, value);
  }

  private *iterateMapSets(collection?: Map<string, Set<string>>): Generator<[string, string]> {
    for (const entry of (collection ?? this.signalRGroups).entries()) {
      const groupName = entry[0];
      for (const requester of entry[1]) {
        yield [groupName, requester];
      }
    }
  }

  protected createConnection(url: string, protocol: IHubProtocol): HubConnection {
    if (window.signalrMock != null) {
      console.info('Using test signalR hub');
      return window.signalrMock;
    }

    return new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => this.sessionService.getToken(),
      })
      .withHubProtocol(protocol)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < ONE_MINUTE) {
            return Math.random() * FIVE_SECONDS;
          } else {
            return ONE_MINUTE / 2;
          }
        },
      })
      .configureLogging(LogLevel.Information)
      .build();
  }

  private hubConnectionMaintenance(): void {
    this.hubConnection.onclose(() => {
      this.logSignalR('Connection closed.');
      this.setReconnected(false);
      this.disconnectedToast();
    });

    this.hubConnection.onreconnecting(() => {
      this.logSignalR('Reconnecting...');
      this.setReconnected(false);
      this.disconnectedToast();
    });

    this.hubConnection.onreconnected(async () => {
      this.logSignalR('Reconnected...');
      this.setReconnected(true);
      this.reconnectedToast();
      return this.rejoinGroups();
    });
  }

  private clearToast(): void {
    this.toastService.clear('disconnect');
  }

  private reconnectedToast(): void {
    this.clearToast();

    this.toastService.createCustomToast(
      'shared.signalr.reconnected',
      {},
      {
        id: 'reconnected',
        tone: ToastSeverityEnum.Success,
        toastText: '',
      }
    );
  }

  private disconnectedToast(): void {
    this.clearToast();
    this.toastService.createCustomToast(
      'shared.signalr.disconnected',
      {},
      {
        id: 'disconnect',
        tone: ToastSeverityEnum.Warning,
        toastText: '',
        duration: DISTANT_FUTURE,
      }
    );
  }

  protected async connect(): Promise<void> {
    if (this.connectionTask !== undefined) {
      await this.connectionTask;
    }

    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.connectionTask = this.startConnection();
      await this.connectionTask;
    }
  }

  private async startConnection(): Promise<void> {
    try {
      await this.hubConnection.start();
      this.logSignalR('SignalR connection established.');
      void this.rejoinGroups();
    } catch (err) {
      console.error(err);
    }
  }

  async joinGroup(messageGroup: string, componentName: string): Promise<void> {
    await this.connect();
    messageGroup = this.formatWorkingArea(messageGroup);

    if ((this.signalRGroups.get(messageGroup)?.size ?? 0) > 0) {
      this.add(messageGroup, componentName);
      return;
    }

    await this.joinGroupUnchecked(messageGroup, componentName);
  }

  private async joinGroupUnchecked(messageGroup: string, componentName: string): Promise<void> {
    console.assert(
      !messageGroup.includes('{'),
      `Group name ${messageGroup} should not contain placeholders`
    );
    try {
      const organization = this.selectedWorkingArea?.organizationName ?? '';
      const workingArea = this.selectedWorkingArea?.name ?? '';
      const groupJoined = await this.hubConnection.invoke<boolean>(
        'JoinGroup',
        messageGroup,
        organization,
        workingArea
      );

      if (!groupJoined) {
        console.error(
          `Failed to join signalR group ${messageGroup} for ${organization}/${workingArea}, permission denied`
        );
        return;
      }

      this.add(messageGroup, componentName);

      this.logSignalR(`Joined signalR group ${messageGroup}`);
    } catch (error) {
      if (error instanceof Error) {
        this.logSignalR(`Could not join signalR group ${messageGroup}`, [error.toString()]);
      }
    }
  }

  hasRouteJoinedGroup(route: SignalrRoutes, arg: GuidString | string): boolean {
    const replacedRoute = route.replace('{0}', arg.toString());
    return this.signalRGroups.has(replacedRoute);
  }

  async joinRoute(
    route: SignalrRoutes,
    arg: GuidString | string | null,
    componentName: string
  ): Promise<void> {
    arg = arg ?? '';
    const replacedRoute = route.replace('{0}', arg.toString());
    await this.joinGroup(replacedRoute, componentName);
  }

  async leaveRoute(
    route: string,
    arg: GuidString | string | null,
    componentName: string
  ): Promise<void> {
    arg = arg ?? this.selectedWorkingArea?.id ?? '';
    const replacedRoute = route.replace('{0}', arg.toString());
    if (this.has(replacedRoute, componentName)) {
      return this.leaveGroup(replacedRoute, componentName);
    }
  }

  async leaveAllGroups(): Promise<void> {
    for (const entry of this.iterateMapSets()) {
      await this.leaveGroup(...entry);
    }
  }

  async leaveGroupOfCurrentWorkArea(messageGroup: string, componentName: string): Promise<void> {
    await this.leaveGroup(this.formatWorkingArea(messageGroup), componentName);
  }

  async leaveGroup(groupName: string, componentName: string): Promise<void> {
    const requester = this.signalRGroups.get(groupName);
    if (!requester) {
      return;
    }

    this.remove(groupName, componentName);

    if (requester.size === 0) {
      await this.leaveGroupUnchecked(groupName);
    }
  }

  private async leaveGroupUnchecked(groupName: string): Promise<void> {
    await this.connect();

    try {
      await this.hubConnection.invoke('LeaveGroup', groupName);

      this.signalRGroups.delete(groupName);

      this.logSignalR(`Left signalR group ${groupName}`);
    } catch (error) {
      if (error instanceof Error) {
        this.logSignalR(`Could not leave signalR group ${groupName}`, [error.toString()]);
      }
    }
  }

  getConnectionId(): string | null {
    return this.hubConnection.connectionId;
  }

  protected async rejoinGroups(): Promise<void> {
    if (this.signalRGroups.size) {
      this.logSignalR(`Rejoining groups | ${this.signalRGroups.size} to join.`);

      for (const entry of this.iterateMapSets()) {
        await this.joinGroup(...entry);
      }
    }
  }

  protected async onWorkingAreaSelected(workingArea: WorkingArea): Promise<void> {
    if (this.selectedWorkingArea != null) {
      this.logSignalR(
        `Switching groups from ${this.selectedWorkingArea.name} to ${workingArea.name}`
      );
      const oldId = this.selectedWorkingArea.id.toString();
      const newId = workingArea.id.toString();

      const waGroups = [...this.iterateMapSets()].filter(it => it[0].includes(oldId));

      await this.leaveAllGroups();
      for (const entry of waGroups) {
        await this.joinGroup(entry[0].replace(oldId, newId), entry[1]);
      }

      this.logSignalR(`Switched to ${workingArea.name}`);
    }
    this.selectedWorkingArea = workingArea;
  }

  protected formatWorkingArea(message: string): string {
    return this.selectedWorkingArea?.id
      ? message.replace(/^\{0\}/, this.selectedWorkingArea?.id.toString())
      : message;
  }

  private logSignalR(message: string, ...optionalParams: object[]): void {
    if (window.flags.logSignalRMeta) console.info(message, ...optionalParams);
  }

  protected logSignalRCall(method: string, data: object): void {
    if (window.flags.logSignalRCalls > 0) {
      console.info(`The server called "${method}" with data: `, data);
      window.flags.logSignalRCalls--;
    }
  }

  protected logSignalRPollingResult(method: string, data: object): void {
    if (window.flags.logSignalRCalls > 0) {
      console.info(`Polled "${method}" with result: `, data);
      window.flags.logSignalRCalls--;
    }
  }

  private preventTabSleeping(): void {
    // Please see https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
    // and https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const n = navigator as any;

    if (n && n.locks && n.locks.request) {
      const promise = new Promise(_res => {});

      n.locks.request('tab_lock', { mode: 'shared' }, () => {
        return promise;
      });
    }
  }
}
