/* eslint-disable max-lines */
import { Dictionary } from '@ngrx/entity';
import { FailureCommentDto, FleetDto, MapDto, PoiDto, TourTriggerType } from 'core/dtos';
import {
  GuidString,
  MissionTrace,
  MissionTraceTrigger,
  MissionTraceTriggerDisplayFilter,
  ProcessTrace,
  StepTraceModel,
  StepType,
  WaitingQueue,
} from 'core/models';
import { getStepAttributeText } from 'store-modules/vehicles-store/selectors/vehicles.selectors.helpers';

export function getStepType(
  currentStepSequenceNumber: number,
  stepTraces: StepTraceModel[]
): StepType {
  return stepTraces[currentStepSequenceNumber]?.sourceStep.type;
}

export function getStepDetail(
  currentStepSequenceNumber: number,
  stepTraces: StepTraceModel[]
): string {
  const step = stepTraces[currentStepSequenceNumber]?.sourceStep;
  if (currentStepSequenceNumber >= 0) {
    return getStepAttributeText(step);
  }
  return '';
}

export function convertMissionTriggerTypes(
  trigger: MissionTraceTrigger
): MissionTraceTriggerDisplayFilter {
  switch (trigger) {
    case MissionTraceTrigger.AdhocInstructionFromMission:
    case MissionTraceTrigger.AdhocInstructionFromStep:
    case MissionTraceTrigger.AdhocInstructionFromProcessChain:
      return MissionTraceTriggerDisplayFilter.Manual;
    case MissionTraceTrigger.SystemParkingInstruction:
    case MissionTraceTrigger.SystemChargingInstruction:
    case MissionTraceTrigger.SystemBreakTestInstruction:
      return MissionTraceTriggerDisplayFilter.ParkingCharging;
    case MissionTraceTrigger.SystemWaitingQueueInstruction:
      return MissionTraceTriggerDisplayFilter.WaitingQueue;
    case MissionTraceTrigger.AdHocFromTourConfig:
      return MissionTraceTriggerDisplayFilter.LongRunning;
    case MissionTraceTrigger.BeginShift:
      return MissionTraceTriggerDisplayFilter.BeginShift;
    default:
      return MissionTraceTriggerDisplayFilter.Production;
  }
}

export function convertTourTriggerTypes(
  trigger: TourTriggerType
): MissionTraceTriggerDisplayFilter {
  switch (trigger) {
    case TourTriggerType.AdhocInstructionFromStep:
      return MissionTraceTriggerDisplayFilter.Manual;
    case TourTriggerType.AdHocFromTourConfiguration:
      return MissionTraceTriggerDisplayFilter.LongRunning;
    case TourTriggerType.BeginShiftTour:
      return MissionTraceTriggerDisplayFilter.BeginShift;
    case TourTriggerType.CallOff:
      return MissionTraceTriggerDisplayFilter.LongRunning;
    default:
      return MissionTraceTriggerDisplayFilter.Production;
  }
}

export function convertTourTriggerTypeToMissionTraceTrigger(
  trigger: TourTriggerType
): MissionTraceTrigger {
  switch (trigger) {
    case TourTriggerType.AdhocInstructionFromStep:
      return MissionTraceTrigger.AdhocInstructionFromStep;
    case TourTriggerType.AdHocFromTourConfiguration:
      return MissionTraceTrigger.AdHocFromTourConfig;
    case TourTriggerType.BeginShiftTour:
      return MissionTraceTrigger.BeginShift;
    default:
      return MissionTraceTrigger.JobInstruction;
  }
}

export function convertToProcessTrace(
  allMissionTraces: MissionTrace[],
  selectedMissionTrace: MissionTrace,
  mapEntities: Dictionary<MapDto>,
  fleetEntities: Dictionary<FleetDto>
): ProcessTrace {
  let processTrace: ProcessTrace;
  processTrace = {
    type: MissionTraceTriggerDisplayFilter.Manual,
    createdDateTime: selectedMissionTrace.createdDateTime,
    mapId: selectedMissionTrace.mapId ? selectedMissionTrace.mapId : undefined,
    mapName: selectedMissionTrace.mapId
      ? getMapName(selectedMissionTrace.mapId, mapEntities)
      : undefined,
    missionTraces: getMissionTraces(selectedMissionTrace.processChainTraceId, allMissionTraces),
    materialNumber: selectedMissionTrace.materialNumber
      ? selectedMissionTrace.materialNumber
      : undefined,
    destination: selectedMissionTrace.destination ? selectedMissionTrace.destination : undefined,
    source: selectedMissionTrace.source ? selectedMissionTrace.source : undefined,
    loadType: selectedMissionTrace.loadType,
  };
  switch (selectedMissionTrace.trigger) {
    case MissionTraceTrigger.AdhocInstructionFromMission:
    case MissionTraceTrigger.AdhocInstructionFromStep:
      processTrace = {
        ...processTrace,
        type: MissionTraceTriggerDisplayFilter.Manual,
      };
      break;
    case MissionTraceTrigger.AdHocFromTourConfig:
      processTrace = {
        ...processTrace,
        type: MissionTraceTriggerDisplayFilter.LongRunning,
      };
      break;
    case MissionTraceTrigger.Sap:
      processTrace = {
        ...processTrace,
        type: MissionTraceTriggerDisplayFilter.Production,
      };
      break;
    case MissionTraceTrigger.BeginShift:
      processTrace = {
        ...processTrace,
        type: MissionTraceTriggerDisplayFilter.BeginShift,
      };
      break;
    case MissionTraceTrigger.SystemParkingInstruction:
    case MissionTraceTrigger.SystemChargingInstruction:
    case MissionTraceTrigger.SystemBreakTestInstruction:
      processTrace = {
        ...processTrace,
        type: MissionTraceTriggerDisplayFilter.ParkingCharging,
      };
      break;
    case MissionTraceTrigger.JobInstruction:
      processTrace = createProcessTraceWithPCInfo(
        processTrace,
        MissionTraceTriggerDisplayFilter.Production,
        selectedMissionTrace,
        fleetEntities
      );
      break;
    case MissionTraceTrigger.SystemWaitingQueueInstruction:
      processTrace = createProcessTraceForWaitingQueue(
        processTrace,
        MissionTraceTriggerDisplayFilter.WaitingQueue,
        selectedMissionTrace,
        fleetEntities
      );
      break;
    case MissionTraceTrigger.AdhocInstructionFromProcessChain:
      processTrace = createProcessTraceWithPCInfo(
        processTrace,
        MissionTraceTriggerDisplayFilter.Manual,
        selectedMissionTrace,
        fleetEntities
      );
  }
  return processTrace;
}

export function getMapName(mapId: GuidString = '', mapEntities: Dictionary<MapDto>): string {
  return mapEntities[String(mapId)]?.name ?? '';
}

export function getMissionTraces(
  processChainTraceId: GuidString,
  allMissionTraces: MissionTrace[]
): MissionTrace[] {
  return allMissionTraces.filter(m => m.processChainTraceId === processChainTraceId);
}

function createProcessTraceWithPCInfo(
  processTrace: ProcessTrace,
  type: MissionTraceTriggerDisplayFilter.Production | MissionTraceTriggerDisplayFilter.Manual,
  selectedMissionTrace: MissionTrace,
  fleetEntities: Dictionary<FleetDto>
): ProcessTrace {
  return {
    ...processTrace,
    type: type,
    processChainName: selectedMissionTrace.processChainName,
    processChainTraceId: selectedMissionTrace.processChainTraceId,
    fleetId: selectedMissionTrace.fleetId,
    fleetName: selectedMissionTrace.fleetId
      ? getFleetName(selectedMissionTrace.fleetId, fleetEntities)
      : undefined,

    materialNumber: selectedMissionTrace.materialNumber,
    source: selectedMissionTrace.source,
    destination: selectedMissionTrace.destination,
    latestDeliveryTime: selectedMissionTrace.provisioningTime,
  };
}

function createProcessTraceForWaitingQueue(
  processTrace: ProcessTrace,
  type: MissionTraceTriggerDisplayFilter.WaitingQueue,
  selectedMissionTrace: MissionTrace,
  fleetEntities: Dictionary<FleetDto>
): WaitingQueue {
  return {
    ...processTrace,
    type: type,
    fleetId: selectedMissionTrace.fleetId,
    fleetName: selectedMissionTrace.fleetId
      ? getFleetName(selectedMissionTrace.fleetId, fleetEntities)
      : undefined,
  };
}

export function getFleetName(
  fleetId: GuidString = '',
  fleetEntities: Dictionary<FleetDto>
): string | undefined {
  return fleetEntities[String(fleetId)]?.name;
}

export function getAssignableWaypointName(
  assignableWaypointId: GuidString | null = '',
  poiEntities: Dictionary<PoiDto>
): string | undefined {
  if (assignableWaypointId) {
    return poiEntities[String(assignableWaypointId)]?.name;
  }

  return undefined;
}

export function reduceReason(source: FailureCommentDto): FailureCommentDto {
  if (source.missionFailureReasonId != null) {
    return {
      missionFailureReasonId: source.missionFailureReasonId,
      missionFailureReasonComments: source.missionFailureReasonComments,
      missionFailureMinutesToSolve: source.missionFailureMinutesToSolve,
      missionFailureMinutesForEmergencyProcess:
        source.missionFailureMinutesForEmergencyProcess === undefined
          ? null
          : source.missionFailureMinutesForEmergencyProcess,

      durationOfMissionInFailed: source.durationOfMissionInFailed,
      missionFailureStartDateTime: source.missionFailureStartDateTime,
      missionFailureEndDateTime: source.missionFailureEndDateTime,
      missionFailureLocationId: source.missionFailureLocationId,
      missionFailureShiftGroupId: source.missionFailureShiftGroupId,
      missionFailureReasonDe: source.missionFailureReasonDe,
      missionFailureReasonEn: source.missionFailureReasonEn,
      vehicleName: source.vehicleName,
      missionName: source.missionName,
    };
  }
  return {
    missionFailureReasonId: null,
    missionFailureReasonComments: null,
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureMinutesToSolve: null,
    durationOfMissionInFailed: null,
    missionFailureStartDateTime: null,
    missionFailureEndDateTime: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    vehicleName: source.vehicleName,
    missionName: source.missionName,
  };
}

export function convertFailureComment(mission: MissionTrace): FailureCommentDto {
  if (mission.failureComment?.missionFailureReasonId !== null) {
    return {
      missionFailureReasonId: mission.failureComment.missionFailureReasonId,
      missionFailureReasonComments: mission.failureComment.missionFailureReasonComments,
      missionFailureMinutesToSolve: mission.failureComment.missionFailureMinutesToSolve,
      missionFailureMinutesForEmergencyProcess:
        mission.failureComment.missionFailureMinutesForEmergencyProcess === undefined
          ? null
          : mission.failureComment.missionFailureMinutesForEmergencyProcess,

      durationOfMissionInFailed: mission.failureComment.durationOfMissionInFailed,
      missionFailureStartDateTime: mission.failureComment.missionFailureStartDateTime,
      missionFailureEndDateTime: mission.failureComment.missionFailureEndDateTime,
      missionFailureLocationId: mission.failureComment.missionFailureLocationId,
      missionFailureShiftGroupId: mission.failureComment.missionFailureShiftGroupId,
      missionFailureReasonDe: mission.failureComment.missionFailureReasonDe,
      missionFailureReasonEn: mission.failureComment.missionFailureReasonEn,
      vehicleName: mission.vehicleName,
      missionName: mission.missionName,
    };
  }
  return {
    missionFailureReasonId: null,
    missionFailureReasonComments: null,
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureMinutesToSolve: null,
    durationOfMissionInFailed: null,
    missionFailureStartDateTime: null,
    missionFailureEndDateTime: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    vehicleName: mission.vehicleName,
    missionName: mission.missionName,
  };
}
