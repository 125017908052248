import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { EMPTY_GUID } from 'core/constants';
import { IpstAlertSettingsV2Dto } from 'core/dtos';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ipst-alertnow-settings-edit',
  templateUrl: './ipst-alertnow-settings-edit.component.html',
  styleUrls: ['./ipst-alertnow-settings-edit.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpstAlertNowSettingsEditComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IpstAlertNowSettingsEditComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowSettingsEditComponent
  implements ControlValueAccessor, OnDestroy, Validator, OnInit
{
  form: FormGroup;
  ipstWorkingAreaSettingV2Dto: IpstAlertSettingsV2Dto = {
    akz: '',
    enabled: false,
    errorLanguage: 0,
    workingAreaId: EMPTY_GUID,
  };
  ngUnsubscribe = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder) {
    this.form = this.createEditForm();
  }

  ngOnInit(): void {
    this.toggleValidatorsBasedOnIpstToggle();
  }

  writeValue(value: IpstAlertSettingsV2Dto): void {
    this.ipstWorkingAreaSettingV2Dto = value;
    this.form.patchValue({
      akz: this.ipstWorkingAreaSettingV2Dto.akz,
      enabled: this.ipstWorkingAreaSettingV2Dto.enabled,
      errorLanguage: this.ipstWorkingAreaSettingV2Dto.errorLanguage.toString(),
    });
  }

  registerOnChange(fn: () => {}): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(fn);
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onTouched = (_value: IpstAlertSettingsV2Dto): void => {};

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    Object.keys(this.form.controls).forEach(key =>
      Object.assign(errors, this.form.get(key)?.errors)
    );

    return this.form.valid ? null : errors;
  }

  createEditForm(): FormGroup {
    return this.formBuilder.group({
      enabled: [false, [Validators.required]],
      akz: ['', [Validators.required]],
      errorLanguage: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleValidatorsBasedOnIpstToggle(): void {
    this.form
      .get('enabled')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((checked: boolean) => {
        if (checked) {
          this.form.get('errorLanguage')?.setValidators(Validators.required);
          this.form.get('akz')?.setValidators(Validators.required);
        } else {
          this.form.get('errorLanguage')?.clearValidators();
          this.form.get('akz')?.clearValidators();
        }
        this.form.get('errorLanguage')?.updateValueAndValidity();
        this.form.get('akz')?.updateValueAndValidity();
      });
  }
}
