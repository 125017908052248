import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GuidString } from 'core/models';
import * as fromVehicleFeatureState from '../reducers/index';
import * as fromFactsheetsReducer from '../reducers/factsheet.reducer';
import { Factsheet } from 'core/dtos';

const selectFactsheetState = createSelector(
  fromVehicleFeatureState.getVehiclesFeatureState,
  fromVehicleFeatureState.getFactsheetState
);

export const selectAllFactsheets = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getAllFactsheets
);

export const selectFactsheetsLoading = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoading
);

export const selectFactsheetsLoaded = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoaded
);

export const selectFactsheetByVehicleId = (
  vehicleId: GuidString
): MemoizedSelector<fromVehicleFeatureState.VehiclesFeatureState, Factsheet[]> =>
  createSelector(selectAllFactsheets, factsheets => {
    return factsheets.filter(fs => fs.vehicleId === vehicleId);
  });
