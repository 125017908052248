import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GuidString } from 'core/models';

import { NodeGroupDto } from 'core/dtos';
import * as NodeGroupActionTypes from '../actions/node-group.actions';

export const featureKey = 'nodeGroups';

export interface NodeGroupState extends EntityState<NodeGroupDto> {
  loading: boolean;
  loaded: boolean;
  selectedNodeGroupId: GuidString;
  errorMessage: string;
  ids: string[];
}

export const nodeGroupAdapter: EntityAdapter<NodeGroupDto> = createEntityAdapter<NodeGroupDto>();

export const initialState: NodeGroupState = nodeGroupAdapter.getInitialState({
  loading: false,
  loaded: false,
  selectedNodeGroupId: '',
  errorMessage: '',
  ids: [],
});

export const nodeGroupReducer = createReducer(
  initialState,
  on(NodeGroupActionTypes.loadNodeGroups, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(NodeGroupActionTypes.loadNodeGroupsSuccess, (state, { nodeGroups }) =>
    nodeGroupAdapter.setAll(nodeGroups, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(
    NodeGroupActionTypes.createNodeGroup,
    NodeGroupActionTypes.updateNodeGroup,
    NodeGroupActionTypes.deleteNodeGroup,
    state => ({
      ...state,
      errorMessage: '',
    })
  ),

  on(NodeGroupActionTypes.createNodeGroupSuccess, (state, { nodeGroup }) =>
    nodeGroupAdapter.addOne(nodeGroup, state)
  ),

  on(NodeGroupActionTypes.updateNodeGroupSuccess, (state, { nodeGroup }) =>
    nodeGroupAdapter.updateOne({ id: nodeGroup.id.toString(), changes: nodeGroup }, state)
  ),

  on(NodeGroupActionTypes.deleteNodeGroupSuccess, (state, { nodeGroup }) =>
    nodeGroupAdapter.removeOne(nodeGroup.id.toString(), state)
  ),

  on(NodeGroupActionTypes.selectNodeGroup, (state, { nodeGroupId }) => ({
    ...state,
    selectedNodeGroupId: nodeGroupId,
  })),

  on(
    NodeGroupActionTypes.loadNodeGroupsFailure,
    NodeGroupActionTypes.createNodeGroupFailure,
    NodeGroupActionTypes.deleteNodeGroupFailure,
    NodeGroupActionTypes.updateNodeGroupFailure,
    NodeGroupActionTypes.loadOccupiedNodesFailure,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      loaded: false,
      errorMessage,
    })
  ),

  on(NodeGroupActionTypes.loadOccupiedNodes, state => ({
    ...state,
    loading: true,
    loaded: false,
  })),

  on(NodeGroupActionTypes.loadOccupiedNodesSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
  }))
);

export function reducer(state: NodeGroupState | undefined, action: Action): NodeGroupState {
  return nodeGroupReducer(state, action);
}

export const { selectEntities, selectAll } = nodeGroupAdapter.getSelectors();
export const getNodeGroups = selectAll;
export const getEntities = selectEntities;
export const getLoading = (state: NodeGroupState): boolean => state.loading;
export const getLoaded = (state: NodeGroupState): boolean => state.loaded;
export const getSelectedNodeGroupId = (state: NodeGroupState): GuidString =>
  state.selectedNodeGroupId;
