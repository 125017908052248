/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadTypeService } from 'core/api-services';

import { ToastService } from 'core/services/toast.service';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { setHasChanges, setIsEditMode } from 'store/actions';
import * as LoadTypeSettingsActions from '../actions/load-type-settings.actions';

@Injectable()
export class LoadTypeSettingsEffects {
  loadJobLoadTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTypeSettingsActions.loadJobLoadTypes),
      concatMap(() =>
        this.loadTypeService.getLoadTypes().pipe(
          map(configurations =>
            LoadTypeSettingsActions.loadJobLoadTypesSuccess({ configurations })
          ),
          catchError(errorMessage =>
            of(LoadTypeSettingsActions.loadJobLoadTypesFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadJobLoadTypesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadTypeSettingsActions.loadJobLoadTypesFailure),
        tap(() => {
          this.toastService.createErrorToast(
            'settings.featureToggles.jobAssignmentSection.jobLoadTypesFailureMessage'
          );
        })
      ),
    { dispatch: false }
  );

  updateLoadType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTypeSettingsActions.updateLoadType),
      concatMap(({ configuration }) =>
        this.loadTypeService.updateLoadType(configuration).pipe(
          map(updatedConfiguration =>
            LoadTypeSettingsActions.updateLoadTypeSuccess({
              configuration: updatedConfiguration,
              notify: true,
            })
          ),
          catchError(errorMessage =>
            of(LoadTypeSettingsActions.updateLoadTypeFailure({ errorMessage }))
          )
        )
      )
    )
  );

  updateLoadTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTypeSettingsActions.updateLoadTypeSuccess),
      filter(({ notify }) => notify),
      tap(() => {
        this.toastService.createSuccessToast('settings.actions.updateLoadTypeSuccess');
      }),
      concatMap(() => [setHasChanges({ hasChanges: false }), setIsEditMode({ isEditMode: false })])
    )
  );

  updateLoadTypeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTypeSettingsActions.updateLoadTypeFailure),
      tap(({ errorMessage }) => {
        this.toastService.createErrorToast(errorMessage);
      }),
      map(() => LoadTypeSettingsActions.loadJobLoadTypes())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly loadTypeService: LoadTypeService
  ) {}
}
