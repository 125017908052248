import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  BaseVehicleMapDataDto,
  DsMapUploadDataRequest,
  VehicleMapDataDownloadRequest,
  VehicleMapDataDownloadResponse,
  VehicleMapDataDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, MapDataUploadStatus } from 'core/models';
import { Observable, catchError, map, of } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class VehicleMapDataService extends TenantHttpClient {
  protected apiUrl = environment.Services.MapManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  loadVehicleMapData(): Observable<VehicleMapDataDto[]> {
    return this.get<VehicleMapDataDto[]>(`${API_SERVICES.MapData}`);
  }

  uploadDsMapData(dsMapUploadData: DsMapUploadDataRequest): Observable<VehicleMapDataDto> {
    const formData = new FormData();
    formData.append('navigationLayerId', dsMapUploadData.navigationLayerId.toString());
    formData.append('name', dsMapUploadData.name);
    formData.append('mapVersion', dsMapUploadData.mapVersion);
    formData.append('map', dsMapUploadData.mapDataFile ?? '');

    return this.requestWithStatus('post', `${API_SERVICES.MapData}/DsMapUpload`, formData).pipe(
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError(error => of(error)),
      map(res => {
        return res.body;
      })
    );
  }

  uploadVehicleMapData(
    fromVehicleId: GuidString,
    vehicleMapData: BaseVehicleMapDataDto
  ): Observable<VehicleMapDataDto> {
    return this.post<VehicleMapDataDto>(
      `${API_SERVICES.Dispatcher}/vehicle/${fromVehicleId}/initiateMapUpload`,
      vehicleMapData
    );
  }

  downloadMultiVehiclesMapData(
    request: VehicleMapDataDownloadRequest
  ): Observable<VehicleMapDataDownloadResponse> {
    return this.post<VehicleMapDataDownloadResponse>(
      `${API_SERVICES.MassCommand}/vehicle/downloadMap`,
      request
    );
  }

  downloadVehicleMapData(request: VehicleMapDataDownloadRequest): Observable<void> {
    const toVehicle = request.vehicleId ?? '';
    return this.post<void>(
      {
        template: '{path}/vehicle/{toVehicle}/updateMap',
        path: API_SERVICES.Dispatcher,
        toVehicle,
      },
      request
    );
  }

  getMapDataFileStatus(vehicleMapDataId: GuidString): Observable<MapDataUploadStatus> {
    return this.get<MapDataUploadStatus>(`${API_SERVICES.MapData}/${vehicleMapDataId}/status`).pipe(
      catchError(() => {
        return of(MapDataUploadStatus.Pending);
      })
    );
  }

  deleteVehicleMapData(vehicleMapData: VehicleMapDataDto): Observable<void> {
    return this.delete<void>(`${API_SERVICES.MapData}/${vehicleMapData.id}`);
  }

  getVehicleIdsByMapData(vehicleMapDataId: GuidString): Observable<GuidString[]> {
    return this.get<GuidString[]>(
      `${API_SERVICES.MapData}/getVehicleIdsByMapData/${vehicleMapDataId}`
    );
  }
}
