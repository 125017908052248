<ds-switch
  data-cy="notificationSwitch"
  *ngIf="errorForwarding && canEdit"
  [(ngModel)]="errorForwarding.sendNotification"
  (ngModelChange)="onToggle()"
  >{{ errorForwarding.sendNotification | enumTranslation : 'switch' }}</ds-switch
>
<ds-icon
  *ngIf="!canEdit"
  size="sm"
  [icon]="errorForwarding?.sendNotification ? 'notification' : 'notification_off'"
  [tone]="errorForwarding?.sendNotification ? 'info' : 'neutral'"></ds-icon
><span
  data-cy="notificationLabel"
  *ngIf="!canEdit"
  class="ms-1x"
  [ngClass]="errorForwarding?.sendNotification ? 'text-info' : 'text-neutral'"
  >{{ errorForwarding?.sendNotification | enumTranslation : 'switch' }}</span
>
