import { GraphNodeShape, GraphNodeType } from 'core/models';
import { MapColors, MapLabelColors, NodeColors } from 'library/styles';
import { RotationStyle } from '../map-item-rotation-container.graphic';

export const SHOW_WAYPOINT_LABELS = true;

export const EdgeStyle = {
  EdgeSize: 0.1,
  EdgeAlpha: 0.6,
  ArrowAlpha: 1,
  ArrowSize: 0.25,
  ArrowSmallSize: 0.15,
  ArrowDistance: 1.5,
};

export const NodeStyle = {
  WaypointSize: 2,
  NodeSize: 3.5,
  NodeAlpha: 0.8,
  NodeScale: 0.05,
  MinScaleFactor: 1.54,
  NodeOutlineAlignment: 2,
  NodeOutlineAlpha: 1,
  NodeOutlineWidth: 0.6,
  NodeSelectionSize: 0.1,
};

export const NodePoiStyle = {
  NodePoiScaleARZ: 0.4,
  NodePoiScale: 0.015,
  NodePoiScale2: 0.025,
  NodePoiAnchor: 0.5,
  NodePoiRotation: 4.71239,
  NodePoiBorderWidth: 0.5,
  NodePoiBorderColorSelected: MapColors.BorderSelected,
  NodePoiFooterBackgroundColor: NodeColors.Footer,
  NodePoiHeaderBackgroundColor: MapColors.Base,
  NodePoiHeaderX: 16.3,
  NodePoiHeaderY: 9.5,
  NodePoiHeaderWidth: 1.7,
  NodePoiHeaderLength: 19,
  NodePoiHeaderRadius: 2,
  NodePoiOutlineX: -17.4,
  NodePoiOutlineY: -10.3,
  NodePoiOutlineWidth: 35.1,
  NodePoiOutlineLength: 20.5,
  NodePoiOutlineRadius: 2.5,
  NodePoiLabelOffset: 0.6,
  NodePoiWidth: 0.3,
};

export const NodePoiBgStyle = {
  NodePoiBgX: 10,
  NodePoiBgY: -10,
  NodePoiBgWidth1: 7.5,
  NodePoiBgWidth2: 2.5,
  NodePoiBgHeight: 20,
  NodePoiBgWidthRadius1: 2,
  NodePoiBgWidthRadius2: 0,
  NodePoiTextColor: MapLabelColors.WhiteText,
  NodePoiTextAlignX: -11.5,
  NodePoiTextAlignY: -6,
  NodePoiScale: 0.1,
};

export const NodeArrowStyle = {
  Offset: 0.35,
  SquareOffset: 0.6,
  Size: 0.14,
  Scale: 10,
  MinScaleFactor: 4,
  MinResolution: 0.1,
  Color: NodeColors.Arrow,
};

export const NodeName = {
  background: MapLabelColors.Background,
  alpha: 0.8,
  padding: 0.02,
  scale: 0.005,
  yOffset: 0.35,
  cornerRadius: 0.05,
};

export const NodeRotation = {
  SquareOffset: 1.2,
};

export const NodeRotationStyle: RotationStyle = {
  iconScale: 150,
  offset: 0.9,
  labelYOffset: -0.17,
  textSize: 0.005,
  opacity: 0.95,
};

export const NodeShape: Record<GraphNodeType, GraphNodeShape> = {
  [GraphNodeType.Waypoint]: GraphNodeShape.SmallRound,
  [GraphNodeType.StoppingPoint]: GraphNodeShape.MediumRound,
  [GraphNodeType.Fueling]: GraphNodeShape.MediumRound,
  [GraphNodeType.ContainerLane]: GraphNodeShape.Rectangle,
  [GraphNodeType.DisposeStation]: GraphNodeShape.Rectangle,
  [GraphNodeType.ContainerStation]: GraphNodeShape.Rectangle,
  [GraphNodeType.ContainerTowerStation]: GraphNodeShape.Rectangle,
  [GraphNodeType.Parking]: GraphNodeShape.RectangleFooter,
  [GraphNodeType.Charging]: GraphNodeShape.MediumRound,
};
