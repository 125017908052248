/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { ArchiveGql } from 'core/api-services';
import { MapMockData } from 'core/api-services/mocks';
import { EMPTY_GUID } from 'core/constants';
import {
  ArchiveMissionHistoryResponse,
  CommentSettingsDto,
  EventTraceDto,
  FailureMissionTrace,
  MissionFailureReasonDto,
  MissionListSignalr,
  MissionTraceDto,
  ODataDto,
  ProcessChainGroupTraceDto,
  ProcessChainTraceDto,
  ProcessChainTraceInGroupDto,
  ShiftGroupDto,
  StepTraceDto,
  TourArchiveDto,
  TourStepDto,
  TourTriggerType,
  TuggerTrainMissionDto,
  isMissionFinalStatus,
} from 'core/dtos';
import { MissionHistoryRow } from 'core/dtos/archived-mission-history.dto';
import { convertBackendStepTypeToFrontEndStepType } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import { mapTourStepDtosToModels } from 'core/helpers/tour-step-dto-to-model.helper';
import { Params } from 'core/http/base-http-client';
import {
  DateString,
  EventType,
  GuidString,
  LoadType,
  Mission,
  MissionDeliveryStatus,
  MissionDisplayHighlightLevel,
  MissionFormat,
  MissionPriorityLevel,
  MissionStatus,
  MissionTrace,
  MissionTraceTrigger,
  MissionTraceTriggerDisplayFilter,
  ProcessChainGroupTraceModel,
  ProcessChainReducedModel,
  ProcessChainTraceModel,
  ProcessChainTraceStatus,
  ProcessTrace,
  StepType,
  StepTypeBackend,
  TourStepUIModel,
  VehicleType,
  WaitingQueue,
} from 'core/models';
import {
  ProcessChainGroupExecutionMode,
  ProcessChainGroupTraceStatus,
} from 'core/models/jobs/process-chain-group.enums';
import {
  MissionListInputModel,
  MissionListInputStepStringModel,
} from 'modules/jobs/mission-monitoring/components/mission-list/mission-list-model';
import { VehiclesTestHelper } from 'modules/vehicles/vehicles-test.helper';
import { convertTourTriggerTypeToMissionTraceTrigger } from 'store-modules/mission-monitoring-store/selectors/mission-trace.selectors.helpers';

export const newDate = new Date().toString();
const missionTraceId: GuidString = 'a44efba7-11a3-45ba-a05b-11f9d8693287';
const vehicleId: GuidString = 'dfcbe822-39b7-4f65-b87f-831397f87b123';
const vehicleId2: GuidString = 'b42fdf06-d7cf-45cd-ab99-08d83ea11ee2';
const dsTuggerVehicleId: GuidString = 'dfcbe822-39b7-4f65-b87f-831397f87678';
const provisioningTime: DateString = '2021-02-02T14:39:48.7997241Z';
const completedTime: DateString = '2021-02-02T20:39:48.7997241Z';
const stepStartTime = '2021-02-04T14:38:01.9838377Z';
const stepStopTime = '2021-02-04T14:38:59.1290833Z';
const missionFailureStartTime: DateString = '2021-02-02T14:39:48.7997241Z';
const missionFailureEndTime: DateString = '2021-02-02T14:40:48.7997241Z';
const missionFailureReasonEn = 'Container discharge: area not completely free (no fault message)';
const missionFailureReasonDe = 'Behälterabgabe: Fläche nicht ganz frei (keine Störmeldung)';
const missionFailureReasonComments = 'Test Mission Failed';
const workAreaId: GuidString = 'aacfe202-6852-4522-865b-76415b58bfdd';
const processChainId: GuidString = 'c9a2512c-2a48-432b-8606-3f6f89d2c92c';
const processChainId2: GuidString = '27806225-a1a9-476e-b4d0-56067d54cede';
const mapId: GuidString = '1214cf10-0313-48a8-afbd-2073b58a778e';
const processChainGroupTraceId = 'process chain group trace id 2';

const WORK_AREA_ID = 'a1ed3a26-46d0-4f1c-a50e-ed7eb5e3c653';
const MISSION_NAME = 'Ad-hoc forklift mission';
const MISSION_ID = 'c6f4f204-2094-48d9-68a9-08dc3dc4b32e';
const VEHILE_ID = '8d031d12-96d1-4e97-9f5a-e6aa20aa0288';
const STEPID_1 = 'd0a9f596-baa3-4ce7-bcf9-ee708e813090';
const STEP_ATTRIBUTE_TEXT_1 = 'Container tower  B3';
const STEPID_2 = '332192cd-f826-4d60-86fc-8bdf17f13a69';
const STEPID_3 = 'fc58038f-7e4e-4a08-a653-d8d451ed3954';
const STEP_ATTRIBUTE_TEXT_3 = 'Container Lane 1';
const STEPID_4 = '64f10109-8114-467b-85cf-5b36aa6292f4';
const STEPID_5 = '3cc7888c-123f-4a07-b696-94d2edf2fca7';
const ASSIGNABLE_WAYPOINT_NAME = 'N_node-charging-1_c3c1fdea-d665-4f7a-a12c-7ad326f66492';

const missionTraces: MissionTraceDto[] = [
  {
    id: missionTraceId,
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.Failed,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepTypeBackend.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        succeeded: false,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    trigger: MissionTraceTrigger.JobInstruction,
    materialNumber: '',
    processChainTraceId: '1',
    processChainId: EMPTY_GUID,
    processChainName: 'name 1',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    fleetId: VehiclesTestHelper.getFleetDto().id,
    mapId: MapMockData.getMap().id,
    destination: 'xxx',
    source: 'zzz',
    eventTraces: [],
    interlockDetails: [],
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 0,
    isMaxExecutionTimeActive: false,
    canAbort: true,
    canRetry: true,
    errorHandlingAllowed: true,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: false,
    currentStepType: StepTypeBackend.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693281',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.InProgress,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 0,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        attributeText: 'Park 04',
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '2',
    processChainName: 'name 2',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    eventTraces: [],
    interlockDetails: [],
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: false,
    currentStepType: StepTypeBackend.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693282',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.Aborted,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 0,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        attributeText: 'Park 04',
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    eventTraces: [],
    interlockDetails: [],
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 0,
    isMaxExecutionTimeActive: false,
    canAbort: false,
    canRetry: false,
    errorHandlingAllowed: false,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: false,
    currentStepType: StepTypeBackend.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
];

const missionListInput: MissionListInputModel = {
  id: '978360b7-4b9f-487f-a9f0-8f1e7ae837a0',
  missionId: EMPTY_GUID,
  missionName: 'SystemInstruction_Goto',
  processChainId: '',
  processChainTraceId: '',
  processChainName: '',
  materialNumber: 'AAAA',
  status: MissionStatus.Completed,
  statusEnum: MissionStatus.Completed,
  vehicleId: 'd778925f-230d-4d3b-46f9-08d7be8a07d3',
  vehicleName: 'HMBOT0',
  vehicleType: VehicleType.UnitLoad,
  isVehiclePaused: false,
  trigger: MissionTraceTriggerDisplayFilter.Production,
  missionTraceTrigger: MissionTraceTrigger.JobInstruction,
  deliveryStatus: MissionDeliveryStatus.Normal,
  stepNr: '[1/1]',
  step: StepType.Goto,
  stepDetail: '18',
  createdDateTime: newDate,
  failureComment: [
    {
      missionFailureReasonId: '',
      missionFailureReasonComments: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionName: 'SystemInstruction_Goto',
      vehicleName: 'HMBOT0',
    },
  ],
  hasManualSupport: false,
  completedDateTime: completedTime,
  provisioningTime: provisioningTime,
  canAbort: false,
  canRetry: false,
  errorHandlingAllowed: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  missionFormat: MissionFormat.ROS,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndTime: '',
  loadType: LoadType.Glt1400X1250,
};

const missionTraceHistoryOData: ODataDto<ArchiveMissionHistoryResponse> = {
  '@odata.count': 100,
  odata: undefined,
  value: [
    {
      id: '978360b7-4b9f-487f-a9f0-8f1e7ae837a0',
      hasManualSupport: false,
      materialNumber: 'AAAA',
      missionFailureMinutesForEmergencyProcess: 0,
      missionFailureMinutesToSolve: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionId: EMPTY_GUID,
      missionName: 'SystemInstruction_Goto',
      processChainName: '',
      processChainId: '',
      processChainTraceId: '',
      trigger: 'JobInstruction',
      status: MissionStatus.Completed,
      totalSteps: 1,
      successfulSteps: 1,
      vehicleId: 'd778925f-230d-4d3b-46f9-08d7be8a07d3',
      vehicleName: 'HMBOT0',
      vehicleType: VehicleType.UnitLoad,
      workAreaId: workAreaId,
      lastStepType: 'Goto',
      lastStepDetail: '18',
      createdDateTime: newDate,
      completedDateTime: completedTime,
      mapId: MapMockData.getMap().id,
      provisioningTime: provisioningTime,
    },
    {
      id: '978360b7-4b9f-487f-a9f0-8f1e7ae83123',
      createdDateTime: newDate,
      successfulSteps: 1,
      hasManualSupport: false,
      materialNumber: '',
      missionFailureMinutesForEmergencyProcess: 0,
      missionFailureMinutesToSolve: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionId: EMPTY_GUID,
      missionName: 'SystemInstruction_Charge',
      processChainName: '',
      processChainId: EMPTY_GUID,
      processChainTraceId: '',
      trigger: 'SystemParkingInstruction',
      status: MissionStatus.Completed,
      totalSteps: 1,
      vehicleId: vehicleId,
      vehicleName: 'HMBOT1',
      vehicleType: VehicleType.UnitLoad,
      workAreaId: workAreaId,

      lastStepType: 'Goto',
      lastStepDetail: '18',
      completedDateTime: completedTime,
      mapId: MapMockData.getMap().id,
      provisioningTime: provisioningTime,
    },
    {
      id: '978360b7-4b9f-487f-a9f0-8f1e7ae83124',
      createdDateTime: newDate,
      successfulSteps: 1,
      hasManualSupport: true,
      materialNumber: '',
      missionFailureMinutesForEmergencyProcess: 0,
      missionFailureMinutesToSolve: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionId: EMPTY_GUID,
      missionName: 'SystemInstruction_Charge',
      processChainName: '',
      processChainId: EMPTY_GUID,
      processChainTraceId: '',
      trigger: 'SystemParkingInstruction',
      status: MissionStatus.Completed,
      totalSteps: 1,
      vehicleId: vehicleId,
      vehicleName: 'HMBOT1',
      vehicleType: VehicleType.UnitLoad,
      workAreaId: workAreaId,

      lastStepType: 'Goto',
      lastStepDetail: '18',
      completedDateTime: completedTime,
      mapId: MapMockData.getMap().id,
      provisioningTime: provisioningTime,
    },
  ],
};

const missionHistoryRows: MissionHistoryRow[] = [
  {
    id: '978360b7-4b9f-487f-a9f0-8f1e7ae837a0',
    hasManualSupport: false,
    materialNumber: 'AAAA',
    missionFailureMinutesForEmergencyProcess: 0,
    missionFailureMinutesToSolve: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
    missionFailureReasonId: '',
    missionId: EMPTY_GUID,
    missionName: 'SystemInstruction_Goto',
    processChainName: '',
    processChainTraceId: '',
    triggerSource: 'JobInstruction',
    status: MissionStatus.Completed,
    totalSteps: 1,
    successfulSteps: 1,
    vehicleId: 'd778925f-230d-4d3b-46f9-08d7be8a07d3',
    vehicleName: 'HMBOT0',
    workAreaId: workAreaId,
    lastStepType: 'Goto',
    lastStepDetail: '18',
    createdUtc: newDate,
    completedUtc: completedTime,
    mapId: MapMockData.getMap().id,
    provisioningTimeUtc: provisioningTime,
  },
  {
    id: '978360b7-4b9f-487f-a9f0-8f1e7ae83123',
    createdUtc: newDate,
    successfulSteps: 1,
    hasManualSupport: false,
    materialNumber: '',
    missionFailureMinutesForEmergencyProcess: 0,
    missionFailureMinutesToSolve: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
    missionFailureReasonId: '',
    missionId: EMPTY_GUID,
    missionName: 'SystemInstruction_Charge',
    processChainName: '',
    processChainTraceId: '',
    triggerSource: 'SystemParkingInstruction',
    status: MissionStatus.Completed,
    totalSteps: 1,
    vehicleId: vehicleId,
    vehicleName: 'HMBOT1',
    workAreaId: workAreaId,
    lastStepType: 'Goto',
    lastStepDetail: '18',
    completedUtc: completedTime,
    mapId: MapMockData.getMap().id,
    provisioningTimeUtc: provisioningTime,
  },
  {
    id: '978360b7-4b9f-487f-a9f0-8f1e7ae83124',
    createdUtc: newDate,
    successfulSteps: 1,
    hasManualSupport: true,
    materialNumber: '',
    missionFailureMinutesForEmergencyProcess: 0,
    missionFailureMinutesToSolve: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
    missionFailureReasonId: '',
    missionId: EMPTY_GUID,
    missionName: 'SystemInstruction_Charge',
    processChainName: '',
    processChainTraceId: '',
    triggerSource: 'SystemParkingInstruction',
    status: MissionStatus.Completed,
    totalSteps: 1,
    vehicleId: vehicleId,
    vehicleName: 'HMBOT1',
    workAreaId: workAreaId,
    lastStepType: 'Goto',
    lastStepDetail: '18',
    completedUtc: completedTime,
    mapId: MapMockData.getMap().id,
    provisioningTimeUtc: provisioningTime,
  },
  {
    id: '978360b7-4b9f-487f-a9f0-8f1e7ae83125',
    createdUtc: newDate,
    successfulSteps: 1,
    hasManualSupport: true,
    materialNumber: '',
    missionFailureMinutesForEmergencyProcess: 0,
    missionFailureMinutesToSolve: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
    missionFailureReasonId: '',
    missionId: EMPTY_GUID,
    missionName: 'SystemInstruction_Charge',
    processChainName: '',
    processChainTraceId: '',
    triggerSource: 'SystemParkingInstruction',
    status: MissionStatus.Completed,
    totalSteps: 1,
    vehicleId: vehicleId,
    vehicleName: 'HMBOT1',
    workAreaId: workAreaId,
    lastStepType: 'Goto',
    lastStepDetail: '18',
    completedUtc: completedTime,
    mapId: MapMockData.getMap().id,
    provisioningTimeUtc: provisioningTime,
  },
];

const dto: MissionTraceDto = {
  id: missionTraceId,
  missionId: EMPTY_GUID,
  vehicleId: vehicleId,
  vehicleName: 'DAVID_EMUBOT2',
  missionName: 'StartToCharge',
  vehicleType: VehicleType.UnitLoad,
  status: MissionStatus.Failed,
  currentStepAttributeText: 'Park 04',
  currentStepSequenceNumber: 0,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  stepTraces: [
    {
      sourceStep: {
        pointOfInterestName: 'Park 04',
        sequenceNumber: 0,
        type: StepTypeBackend.Goto,
        hasStepCompletionNotificationEnabled: false,
        pointOfInterestGroupId: '',
        trajectory: [],
      },
      succeeded: false,
      startedDateTime: stepStartTime,
      stoppedDateTime: stepStopTime,
      attributeText: 'Park 04',
    },
  ],
  missionStepNo: '1/5',
  createdDateTime: newDate,
  updatedDateTime: newDate,
  provisioningTime: newDate,
  forecastedEndTime: '',
  trigger: MissionTraceTrigger.JobInstruction,
  materialNumber: '',
  processChainId: EMPTY_GUID,
  processChainTraceId: '1',
  processChainName: 'name 1',
  failureComment: {
    missionFailureReasonId: '',
    missionFailureReasonEn: '',
    missionFailureReasonDe: '',
    missionFailureMinutesToSolve: 0,
    missionFailureMinutesForEmergencyProcess: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: 'test',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
  },
  fleetId: VehiclesTestHelper.getFleetDto().id,
  mapId: MapMockData.getMap().id,
  destination: 'xxx',
  source: 'zzz',
  assignableWaypointId: 'e33f644f-4903-4a55-9111-ff789ee78861',
  canBeReAssigned: true,
  canBeContinuedFromStep: true,
  deliveryStatus: MissionDeliveryStatus.NotUrgent,
  maxExecutionTime: 0,
  isMaxExecutionTimeActive: false,
  canAbort: true,
  canRetry: true,
  errorHandlingAllowed: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
  assignmentConditions: [],
  stepCount: 1,
  isAssignedToVehicle: true,
  currentStepType: StepTypeBackend.Goto,
  tourSteps: [],
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  eventTraces: [],
  interlockDetails: [],
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
};

const missionTraceModels: MissionTrace[] = [
  {
    id: missionTraceId,
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.Failed,
    currentStepAttributeText: 'Park 04',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepType.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.JobInstruction,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '1',
    processChainName: 'name 1',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: 'test',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    fleetId: VehiclesTestHelper.getFleetDto().id,
    mapId: MapMockData.getMap().id,
    destination: 'xxx',
    source: 'zzz',
    hasManualSupport: false,
    assignableWaypointId: 'e33f644f-4903-4a55-9111-ff789ee78861',
    assignableWaypointName: 'AssignablePoi1',
    canBeReAssigned: true,
    canBeContinuedFromStep: true,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 0,
    isMaxExecutionTimeActive: false,
    canAbort: true,
    canRetry: true,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    tourSteps: [],
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693281',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.InProgress,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '2',
    processChainName: 'name 2',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    hasManualSupport: false,
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693282',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 5,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: false,
    assignableWaypointId: 'poi-id-15',
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    currentStepType: StepTypeBackend.Goto,
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693288',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    vehicleType: VehicleType.UnitLoad,
    missionName: 'Not the last mission and without provisioning time',
    status: 6,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: '',
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: true,
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693272',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 6,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: true,
    assignableWaypointId: null,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-111111111111',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 3,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: false,
    assignableWaypointId: null,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    errorHandlingAllowed: false,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    tourChainId: null,
    tourChainName: null,
    loadType: LoadType.Glt1400X1250,
  },
];

const missionTraceModelsForVehicleSelectors: MissionTrace[] = [
  {
    id: missionTraceId,
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.Failed,
    currentStepAttributeText: 'Park 04',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: StepType.Goto,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.JobInstruction,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '1',
    processChainName: 'name 1',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: 'test',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    fleetId: VehiclesTestHelper.getFleetDto().id,
    mapId: MapMockData.getMap().id,
    destination: 'xxx',
    source: 'zzz',
    hasManualSupport: false,
    assignableWaypointId: 'e33f644f-4903-4a55-9111-ff789ee78861',
    assignableWaypointName: 'AssignablePoi1',
    canBeReAssigned: true,
    canBeContinuedFromStep: true,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 0,
    isMaxExecutionTimeActive: false,
    canAbort: true,
    canRetry: true,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    tourSteps: [],
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693281',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: MissionStatus.InProgress,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '2',
    processChainName: 'name 2',
    failureComment: {
      missionFailureReasonId: '',
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: '',
    },
    hasManualSupport: false,
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693282',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 5,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: false,
    assignableWaypointId: 'poi-id-15',
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    currentStepType: StepTypeBackend.Goto,
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693288',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    vehicleType: VehicleType.UnitLoad,
    missionName: 'Not the last mission and without provisioning time',
    status: 6,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: '',
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: true,
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693272',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 6,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: true,
    assignableWaypointId: null,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    canBeReAssigned: false,
    errorHandlingAllowed: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'a44efba7-11a3-45ba-a05b-111111111111',
    missionId: EMPTY_GUID,
    vehicleId: vehicleId2,
    vehicleName: 'DAVID_EMUBOT2',
    missionName: 'StartToCharge',
    vehicleType: VehicleType.UnitLoad,
    status: 3,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestName: 'Park 04',
          sequenceNumber: 0,
          type: 100,
          hasStepCompletionNotificationEnabled: false,
          pointOfInterestGroupId: '',
          trajectory: [],
        },
        succeeded: false,
        startedDateTime: stepStartTime,
        stoppedDateTime: stepStopTime,
        attributeText: 'Park 04',
      },
    ],
    missionStepNo: '1/5',
    createdDateTime: newDate,
    completedDateTime: newDate,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: '',
    trigger: MissionTraceTrigger.AdhocInstructionFromStep,
    materialNumber: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: '3',
    processChainName: 'name 3',
    failureComment: {
      missionFailureReasonId: 'xxxxxx',
      missionFailureReasonEn: missionFailureReasonEn,
      missionFailureReasonDe: missionFailureReasonDe,
      missionFailureMinutesToSolve: 0,
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionFailureReasonComments: missionFailureReasonComments,
    },
    hasManualSupport: false,
    assignableWaypointId: null,
    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    maxExecutionTime: 25,
    isMaxExecutionTimeActive: true,
    canAbort: false,
    canRetry: false,
    errorHandlingAllowed: false,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: true,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.ROS,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
];

const processChainTraceModels: ProcessChainTraceModel[] = [
  {
    createdDateTime: newDate,
    dedupIdentifier: '',
    destination: 'dst 1',
    id: '1',
    materialNumber: 'mat 1',
    missionTraces: [missionTraceModels[0]],
    name: 'name 1',
    processChainId: '4464f2cb-f761-4c3c-a025-214fc2b4fe8e',
    source: 'src 1',
    status: ProcessChainTraceStatus.Active,
    updatedDateTime: newDate,
    workAreaId: 'workArea 1',
  },
  {
    createdDateTime: newDate,
    dedupIdentifier: '',
    destination: 'dst 2',
    id: '2',
    materialNumber: 'mat 2',
    missionTraces: [missionTraceModels[1]],
    name: 'name 2',
    processChainId: 'bdadafc2-89fe-4e89-a628-a596bad7b3f3',
    source: 'src 2',
    status: ProcessChainTraceStatus.Active,
    updatedDateTime: newDate,
    workAreaId: 'workArea 1',
  },
];

const jobInstructionProcessTrace: ProcessTrace = {
  type: MissionTraceTriggerDisplayFilter.Production,
  createdDateTime: missionTraces[0].createdDateTime,
  mapId: MapMockData.getMap().id,
  mapName: MapMockData.getMap().name,
  missionTraces: [missionTraceModels[0]],
  processChainName: missionTraces[0].processChainName,
  processChainTraceId: missionTraces[0].processChainTraceId,
  fleetId: VehiclesTestHelper.getFleetDto().id,
  fleetName: VehiclesTestHelper.getFleetDto().name,
  materialNumber: missionTraces[0].materialNumber,
  source: missionTraces[0].source,
  destination: missionTraces[0].destination,
  latestDeliveryTime: missionTraces[0].provisioningTime ?? '',
  loadType: missionTraces[0].loadType,
};

const adHocStepProcessTrace: ProcessTrace = {
  type: MissionTraceTriggerDisplayFilter.Manual,
  createdDateTime: missionTraceModels[1].createdDateTime,
  mapId: undefined,
  mapName: undefined,
  missionTraces: [missionTraceModels[1]],
  loadType: missionTraceModels[1].loadType,
};

const waitingQueueProcessTrace: WaitingQueue = {
  type: MissionTraceTriggerDisplayFilter.WaitingQueue,
  createdDateTime: missionTraceModels[1].createdDateTime,
  mapId: undefined,
  mapName: undefined,
  missionTraces: [missionTraceModels[1]],
  fleetId: VehiclesTestHelper.getFleetDto().id,
  fleetName: VehiclesTestHelper.getFleetDto().name,
  loadType: missionTraceModels[1].loadType,
};

const missionFailureReasons: MissionFailureReasonDto[] = [
  {
    failureCommentCategory: 3,
    descriptionDe: 'DE Reason1 - Fahrzeug',
    descriptionEn: 'EN Reason1 - Vehicle',
    id: 'c37a1514-20e7-4e5b-a047-c106e42e077a',
    isCustom: true,
    isActive: 1,
    vehicleType: 0,
  },
  {
    failureCommentCategory: 1,
    descriptionDe: 'DE Reason2 - Organisatorische',
    descriptionEn: 'EN Reason2 - Organizational',
    id: '234c7e6c-f5ab-4871-816e-95a67f4ecbb9',
    isCustom: true,
    isActive: 1,
    vehicleType: 0,
  },
];

const processChainTraces: ProcessChainTraceDto[] = [
  {
    status: ProcessChainTraceStatus.Active,
    createdDateTime: newDate,
    dedupIdentifier: 'dedupId1',
    destination: 'dest1',
    eventTraces: [],
    id: '32806983-156d-415b-9a6b-a4a9b258393a',
    materialNumber: 'mat1',
    missionTraces: [
      {
        ...missionTraces[0],
        processChainName: 'ProcessChainTrace_1',
        processChainTraceId: '32806983-156d-415b-9a6b-a4a9b258393a',
      },
    ],
    name: 'ProcessChainTrace_1',
    processChainId: 'b7ad857c-0920-4dea-b6d4-c99fe55800c1',
    source: 'src1',
    updatedDateTime: newDate,
    workAreaId: '81ed2e19-c39a-4cb8-808c-7ce31e95fa81',
  },
];

const eventTraceAma: EventTraceDto = {
  deviceId: 'DP220',
  eventId: '1aec7459-64ef-4b9e-87c3-984c15df1821',
  id: '52ca76a7-a29e-4f8a-aab7-c4034b1a50e4',
  isSignaled: false,
  name: 'Wait for receiving destionation: DP220',
  type: EventType.Process,
};

const stepTraceDtoAma1: StepTraceDto = {
  succeeded: false,
  startedDateTime: stepStartTime,
  stoppedDateTime: stepStopTime,
  sourceStep: {
    type: StepTypeBackend.Goto,
    pointOfInterestGroupId: '4fa29d13-1ff1-4fc7-a785-037f130227f7',
    pointOfInterestName: 'SomePoi',
    sequenceNumber: 0,
    trajectory: [],
    hasStepCompletionNotificationEnabled: false,
  },
  attributeText: 'SomePoi',
};

const stepTraceDtoAma2: StepTraceDto = {
  succeeded: false,
  startedDateTime: stepStartTime,
  stoppedDateTime: stepStopTime,
  sourceStep: {
    type: StepTypeBackend.WaitForEvent,
    eventId: eventTraceAma.eventId,
    eventName: eventTraceAma.name,
    sequenceNumber: 1,
  },
  attributeText: '',
};

const missionTraceDtoAma: MissionTraceDto = {
  currentStepType: StepTypeBackend.Goto,
  createdDateTime: newDate,
  currentStepAttributeText: '',
  currentStepSequenceNumber: 1,
  eventTraces: [eventTraceAma],
  id: '3dfee1ea-d557-406c-9e45-381ee9159513',
  materialNumber: 'Mat_Ama',
  failureComment: {
    missionFailureMinutesToSolve: 0,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
    missionFailureReasonComments: '',
    missionFailureReasonDe: '',
    missionFailureReasonEn: '',
    missionFailureReasonId: '',
    missionFailureErrorClass: 'Error',
    missionFailureMinutesForEmergencyProcess: 2,
    missionFailureReasonDate: newDate,
  },
  missionId: '8fb0a0c0-e2fc-4b63-a04d-9ec8806697fe',
  missionName: 'AMA Mission',
  missionStepNo: '1/2',
  processChainName: 'AMA Process Chain',
  processChainTraceId: '',
  processChainId: EMPTY_GUID,
  status: MissionStatus.InProgress,
  stepTraces: [stepTraceDtoAma1, stepTraceDtoAma2],
  trigger: MissionTraceTrigger.JobInstruction,
  updatedDateTime: newDate,
  vehicleId: 'b01ece8b-fa9f-4a34-ba81-d3304a188a9e',
  vehicleName: 'AMA Vehicle',
  vehicleType: VehicleType.UnitLoad,
  destination: 'Dest_Ama',
  fleetId: '4d8b7e1b-a091-48a9-937b-ec91f15781d4',
  mapId: 'ae629c3e-17e3-411b-89ee-55aa3fd86f92',
  missionStep: '1',
  provisioningTime: newDate,
  forecastedEndTime: '2020-07-31T15:43:24.6975014Z',
  source: 'Src_Ama',
  interlockDetails: [],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  deliveryStatus: MissionDeliveryStatus.NotUrgent,
  maxExecutionTime: 25,
  isMaxExecutionTimeActive: true,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  canBeReAssigned: false,
  canBeContinuedFromStep: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
  assignmentConditions: [],
  stepCount: 2,
  isAssignedToVehicle: false,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
};

const processChainTraceDtoAma: ProcessChainTraceDto = {
  status: ProcessChainTraceStatus.Active,
  createdDateTime: newDate,
  dedupIdentifier: '16faf8eb-4e55-476f-a59f-8f0e17ac672d',
  destination: 'Dest_Ama',
  eventTraces: [eventTraceAma],
  id: '4bf7e1fb-49dc-4556-8959-99922492464e',
  materialNumber: 'Mat_Ama',
  missionTraces: [missionTraceDtoAma],
  name: 'AMA Process Chain',
  processChainId: 'b7ad857c-0920-4dea-b6d4-c99fe55800c1',
  source: 'Src_Ama',
  updatedDateTime: newDate,
  workAreaId: '81ed2e19-c39a-4cb8-808c-7ce31e95fa81',
};

const missionTraceModelAma: MissionTrace = {
  createdDateTime: missionTraceDtoAma.createdDateTime,
  completedDateTime: newDate,
  currentStepAttributeText: missionTraceDtoAma.currentStepAttributeText,
  currentStepSequenceNumber: missionTraceDtoAma.currentStepSequenceNumber,
  id: missionTraceDtoAma.id,
  materialNumber: missionTraceDtoAma.materialNumber,
  failureComment: missionTraceDtoAma.failureComment,
  missionId: missionTraceDtoAma.missionId,
  missionName: missionTraceDtoAma.missionName,
  missionStepNo: missionTraceDtoAma.missionStepNo,
  processChainName: missionTraceDtoAma.processChainName,
  processChainId: missionTraceDtoAma.processChainId,
  processChainTraceId: missionTraceDtoAma.processChainTraceId,
  status: missionTraceDtoAma.status,
  hasManualSupport: false,
  stepTraces: [
    {
      succeeded: false,
      startedDateTime: stepStartTime,
      stoppedDateTime: stepStopTime,
      sourceStep: {
        type: StepType.Goto,
        pointOfInterestGroupId: '4fa29d13-1ff1-4fc7-a785-037f130227f7',
        pointOfInterestName: 'SomePoi',
        sequenceNumber: 0,
        trajectory: [],
        hasStepCompletionNotificationEnabled: false,
      },
      attributeText: 'SomePoi',
    },
    {
      succeeded: false,
      startedDateTime: stepStartTime,
      stoppedDateTime: stepStopTime,
      sourceStep: {
        type: StepType.WaitForEndOfStep,
        sequenceNumber: 1,
        waitForStep: {
          missionIndex: 0,
          sequenceNumber: 0,
          missionId: undefined,
          stepAttribute: undefined,
          stepMission: undefined,
          stepType: undefined,
        },
        waitForStepId: '0-0',
      },
      attributeText: '',
    },
  ],
  trigger: missionTraceDtoAma.trigger,
  updatedDateTime: missionTraceDtoAma.updatedDateTime,
  vehicleId: missionTraceDtoAma.vehicleId,
  vehicleName: missionTraceDtoAma.vehicleName,
  vehicleType: missionTraceDtoAma.vehicleType,
  destination: missionTraceDtoAma.destination,
  fleetId: missionTraceDtoAma.fleetId,
  mapId: missionTraceDtoAma.mapId,

  missionStep: missionTraceDtoAma.missionStep,
  provisioningTime: missionTraceDtoAma.provisioningTime ?? '',
  forecastedEndTime: missionTraceDtoAma.forecastedEndTime ?? '',
  source: missionTraceDtoAma.source,
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  deliveryStatus: MissionDeliveryStatus.NotUrgent,
  maxExecutionTime: 25,
  isMaxExecutionTimeActive: true,
  canAbort: missionTraceDtoAma.canAbort,
  canRetry: missionTraceDtoAma.canRetry,
  errorHandlingAllowed: missionTraceDtoAma.errorHandlingAllowed,
  canBeReAssigned: missionTraceDtoAma.canBeReAssigned,
  canBeContinuedFromStep: missionTraceDtoAma.canBeContinuedFromStep,
  missionDisplayHighlightLevel: missionTraceDtoAma.missionDisplayHighlightLevel,
  assignmentConditions: missionTraceDtoAma.assignmentConditions,
  stepCount: missionTraceDtoAma.stepCount,
  isAssignedToVehicle: missionTraceDtoAma.isAssignedToVehicle,
  currentStepType: StepTypeBackend.Goto,
  currentStepTypeFrontEnd: StepType.Goto,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
};

const processChainTraceModelAma: ProcessChainTraceModel = {
  createdDateTime: processChainTraceDtoAma.createdDateTime,
  dedupIdentifier: processChainTraceDtoAma.dedupIdentifier,
  destination: processChainTraceDtoAma.destination,
  id: processChainTraceDtoAma.id,
  materialNumber: processChainTraceDtoAma.materialNumber,
  missionTraces: [missionTraceModelAma],
  name: processChainTraceDtoAma.name,
  processChainId: processChainTraceDtoAma.processChainId,
  source: processChainTraceDtoAma.source,
  status: processChainTraceDtoAma.status,
  updatedDateTime: processChainTraceDtoAma.updatedDateTime,
  workAreaId: processChainTraceDtoAma.workAreaId,
};

const interlockProcessChainName = 'Interlock PC';
const processChainTraceInterlock: ProcessChainTraceModel = {
  createdDateTime: newDate,
  dedupIdentifier: '',
  destination: '',
  id: '',
  materialNumber: '',
  missionTraces: [
    {
      currentStepType: StepTypeBackend.Goto,
      currentStepTypeFrontEnd: StepType.Goto,
      createdDateTime: newDate,
      completedDateTime: newDate,
      currentStepAttributeText: '',
      currentStepSequenceNumber: 0,
      id: 'missionTraceId',
      materialNumber: '',
      failureComment: {
        missionFailureMinutesToSolve: 0,
        missionFailureReasonComments: '',
        durationOfMissionInFailed: 0,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionFailureReasonDe: '',
        missionFailureReasonEn: '',
        missionFailureReasonId: '',
        missionFailureMinutesForEmergencyProcess: null,
      },
      missionId: 'missionId1',
      missionName: 'Mission A',
      missionStepNo: '1/2',
      processChainName: interlockProcessChainName,
      processChainId: EMPTY_GUID,
      processChainTraceId: '',
      status: MissionStatus.InProgress,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 5,
      stepTraces: [
        {
          succeeded: false,
          startedDateTime: stepStartTime,
          stoppedDateTime: stepStopTime,
          sourceStep: {
            type: StepType.WaitForEndOfStep,
            sequenceNumber: 0,
            waitForStep: {
              missionIndex: 1,
              sequenceNumber: 0,
              missionId: 'missionId2',
            },
            waitForStepId: '1-0',
          },
          attributeText: '',
        },
      ],
      trigger: MissionTraceTrigger.JobInstruction,
      updatedDateTime: newDate,
      provisioningTime: newDate,
      forecastedEndTime: '2021-02-04T14:41:02.1290833Z',
      vehicleId: '',
      vehicleName: '',
      vehicleType: VehicleType.UnitLoad,
      hasManualSupport: false,
      assignableWaypointId: null,
      deliveryStatus: MissionDeliveryStatus.NotUrgent,
      maxExecutionTime: 25,
      isMaxExecutionTimeActive: true,
      canAbort: true,
      canRetry: false,
      errorHandlingAllowed: true,
      canBeReAssigned: false,
      canBeContinuedFromStep: false,
      missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
      assignmentConditions: [],
      stepCount: 1,
      isAssignedToVehicle: false,
      priorityLevel: MissionPriorityLevel.None,
      assignmentDelayEndDateTime: '',
      missionFormat: MissionFormat.ROS,
      loadType: LoadType.Glt1400X1250,
      tourChainId: null,
      tourChainName: null,
    },
    {
      currentStepTypeFrontEnd: StepType.Goto,
      currentStepType: StepTypeBackend.Goto,
      createdDateTime: newDate,
      completedDateTime: newDate,
      currentStepAttributeText: '',
      currentStepSequenceNumber: 0,
      id: 'missionTraceId2',
      materialNumber: '',
      failureComment: {
        missionFailureMinutesToSolve: 0,
        durationOfMissionInFailed: 0,
        missionFailureMinutesForEmergencyProcess: null,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionFailureReasonComments: '',
        missionFailureReasonDe: '',
        missionFailureReasonEn: '',
        missionFailureReasonId: '',
      },
      missionId: 'missionId2',
      missionName: 'Mission B',
      missionStepNo: '1/1',
      processChainName: interlockProcessChainName,
      processChainId: EMPTY_GUID,
      processChainTraceId: '',
      status: MissionStatus.InProgress,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 5,
      stepTraces: [
        {
          succeeded: false,
          startedDateTime: stepStartTime,
          stoppedDateTime: stepStopTime,
          sourceStep: {
            type: StepType.Goto,
            pointOfInterestGroupId: 'poiGroupId',
            pointOfInterestName: 'Waypoint 7',
            sequenceNumber: 0,
            trajectory: [],
            hasStepCompletionNotificationEnabled: false,
          },
          attributeText: 'Waypoint 7',
        },
      ],
      trigger: MissionTraceTrigger.JobInstruction,
      updatedDateTime: newDate,
      provisioningTime: newDate,
      forecastedEndTime: '2021-02-04T14:41:02.1290833Z',
      vehicleId: '',
      vehicleName: '',
      vehicleType: VehicleType.UnitLoad,
      hasManualSupport: false,
      assignableWaypointId: null,
      deliveryStatus: MissionDeliveryStatus.NotUrgent,
      maxExecutionTime: 25,
      isMaxExecutionTimeActive: true,
      canAbort: true,
      canRetry: false,
      errorHandlingAllowed: true,
      canBeReAssigned: false,
      canBeContinuedFromStep: false,
      missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
      assignmentConditions: [],
      stepCount: 1,
      isAssignedToVehicle: false,
      priorityLevel: MissionPriorityLevel.None,
      assignmentDelayEndDateTime: '',
      missionFormat: MissionFormat.ROS,
      loadType: LoadType.Glt1400X1250,
      tourChainId: null,
      tourChainName: null,
    },
  ],
  name: interlockProcessChainName,
  processChainId: '',
  source: '',
  status: ProcessChainTraceStatus.Active,
  updatedDateTime: newDate,
  workAreaId: '',
};

const processChainTraceNoInterlock: ProcessChainTraceModel = {
  createdDateTime: newDate,
  dedupIdentifier: '',
  destination: 'Dest',
  id: '090fc03f-d6ec-4cae-9715-e889824804b2',
  materialNumber: 'Mat',
  missionTraces: [
    {
      createdDateTime: newDate,
      completedDateTime: newDate,
      currentStepAttributeText: '',
      currentStepSequenceNumber: 0,
      id: missionTraceId,
      materialNumber: 'Mat',
      failureComment: {
        missionFailureMinutesToSolve: 0,
        durationOfMissionInFailed: 0,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionFailureMinutesForEmergencyProcess: null,
        missionFailureReasonComments: '',
        missionFailureReasonDe: '',
        missionFailureReasonEn: '',
        missionFailureReasonId: '',
      },

      missionId: 'e52df967-808e-4664-944e-64e374e05bb0',
      missionName: 'Mission A',
      missionStepNo: '1/2',
      processChainName: interlockProcessChainName,
      processChainId: EMPTY_GUID,
      processChainTraceId: '',
      status: MissionStatus.InProgress,
      isAlarmForLateDeliveryActive: true,
      lateDeliveryAlarmTime: 5,
      stepTraces: [
        {
          succeeded: false,
          startedDateTime: stepStartTime,
          stoppedDateTime: stepStopTime,
          sourceStep: {
            type: StepType.Goto,
            sequenceNumber: 0,
            pointOfInterestGroupId: '1cf9e82a-d403-4bb6-a10f-89175995dc6f',
            pointOfInterestName: 'Test Poi',
            trajectory: [],
            hasStepCompletionNotificationEnabled: false,
          },
          attributeText: 'Test Poi',
        },
      ],
      trigger: MissionTraceTrigger.JobInstruction,
      updatedDateTime: newDate,
      provisioningTime: newDate,
      forecastedEndTime: newDate,
      vehicleId: '',
      vehicleName: '',
      vehicleType: VehicleType.UnitLoad,
      hasManualSupport: false,
      assignableWaypointId: null,
      deliveryStatus: MissionDeliveryStatus.NotUrgent,
      maxExecutionTime: 25,
      isMaxExecutionTimeActive: true,
      canAbort: true,
      canRetry: false,
      errorHandlingAllowed: true,
      canBeReAssigned: false,
      canBeContinuedFromStep: false,
      missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
      assignmentConditions: [],
      stepCount: 1,
      isAssignedToVehicle: false,
      currentStepType: StepTypeBackend.Goto,
      currentStepTypeFrontEnd: StepType.Goto,
      priorityLevel: MissionPriorityLevel.None,
      assignmentDelayEndDateTime: '',
      missionFormat: MissionFormat.ROS,
      loadType: LoadType.Glt1400X1250,
      tourChainId: null,
      tourChainName: null,
    },
  ],
  name: interlockProcessChainName,
  processChainId: '',
  source: '',
  status: ProcessChainTraceStatus.Active,
  updatedDateTime: newDate,
  workAreaId: '',
};

const noInterlockMission: MissionTrace = {
  createdDateTime: newDate,
  completedDateTime: newDate,
  currentStepAttributeText: '',
  currentStepSequenceNumber: 0,
  id: missionTraceId,
  materialNumber: 'Mat',
  failureComment: {
    missionFailureMinutesToSolve: 0,
    missionFailureReasonComments: '',
    missionFailureReasonDe: '',
    missionFailureReasonEn: '',
    missionFailureReasonId: '',
    missionFailureMinutesForEmergencyProcess: null,
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '1',
    missionFailureShiftGroupId: '1',
  },

  missionId: 'e52df967-808e-4664-944e-64e374e05bb0',
  missionName: 'Mission A',
  missionStepNo: '1/2',
  processChainName: interlockProcessChainName,
  processChainId: EMPTY_GUID,
  processChainTraceId: '',
  status: MissionStatus.InProgress,

  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  stepTraces: [
    {
      succeeded: false,
      startedDateTime: stepStartTime,
      stoppedDateTime: stepStopTime,
      sourceStep: {
        type: StepType.Goto,
        sequenceNumber: 0,
        pointOfInterestGroupId: '1cf9e82a-d403-4bb6-a10f-89175995dc6f',
        pointOfInterestName: 'Test Poi',
        trajectory: [],
        hasStepCompletionNotificationEnabled: false,
      },
      attributeText: 'Test Poi',
    },
  ],
  trigger: MissionTraceTrigger.JobInstruction,
  updatedDateTime: newDate,
  provisioningTime: newDate,
  forecastedEndTime: newDate,
  vehicleId: '',
  vehicleName: '',
  vehicleType: VehicleType.UnitLoad,
  hasManualSupport: false,
  assignableWaypointId: null,
  deliveryStatus: MissionDeliveryStatus.NotUrgent,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  canBeReAssigned: false,
  canBeContinuedFromStep: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
  assignmentConditions: [],
  stepCount: 1,
  isAssignedToVehicle: false,
  currentStepType: StepTypeBackend.Goto,
  currentStepTypeFrontEnd: StepType.Goto,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
};

const mission: MissionListInputModel = {
  id: '00a27369-e9b9-43b5-ad22-d10916a80de4',
  missionId: '1d2b1304-4396-485c-ba9a-47015bee8706',
  missionName: 'GotoDollyParallel1',
  failureComment: [
    {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionName: 'GotoDollyParallel1',
      vehicleName: 'ROBOT4',
    },
  ],
  processChainId: 'c9a2512c-2a48-432b-8606-3f6f89dec92c',
  processChainTraceId: 'c9a2512c-2a48-432b-8606-3f6f89dec93c',
  processChainName: 'Test PC',
  materialNumber: 'Material',
  status: MissionStatus.QueuedOnSystem,
  statusEnum: -1,
  vehicleId: 'b4e97bed-7b8a-4e45-3fb5-08d855a50939',
  vehicleName: 'ROBOT4',
  vehicleType: VehicleType.UnitLoad,
  step: StepType.Goto,
  stepDetail: 'DollyParallel1',
  stepNr: '[1/1]',
  trigger: MissionTraceTriggerDisplayFilter.Production,
  missionTraceTrigger: MissionTraceTrigger.JobInstruction,
  createdDateTime: '2021-02-02T14:39:48.7997241Z',
  toolTip: undefined,
  provisioningTime: provisioningTime,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  fleetName: 'Test Name',
  missionFormat: MissionFormat.ROS,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndTime: '',
  loadType: LoadType.Glt1400X1250,
};

const mission2: MissionListInputModel = {
  id: '00a27369-e9b9-43b5-ad22-d99916a80de4',
  missionId: '1d2b2204-4396-485c-ba9a-47015bee8706',
  missionName: 'GotoPOI',
  failureComment: [
    {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionName: 'GotoPOI',
      vehicleName: 'ROBOT4',
    },
  ],
  processChainId: processChainId,
  processChainTraceId: 'c9a2512c-2a48-432b-8606-3f6f89d2c93c',
  processChainName: 'Test PC2',
  materialNumber: 'Material2',
  status: 0,
  statusEnum: 0,
  vehicleId: 'b4e97bed-7b8a-4e45-3fb5-08d855a20939',
  vehicleName: 'ROBOT4',
  vehicleType: VehicleType.UnitLoad,
  step: undefined,
  stepDetail: 'TestPOI',
  stepNr: '[2/2]',
  trigger: MissionTraceTriggerDisplayFilter.Manual,
  missionTraceTrigger: MissionTraceTrigger.AdhocInstructionFromProcessChain,
  toolTip: undefined,
  provisioningTime: provisioningTime,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  missionFormat: MissionFormat.ROS,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndTime: '',
  loadType: LoadType.Glt1400X1250,
};

const mission3: MissionListInputModel = {
  id: '11a27369-e9b9-43b5-ad22-d99916a80df5',
  missionId: '1d2b2204-4396-485c-ba9a-47015bee8706',
  missionName: 'GotoPOI',
  failureComment: [
    {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '',
      missionFailureShiftGroupId: '1',
      missionName: 'GotoPOI',
      vehicleName: 'ROBOT4',
    },
  ],
  processChainId: processChainId,
  processChainTraceId: 'c9a2512c-2a48-432b-8606-3f6f89d2c93c',
  processChainName: 'Test Waiting Queue',
  materialNumber: 'Material2',
  status: MissionStatus.QueuedOnSystem,
  statusEnum: 0,
  vehicleId: '',
  vehicleName: 'ROBOT4',
  vehicleType: VehicleType.UnitLoad,
  fleetName: 'Test Name',
  step: undefined,
  stepDetail: undefined,
  stepNr: '',
  trigger: MissionTraceTriggerDisplayFilter.WaitingQueue,
  missionTraceTrigger: MissionTraceTrigger.SystemWaitingQueueInstruction,
  toolTip: undefined,
  provisioningTime: provisioningTime,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  missionFormat: MissionFormat.ROS,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndTime: '',
  loadType: LoadType.Glt1400X1250,
};

const mission4: MissionListInputModel = {
  id: '11a27369-e9b9-43b5-ad22-d99916a80df6',
  missionId: '1d2b2204-4396-485c-ba9a-47015bee8767',
  missionName: 'GotoChargingStation',
  failureComment: [
    {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: 0,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: missionFailureEndTime,
      missionFailureStartDateTime: missionFailureStartTime,
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
      missionName: 'GotoChargingStation',
      vehicleName: 'ROBOT7',
    },
  ],
  processChainId: processChainId,
  processChainTraceId: 'c9a2512c-2a48-432b-8606-3f6f89d2c93c',
  processChainName: 'Test Waiting Queue',
  materialNumber: 'Material2',
  status: MissionStatus.WaitingForAssignment,
  statusEnum: 0,
  vehicleId: '',
  vehicleName: 'ROBOT7',
  vehicleType: VehicleType.UnitLoad,
  fleetName: 'Test Name',
  step: undefined,
  stepDetail: 'Test',
  stepNr: '[0/2]',
  trigger: MissionTraceTriggerDisplayFilter.Production,
  missionTraceTrigger: MissionTraceTrigger.AdhocInstructionFromProcessChain,
  toolTip: undefined,
  provisioningTime: provisioningTime,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  missionFormat: MissionFormat.ROS,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndTime: '2021-02-02T14:39:48.000',
  loadType: LoadType.Glt1400X1250,
};

const missionListConverted: MissionListInputStepStringModel[] = [
  {
    id: mission.id,
    isSelected: true,
    missionId: mission.missionId,
    missionName: mission.missionName,
    failureComment: [
      {
        missionFailureMinutesToSolve: 0,
        missionFailureReasonComments: '',
        missionFailureReasonId: '',
        missionFailureMinutesForEmergencyProcess: 0,
        durationOfMissionInFailed: 0,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionName: mission.missionName,
        vehicleName: 'ROBOT4',
      },
    ],
    processChainId: 'c9a2512c-2a48-432b-8606-3f6f89dec92c',
    processChainName: 'Test PC',
    materialNumber: 'Material',
    statusEnum: -1,
    status: '[en:enums.MissionStatus.-1]',
    vehicleId: 'b4e97bed-7b8a-4e45-3fb5-08d855a50939',
    vehicleName: 'ROBOT4',
    step: '[1/1] [en:enums.stepTypes.100] [en:DollyParallel1]',
    stepDetail: 'DollyParallel1',
    stepNr: '[1/1]',
    trigger: mission.trigger.toString(),
    createdDateTime: '14:39 (in 36 mins)',
    toolTip: undefined,
    canAbort: true,
    canRetry: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  },
  {
    id: mission2.id,
    missionId: mission2.missionId,
    missionName: mission2.missionName,
    failureComment: [
      {
        missionFailureMinutesToSolve: 0,
        missionFailureReasonComments: '',
        missionFailureReasonId: '',
        missionFailureMinutesForEmergencyProcess: 0,
        durationOfMissionInFailed: 0,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionName: mission2.missionName,
        vehicleName: 'ROBOT4',
      },
    ],
    processChainId: processChainId,
    processChainName: 'Test PC2',
    materialNumber: 'Material2',
    statusEnum: 0,
    status: '[en:enums.MissionStatus.0]',
    vehicleId: 'b4e97bed-7b8a-4e45-3fb5-08d855a20939',
    vehicleName: 'ROBOT4',
    step: '[2/2]',
    stepDetail: 'TestPOI',
    stepNr: '[2/2]',
    trigger: mission2.trigger.toString(),
    toolTip: undefined,
    canAbort: true,
    canRetry: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  },
  {
    id: mission4.id,
    missionId: mission4.missionId,
    missionName: mission4.missionName,
    failureComment: [
      {
        missionFailureMinutesToSolve: 0,
        missionFailureReasonComments: '',
        missionFailureReasonId: '',
        missionFailureMinutesForEmergencyProcess: 0,
        durationOfMissionInFailed: 0,
        missionFailureEndDateTime: missionFailureEndTime,
        missionFailureStartDateTime: missionFailureStartTime,
        missionFailureLocationId: '1',
        missionFailureShiftGroupId: '1',
        missionName: mission4.missionName,
        vehicleName: 'ROBOT7',
      },
    ],
    processChainId: processChainId,
    processChainName: 'Test PC3',
    materialNumber: 'Material3',
    statusEnum: 0,
    status: '[en:enums.MissionStatus.-3] 14:39',
    vehicleId: 'b4e97bed-7b8a-4e45-3fb5-08d855a20944',
    vehicleName: 'ROBOT7',
    step: '[0/2]',
    stepDetail: 'Test',
    stepNr: '[0/2]',
    trigger: mission4.trigger.toString(),
    toolTip: undefined,
    canAbort: true,
    canRetry: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
  },
];

const shiftGroups: ShiftGroupDto[] = [
  {
    name: 'testStr',
    id: '1',
    vehicleType: VehicleType.UnitLoad,
    workAreaId: '1',
  },
  {
    name: 'testStr',
    id: '2',
    vehicleType: VehicleType.UnitLoad,
    workAreaId: '1',
  },
];

const commentLocations: CommentSettingsDto[] = [
  {
    name: 'loc 1',
    id: '1',
    vehicleType: VehicleType.UnitLoad,
    workAreaId: '1',
  },
  {
    name: 'loc 2',
    id: '2',
    vehicleType: VehicleType.UnitLoad,
    workAreaId: '1',
  },
];

const tuggerMissions: TuggerTrainMissionDto[] = [
  {
    name: 'testTugger1',
    id: '1',
    vehicleType: VehicleType.TuggerTrain,
    workAreaId: '1',
  },
  {
    name: 'testTugger2',
    id: '2',
    vehicleType: VehicleType.TuggerTrain,
    workAreaId: '1',
  },
];

export const initMissionTraceDto: MissionTraceDto = {
  id: '',
  missionId: '',
  vehicleId: '',
  vehicleName: '',
  missionName: '',
  vehicleType: VehicleType.UnitLoad,
  status: MissionStatus.QueuedOnSystem,
  currentStepAttributeText: '',
  currentStepSequenceNumber: 0,
  stepTraces: [],
  missionStepNo: '',
  createdDateTime: '',
  updatedDateTime: '',
  forecastedEndTime: '',
  trigger: MissionTraceTrigger.None,
  materialNumber: '',
  processChainName: '',
  processChainId: '',
  processChainTraceId: '',
  eventTraces: [],
  interlockDetails: [],
  failureComment: {
    missionFailureReasonId: null,
    missionFailureReasonComments: null,
    missionFailureMinutesToSolve: null,
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureReasonEn: '',
    missionFailureReasonDe: '',
    durationOfMissionInFailed: 0,
    missionFailureEndDateTime: missionFailureEndTime,
    missionFailureStartDateTime: missionFailureStartTime,
    missionFailureLocationId: '',
    missionFailureShiftGroupId: '1',
  },
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  deliveryStatus: MissionDeliveryStatus.NotUrgent,

  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  canAbort: true,
  canRetry: false,
  errorHandlingAllowed: true,
  canBeReAssigned: false,
  canBeContinuedFromStep: false,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Warning,
  assignmentConditions: [],
  stepCount: 1,
  isAssignedToVehicle: true,
  currentStepType: StepTypeBackend.Goto,
  priorityLevel: MissionPriorityLevel.None,
  assignmentDelayEndDateTime: '',
  missionFormat: MissionFormat.ROS,
  loadType: LoadType.Glt1400X1250,
  tourChainId: null,
  tourChainName: null,
};

const interlockRaisingMission: Mission = {
  id: '334085e8-0c46-4657-8982-33ef9d9e8f7a',
  mapId: mapId,
  processChainId: processChainId2,
  name: 'Interlock_RaisingMission',
  steps: [
    {
      type: StepType.Goto,
      sequenceNumber: 0,
      pointOfInterestGroupId: 'cb04d48a-c755-4efc-95f8-dada7964047f',
      pointOfInterestName: 'Waypoint_1',
      trajectory: [],
      hasStepCompletionNotificationEnabled: false,
    },
  ],
  abortAction: null,
  pauseConditions: [],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const interlockWaitingMission: Mission = {
  id: '3e956de6-fd99-4cb7-a963-e0ba53d13efe',
  mapId: mapId,
  processChainId: processChainId2,
  name: 'Interlock_WaitingMission',
  steps: [
    {
      type: StepType.WaitForEndOfStep,
      sequenceNumber: 0,
      waitForStep: {
        missionIndex: 0,
        sequenceNumber: 0,
      },
      waitForStepId: '0-0',
    },
    {
      type: StepType.Goto,
      sequenceNumber: 1,
      pointOfInterestGroupId: '8942bf90-4ccb-4b21-80b8-9d7f428821a6',
      pointOfInterestName: 'Waypoint_2',
      trajectory: [],
      hasStepCompletionNotificationEnabled: false,
    },
  ],
  abortAction: null,
  pauseConditions: [],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const simpleMission: Mission = {
  id: '3e956de6-fd99-4cb7-a963-e0ba53d13efe',
  mapId: mapId,
  processChainId: processChainId2,
  name: 'Simple_Mission',
  steps: [
    {
      type: StepType.Goto,
      sequenceNumber: 0,
      pointOfInterestGroupId: '8942bf90-4ccb-4b21-80b8-9d7f428821a6',
      pointOfInterestName: 'Waypoint_2',
      trajectory: [],
      hasStepCompletionNotificationEnabled: false,
    },
  ],
  abortAction: {
    device: 'device',
    nodeName: 'nodeName',
    value: 'value',
    stepNumber: 0,
  },
  pauseConditions: [
    {
      device: 'device',
      nodeName: 'nodeName',
      value: 'value',
      stepNumber: 0,
    },
  ],
  assignableWaypointId: null,
  isAlarmForLateDeliveryActive: true,
  lateDeliveryAlarmTime: 5,
  isMaxExecutionTimeActive: false,
  maxExecutionTime: 0,
  isAssignmentDelayTimeActive: false,
  assignmentDelayTime: 0,
};

const processChainTraceInGroupDto1: ProcessChainTraceInGroupDto = {
  id: 'process chain trace 1',
  processChainName: 'process chain 1 name',
  processChainGroupTraceId: 'process chain group trace id 1',
  status: ProcessChainTraceStatus.Active,
};

const processChainTraceInGroupDto2: ProcessChainTraceInGroupDto = {
  id: 'process chain trace 2',
  processChainName: 'process chain 2 name',
  processChainGroupTraceId: processChainGroupTraceId,
  status: ProcessChainTraceStatus.Completed,
};

const processChainTraceInGroupDto3: ProcessChainTraceInGroupDto = {
  id: 'process chain trace 3',
  processChainName: 'process chain 3 name',
  processChainGroupTraceId: processChainGroupTraceId,
  status: ProcessChainTraceStatus.Completed,
};

const processChainGroupTraceDtos: ProcessChainGroupTraceDto[] = [
  {
    id: 'process chain group trace id 1',
    name: 'Process Chain Group 1',
    workAreaId: workAreaId,
    processChainGroupId: 'process chain group 1 id',
    processChainTraces: [processChainTraceInGroupDto1],
    status: ProcessChainGroupTraceStatus.Active,
    mapId: MapMockData.getMap().id,
    createdDateTime: '2023-05-10T14:40:48.7997241Z',
    updatedDateTime: '2023-05-10T14:45:48.7997241Z',
    executionMode: ProcessChainGroupExecutionMode.Parallel,
  },
  {
    id: processChainGroupTraceId,
    name: 'Process Chain Group 2',
    workAreaId: workAreaId,
    processChainGroupId: 'process chain group 2 id',
    processChainTraces: [processChainTraceInGroupDto2, processChainTraceInGroupDto3],
    status: ProcessChainGroupTraceStatus.Active,
    mapId: MapMockData.getMap().id,
    createdDateTime: '2023-05-10T14:40:48.7997241Z',
    updatedDateTime: '2023-05-10T14:45:48.7997241Z',
    executionMode: ProcessChainGroupExecutionMode.Parallel,
  },
];

const processChainReducedModels: ProcessChainReducedModel[] = [
  {
    id: 'process chain reduce model id 1',
    mapId: MapMockData.getMap().id,
    name: 'Process Chain Reduce Model 1',
  },
  {
    id: 'process chain reduce model id 2',
    mapId: MapMockData.getMap().id,
    name: 'Process Chain Reduce Model 2',
  },
];

const processChainGroupTraceModel: ProcessChainGroupTraceModel = {
  id: 'process chain group trace model id',
  groupName: 'Process Chain Group Trace Model',
  processChains: processChainReducedModels,
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
  mapId: MapMockData.getMap().id,
  mapName: MapMockData.getMap().name,
  createdDateTime: '2023-05-10T14:40:48.7997241Z',
  status: ProcessChainGroupTraceStatus.Active,
  executionMode: ProcessChainGroupExecutionMode.Parallel,
};

const tourStepDtos: TourStepDto[] = [
  {
    stepAttributeText: 'test step attribute text 1',
    startedDateUtc: '2023-06-08 10:15:14.2817538',
    stoppedDateUtc: '2023-06-08 11:15:14.2817538',
    id: 'E2DAD345-55BA-4816-241F-08DB68A5B012',
    type: StepTypeBackend.Goto,
    sequenceId: 0,
    skipped: false,
    alreadyExecuted: false,
  },
  {
    stepAttributeText: 'test step attribute text 2',
    startedDateUtc: '2023-06-09 10:15:14.2817538',
    stoppedDateUtc: '2023-06-09 11:15:14.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDF',
    type: StepTypeBackend.WaitForSideButton,
    sequenceId: 1,
    skipped: false,
    alreadyExecuted: false,
  },
  {
    stepAttributeText: 'test step attribute text 3',
    startedDateUtc: '2023-06-09 10:15:15.2817538',
    stoppedDateUtc: '2023-06-09 11:15:15.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDE',
    type: StepTypeBackend.StartToCharge,
    sequenceId: 2,
    skipped: false,
    alreadyExecuted: false,
  },
  {
    stepAttributeText: 'test step attribute text 4',
    startedDateUtc: '2023-06-09 10:15:15.2817538',
    stoppedDateUtc: '2023-06-09 11:15:15.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDB',
    type: StepTypeBackend.StopToCharge,
    sequenceId: 3,
    skipped: false,
    alreadyExecuted: false,
  },
];

const tourStepUIModels: TourStepUIModel[] = [
  {
    stepAttributeText: 'test step attribute text 1',
    startedDateUtc: '2023-06-08 10:15:14.2817538',
    stoppedDateUtc: '2023-06-08 11:15:14.2817538',
    id: 'E2DAD345-55BA-4816-241F-08DB68A5B012',
    type: StepType.Goto,
    skipped: false,
    alreadyExecuted: false,
    sequenceNum: 0,
  },
  {
    stepAttributeText: 'test step attribute text 2',
    startedDateUtc: '2023-06-09 10:15:14.2817538',
    stoppedDateUtc: '2023-06-09 11:15:14.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDF',
    type: StepType.WaitForSideButton,
    skipped: false,
    alreadyExecuted: false,
    sequenceNum: 1,
  },
  {
    stepAttributeText: 'test step attribute text 3',
    startedDateUtc: '2023-06-09 10:15:15.2817538',
    stoppedDateUtc: '2023-06-09 11:15:15.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDE',
    type: StepType.StartToCharge,
    skipped: false,
    alreadyExecuted: false,
    sequenceNum: 2,
  },
  {
    stepAttributeText: 'test step attribute text 4',
    startedDateUtc: '2023-06-09 10:15:15.2817538',
    stoppedDateUtc: '2023-06-09 11:15:15.2817538',
    id: 'D6E6A22D-225F-4A7B-AB81-59CD4912ABDB',
    type: StepType.StopToCharge,
    skipped: false,
    alreadyExecuted: false,
    sequenceNum: 3,
  },
];

const tours: MissionTrace[] = [
  {
    createdDateTime: newDate,
    completedDateTime: newDate,
    currentStepAttributeText: '',
    currentStepSequenceNumber: 0,
    id: 'a44efba7-11a3-45ba-a05b-11f9d8693287',
    materialNumber: 'Mat',
    failureComment: {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonDe: '',
      missionFailureReasonEn: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: null,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: '2021-02-02T14:40:48.7997241Z',
      missionFailureStartDateTime: '2021-02-02T14:39:48.7997241Z',
      missionFailureLocationId: '1',
      missionFailureShiftGroupId: '1',
    },

    missionId: 'e52df967-808e-4664-944e-64e374e05bb0',
    missionName: 'Mission A',
    missionStepNo: '1/2',
    processChainName: interlockProcessChainName,
    processChainId: EMPTY_GUID,
    processChainTraceId: '',
    status: MissionStatus.InProgress,

    isAlarmForLateDeliveryActive: true,
    lateDeliveryAlarmTime: 5,
    stepTraces: [],
    trigger: MissionTraceTrigger.Sap,
    updatedDateTime: newDate,
    provisioningTime: newDate,
    forecastedEndTime: newDate,
    vehicleId: dsTuggerVehicleId,
    vehicleName: 'Vehicle 5',
    vehicleType: VehicleType.UnitLoad,
    hasManualSupport: false,
    assignableWaypointName: 'assignableWaypointId_1',
    assignableWaypointId: 'assignableWaypointId_1',
    deliveryStatus: MissionDeliveryStatus.NotUrgent,
    isMaxExecutionTimeActive: false,
    maxExecutionTime: 0,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    canBeReAssigned: false,
    canBeContinuedFromStep: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
    assignmentConditions: [],
    stepCount: 1,
    isAssignedToVehicle: false,
    currentStepType: StepTypeBackend.Goto,
    currentStepTypeFrontEnd: StepType.Goto,
    tourSteps: tourStepUIModels,
    priorityLevel: MissionPriorityLevel.None,
    assignmentDelayEndDateTime: '',
    missionFormat: MissionFormat.VDA5050,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: '5ec8c47e-8df3-4db4-b639-442582eae683',
    missionId: '7c1e9935-db98-4f95-9dd9-229d19a6bc82',
    vehicleId: 'd037a166-da03-4884-a08e-fe76b5b62c76',
    vehicleName: 'Forklift',
    vehicleType: VehicleType.Forklift,
    missionName: 'Forklift_Mission',
    status: MissionStatus.InProgress,
    currentStepSequenceNumber: 0,
    stepTraces: [],
    missionStepNo: '1',
    createdDateTime: '',
    completedDateTime: null,
    updatedDateTime: '',
    trigger: MissionTraceTrigger.AdHocFromTourConfig,
    materialNumber: '',
    processChainName: '',
    processChainId: EMPTY_GUID,
    processChainTraceId: EMPTY_GUID,
    lateDeliveryAlarmTime: 0,
    isAlarmForLateDeliveryActive: false,
    maxExecutionTime: 0,
    isMaxExecutionTimeActive: false,
    provisioningTime: null,
    forecastedEndTime: null,
    hasManualSupport: false,
    assignableWaypointId: null,
    deliveryStatus: MissionDeliveryStatus.Normal,
    currentStepTypeFrontEnd: StepType.WaitForDevice,
    priorityLevel: MissionPriorityLevel.None,
    failureComment: {
      missionFailureMinutesToSolve: 0,
      missionFailureReasonComments: '',
      missionFailureReasonDe: '',
      missionFailureReasonEn: '',
      missionFailureReasonId: '',
      missionFailureMinutesForEmergencyProcess: null,
      durationOfMissionInFailed: 0,
      missionFailureEndDateTime: '',
      missionFailureStartDateTime: '',
      missionFailureLocationId: '',
      missionFailureShiftGroupId: '1',
    },
    assignmentDelayEndDateTime: null,
    currentStepAttributeText: '',
    canBeContinuedFromStep: false,
    canBeReAssigned: false,
    canAbort: false,
    missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
    errorHandlingAllowed: false,
    canRetry: false,
    assignmentConditions: [],
    stepCount: 0,
    isAssignedToVehicle: false,
    missionFormat: MissionFormat.VDA5050,
    tourSteps: [
      {
        startedDateUtc: null,
        stoppedDateUtc: null,
        id: '0d6b0457-af11-49d2-b00a-95168c867b07',
        type: StepType.WaitForDevice,
        stepAttributeText: 'some_device_0',
        sequenceNum: 1,
        alreadyExecuted: false,
        subSteps: [
          {
            nodeName: 'node_3',
            value: 'true',
            sequenceNumber: 3,
          },
          {
            nodeName: 'node_1',
            value: 'true',
            sequenceNumber: 1,
          },
          {
            nodeName: 'node_0',
            value: 'true',
            sequenceNumber: 0,
          },
          {
            nodeName: 'node_2',
            value: 'true',
            sequenceNumber: 2,
          },
        ],
      },
      {
        startedDateUtc: null,
        stoppedDateUtc: null,
        type: StepType.Goto,
        id: '0d6b0457-af11-49d2-b00a-95168c867b07',
        stepAttributeText: 'dispose_station_0',
        sequenceNum: 0,
        alreadyExecuted: false,
      },
    ],
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
];

const allActiveFailureMissionTraces: FailureMissionTrace[] = [
  {
    missionFailureReasonId: mission.failureComment?.[0]?.missionFailureReasonId,
    missionFailureShiftGroupId: mission.failureComment?.[0]?.missionFailureShiftGroupId,
    missionTraceId: 'a44efba7-11a3-45ba-a05b-11f9d8693287',
  },
  {
    missionFailureReasonId: mission2.failureComment?.[0]?.missionFailureReasonId,
    missionFailureShiftGroupId: mission2.failureComment?.[0]?.missionFailureShiftGroupId,
    missionTraceId: 'a44efba7-11a3-45ba-a05b-11f9d8693282',
  },
  {
    missionFailureReasonId: mission3.failureComment?.[0]?.missionFailureReasonId,
    missionFailureShiftGroupId: mission3.failureComment?.[0]?.missionFailureShiftGroupId,
    missionTraceId: 'a44efba7-11a3-45ba-a05b-11f9d8693281',
  },
];

const mockMissionInput: MissionListInputStepStringModel = {
  id: 'id',
  missionId: 'missionId',
  missionName: 'missionName',
  failureComment: [
    {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesToSolve: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      vehicleName: null,
      missionName: null,
    },
  ],
  processChainName: 'pcName',
  processChainId: 'pcId',
  materialNumber: 'materialNumber',
  statusEnum: MissionStatus.InProgress,
  status: 'status',
  vehicleName: 'vehicleName',
  vehicleId: 'vehicleId',
  trigger: 'trigger',
  stepNr: 'stepNo',
  step: 'step',
  canAbort: true,
  canRetry: true,
  missionDisplayHighlightLevel: MissionDisplayHighlightLevel.Info,
};

const missionListItems: MissionListSignalr[] = [
  {
    id: '4a14eb19-5245-4547-82af-26c12c5d23f8',
    mapId: 'b14eeabf-fda4-40ae-ad77-a19589f0ecd8',
    missionId: '6b7d6089-724f-487e-be69-3b34ad7ca5a7',
    missionName: 'All Steps',
    processChainName: 'Round Robin',
    processChainTraceId: '317b8988-4695-410d-9725-51f43c74c116',
    processChainId: '979b389c-a236-4e62-9539-d1587dc565ef',
    materialNumber: 'h5757',
    status: 2,
    fleetName: undefined,
    vehicleName: 'super-str',
    vehicleId: 'bd383dc6-bba5-4e21-bfb5-6f2d7644d79d',
    vehicleType: 0,
    trigger: 7,
    currentStepSequenceNumber: 12,
    currentStepType: 7,
    currentStepAttributeText: 'charging',
    createdDateTime: '2024-02-21T19:52:28.309Z',
    forecastedEndTime: '2024-02-21T19:57:38.334Z',
    updatedDateTime: '2024-02-21T19:57:01.370Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 0,
    tourSteps: undefined,
    priorityLevel: 0,
    assignmentDelayEndDateTime: '2024-02-21T19:52:28.309Z',
    missionStepNo: '12/13',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 13,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          pointOfInterestName: 'poi',
          pointOfInterestGroupId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 0,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:52:32.772Z',
        stoppedDateTime: '2024-02-21T19:53:06.358Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          pointOfInterestName: 'dolly',
          pointOfInterestGroupId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 1,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:53:06.368Z',
        stoppedDateTime: '2024-02-21T19:54:03.834Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 2,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:54:03.840Z',
        stoppedDateTime: '2024-02-21T19:54:05.867Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 3000,
          sequenceNumber: 3,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:54:05.873Z',
        stoppedDateTime: '2024-02-21T19:54:09.897Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 4,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:54:09.901Z',
        stoppedDateTime: '2024-02-21T19:54:11.907Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          pointOfInterestName: 'Parking',
          pointOfInterestGroupId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 5,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:54:11.912Z',
        stoppedDateTime: '2024-02-21T19:54:32.100Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          pointOfInterestName: 'handover',
          pointOfInterestGroupId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 6,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:54:32.107Z',
        stoppedDateTime: '2024-02-21T19:55:15.655Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 7,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:55:15.658Z',
        stoppedDateTime: '2024-02-21T19:55:17.459Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 4000,
          sequenceNumber: 8,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:55:17.463Z',
        stoppedDateTime: '2024-02-21T19:55:22.501Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          pointOfInterestName: 'turntable',
          pointOfInterestGroupId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 9,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:55:22.508Z',
        stoppedDateTime: '2024-02-21T19:56:15.969Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 10,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:56:15.981Z',
        stoppedDateTime: '2024-02-21T19:56:17.974Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          pointOfInterestName: 'awp',
          pointOfInterestGroupId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 11,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:56:17.979Z',
        stoppedDateTime: '2024-02-21T19:56:33.345Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          pointOfInterestName: 'charging',
          pointOfInterestGroupId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 12,
          type: 107,
        },
        startedDateTime: '2024-02-21T19:56:33.350Z',
        stoppedDateTime: null,
        succeeded: false,
        attributeText: '',
      },
    ],
    currentStepTypeFrontEnd: 107,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: '4a14eb19-5245-4547-0011-26c12c5d29f8',
    mapId: 'b14eeabf-fda4-40ae-ad77-a19589f0ecd8',
    missionId: '6b7d6089-724f-487e-be69-3b34ad7ca5a7',
    missionName: 'All Steps',
    processChainName: 'Round Robin',
    processChainTraceId: '317b8988-4695-410d-9725-51f43c74c116',
    processChainId: '979b389c-a236-4e62-9539-d1587dc565ef',
    materialNumber: 'h5757',
    status: 3,
    fleetName: undefined,
    vehicleName: 'super-str',
    vehicleId: 'bd383dc6-bba5-4e21-bfb5-6f2d7644d79d',
    vehicleType: 0,
    trigger: 7,
    currentStepSequenceNumber: 12,
    currentStepType: 7,
    currentStepAttributeText: 'charging',
    createdDateTime: '2024-02-21T19:52:28.309Z',
    forecastedEndTime: '2024-02-21T19:57:38.334Z',
    updatedDateTime: '2024-02-21T19:57:01.370Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 0,
    tourSteps: undefined,
    priorityLevel: 0,
    assignmentDelayEndDateTime: '2024-02-21T19:52:28.309Z',
    missionStepNo: '12/13',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 13,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          pointOfInterestName: 'poi',
          pointOfInterestGroupId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 0,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:52:32.772Z',
        stoppedDateTime: '2024-02-21T19:53:06.358Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          pointOfInterestName: 'dolly',
          pointOfInterestGroupId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 1,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:53:06.368Z',
        stoppedDateTime: '2024-02-21T19:54:03.834Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 2,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:54:03.840Z',
        stoppedDateTime: '2024-02-21T19:54:05.867Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 3000,
          sequenceNumber: 3,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:54:05.873Z',
        stoppedDateTime: '2024-02-21T19:54:09.897Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 4,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:54:09.901Z',
        stoppedDateTime: '2024-02-21T19:54:11.907Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          pointOfInterestName: 'Parking',
          pointOfInterestGroupId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 5,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:54:11.912Z',
        stoppedDateTime: '2024-02-21T19:54:32.100Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          pointOfInterestName: 'handover',
          pointOfInterestGroupId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 6,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:54:32.107Z',
        stoppedDateTime: '2024-02-21T19:55:15.655Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 7,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:55:15.658Z',
        stoppedDateTime: '2024-02-21T19:55:17.459Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 4000,
          sequenceNumber: 8,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:55:17.463Z',
        stoppedDateTime: '2024-02-21T19:55:22.501Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          pointOfInterestName: 'turntable',
          pointOfInterestGroupId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 9,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:55:22.508Z',
        stoppedDateTime: '2024-02-21T19:56:15.969Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 10,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:56:15.981Z',
        stoppedDateTime: '2024-02-21T19:56:17.974Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          pointOfInterestName: 'awp',
          pointOfInterestGroupId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 11,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:56:17.979Z',
        stoppedDateTime: '2024-02-21T19:56:33.345Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          pointOfInterestName: 'charging',
          pointOfInterestGroupId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 12,
          type: 107,
        },
        startedDateTime: '2024-02-21T19:56:33.350Z',
        stoppedDateTime: null,
        succeeded: false,
        attributeText: '',
      },
    ],
    currentStepTypeFrontEnd: 107,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: '3a14eb19-5245-4547-82af-26c12c5d23f8',
    mapId: 'b14eeabf-fda4-40ae-ad77-a19589f0ecd8',
    missionId: '6b7d6089-724f-487e-be69-3b34ad7ca5a7',
    missionName: 'All Steps',
    processChainName: 'Round Robin 4',
    processChainTraceId: '317b8988-4695-410d-9725-51f43c74c116',
    processChainId: '979b389c-a236-4e62-9539-d1587dc565ef',
    materialNumber: 'h5757',
    status: 2,
    fleetName: undefined,
    vehicleName: 'super-str',
    vehicleId: 'bd323dc6-6565-4e21-bfb5-6f2d7644d79d',
    vehicleType: 0,
    trigger: 7,
    currentStepSequenceNumber: 12,
    currentStepType: 7,
    currentStepAttributeText: 'charging',
    createdDateTime: '2024-02-21T19:52:28.309Z',
    forecastedEndTime: '2024-02-21T19:57:38.334Z',
    updatedDateTime: '2024-02-21T19:57:01.370Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 0,
    tourSteps: undefined,
    priorityLevel: 0,
    assignmentDelayEndDateTime: '2024-02-21T19:52:28.309Z',
    missionStepNo: '12/13',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 13,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          pointOfInterestName: 'poi',
          pointOfInterestGroupId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 0,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:52:32.772Z',
        stoppedDateTime: '2024-02-21T19:53:06.358Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          pointOfInterestName: 'dolly',
          pointOfInterestGroupId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 1,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:53:06.368Z',
        stoppedDateTime: '2024-02-21T19:54:03.834Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 2,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:54:03.840Z',
        stoppedDateTime: '2024-02-21T19:54:05.867Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 3000,
          sequenceNumber: 3,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:54:05.873Z',
        stoppedDateTime: '2024-02-21T19:54:09.897Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 4,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:54:09.901Z',
        stoppedDateTime: '2024-02-21T19:54:11.907Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          pointOfInterestName: 'Parking',
          pointOfInterestGroupId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 5,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:54:11.912Z',
        stoppedDateTime: '2024-02-21T19:54:32.100Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          pointOfInterestName: 'handover',
          pointOfInterestGroupId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 6,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:54:32.107Z',
        stoppedDateTime: '2024-02-21T19:55:15.655Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 7,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:55:15.658Z',
        stoppedDateTime: '2024-02-21T19:55:17.459Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 4000,
          sequenceNumber: 8,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:55:17.463Z',
        stoppedDateTime: '2024-02-21T19:55:22.501Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          pointOfInterestName: 'turntable',
          pointOfInterestGroupId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 9,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:55:22.508Z',
        stoppedDateTime: '2024-02-21T19:56:15.969Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 10,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:56:15.981Z',
        stoppedDateTime: '2024-02-21T19:56:17.974Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          pointOfInterestName: 'awp',
          pointOfInterestGroupId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 11,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:56:17.979Z',
        stoppedDateTime: '2024-02-21T19:56:33.345Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          pointOfInterestName: 'charging',
          pointOfInterestGroupId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 12,
          type: 107,
        },
        startedDateTime: '2024-02-21T19:56:33.350Z',
        stoppedDateTime: null,
        succeeded: false,
        attributeText: '',
      },
    ],
    currentStepTypeFrontEnd: 107,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: '7cd53636-6f2b-4673-8938-6e37f7da7719',
    mapId: 'b14eeabf-fda4-40ae-ad77-a19589f0ecd8',
    missionId: '644c5414-29db-4115-8bac-41870f0871c9',
    missionName: 'All Steps',
    processChainName: 'Round Robin 4',
    processChainTraceId: '317b8988-4695-410d-9725-51f43c74c116',
    processChainId: '979b389c-a236-4e62-9539-d1587dc565ef',
    materialNumber: 'h5757',
    status: 2,
    fleetName: undefined,
    vehicleName: 'active-vehicle',
    vehicleId: 'dfcbe822-39b7-4f65-b87f-831397f87b3c',
    vehicleType: 0,
    trigger: 7,
    currentStepSequenceNumber: 12,
    currentStepType: 7,
    currentStepAttributeText: 'charging',
    createdDateTime: '2024-02-21T19:52:28.309Z',
    forecastedEndTime: '2024-02-21T19:57:38.334Z',
    updatedDateTime: '2024-02-21T19:57:01.370Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 0,
    tourSteps: undefined,
    priorityLevel: 0,
    assignmentDelayEndDateTime: '2024-02-21T19:52:28.309Z',
    missionStepNo: '12/13',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 13,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          pointOfInterestName: 'poi',
          pointOfInterestGroupId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 0,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:52:32.772Z',
        stoppedDateTime: '2024-02-21T19:53:06.358Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          pointOfInterestName: 'dolly',
          pointOfInterestGroupId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 1,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:53:06.368Z',
        stoppedDateTime: '2024-02-21T19:54:03.834Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 2,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:54:03.840Z',
        stoppedDateTime: '2024-02-21T19:54:05.867Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 3000,
          sequenceNumber: 3,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:54:05.873Z',
        stoppedDateTime: '2024-02-21T19:54:09.897Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 4,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:54:09.901Z',
        stoppedDateTime: '2024-02-21T19:54:11.907Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          pointOfInterestName: 'Parking',
          pointOfInterestGroupId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 5,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:54:11.912Z',
        stoppedDateTime: '2024-02-21T19:54:32.100Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          pointOfInterestName: 'handover',
          pointOfInterestGroupId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 6,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:54:32.107Z',
        stoppedDateTime: '2024-02-21T19:55:15.655Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 7,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:55:15.658Z',
        stoppedDateTime: '2024-02-21T19:55:17.459Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 4000,
          sequenceNumber: 8,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:55:17.463Z',
        stoppedDateTime: '2024-02-21T19:55:22.501Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          pointOfInterestName: 'turntable',
          pointOfInterestGroupId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 9,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:55:22.508Z',
        stoppedDateTime: '2024-02-21T19:56:15.969Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 10,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:56:15.981Z',
        stoppedDateTime: '2024-02-21T19:56:17.974Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          pointOfInterestName: 'awp',
          pointOfInterestGroupId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 11,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:56:17.979Z',
        stoppedDateTime: '2024-02-21T19:56:33.345Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          pointOfInterestName: 'charging',
          pointOfInterestGroupId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 12,
          type: 107,
        },
        startedDateTime: '2024-02-21T19:56:33.350Z',
        stoppedDateTime: null,
        succeeded: false,
        attributeText: '',
      },
    ],
    currentStepTypeFrontEnd: 107,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
  {
    id: '7cd53636-6f2b-4673-8938-6e37f7da7719',
    mapId: 'b14eeabf-fda4-40ae-ad77-a19589f0ecd8',
    missionId: '644c5414-29db-4115-8bac-41870f0871c9',
    missionName: 'All Steps Aborted',
    processChainName: 'Round Robin 4',
    processChainTraceId: '317b8988-4695-410d-9725-51f43c74c116',
    processChainId: '979b389c-a236-4e62-9539-d1587dc565ef',
    materialNumber: 'h5757',
    status: 5,
    fleetName: undefined,
    vehicleName: 'active-vehicle',
    vehicleId: '000be822-39b7-4f65-b87f-831397f87b3c',
    vehicleType: 0,
    trigger: 7,
    currentStepSequenceNumber: 12,
    currentStepType: 7,
    currentStepAttributeText: 'charging',
    createdDateTime: '2024-02-21T19:52:28.309Z',
    forecastedEndTime: '2024-02-21T19:57:38.334Z',
    updatedDateTime: '2024-02-21T19:57:01.370Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 0,
    tourSteps: undefined,
    priorityLevel: 0,
    assignmentDelayEndDateTime: '2024-02-21T19:52:28.309Z',
    missionStepNo: '12/13',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 13,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [
      {
        sourceStep: {
          pointOfInterestId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          pointOfInterestName: 'poi',
          pointOfInterestGroupId: 'fa4c723b-e18b-4046-9cb9-1df58daa682a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 0,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:52:32.772Z',
        stoppedDateTime: '2024-02-21T19:53:06.358Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          pointOfInterestName: 'dolly',
          pointOfInterestGroupId: '75ed89e1-61e3-4133-8136-76ac51bdcade',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 1,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:53:06.368Z',
        stoppedDateTime: '2024-02-21T19:54:03.834Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 2,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:54:03.840Z',
        stoppedDateTime: '2024-02-21T19:54:05.867Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 3000,
          sequenceNumber: 3,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:54:05.873Z',
        stoppedDateTime: '2024-02-21T19:54:09.897Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 4,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:54:09.901Z',
        stoppedDateTime: '2024-02-21T19:54:11.907Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          pointOfInterestName: 'Parking',
          pointOfInterestGroupId: '9c34bedd-c776-4e1f-ab74-61d26da0de2a',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 5,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:54:11.912Z',
        stoppedDateTime: '2024-02-21T19:54:32.100Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          pointOfInterestName: 'handover',
          pointOfInterestGroupId: '4d86c9ab-b684-4dac-a223-3072f20b3966',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 6,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:54:32.107Z',
        stoppedDateTime: '2024-02-21T19:55:15.655Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 7,
          type: 104,
        },
        startedDateTime: '2024-02-21T19:55:15.658Z',
        stoppedDateTime: '2024-02-21T19:55:17.459Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          timeSpanS: 4000,
          sequenceNumber: 8,
          type: 103,
        },
        startedDateTime: '2024-02-21T19:55:17.463Z',
        stoppedDateTime: '2024-02-21T19:55:22.501Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          pointOfInterestName: 'turntable',
          pointOfInterestGroupId: '9499fba8-e53b-4250-9ff2-08052b67e03e',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 9,
          type: 101,
        },
        startedDateTime: '2024-02-21T19:55:22.508Z',
        stoppedDateTime: '2024-02-21T19:56:15.969Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          sequenceNumber: 10,
          type: 105,
        },
        startedDateTime: '2024-02-21T19:56:15.981Z',
        stoppedDateTime: '2024-02-21T19:56:17.974Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          pointOfInterestName: 'awp',
          pointOfInterestGroupId: '7b830c6c-ffd7-46da-917e-645e75c2dc90',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 11,
          type: 100,
        },
        startedDateTime: '2024-02-21T19:56:17.979Z',
        stoppedDateTime: '2024-02-21T19:56:33.345Z',
        succeeded: true,
        attributeText: '',
      },
      {
        sourceStep: {
          pointOfInterestId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          pointOfInterestName: 'charging',
          pointOfInterestGroupId: '47609b49-c0ab-4367-a02d-4e3b475ead27',
          trajectory: [],
          hasStepCompletionNotificationEnabled: false,
          sequenceNumber: 12,
          type: 107,
        },
        startedDateTime: '2024-02-21T19:56:33.350Z',
        stoppedDateTime: null,
        succeeded: false,
        attributeText: '',
      },
    ],
    currentStepTypeFrontEnd: 107,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
];

const vdaMissionListItems: MissionListSignalr[] = [
  {
    id: '5363815d-4939-4510-9323-0e7859556c73',
    missionId: '775b5a47-ad63-4794-47c7-08dca1aaee58',
    missionName: 'Forklift_Mission_2',
    processChainName: '',
    processChainTraceId: '',
    processChainId: '',
    materialNumber: '',
    status: 2,
    fleetName: undefined,
    vehicleName: 'FORKLIFT2_XVNE',
    vehicleId: '607e9103-4643-47ec-921c-4b51b16bbd65',
    vehicleType: VehicleType.Forklift,
    trigger: 10,
    currentStepSequenceNumber: 2,
    currentStepType: 0,
    currentStepAttributeText: 'Charging spot 2',
    createdDateTime: '2024-07-11T13:36:53.427Z',
    forecastedEndTime: null,
    updatedDateTime: '2024-07-11T13:36:53.427Z',
    deliveryStatus: 3,
    hasManualSupport: false,
    canAbort: true,
    canRetry: false,
    errorHandlingAllowed: true,
    missionDisplayHighlightLevel: 0,
    missionFormat: 1,
    tourSteps: [
      {
        id: '54ad5bdd-eac5-47b7-bdb4-6cbb0b3957b5',
        type: 126,
        startedDateUtc: '2024-07-11T13:36:56.875Z',
        stoppedDateUtc: '2024-07-11T13:36:56.875Z',
        skipped: false,
        alreadyExecuted: false,
        sequenceNum: 1,
      },
      {
        id: 'f1a5981e-66bc-4f00-b0a2-c2d93b1d0495',
        type: 100,
        stepAttributeText: 'Container tower B2',
        startedDateUtc: '2024-07-11T13:36:56.875Z',
        stoppedDateUtc: '2024-07-11T13:36:56.875Z',
        skipped: false,
        alreadyExecuted: false,
        sequenceNum: 2,
      },
      {
        id: '90ac6c49-8526-4d2b-84e2-5cf32d880073',
        type: 104,
        startedDateUtc: '2024-07-11T13:36:56.875Z',
        stoppedDateUtc: null,
        skipped: false,
        alreadyExecuted: false,
        sequenceNum: 3,
      },
    ],
    priorityLevel: 0,
    assignmentDelayEndDateTime: '',
    missionStepNo: '2/3',
    assignableWaypointId: null,
    assignmentConditions: [],
    maxExecutionTime: 0,
    completedDateTime: '-',
    lateDeliveryAlarmTime: 0,
    provisioningTime: null,
    isAlarmForLateDeliveryActive: true,
    canBeContinuedFromStep: false,
    canBeReAssigned: true,
    isAssignedToVehicle: true,
    stepCount: 3,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: null,
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureMinutesToSolve: null,
      durationOfMissionInFailed: null,
      missionFailureStartDateTime: null,
      missionFailureEndDateTime: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      vehicleName: 'Vehicle Name',
      missionName: 'All Steps',
    },
    isMaxExecutionTimeActive: false,
    stepTraces: [],
    currentStepTypeFrontEnd: 100,
    loadType: LoadType.Glt1400X1250,
    tourChainId: null,
    tourChainName: null,
  },
];

const archivedTours: TourArchiveDto[] = [
  {
    id: 'f4832a6f-f70b-47d0-a086-efb2b8806191',
    workAreaId: WORK_AREA_ID,
    name: MISSION_NAME,
    vehicleName: 'FORKLIFT_HRQM',
    status: MissionStatus.Aborted,
    missionId: MISSION_ID,
    vehicleId: VEHILE_ID,
    createdDateUtc: '2024-03-06T10:03:31.3859076Z',
    completedDateUtc: '2024-03-06T10:04:35.7095879Z',
    trigger: TourTriggerType.AdHocFromTourConfiguration,
    currentStepSequenceNumber: 1,
    startSequenceOffset: 0,
    startStepSequenceNumber: 0,
    vehicleType: VehicleType.Forklift,
    tourConfigurationName: '',
    successfulSteps: 2,
    totalSteps: 0,
    lastStepType: StepType.Dock,
    runIndex: 0,
    steps: [
      {
        id: STEPID_1,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:03:42.0054742Z',
        stoppedDateUtc: '2024-03-06T10:03:51.0174169Z',
        sequenceId: 0,
        alreadyExecuted: true,
      },
      {
        id: STEPID_2,
        type: 4,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:03:52.0174169Z',
        stoppedDateUtc: '2024-03-06T10:03:53.0317552Z',
        sequenceId: 1,
        alreadyExecuted: true,
      },
      {
        id: STEPID_3,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_3,
        startedDateUtc: '2024-03-06T10:03:54.0317552Z',
        stoppedDateUtc: null,
        sequenceId: 2,
        alreadyExecuted: false,
      },
      {
        id: STEPID_4,
        type: 5,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:04:14.2432314Z',
        stoppedDateUtc: null,
        sequenceId: 3,
        alreadyExecuted: false,
      },
      {
        id: STEPID_5,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:04:17.243188Z',
        stoppedDateUtc: null,
        sequenceId: 4,
        alreadyExecuted: false,
      },
    ],
    assignableWaypointName: ASSIGNABLE_WAYPOINT_NAME,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: '',
      missionFailureMinutesToSolve: null,
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: 0,
      missionFailureStartDateTime: '',
      missionFailureEndDateTime: '',
    },

    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'f4832a6f-f70b-47d0-a086-efb2b8806192',
    workAreaId: WORK_AREA_ID,
    name: MISSION_NAME,
    vehicleName: 'FORKLIFT_HRQM',
    status: MissionStatus.Aborted,
    missionId: MISSION_ID,
    vehicleId: VEHILE_ID,
    createdDateUtc: '2024-03-06T10:04:36.3859076Z',
    completedDateUtc: '2024-03-06T10:04:55.7095879Z',
    trigger: TourTriggerType.AdHocFromTourConfiguration,
    currentStepSequenceNumber: 2,
    startSequenceOffset: 2,
    startStepSequenceNumber: 2,
    vehicleType: VehicleType.Forklift,
    tourConfigurationName: '',
    successfulSteps: 3,
    totalSteps: 0,
    lastStepType: StepType.Dock,
    runIndex: 1,
    steps: [
      {
        id: STEPID_1,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:03:43.0054742Z',
        stoppedDateUtc: '2024-03-06T10:03:50.0174169Z',
        alreadyExecuted: true,
        sequenceId: 0,
      },
      {
        id: STEPID_2,
        type: 4,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:03:51.0174169Z',
        stoppedDateUtc: '2024-03-06T10:03:52.0317552Z',
        alreadyExecuted: true,
        sequenceId: 1,
      },
      {
        id: STEPID_3,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_3,
        startedDateUtc: '2024-03-06T10:03:53.0317552Z',
        stoppedDateUtc: '2024-03-06T10:04:13.2432314Z',
        alreadyExecuted: true,
        sequenceId: 2,
      },
      {
        id: STEPID_4,
        type: 5,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:04:14.2432314Z',
        stoppedDateUtc: null,
        alreadyExecuted: false,
        sequenceId: 3,
      },
      {
        id: STEPID_5,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:04:16.243188Z',
        stoppedDateUtc: null,
        alreadyExecuted: false,
        sequenceId: 4,
      },
    ],
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: '',
      missionFailureMinutesToSolve: null,
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: 0,
      missionFailureStartDateTime: '',
      missionFailureEndDateTime: '',
    },
    assignableWaypointName: ASSIGNABLE_WAYPOINT_NAME,

    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'f4832a6f-f70b-47d0-a086-efb2b8806192',
    workAreaId: WORK_AREA_ID,
    name: MISSION_NAME,
    vehicleName: 'FORKLIFT_HRQM',
    status: MissionStatus.Aborted,
    missionId: MISSION_ID,
    vehicleId: VEHILE_ID,
    createdDateUtc: '2024-03-06T10:04:56.3859076Z',
    completedDateUtc: '2024-03-06T10:04:59.7095879Z',
    trigger: TourTriggerType.AdHocFromTourConfiguration,
    currentStepSequenceNumber: 3,
    startSequenceOffset: 3,
    startStepSequenceNumber: 3,
    vehicleType: VehicleType.Forklift,
    tourConfigurationName: '',
    successfulSteps: 4,
    totalSteps: 0,
    lastStepType: StepType.Dock,
    runIndex: 2,
    steps: [
      {
        id: STEPID_1,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:03:43.0054742Z',
        stoppedDateUtc: '2024-03-06T10:03:52.0174169Z',
        alreadyExecuted: true,
        sequenceId: 0,
      },
      {
        id: STEPID_2,
        type: 4,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:03:53.0174169Z',
        stoppedDateUtc: '2024-03-06T10:03:54.0317552Z',
        alreadyExecuted: true,
        sequenceId: 1,
      },
      {
        id: STEPID_3,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_3,
        startedDateUtc: '2024-03-06T10:03:55.0317552Z',
        stoppedDateUtc: '2024-03-06T10:04:12.2432314Z',
        alreadyExecuted: true,
        sequenceId: 2,
      },
      {
        id: STEPID_4,
        type: 5,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:04:12.2432314Z',
        stoppedDateUtc: '2024-03-06T10:04:13.243188Z',
        alreadyExecuted: true,
        sequenceId: 3,
      },
      {
        id: STEPID_5,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:04:13.243188Z',
        stoppedDateUtc: null,
        alreadyExecuted: false,
        sequenceId: 4,
      },
    ],
    assignableWaypointName: ASSIGNABLE_WAYPOINT_NAME,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: '',
      missionFailureMinutesToSolve: null,
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: 0,
      missionFailureStartDateTime: '',
      missionFailureEndDateTime: '',
    },

    tourChainId: null,
    tourChainName: null,
  },
  {
    id: 'f4832a6f-f70b-47d0-a086-efb2b88061q2',
    workAreaId: WORK_AREA_ID,
    name: MISSION_NAME,
    vehicleName: 'FORKLIFT_HRQM',
    status: MissionStatus.CompletedWithSupport,
    missionId: MISSION_ID,
    vehicleId: VEHILE_ID,
    createdDateUtc: '2024-03-06T10:04:56.3859076Z',
    completedDateUtc: '2024-03-06T10:04:59.7095879Z',
    trigger: TourTriggerType.AdHocFromTourConfiguration,
    currentStepSequenceNumber: 4,
    startSequenceOffset: 4,
    startStepSequenceNumber: 4,
    vehicleType: VehicleType.Forklift,
    tourConfigurationName: '',
    successfulSteps: 5,
    totalSteps: 0,
    lastStepType: StepType.Dock,
    runIndex: 3,
    retryRunId: '1234',
    steps: [
      {
        id: STEPID_1,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:03:43.0054742Z',
        stoppedDateUtc: '2024-03-06T10:03:52.0174169Z',
        alreadyExecuted: true,
        sequenceId: 0,
      },
      {
        id: STEPID_2,
        type: 4,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:03:53.0174169Z',
        stoppedDateUtc: '2024-03-06T10:03:54.0317552Z',
        alreadyExecuted: true,
        sequenceId: 1,
      },
      {
        id: STEPID_3,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_3,
        startedDateUtc: '2024-03-06T10:03:55.0317552Z',
        stoppedDateUtc: '2024-03-06T10:04:12.2432314Z',
        alreadyExecuted: true,
        sequenceId: 2,
      },
      {
        id: STEPID_4,
        type: 5,
        stepAttributeText: undefined,
        startedDateUtc: '2024-03-06T10:04:12.2432314Z',
        stoppedDateUtc: '2024-03-06T10:04:13.243188Z',
        alreadyExecuted: true,
        sequenceId: 3,
      },
      {
        id: STEPID_5,
        type: 0,
        stepAttributeText: STEP_ATTRIBUTE_TEXT_1,
        startedDateUtc: '2024-03-06T10:04:13.243188Z',
        stoppedDateUtc: null,
        alreadyExecuted: false,
        sequenceId: 4,
      },
    ],
    assignableWaypointName: ASSIGNABLE_WAYPOINT_NAME,
    failureComment: {
      missionFailureReasonId: null,
      missionFailureReasonComments: '',
      missionFailureMinutesToSolve: null,
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureMinutesForEmergencyProcess: null,
      missionFailureLocationId: null,
      missionFailureShiftGroupId: null,
      durationOfMissionInFailed: 0,
      missionFailureStartDateTime: '',
      missionFailureEndDateTime: '',
    },

    tourChainId: null,
    tourChainName: null,
  },
];

export class MissionMonitoringTestHelper {
  static getMissionTraceDtos(): MissionTraceDto[] {
    return objectHelper.cloneDeep(missionTraces);
  }

  static getMissionHistoryRows(
    _filter?: ArchiveGql.MissionTraceArchiveFilterInput | null,
    _order?: ArchiveGql.InputMaybe<ArchiveGql.SortEnumType>
  ): MissionHistoryRow[] {
    return objectHelper.cloneDeep(missionHistoryRows);
  }

  static getBaseMissionTraceDto(): MissionTraceDto {
    return objectHelper.cloneDeep(initMissionTraceDto);
  }

  static getMissionListInputModel(): MissionListInputModel {
    return objectHelper.cloneDeep(missionListInput);
  }

  static getMissionFailureReasons(): MissionFailureReasonDto[] {
    return [...missionFailureReasons];
  }

  static getMissionTraceHistoryViaOdata(params?: Params): ODataDto<ArchiveMissionHistoryResponse> {
    if (params) {
      // some kind of filtering
    }
    return { ...missionTraceHistoryOData };
  }

  static getMissionListInputStepStringModel(): MissionListInputStepStringModel {
    return mockMissionInput;
  }

  static getJobInstructionProcessTrace(): ProcessTrace {
    return objectHelper.cloneDeep(jobInstructionProcessTrace);
  }

  static getAdHocInstructionFromProcessChain(): ProcessTrace {
    return objectHelper.cloneDeep({
      ...jobInstructionProcessTrace,
      type: MissionTraceTriggerDisplayFilter.Manual,
      missionTraces: [
        {
          ...jobInstructionProcessTrace.missionTraces[0],
          trigger: MissionTraceTrigger.AdhocInstructionFromProcessChain,
        },
      ],
    });
  }

  static getAdHocStepProcessTrace(): ProcessTrace {
    return objectHelper.cloneDeep(adHocStepProcessTrace);
  }

  static getWaitingQueueProcessTrace(): ProcessTrace {
    return objectHelper.cloneDeep(waitingQueueProcessTrace);
  }

  static getProcessTraces(): ProcessTrace[] {
    return objectHelper.cloneDeep([jobInstructionProcessTrace, adHocStepProcessTrace]);
  }

  static getProcessChainTraceDtos(): ProcessChainTraceDto[] {
    return [...processChainTraces];
  }

  static getMissionTraces(): MissionTrace[] {
    return [...missionTraceModels];
  }

  static getMissionTracesForVehicleSelectors(): MissionTrace[] {
    return [...missionTraceModelsForVehicleSelectors];
  }

  static getBackendMissionTrace(): MissionTraceDto {
    return { ...dto };
  }

  static getProcessChainTraces(): ProcessChainTraceModel[] {
    return [...processChainTraceModels];
  }

  static getProcessChainTraceDtoAma(): ProcessChainTraceDto {
    return { ...processChainTraceDtoAma };
  }

  static getProcessChainTraceAma(): ProcessChainTraceModel {
    return { ...processChainTraceModelAma };
  }

  static getProcessChainTraceInterlock(): ProcessChainTraceModel {
    return { ...processChainTraceInterlock };
  }

  static getMission1(): MissionListInputModel {
    return { ...mission };
  }

  static getMission2(): MissionListInputModel {
    return { ...mission2 };
  }

  static getMission3(): MissionListInputModel {
    return { ...mission3 };
  }

  static getMission4(): MissionListInputModel {
    return { ...mission4 };
  }

  static getConvertedMissionList(): MissionListInputStepStringModel[] {
    return [...missionListConverted];
  }

  static getProcessChainTraceInterlockSingleMission(): ProcessChainTraceModel {
    return { ...processChainTraceNoInterlock };
  }

  static getNoInterLockMission(): MissionTrace {
    return { ...noInterlockMission };
  }

  static getInterlockMissions(): Mission[] {
    return [interlockRaisingMission, interlockWaitingMission];
  }

  static getSimpleMission(): Mission {
    return simpleMission;
  }

  static getShiftGroups(): ShiftGroupDto[] {
    return [...shiftGroups];
  }

  static getCommentLocations(): CommentSettingsDto[] {
    return [...commentLocations];
  }

  static getTuggerMissions(): TuggerTrainMissionDto[] {
    return [...tuggerMissions];
  }

  static getProcessChainGroupTraceDtos(): ProcessChainGroupTraceDto[] {
    return objectHelper.cloneDeep(processChainGroupTraceDtos);
  }

  static getProcessChainTraceInGroupDto1(): ProcessChainTraceInGroupDto {
    return objectHelper.cloneDeep(processChainTraceInGroupDto1);
  }

  static getProcessChainTraceInGroupDto2(): ProcessChainTraceInGroupDto {
    return objectHelper.cloneDeep(processChainTraceInGroupDto2);
  }

  static getProcessChainGroupTraceModel(): ProcessChainGroupTraceModel {
    return objectHelper.cloneDeep(processChainGroupTraceModel);
  }

  static getTourStepDtos(): TourStepDto[] {
    return objectHelper.cloneDeep(tourStepDtos);
  }

  static getTourStepUIModels(): TourStepUIModel[] {
    return objectHelper.cloneDeep(tourStepUIModels);
  }

  static getTours(): MissionTrace[] {
    return objectHelper.cloneDeep(tours);
  }

  static getAllActiveFailureMissionTraces(): FailureMissionTrace[] {
    return [...allActiveFailureMissionTraces];
  }

  static getAllActiveMissionList(): MissionListSignalr[] {
    return [...missionListItems];
  }

  static getAllNonFinalMissionList(): MissionListSignalr[] {
    return [...missionListItems.filter(s => !isMissionFinalStatus(s))];
  }

  static getCompletedMissionList(): MissionListSignalr[] {
    return [...missionListItems.filter(s => s.status === MissionStatus.Completed)];
  }

  static getAllActiveVdaMissionList(): MissionListSignalr[] {
    return [...vdaMissionListItems];
  }

  static getArchivedTours(): TourArchiveDto[] {
    return objectHelper.cloneDeep(archivedTours);
  }

  static getArchivedToursTrace(): MissionTrace[] {
    return archivedTours.map(archivedTour => {
      const tourSteps: TourStepDto[] = archivedTour.steps.map(step => ({
        id: step.id,

        type: step.type,
        stepAttributeText: step.stepAttributeText,
        startedDateUtc: step.startedDateUtc,
        stoppedDateUtc: step.stoppedDateUtc,
        sequenceId: step.sequenceId,
        alreadyExecuted: step.alreadyExecuted,
      }));
      return {
        ...archivedTour,
        id: archivedTour.id,
        missionName: archivedTour.name,
        stepTraces: [],
        missionStepNo: '',
        createdDateTime: archivedTour.createdDateUtc,
        completedDateTime: archivedTour.completedDateUtc ?? '',
        updatedDateTime: archivedTour.completedDateUtc ?? '',
        materialNumber: '',
        processChainName: archivedTour.tourConfigurationName,
        processChainId: archivedTour.tourConfigurationId ?? '',
        processChainTraceId: archivedTour.id,
        lateDeliveryAlarmTime: 0,
        isAlarmForLateDeliveryActive: false,
        maxExecutionTime: 0,
        isMaxExecutionTimeActive: false,
        provisioningTime: null,
        forecastedEndTime: null,
        hasManualSupport: false,
        assignableWaypointId: '',
        deliveryStatus: MissionDeliveryStatus.Normal,
        currentStepAttributeText: '',
        currentStepTypeFrontEnd: convertBackendStepTypeToFrontEndStepType(
          archivedTour.steps[archivedTour.currentStepSequenceNumber].type
        ),
        trigger: convertTourTriggerTypeToMissionTraceTrigger(archivedTour.trigger),

        tourSteps: mapTourStepDtosToModels(tourSteps),
        priorityLevel: MissionPriorityLevel.None,
        failureComment: archivedTour.failureComment,
        assignmentDelayEndDateTime: null,

        canBeContinuedFromStep: false,
        canBeReAssigned: false,
        canAbort: false,
        missionDisplayHighlightLevel: MissionDisplayHighlightLevel.None,
        errorHandlingAllowed: false,
        canRetry: false,
        assignmentConditions: [],
        stepCount: 0,
        isAssignedToVehicle: false,
        missionFormat: MissionFormat.VDA5050,
        loadType: LoadType.Glt1400X1250,
      };
    });
  }
}
