import { ZoneType } from 'core/dtos';
import { GuidString, MapItemType, Vector2D } from 'core/models';
import { DisplayObject, Graphics } from 'pixi.js';
import { GeometryHelper } from 'shared/helpers';
import { MapItemContainer } from '../map-item-container';

export class ZoneMapItemContainer extends MapItemContainer {
  constructor(dtoId: string, readonly masterZoneId: GuidString, readonly zoneType: ZoneType) {
    super(dtoId, MapItemType.Zone);
  }

  getZoneShape(): Vector2D[] {
    const graphic = this.children[0];

    return graphic instanceof Graphics
      ? GeometryHelper.mapXYArrayToVector(graphic.geometry.points)
      : [];
  }
}

export function isZoneMapItemContainer(
  item: DisplayObject | MapItemContainer
): item is ZoneMapItemContainer {
  return 'getZoneShape' in item;
}
