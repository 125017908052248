import { createAction, props } from '@ngrx/store';
import { LayoutDto, NodeDto, NodeOccupancyStatusChangeDto } from 'core/dtos';
import { GuidString } from 'core/models';

export enum GraphLayerTypes {
  LoadGraphLayers = '[Graph Layers] Load Graph Layers',
  LoadGraphLayersSuccess = '[Graph Layers] Load Graph Layers Success',
  LoadGraphLayersFailure = '[Graph Layers] Load Graph Layers Fail',

  UpdateGraphLayerNode = '[Graph Layers] Update Graph Layer Node',
  UpdateGraphLayerNodeSuccess = '[Graph Layers] Update Graph Layer Node Success',
  UpdateGraphLayerNodeFailure = '[Graph Layers] Update Graph Layer Node Fail',

  ResetGraphLayerActionStatus = '[Graph Layers] Reset Action Status',

  UpdateGraphLayerNodeOccupancyStatus = '[Graph Layers] Update Graph Layer Node Occupancy Status',
  UpdateGraphLayerNodeOccupancyStatusFailure = '[Graph Layers] Update Graph Layer Node Occupancy Status Failure',

  GetNodeBooking = '[Graph Layers] Get Node Booking',
  UnbookNode = '[Graph Layers] Unbook Node',
  UnbookNodeSuccess = '[Graph Layers] Unbook Node Success',
  UnbookNodeFailure = '[Graph Layers] Unbook Node Failure',
}

export const resetGraphLayerActionStatus = createAction(
  GraphLayerTypes.ResetGraphLayerActionStatus
);

export const loadGraphLayers = createAction(
  GraphLayerTypes.LoadGraphLayers,
  props<{ mapId: GuidString }>()
);

export const loadGraphLayerSuccess = createAction(
  GraphLayerTypes.LoadGraphLayersSuccess,
  props<{ graphLayer: LayoutDto }>()
);

export const loadGraphLayersFailure = createAction(
  GraphLayerTypes.LoadGraphLayersFailure,
  props<{ errorMessage: string }>()
);

export const updateGraphLayerNode = createAction(
  GraphLayerTypes.UpdateGraphLayerNode,
  props<{ node: NodeDto }>()
);

export const updateGraphLayerNodeSuccess = createAction(
  GraphLayerTypes.UpdateGraphLayerNodeSuccess,
  props<{ layout: LayoutDto }>()
);

export const updateGraphLayerNodeFailure = createAction(
  GraphLayerTypes.UpdateGraphLayerNodeFailure,
  props<{ errorMessage: string }>()
);

export const updateGraphLayerNodeOccupancyStatus = createAction(
  GraphLayerTypes.UpdateGraphLayerNodeOccupancyStatus,
  props<{ nodeOccupancyStatusMessage: NodeOccupancyStatusChangeDto }>()
);

export const updateGraphLayerNodeOccupancyStatusFailure = createAction(
  GraphLayerTypes.UpdateGraphLayerNodeOccupancyStatusFailure,
  props<{ errorMessage: string }>()
);

export const getNodeBooking = createAction(
  GraphLayerTypes.GetNodeBooking,
  props<{ nodeId: GuidString }>()
);

export const unbookNode = createAction(GraphLayerTypes.UnbookNode, props<{ nodeId: GuidString }>());

export const unbookNodeSuccess = createAction(
  GraphLayerTypes.UnbookNodeSuccess,
  props<{ nodeId: GuidString }>()
);

export const unbookNodeFailure = createAction(
  GraphLayerTypes.UnbookNodeFailure,
  props<{ errorMessage: string }>()
);
