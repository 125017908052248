<h5>{{ 'settings.ipstAlertNowSettings.ipstSettings' | translate }}</h5>

<div class="four-columns">
  <div class="ipst-switch-container">
    <ds-switch
      class="ipst-switch ps-3x"
      [ngModel]="ipstWorkingAreaSettingV2Dto?.enabled"
      [disabled]="true"
      data-cy="IpstWorkAreaSettingEnabledInput">
      {{ 'settings.ipst.workingAreaSettings.ipst' | translate }}
    </ds-switch>
    <ds-hint class="ipst-switch-hint">{{
      'settings.ipstAlertNowSettings.ipstSettingsHint' | translate
    }}</ds-hint>
  </div>
  <div class="labeled-static">
    <label for="akz">{{
      'settings.ipstAlertNowSettings.workingAreaSettings.akz' | translate
    }}</label>
    <div class="inputValue" id="akz" data-cy="IpstWorkAreaSettingAKZ">
      {{ ipstWorkingAreaSettingV2Dto?.akz | notApplicable }}
    </div>
  </div>
  <div class="labeled-static">
    <label for="language">{{
      'settings.ipstAlertNowSettings.workingAreaSettings.errorLanguage' | translate
    }}</label>
    <div class="inputValue" id="language" data-cy="IpstWorkAreaSettingLanguage">
      {{ ipstWorkingAreaSettingV2Dto?.errorLanguage | enumTranslation : 'ErrorLanguage' }}
    </div>
  </div>
  <div class="labeled-static">
    <label for="resolveIpstMessages">
      {{ 'settings.ipstAlertNowSettings.workingAreaSettings.resolveIpstMessages' | translate }}
    </label>
    <div
      id="resolveIpstMessages"
      *ngIf="canResolveMessages && ipstWorkingAreaSettingV2Dto?.enabled">
      <button
        ds-button
        variant="outline"
        data-cy="resolveIpstMessagesButton"
        ds-tooltip="{{
          'settings.ipstAlertNowSettings.workingAreaSettings.resolveAllWarning' | translate
        }}"
        [dsTooltipConfig]="{ width: '150px' }"
        (click)="onResolveAllMessages()"
        class="resolve-button">
        {{ 'settings.ipstAlertNowSettings.workingAreaSettings.resolve' | translate }}
      </button>
      <ds-hint class="last-resolved-hint">
        {{ ipstWorkingAreaSettingV2Dto?.lastMessageResolve }}
      </ds-hint>
    </div>
  </div>
</div>
<hr />
